import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Button, Card, CardBody, Col, Input, Row } from 'reactstrap';
import FeatherIcon from "feather-icons-react";
import TableContainer from './TableComponent';
import { withTranslation } from 'react-i18next';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import CreateLinkOffCanvas from './CreateLinkOffCanvas';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { StudentListGetRequest, studentDelete, studentGetRequest, studentsMetadataGetRequest } from 'store/student/action';

const Links = (props: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const studentMetadata = useSelector((state: ApplicationState) => state.student.studentsMetadata);
    const [search, setsearch] = useState<string>("");
    const [filterObject, setFilterObject] = useState<any>({});
    const [createOpen, setCreateOpen] = useState<boolean>(false)
    const [columns, setColumns] = useState<any>([])
    const [applications, setApplication] = useState([]);
    const [pageSize, setPageSize] = useState<number>(10)
    const [createLinkCanvas, setCreateLinkCanvas] = useState<boolean>(false);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null); 
    const [metaData, setMetaData] = useState<any>(studentMetadata);

    useEffect(() => {
        const filters = {pageSize: 100}
        dispatch(studentsMetadataGetRequest(filters));
        setMetaData(studentMetadata)
    }, [])

    useEffect(() => {
        setMetaData(studentMetadata);
    }, [studentMetadata]);
    
    const handleChange = (value: any) => {
        setsearch(value);
        const filters = { ...filterObject, search: value }
        setFilterObject(filters)
        
        
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {  
        }, 2000);

        setTypingTimeout(newTimeout);
        
    };
    return (
        <>
        <CreateLinkOffCanvas
          show={createLinkCanvas}
          onCloseClick={() => setCreateLinkCanvas(false)}
          dataFields={metaData}
          props={props} />
          <Row>
                <Col md={3} sm={12} xl={3} xxl={3} lg={3}>
                    <div className="search-box">
                        <Input
                            type="text"
                            size={14}
                            className="search"
                            placeholder={props.t("applications.search_for")}
                            onChange={(e) => handleChange(e.target.value)}
                            value={search}
                        />
                        {search == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleChange("")}></i>}
            
                    </div>
                </Col>
                <Col md={9} sm={9} xl={9} xxl={9} lg={9} className='gap-3 hstack justify-content-end'>
                <Button
                                        type="button"
                                        size='sm'
                                        className="py-2"
                                        color="primary"
                                        onClick={() => setCreateLinkCanvas(true)}
                                    >
                                        <FeatherIcon icon="link-2" className="icon-xs" />{" "}

                                        {props.t("student.create_link")}
                                    </Button>
                </Col>
                <Col md={12} sm={12} xl={12} xxl={12} lg={12}>
                    <Card>
                        <CardBody className="border border-dashed border-end-0 border-start-0 vstack gap-2">
                            <Row className='students-table'>
                                Table
                                {/* <TableContainer
                                    columns={columns && columns.length ? columns.filter((column: any) => column.show == true) : []}
                                    data={applications || []}
                                    customPageSize={pageSize}
                                    divClass="table-responsive table-card mb-1"
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light text-muted"
                                    SearchPlaceholder='Search...'
                                    isPageSizeChange={true}
                                    isBordered={true}
                                    handleEditClick={handleEditClick}
                                    handleLeadClick={handleLeadClick}
                                    setisDeleteOpen={setisDeleteOpen}
                                    setIsMultiDeleteButton={setIsMultiDeleteButton}
                                    setselectedcount={setselectedcount}
                                    pageChanged={pageChanged}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    pagesList={pagesList}
                                    onChangePageSize={onChangePageSize}
                                    pageSize={pageSize}
                                    setpreview={setpreview}
                                    setNotesModals={setNotesModals}
                                    props={props}
                                /> */}
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default withTranslation()(Links)
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

//import images
import university from '../../../src/assets/images/dtsimg/svg/university.svg';
import OverviewTab from './OverviewTab';
import { useParams } from 'react-router';
import { useFetcher } from 'react-router-dom';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { InvoicesStudentsList, channelGetRequest, channelMetadataGetRequest } from 'store/channels/action';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { ToastContainer } from 'react-toastify';
import Finance from './Finanace/Finance';
import { propertyListGetRequest } from 'store/properties/action';
import moment from 'moment';
import UserAvatar from 'Components/Common/UserAvtar/UserAvatar';

const Section = () => {
    const { id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const ChannelDetail = useSelector((state: ApplicationState) => state.channels.channel)
    const metaData = useSelector((state: ApplicationState) => state.properties.list)
    console.log("Section metaData", metaData)
    const [activeTab, setActiveTab] = useState<string>('1');
    const [filterObject, setfilterObject] = useState<any>({
        pageSize:100
    })
    const [info, setinfo] = useState<any>({});
    const toggleTab = (tab:any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    useEffect(() => {
        dispatch(channelGetRequest(id))
        dispatch(propertyListGetRequest("channels",filterObject))
        dispatch(InvoicesStudentsList(id))
    },[]) 

    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            //Error
            //JSON is not okay
            return false;
        }
    }
    
    useEffect(() => {
        let details = ChannelDetail && ChannelDetail.valuesJson && isJson(ChannelDetail.valuesJson) ? JSON.parse(ChannelDetail.valuesJson) : {}
        setinfo(details)
        console.log("details", details)
    },[ChannelDetail]) 
    console.log("ChannelDetail", ChannelDetail)
    return (
        <React.Fragment>
             <ToastContainer />
            <Row>
                <Col lg={12}>
                    <Card className="mt-n4 mx-n4">
                        <div className="bg-warning-subtle">
                            <CardBody className="pb-0 px-4">
                                <Row className="mb-3">
                                    <div className="col-md">
                                        <Row className="align-items-center g-3">
                                            <div className="col-md-auto">
                                                <div className="avatar-md">
                                                    <div className="avatar-title bg-white rounded-circle">
                                                        <UserAvatar img={info && info.img} firstName={info?.name || info?.channel_name} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md">
                                                <div>
                                                    <h4 className="fw-bold">{info?.channel_name}</h4>
                                                    <div className="hstack gap-3 flex-wrap">
                                                        <div><i className="ri-map-pin-fill align-bottom me-1 fs-16"></i>{info?.city}</div>
                                                        <div className="vr"></div>
                                                        {ChannelDetail?.createdAt ? <div>Create Date : <span className="fw-medium">{moment(ChannelDetail?.createdAt).format('ll')}</span></div> : null}
                                                        <div className="vr"></div>
                                                        {ChannelDetail?.updatedAt ?<div>Updated Date : <span className="fw-medium">{moment(ChannelDetail?.updatedAt).format('ll')}</span></div> : null}
                                                       </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                </Row>
                                <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' }, "fw-bold")}
                                            onClick={() => { toggleTab('1'); }}
                                            href="#">
                                            Overview
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' }, "fw-bold")}
                                            onClick={() => { toggleTab('2'); }}
                                            href="#">
                                            Finance
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardBody>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <TabContent activeTab={activeTab} className="text-muted">
                    <TabPane tabId="1">
                        <OverviewTab info={info} metaData={metaData} ChannelDetail={ChannelDetail} />
                    </TabPane>
                    <TabPane tabId="2">
                        <Finance />
                    </TabPane>
                    {/* <TabPane tabId="3">
                        <ActivitiesTab />
                    </TabPane>
                    <TabPane tabId="4">
                        <TeamTab />
                    </TabPane> */}
                    </TabContent>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Section;
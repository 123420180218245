import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

//import images
import university from '../../../src/assets/images/dtsimg/svg/university.svg';
import OverviewTab from './OverviewTab';
import { useParams } from 'react-router';
import { useFetcher } from 'react-router-dom';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { companyGetRequest } from 'store/companies/action';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { ToastContainer } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import Banks from './Banks/Banks';
import UserAvatar from 'Components/Common/UserAvtar/UserAvatar';


const Section = (props: any) => {
    const { id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const SourceDetail = useSelector((state: ApplicationState) => state.companies.company)
    const metaData = useSelector((state: ApplicationState) => state.companies.companiesMetadata)
    console.log("SourceDetail", SourceDetail, "metaData", metaData)
    const [activeTab, setActiveTab] = useState<string>('1');
    const [info, setinfo] = useState<any>({});
    const toggleTab = (tab:any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    useEffect(() => {
        dispatch(companyGetRequest(id))
    },[]) 

    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            //Error
            //JSON is not okay
            return false;
        }
    }
    
    useEffect(() => {
        let details = SourceDetail && SourceDetail.valuesJson && isJson(SourceDetail.valuesJson)  ? JSON.parse(SourceDetail.valuesJson) : {}
        setinfo(details)
    },[SourceDetail]) 
    console.log("metaData", metaData, "info", info)
    return (
        <React.Fragment>
             <ToastContainer />
            <Row>
                <Col lg={12}>
                    <Card className="mt-n4 mx-n4">
                        <div className="bg-warning-subtle">
                            <CardBody className="pb-0 px-4">
                                <Row className="mb-3">
                                    <div className="col-md">
                                        <Row className="align-items-center g-3">
                                            <div className="col-md-auto">
                                                <div className="avatar-md">
                                                    <div className="avatar-title bg-white rounded-circle">
                                                        <UserAvatar img={info && info.img} firstName={info?.name} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md">
                                                <div>
                                                    <h4 className="fw-bold">{info?.name}</h4>
                                                    <div className="hstack gap-3 flex-wrap">
                                                        <div><i className="ri-map-pin-fill align-bottom me-1 fs-16"></i>{info?.city}</div>
                                                        <div className="vr"></div>
                                                        <div>Create Date : <span className="fw-medium">15 Sep, 2021</span></div>
                                                        <div className="vr"></div>
                                                        {/* <div>Updated Date : <span className="fw-medium">29 Dec, 2021</span></div>
                                                        <div className="vr"></div> */}
                                                        {/* <div className="badge rounded-pill bg-info fs-12">New</div>
                                                        <div className="badge rounded-pill bg-danger fs-12">High</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                </Row>
                                <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' }, "fw-bold")}
                                            onClick={() => { toggleTab('1'); }}
                                            href="#">
                                            Overview
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' }, "fw-bold")}
                                            onClick={() => { toggleTab('2'); }}
                                            href="#">
                                            Bank
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardBody>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <TabContent activeTab={activeTab} className="text-muted">
                    <TabPane tabId="1">
                        <OverviewTab info={info} metaData={metaData} props={props} />
                    </TabPane>
                    <TabPane tabId="2">
                        <Banks props={props} />
                    </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default withTranslation()(Section);
import React, { useEffect, useState } from "react";
import { Card, CardBody, Form, Button, Col } from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";

const DTSFullForm = ({ dataFields, btnAction, btn_text, data }: any) => {
  const filterFields: any = [];
  const isJson = (str: any) => {
    try {
      let options = JSON.parse(str);
      return options;
    } catch (e) {
      return false;
    }
  };
  dataFields &&
    dataFields.length &&
    dataFields.map((field: any) => {
      let item =
        field.valuesJson && isJson(field.valuesJson)
          ? JSON.parse(field.valuesJson)
          : {};
      if (field.valuesJson) return filterFields.push(item);
    });
  const [formValues, setFormValues] = useState<any>({});
  const [disabled, setdisabled] = useState<any>(true);
  const [formerror, setError] = useState<any>([]);

  const onChange = (option: any, key: any) => {
    if (typeof option == "object") {
      setFormValues({ ...formValues, [key]: option.value });
    } else setFormValues({ ...formValues, [key]: option });
  };

  const allMandatoryFieldsFilled = () => {
    for (const field of filterFields) {
      if (
        field &&
        field.validate &&
        field.validate.required &&
        !formValues[field.key]
      ) {
        return false;
      }
    }
    return true;
  };
  useEffect(() => {
    if (data && data.valuesJson) {
      let values =
        data.valuesJson && isJson(data.valuesJson)
          ? JSON.parse(data.valuesJson)
          : {};
      setFormValues(values);
    }
  }, [data]);

  const formedisabled = Object.keys(formerror).length === 0;
  return (
    <Form action="" className="d-flex flex-column justify-content-end h-100">
      <div className="live-preview hstack gap-2">
        {filterFields && filterFields.length
          ? filterFields.map((field: any) => {
              // if(field.quickCreate)
              return (
                <Col xl={6} lg={6} md={6}>
                  <FormBuilder
                    placeholder={field.placeholder}
                    name={field.key}
                    onChange={(e: any) => onChange(e, field.key)}
                    options={(field.values && field.values.reverse()) || []}
                    label={field.label}
                    type={field.type}
                    value={formValues[field.key]
                    }
                    isEditState={true}
                    actionButtons={false}
                    defaultValue={formValues[field.key]}
                    Editable={true}
                    rest={field}
                    invalid={true}
                    formValues={formValues}
                    dataFields={filterFields}
                    setdisabled={setdisabled}
                    setError={setError}
                    formerror={formerror}
                  />
                </Col>
              );
            })
          : null}
      </div>
      <div className="offcanvas-footer bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">
        <Button
          className="btn btn-success w-100"
          disabled={!allMandatoryFieldsFilled() || disabled || !formedisabled}
          onClick={() => btnAction(formValues)}
        >
          {btn_text}
        </Button>
      </div>
    </Form>
  );
};

export default DTSFullForm;

import React from "react";
import { Col, Row } from "reactstrap";

interface SignUpState {
    email: string;
    otp: string;
    full_name: string;
    phone_number: string;
    company_name: string;
    company_website: string;
    password: string;
    people_count: string;
}

interface Step6Props {
    toggleArrowTab: () => void;
    inputs: SignUpState;
    setInputs: React.Dispatch<React.SetStateAction<SignUpState>>;
    onCreate: () => void;
    t: (key: string) => string;
    OnUpdate: (object: any) => void;
    loading: boolean;
}

const Step6: React.FC<Step6Props> = ({ toggleArrowTab, inputs, setInputs, onCreate, t, OnUpdate, loading }: Step6Props) => {
    const onClickUpdate = (value: string) => {
        const attributes = [
            {
                "key": "no_of_employees",
                "value": value
            }
        ]
        OnUpdate(attributes)
    }
    return (
        <div>
            <Row className="hstack gap-3 flex-column justify-content-center">
                <Col lg={12}>
                    <div className="hstack fs-32 fw-bold justify-content-center">
                        <h5 className="fw-bold">{t("signup.how_many_people_works_at_your_company")}</h5>
                    </div>
                </Col>
                <Col lg={7}>
                    <Row className="hstack gap-3 hstack justify-content-center">
                        <Col
                            lg={3}
                            onClick={() => onClickUpdate("Just Me")}
                            onChange={(e) => setInputs({ ...inputs, people_count: "Just Me" })}
                            className="fs-16 fw-medium pt-4 pb-4 border border-info border-2 hstack justify-content-center h-25"
                            style={{ cursor: "pointer" }}
                        >
                            {t("signup.just_me")}
                        </Col>
                        <Col
                            lg={3}
                            onClick={() => onClickUpdate("2 to 5")}
                            onChange={(e) => setInputs({ ...inputs, people_count: "2 to 5" })}
                            className="fs-16 fw-medium pt-4 pb-4 border border-info border-2 hstack justify-content-center h-25"
                            style={{ cursor: "pointer" }}
                        >
                            {t("signup.2_to_5")}
                        </Col>
                        <Col
                            lg={3}
                            onClick={() => onClickUpdate("6 to 10")}
                            onChange={(e) => setInputs({ ...inputs, people_count: "6 to 10" })}
                            className="fs-16 fw-medium pt-4 pb-4 border border-info border-2 hstack justify-content-center h-25"
                            style={{ cursor: "pointer" }}
                        >
                            {t("signup.6_to_10")}
                        </Col>
                        <Col
                            lg={3}
                            onClick={() => onClickUpdate("11 to 25")}
                            onChange={(e) => setInputs({ ...inputs, people_count: "11 to 25" })}
                            className="fs-16 fw-medium pt-4 pb-4 border border-info border-2 hstack justify-content-center h-25"
                            style={{ cursor: "pointer" }}
                        >
                            {t("signup.11_to_25")}
                        </Col>
                        <Col
                            lg={3}
                            onClick={() => onClickUpdate("26 to 50")}
                            onChange={(e) => setInputs({ ...inputs, people_count: "26 to 50" })}
                            className="fs-16 fw-medium pt-4 pb-4 border border-info border-2 hstack justify-content-center h-25"
                            style={{ cursor: "pointer" }}
                        >
                            {t("signup.26_to_50")}
                        </Col>
                        <Col
                            lg={3}
                            onClick={() => onClickUpdate("51 to 200")}
                            onChange={(e) => setInputs({ ...inputs, people_count: "51 to 200" })}
                            className="fs-16 fw-medium pt-4 pb-4 border border-info border-2 hstack justify-content-center h-25"
                            style={{ cursor: "pointer" }}
                        >
                            {t("signup.51_to_200")}
                        </Col>
                        <Col
                            lg={3}
                            onClick={() => onClickUpdate("201 to 1,000")}
                            onChange={(e) => setInputs({ ...inputs, people_count: "201 to 1,000" })}
                            className="fs-16 fw-medium pt-4 pb-4 border border-info border-2 hstack justify-content-center h-25"
                            style={{ cursor: "pointer" }}
                        >
                            {t("signup.201_to_1000")}
                        </Col>
                        <Col
                            lg={3}
                            onClick={() => onClickUpdate("1,001 to 10,000")}
                            onChange={(e) => setInputs({ ...inputs, people_count: "1,001 to 10,000" })}
                            className="fs-16 fw-medium pt-4 pb-4 border border-info border-2 hstack justify-content-center h-25"
                            style={{ cursor: "pointer" }}
                        >
                            {t("signup.1001_to_10000")}
                        </Col>
                        <Col
                            lg={3}
                            onClick={() => onClickUpdate("10,001 or more")}
                            onChange={(e) => setInputs({ ...inputs, people_count: "10,001 or more" })}
                            className="fs-16 fw-medium pt-4 pb-4 border border-info border-2 hstack justify-content-center h-25"
                            style={{ cursor: "pointer" }}
                        >
                            {t("signup.10001_or_more")}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default Step6;

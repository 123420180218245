import {UserActionTypes, User} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";

const { api } = config;

const DTS_API_DOMAIN = api.DTS_API_DOMAIN

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

// export const authTokenGetRequest: AppThunk = (token, handleSuccess) => {
//     return async (dispatch: Dispatch): Promise<void> => {
//         dispatch({
//             type: AuthActionTypes.GET_REQUEST
//         });
//         const response = await fetch(`${USER_API_DOMAIN}api/user/token/auth/${token}/`);
//         const body = await response.json();
//         const {response: response_1, body: body_1} = {response, body};
//         if (!response_1.ok) {
//             dispatch({
//                 type: AuthActionTypes.GET_ERROR,
//                 payload: body_1
//             });
//         } else {
//             dispatch({
//                 type: AuthActionTypes.GET_SUCCESS,
//                 payload: body_1
//             });
//             handleSuccess(body_1);
//         }
//     };
// };

export const userGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: UserActionTypes.GET_USER_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_DOMAIN}/users/${id}`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: UserActionTypes.GET_USER_ERROR,
                    payload: body
                });
                handleError && handleError(body);
            } else {
                dispatch({
                    type: UserActionTypes.GET_USER_SUCCESS,
                    payload: body
                });
                handleSuccess && handleSuccess(body);
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
            // Handle parsing error here
        }
    };
};

const createQueryLink = (filterObject: any, page: any) => {
    const keys = Object.keys(filterObject);
    const values = Object.values(filterObject);
    let query = `${DTS_API_DOMAIN}/users`;
    for (let i = 0; i < keys.length; i++) {
        if (values[i]) query += `${keys[i]}=${values[i]}&`;
    }
    return query;
};
export const usersListGetRequest: AppThunk = (queryParams, page, pageSize, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: UserActionTypes.GET_USERS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const response = await fetch(`${DTS_API_DOMAIN}/users?${queryParams}`, {
            method: "GET",
            headers: headers
        });
    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: UserActionTypes.GET_USERS_ERROR,
                payload: body_1
            });
            // handleError(body_1);
        } else {
            dispatch({
                type: UserActionTypes.GET_USERS_SUCCESS,
                payload: body_1
            });
            // handleSuccess("Users fetched successfully");
        }
    };
};
export const userUpdateRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        console.log("user ID",id)
        const { auth: { token } } = getState();
        dispatch({
            type: UserActionTypes.PUT_USER_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_DOMAIN}/users/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: UserActionTypes.PUT_USER_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: UserActionTypes.PUT_USER_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};
export const userDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();

        dispatch({
            type: UserActionTypes.DELETE_USER_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_DOMAIN}/users/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: UserActionTypes.DELETE_USER_ERROR,
                payload: body_1
            });
            handleError(body_1);
        } else {
            dispatch({
                type: UserActionTypes.DELETE_USER_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};
export const resendOtp: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: UserActionTypes.POST_INVITE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_DOMAIN}/users/resendOtp`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: UserActionTypes.POST_INVITE_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: UserActionTypes.POST_INVITE_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};
export const userInviteRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: UserActionTypes.POST_INVITE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_DOMAIN}/users/invite`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: UserActionTypes.POST_INVITE_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: UserActionTypes.POST_INVITE_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const userRoleUpdateRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (id,data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        console.log("Action user update")    
        const { auth: { token } } = getState();
        dispatch({
            type: UserActionTypes.PUT_ROLE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_DOMAIN}/users/${id}`,
            {
                method: "PUT",
                headers:headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: UserActionTypes.PUT_ROLE_ERROR
            });
            handleError && handleError();
        } else {
            console.log("Handle success")
            dispatch({
                type: UserActionTypes.PUT_ROLE_SUCCESS,
                payload: []
            });
            handleSuccess && handleSuccess(body_1);
        }
    };
};
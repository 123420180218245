import React, { useEffect, useState } from "react";
import {
  Col,
  Input,
  Label,
  Row,
  Form,
  Card,
  Button
} from "reactstrap";
import Select from "react-select";

import FeatherIcon from 'feather-icons-react';

const Text = ({filterJson, filterObject, toggleArrowTab, setNewFilter, setFilterCount,filterCount}: any) => {  
    const onAndClick = (operator: string, index: number, item: any,) => {
        toggleArrowTab(2)
        setNewFilter({operator: operator, index: index, item: item,})
    }

    const rendercomponent = (subGroup: any, index: any, islastitem?: any, itemgroup?:any) => {
        return <>{
        islastitem ? (<Card>
                    <Card key={index} className="hstack p-2 border border-opacity-50">
                    <p className="mb-1"><span className="fw-bold fs-14 text-info">{subGroup.propertylabel}</span>&nbsp;
                    <span className="fw-normal fs-16">{subGroup.conditionLabel}</span>&nbsp;
                    <span className="fw-bold fs-14 text-info">{subGroup.value}</span></p>
                    </Card>
                    {
                        islastitem ?  <Button
                        color="secondary"
                        className="btn-sm mt-1"
                        style={{width: 'fit-content'}}
                        outline={true}
                        onClick={() => onAndClick("AND", index, itemgroup)}
                    >
                        AND
                    </Button>: null
                    }                   
                    </Card>): <Card key={index} className="hstack p-2 border border-opacity-50">
                        <p className="mb-1"><span className="fw-bold fs-14 text-info">{subGroup.propertylabel}</span>&nbsp;
                    <span className="fw-normal fs-16">{subGroup.conditionLabel}</span>&nbsp;
                    <span className="fw-bold fs-14 text-info">{subGroup.value}</span></p>
                    </Card>
        }</>
    }
    const renderFilterGroup = (group: any, itemindex: any) => {
        if (group.length) {
            return group.map((item: any, index: number) => {                
                if (item.OR) {
                    return (
                        <div key={index}>
                            {item.OR.map((subGroup: any, subIndex: number) => (
                                <div key={subIndex}>
                                    {renderFilterGroup(subGroup, subIndex)}
                                    {subIndex !== item.OR.length - 1 && <span className="fw-semibold fs-14">or</span>}
                                </div>
                            ))}
                        </div>
                    );
                } else if (item.AND) {
                    return (
                        <div className="p-2 border border-opacity-50 mb-3" key={index}>
                            {item.AND.map((subGroup: any, subIndex: number) => {
                                let islastitem = item.AND.length == subIndex+1 ? true : false;
                                return <div key={subIndex}>
                                    {rendercomponent(subGroup, subIndex,islastitem, item.AND)}
                                    {item.AND.length > 1 && subIndex !== item.AND.length - 1 && <span  className="fw-semibold fs-14">and</span>}
                                </div>
                            })}
                        </div>
                    );
                } else {
                    return (
                        <div>
                                {rendercomponent(group, itemindex, false, group)}                               
                        </div>
                    );
                }
            });
        }
        return <>{rendercomponent(group, itemindex, true, group)}</>
    };
    

    console.log("filterJson", filterJson, "filterCount", filterCount)
    return (
        <div> 
            <div className="fw-bold fs-18">Advanced Filters({filterCount})</div>
          {filterJson && filterJson.length ? (
                <div>
                    {filterJson.map((item: any, groupIndex: any) => {
                        return <Card key={groupIndex} className="p-2 border border-opacity-50">
                            {renderFilterGroup(item,groupIndex)}                        
                                <Button
                                    color="secondary"
                                    className="btn-sm mt-1"
                                    style={{width: 'fit-content'}}
                                    outline={true}
                                    onClick={() => onAndClick("OR", groupIndex,filterJson)}
                                >
                                    OR
                                </Button>
                        </Card>})}
                </div>
            ) : <div className="mt-2">
            <Button                            
                className="btn btn-success w-auto"
                onClick={() => toggleArrowTab(2)}
            >
                Add Filters
            </Button>
        </div>}
            
        </div>
    );
};

export default Text;

import React, { useState } from "react";
import { TextractClient, AnalyzeDocumentCommand } from "@aws-sdk/client-textract";
import { Container, Row } from "reactstrap";
import { Buffer } from "buffer";

import config from "../../../config";

const { AWS_API } = config.api;

globalThis.Buffer = Buffer;

const Textract = () => {
	const [src, setSrc] = useState("");
	const [data, setData] = useState({});
	const [file, setFile] = useState(null);

	const onSelectFile = (e: any) => {
		if (!e.target.files || e.target.files.length === 0) {
			return;
		}
		const file = e.target.files[0];
		setFile(file)
	};

	// const onRunOCR = async () => {
	// 	const client = new TextractClient({
	// 		region: "us-east-1",
	// 		credentials: {
	// 			accessKeyId: "AKIAS5ETAUZXJPCGFK7G",
	// 			secretAccessKey: "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",
	// 		},
	// 	});

	// 	// Convert image to byte Uint8Array base 64
	// 	const blob = Buffer.from(src.split(",")[1], "base64");

	// 	const params: any = {
	// 		Document: {
	// 			Bytes: blob,
	// 		},
	// 		FeatureTypes: ["FORMS"],
	// 	};

	// 	const command = new AnalyzeDocumentCommand(params);
	// 	try {
	// 		const data = await client.send(command);
	// 		const keyValuePairs = extractKeyValuePairs(data);
	// 		setData(keyValuePairs);
	// 	} catch (error) {
	// 		console.log("Error:", error);
	// 	}
	// };

	// const extractKeyValuePairs = (data: any) => {
	// 	const keyMap: any = {};
	// 	const valueMap: any = {};
	// 	const blockMap: any = {};

	// 	data.Blocks.forEach((block: any) => {
	// 		blockMap[block.Id] = block;
	// 		if (block.BlockType === "KEY_VALUE_SET") {
	// 			if (block.EntityTypes.includes("KEY")) {
	// 				keyMap[block.Id] = block;
	// 			} else {
	// 				valueMap[block.Id] = block;
	// 			}
	// 		}
	// 	});

	// 	const keyValues: any = {};
	// 	Object.values(keyMap).forEach((keyBlock: any) => {
	// 		const valueBlock = findValueBlock(keyBlock, valueMap, blockMap);
	// 		const key: any = getText(keyBlock, blockMap);
	// 		const value = getText(valueBlock, blockMap);
	// 		keyValues[key] = value;
	// 	});

	// 	return keyValues;
	// };

	// const findValueBlock = (keyBlock: any, valueMap: any, blockMap: any) => {
	// 	let valueBlock = null;
	// 	keyBlock.Relationships.forEach((relationship: any) => {
	// 		if (relationship.Type === "VALUE") {
	// 			relationship.Ids.forEach((id: any) => {
	// 				if (valueMap[id]) {
	// 					valueBlock = valueMap[id];
	// 				}
	// 			});
	// 		}
	// 	});
	// 	return valueBlock;
	// };

	// const getText = (block: any, blockMap: any) => {
	// 	let text = "";
	// 	if (block && block.Relationships) {
	// 		block.Relationships.forEach((relationship: any) => {
	// 			if (relationship.Type === "CHILD") {
	// 				relationship.Ids.forEach((id: any) => {
	// 					const childBlock = blockMap[id];
	// 					if (childBlock.BlockType === "WORD") {
	// 						text += `${childBlock.Text} `;
	// 					}
	// 				});
	// 			}
	// 		});
	// 	}
	// 	return text.trim();
	// };

	const onRunOCR = async () => {
		if (!file) {
		  alert('Please select a file to upload.');
		  return;
		}
	
		const formData = new FormData();
		formData.append('file', file);
	
		try {
		  const response = await fetch(`${AWS_API}/quicksight/upload`, {
			method: 'POST',
			body: formData,
		  });
	
		  if (!response.ok) {
			throw new Error('Failed to upload file.');
		  }
	
		  const data = await response.json();
		  setData(data);
		} catch (error) {
		  console.error('Error uploading file:', error);
		  setData({});
		  alert('Error uploading file. Please try again.');
		}
	  };
	

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<Row>
						<div className="App">
							<div>
								<input
									className="inputfile"
									id="file"
									type="file"
									name="file"
									onChange={onSelectFile}
								/>
							</div>
							<div>
								<button onClick={onRunOCR} style={{ margin: "10px" }}>
									Run OCR
								</button>

								<div style={{ border: "1px" }}>
									{Object.entries(data).map(([key, value]: any, index) => (
										<div key={index}>
											<strong>{key}</strong> {value}
											<br />
										</div>
									))}
								</div>
							</div>
						</div>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Textract;


import moment from "moment";
import React, { useEffect, useState } from "react";
import {
    Col,
    Input,
    Label,
    Row,
    Form,
    Button
} from "reactstrap";

const BasicInfo = ({ setCreateInputs, createInputs, setFieldJson, fieldJson }: any) => {
    const [mincounter, setMinCounter] = useState<any>(1)
    const [maxcounter, setMaxCounter] = useState<any>(30)
    const [dateRule, setDateRule] = useState<any>()
    const [rules, setRules] = useState<any>({
        show_informs: false,
        required: false,
        unique: false,
        minLimit: {restrictminLimit: false, value: 1},
        maxLimit: {restrictmaxLimit: false, value: 30},
        restrictspecialcharacter: false,
        restrictalpha: false
})

    const onChanged = (target: any, name: any) => {        
        if(name == "hidden") {
            setRules({...rules, [name]: !target.checked})
            let newfield = {...fieldJson, hidden: !target.checked }
            let newrules ={...rules, [name]: !target.checked}
            setCreateInputs({...createInputs, rules: newrules})
            setFieldJson(newfield)
        }
        else if(name == "quickCreate") {
            setRules({...rules, [name]: target.checked})
            let newfield = {...fieldJson, quickCreate: target.checked }
            let newrules ={...rules, [name]: target.checked}
            setCreateInputs({...createInputs, rules: newrules})
            setFieldJson(newfield)
        }
        else if(name == "unique") {
            setRules({...rules, [name]: target.checked})
            let newvalidate = fieldJson.validate
            let newuniquevalidation = {...newvalidate, unique: target.checked}
            console.log("newuniquevalidation", newuniquevalidation)
            let newfield = {...fieldJson, validate: newuniquevalidation }
            console.log("newfield", newfield)
            let newrules ={...rules, unique: target.checked}
            setCreateInputs({...createInputs, rules: newrules})
            setFieldJson(newfield)
        }
        else if(name == "required") {
            setRules({...rules, [name]: target.checked})
            let newvalidate = fieldJson.validate
            let newuniquevalidation = {...newvalidate, required: target.checked}
            console.log("newuniquevalidation", newuniquevalidation)
            let newfield = {...fieldJson, validate: newuniquevalidation }
            console.log("newfield", newfield)
            let newrules ={...rules, required: target.checked}
            setCreateInputs({...createInputs, rules: newrules})
            setFieldJson(newfield)
        }
        else if(name == "filter") {
            setRules({...rules, [name]: target.checked})
            let newfield = {...fieldJson, filter: target.checked }
            let newrules ={...rules, [name]: target.checked}
            setCreateInputs({...createInputs, rules: newrules})
            setFieldJson(newfield)
        }
        else if(name == "tablecolumn") {
            setRules({...rules, [name]: target.checked})
            let newfield = {...fieldJson, tablecolumn: target.checked }
            let newrules ={...rules, [name]: target.checked}
            setCreateInputs({...createInputs, rules: newrules})
            setFieldJson(newfield)
        }
        else if(name == "dateRule") {
            setDateRule(target)
            if(target == 'futureonly')
            {
                setRules({...rules, [name]: target.checked})
                let newvalidate = fieldJson.validate
                let newuniquevalidation = {...newvalidate, dateAllowed: target}
                console.log("newuniquevalidation", newuniquevalidation)
                let newfield = {...fieldJson, validate: newuniquevalidation }
                console.log("newfield", newfield)
                let newrules ={...rules, dateAllowed: target}
                setCreateInputs({...createInputs, rules: newrules})
                setFieldJson(newfield)
            }
            else if(target == 'pastonly')
            {
                setRules({...rules, [name]: target.checked})
                let newvalidate = fieldJson.validate
                let newuniquevalidation = {...newvalidate, dateAllowed: target}
                console.log("newuniquevalidation", newuniquevalidation)
                let newfield = {...fieldJson, validate: newuniquevalidation }
                console.log("newfield", newfield)
                let newrules ={...rules, dateAllowed: target}
                setCreateInputs({...createInputs, rules: newrules})
                setFieldJson(newfield)
            }
            else if(target == 'any')
            {                
                setRules({...rules, [name]: target.checked})
                let newvalidate = fieldJson.validate
                delete newvalidate.dateAllowed;
                console.log("newvalidate", newvalidate)
                let newfield = {...fieldJson, validate: newvalidate }
                setFieldJson(newfield)
            }
        }
        else {
            setRules({...rules, [name]: target.checked})
            let newvalidate = fieldJson.validate
            let newuniquevalidation = {...newvalidate, [name]: target.checked}
            console.log("newuniquevalidation", newuniquevalidation)
            let newfield = {...fieldJson, validate: newuniquevalidation }
            console.log("newfield", newfield)
            let newrules ={...rules, [name]: target.checked}
            setCreateInputs({...createInputs, rules: newrules})
            setFieldJson(newfield)
        }
    }

    const handleChange = (target: any) => {
        const [keyword, value] = target;
        setRules((prevRules: any) => {
          if (keyword === 'maxLimit') {
            return {
              ...prevRules,
              minLimit: {
                ...prevRules.minLimit,
                restrictminLimit: value,
              },
            };
          }
          if (keyword === 'maxLimit') {
            return {
              ...prevRules,
              maxLimit: {
                ...prevRules.maxLimit,
                restrictmaxLimit: value,
              },
            };
          }
          return prevRules;
        });

        setRules({...rules, [keyword]: target.checked})
            let newvalidate = fieldJson.validate
            let newuniquevalidation = {...newvalidate, [keyword]: target.checked}
            let newfield = {...fieldJson, validate: newuniquevalidation }
            let newrules ={...rules, [keyword]: target.checked}
            setCreateInputs({...createInputs, rules: newrules})
            setFieldJson(newfield)
      };

    const onChange = (newvalue :any , name: any) => {
        let value = parseInt(newvalue)
        let newvalidate = fieldJson.validate
        let newuniquevalidation = {...newvalidate, [name]: value}
        let newfield = {...fieldJson, validate: newuniquevalidation }
        let newrules ={...rules, [name]: value}
        setCreateInputs({...createInputs, rules: newrules})
        setFieldJson(newfield)
        if(name == "minLength") setMinCounter(value)
        else setMaxCounter(value)
    }
    

    
    useEffect(() => {
        setCreateInputs({...createInputs, rules})
    }, [rules]); 
    console.log("fieldJson", fieldJson)
    
    return (
        <div>
            <Row className="hstack gap-3 flex-column justify-content-center">
                <Col lg={12} className="d-flex gap-2 fs-24 fw-bold flex-column justify-content-center text-center">
                    {createInputs.label}
                </Col>
                <Col lg={12} className="d-flex gap-2 flex-column justify-content-center">
                    <div className="fs-18 mb-1 fw-bold">
                        Set Field rules
                    </div>
                    <div className="fs-16 mb-1 fw-SemiBold">
                        Field visibility
                    </div>
                    <div className="hstack align-content-center gap-1 mb-1">
                        <Input className="mb-1 form-check-input" type="checkbox" name="show-informs" onClick={(e) => onChanged(e.target,'hidden')}/>
                        <Label className="fs-16 mb-0 fw-medium">Show in forms, pop-up forms and bots</Label>
                    </div>
                    <div className="hstack align-content-center gap-1 mb-1">
                        <Input className="mb-1 form-check-input" type="checkbox" name="show-informs" onClick={(e) => onChanged(e.target,'quickCreate')}/>
                        <Label className="fs-16 mb-0 fw-medium">Show in quick create forms</Label>
                    </div>
                    <div className="hstack align-content-center gap-1 mb-1">
                        <Input className="mb-1 form-check-input" type="checkbox" name="show-informs" onClick={(e) => onChanged(e.target,'tablecolumn')}/>
                        <Label className="fs-16 mb-0 fw-medium">Show in table</Label>
                    </div>
                    <div className="hstack align-content-center gap-1 mb-1">
                        <Input className="mb-1 form-check-input" type="checkbox" name="show-informs" onClick={(e) => onChanged(e.target,'filter')}/>
                        <Label className="fs-16 mb-0 fw-medium">Add in Filters</Label>
                    </div>
                    <div className="fs-16 mb-1 fw-SemiBold">
                        Validation rules
                        <p className="fs-14 mb-0 fw-medium">Specify what values are allowed for this property. These rules apply when creating, editing, and importing records with the CRM</p>
                    </div>
                    <div className="hstack align-content-center gap-1 mb-1"  onClick={(e) => onChanged(e.target,'required')}>
                        <Input className="mb-1 form-check-input" type="checkbox" />
                        <Label className="fs-16 mb-0 fw-normal">Set as mandatory field value for this property </Label>
                    </div>
                
                {
                    createInputs.fieldtype == 'textfield' || createInputs.fieldtype == 'textarea' || createInputs.fieldtype == 'phone' || createInputs.fieldtype == 'number' || createInputs.fieldtype == 'email' ?
                    <>
                        <div className="hstack align-content-center gap-1 mb-1"  onClick={(e) => onChanged(e.target,'unique')}>
                            <Input className="mb-1 form-check-input" type="checkbox" />
                            <Label className="fs-16 mb-0 fw-normal">Require unique values for this property </Label>
                        </div>
                        <div className="hstack align-content-center gap-1 mb-1">
                            <Input className="mb-1 form-check-input" type="checkbox" />
                            <Label className="fs-16 mb-0 fw-normal">Set min character limit</Label>
                        </div>
                        <div className="input-step step-info" style={{marginLeft: '5px', width: 200}}>
                            <button
                                type="button"
                                className="minus"
                                onClick={() => {
                                    setMinCounter(mincounter - 1);
                                    onChange(mincounter - 1,"minLength")
                                }}
                            >
                                –
                            </button>
                            <Input
                                type="number"
                                value={mincounter}
                                className="w-100"
                                min="0"
                                max="100"
                                onChange={(e) => onChange(e.target.value,"minLength")}
                                // readOnly
                            />
                            <button
                                type="button"
                                className="plus"
                                onClick={() => {
                                    setMinCounter(mincounter + 1);                                    
                                    onChange(mincounter + 1,"minLength")
                                }}
                            >
                                +
                            </button>
                        </div>
                        <div className="hstack align-content-center gap-1 mt-3">
                            <Input className="mb-1 form-check-input" type="checkbox" />
                            <Label className="fs-16 mb-0 fw-normal">Set max character limit</Label>
                        </div>
                        <div className="input-step step-info" style={{marginLeft: '5px', width: 200}}>
                            <button
                                type="button"
                                className="minus"
                                onClick={() => {
                                    setMaxCounter(maxcounter - 1);
                                    onChange(maxcounter - 1, "maxLength");
                                }}
                            >
                                –
                            </button>
                            <Input
                                type="number"
                                value={maxcounter}
                                className="w-100"
                                min="30"
                                max="1000"
                                onChange={(e) => onChange(e.target.value, "maxLength")}
                                // readOnly
                            />
                            <button
                                type="button"
                                className="plus"
                                onClick={() => {
                                    setMaxCounter(maxcounter + 1);
                                    onChange(maxcounter + 1, "maxLength");
                                }}
                            >
                                +
                            </button>
                        </div>
                    </> : null
                }
                {
                    createInputs.fieldtype == 'datetime' ?
                    <>
                        <div>
                            <div className="fs-15 mb-1 fw-bold">
                                What dates are allowed for this property.
                            </div>
                            <div className="fs-14 mb-1 fw-SemiBold">
                                These rules are based on your account's time zone (UTC -04:00 EDT).
                            </div>
                        </div>
                        <div className="hstack align-content-center gap-1 mb-1">
                            <Input className="form-check-input" type="radio" name="formradiocolor1" id="any" checked={dateRule == "any"} onChange={(e) => onChanged('any',"dateRule")} />
                            <Label className="form-check-label" htmlFor="is">
                                Any Date
                            </Label>
                        </div>
                        <div className="hstack align-content-center gap-1 mb-1">
                            <Input className="form-check-input" type="radio" name="formradiocolor1" id="futureonly" checked={dateRule == "futureonly"} onChange={(e) => onChanged('futureonly',"dateRule")} />
                            <Label className="form-check-label" htmlFor="is">
                                Future Date only
                            </Label>
                        </div>
                        <div className="hstack align-content-center gap-1 mb-1">
                            <Input className="form-check-input" type="radio" name="formradiocolor1" id="pastonly" checked={dateRule == "pastonly"} onChange={(e) => onChanged('pastonly',"dateRule")} />
                            <Label className="form-check-label" htmlFor="is">
                                Past Date only
                            </Label>
                        </div>
                    </> : null
                }
                </Col>
                
            </Row>
        </div>
    );
};

export default BasicInfo;

import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, ButtonGroup, Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Table, UncontrolledDropdown } from 'reactstrap';
import { propertyGetRequest, propertyUpdateRequest } from 'store/properties/action';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CreateStageDrawer from './CreateStageDrawer';
import StageUpdateDrawer from './StageUpdateDrawer';
import { DNDRow } from './DNDRow';

const PROPERTY_ID = 'e14ef4dc-9b33-4664-97f8-b669ea2d9eac';
let TIMER: any = null;

// Utility function to check if a string is a valid JSON and parse it
const parseJson = (str: string) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return null;
    }
};

const checkLifeCycleKeysExists = (parsedData: any) => {
    // Ensure lifecycle exists
    if (!parsedData.lifecycle) {
        parsedData.lifecycle = {};
    }

    // Ensure automation exists inside lifecycle
    if (!parsedData.lifecycle.automation) {
        parsedData.lifecycle.automation = {};
    }

    // Ensure created exists inside automation
    if (!parsedData.lifecycle.automation.created) {
        parsedData.lifecycle.automation.created = {};
    }

    // Ensure default exists inside created
    if (!parsedData.lifecycle.automation.created.default) {
        parsedData.lifecycle.automation.created.default = {};
    }

    // Ensure application exists inside automation
    if (!parsedData.lifecycle.automation.application) {
        parsedData.lifecycle.automation.application = {};
    }

    // Ensure default exists inside application
    if (!parsedData.lifecycle.automation.application.default) {
        parsedData.lifecycle.automation.application.default = {};
    }

    // Ensure enrolled exists inside automation
    if (!parsedData.lifecycle.automation.enrolled) {
        parsedData.lifecycle.automation.enrolled = {};
    }

    // Ensure default exists inside enrolled
    if (!parsedData.lifecycle.automation.enrolled.default) {
        parsedData.lifecycle.automation.enrolled.default = {};
    }
    return parsedData
}

const Student = ({ model, ...props }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const property = useSelector((state: ApplicationState) => state.properties.property);

    const [lifeCycleData, setLifeCycleData]: any = useState(null);
    const [automationEnabled, setAutomationEnabled] = useState(false);
    const [createdStageEnabled, setCreatedStageEnabled] = useState(false);
    const [selectedCreatedStage, setSelectedCreatedStage] = useState<any>(null);
    const [applicationStageEnabled, setApplicationStageEnabled] = useState(false);
    const [selectedApplicationStage, setSelectedApplicationStage] = useState<any>(null);
    const [enrolledStageEnabled, setEnrolledStageEnabled] = useState(false);
    const [selectedEnrolledStage, setSelectedEnrolledStage] = useState<any>(null);
    const [isCreatingStage, setIsCreatingStage] = useState(false);
    const [stages, setStages] = useState<any[]>([]);
    const [selectedStageToUpdate, setSelectedStageToUpdate] = useState<any>(null);

    // Fetch the property data when the component mounts
    useEffect(() => {
        dispatch(propertyGetRequest(PROPERTY_ID));
    }, [dispatch]);

    // Parse and set lifecycle and stages data when property data is available
    useEffect(() => {
        if (property?.valuesJson) {
            const parsedData = parseJson(property.valuesJson);
            setLifeCycleData(checkLifeCycleKeysExists(parsedData))
            setStages(parsedData?.values || []);
            setAutomationEnabled(parsedData?.lifecycle?.automation?.status || false);
            setCreatedStageEnabled(parsedData?.lifecycle?.automation?.created?.status || false);
            setSelectedCreatedStage(parsedData?.lifecycle?.automation?.created?.default?.stage || '');
            setApplicationStageEnabled(parsedData?.lifecycle?.automation?.application?.status || false);
            setSelectedApplicationStage(parsedData?.lifecycle?.automation?.application?.default?.stage || '');
            setEnrolledStageEnabled(parsedData?.lifecycle?.automation?.enrolled?.status || false);
            setSelectedEnrolledStage(parsedData?.lifecycle?.automation?.enrolled?.default?.stage || '');
        }
    }, [property]);

    const updateProperty = (updatedLifecycleData: any, timeOut=0) => {
        if(TIMER) {
            clearTimeout(TIMER)
        }
        TIMER = setTimeout(() => {
            const { createdAt, updatedAt, deletedAt, createdBy, id, ...remainingProperties } = property;
            const updatedPropertyData = {
                ...remainingProperties,
                valuesJson: JSON.stringify(updatedLifecycleData),
                model: "students"
            };
            const handleSuccess = () => {
                dispatch(propertyGetRequest(PROPERTY_ID));
            };
            const handleFailure = () => { /* Handle failure if necessary */ };
            dispatch(propertyUpdateRequest(PROPERTY_ID, updatedPropertyData, handleSuccess, handleFailure));
        }, timeOut)
    }

    const onAutomationUpdate = () => {
        setAutomationEnabled(prev => {
            lifeCycleData.lifecycle.automation.status = !prev;
            updateProperty(lifeCycleData)
            return !prev
        })
    }

    const onCreateStageUpdate = () => {
        setCreatedStageEnabled(prev => {
            lifeCycleData.lifecycle.automation.created.status = !prev;
            updateProperty(lifeCycleData)
            return !prev
        })
    }

    const onSelectedCreatedStage = (stage: any) => {
        setSelectedCreatedStage(stage)
        lifeCycleData.lifecycle.automation.created.default.stage = stage;
        updateProperty(lifeCycleData)
    }

    const onApplicationStageUpdate = () => {
        setApplicationStageEnabled(prev => {
            lifeCycleData.lifecycle.automation.application.status = !prev;
            updateProperty(lifeCycleData)
            return !prev
        })
    }

    const onSelectedApplicationStage = (stage: any) => {
        setSelectedApplicationStage(stage)
        lifeCycleData.lifecycle.automation.application.default.stage = stage;
        updateProperty(lifeCycleData)
    }

    const onEnrolledStageUpdate = () => {
        setEnrolledStageEnabled(prev => {
            lifeCycleData.lifecycle.automation.enrolled.status = !prev;
            updateProperty(lifeCycleData)
            return !prev
        })
    }

    const onSelectedEnrolledStage = (stage: any) => {
        setSelectedEnrolledStage(stage)
        lifeCycleData.lifecycle.automation.enrolled.default.stage = stage;
        updateProperty(lifeCycleData)
    }

    // Function to handle moving stages in the list
    const moveStage = useCallback((dragIndex: number, hoverIndex: number) => {
        setStages((prevStages) => {
            const updatedStages = [...prevStages];
            const [draggedStage] = updatedStages.splice(dragIndex, 1);
            updatedStages.splice(hoverIndex, 0, draggedStage);
            lifeCycleData.values = updatedStages
            updateProperty(lifeCycleData, 1200)
            return updatedStages;
        });
    }, [lifeCycleData]);

    const onAddStage = (stage: any) => {
        setStages((prevStages) => {
            const updatedStages = [...prevStages, stage]
            lifeCycleData.values = updatedStages
            updateProperty(lifeCycleData)
            return updatedStages
        });
    }

    const onUpdateStage = (oldStage: any, newStage: any) => {
        const updatedStages = stages.map((stage: any) => {
            if (stage.value === oldStage.value) {
                return newStage
            } else {
                return stage
            }
        })
        lifeCycleData.values = updatedStages
        updateProperty(lifeCycleData)
        setStages(updatedStages);
    }

    return (
        <React.Fragment>
            <Card>
                <CardBody className="border border-dashed border-end-0 border-start-0 vstack gap-2">
                    <p className="pb-3 border-bottom border-light-subtle">
                        {props.t('properties.lifecycle_stage.student.heading_line')}
                    </p>
                    <div className="d-flex flex-column gap-4 mb-4">
                        {/* Automation Toggle */}
                        <div className="form-check form-switch form-switch-lg" dir="ltr">
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                checked={automationEnabled}
                                onChange={() => onAutomationUpdate()}
                            />
                            <div className="d-flex gap-2">
                                <Label className="form-check-label fw-bold fs-5">
                                    {props.t('properties.lifecycle_stage.student.automation')}
                                </Label>
                                <span className="text-muted small mark">
                                    <abbr title={automationEnabled ? "Enabled" : "Disabled"}>
                                        (Enable and Disable the below automation)
                                    </abbr>
                                </span>
                            </div>
                        </div>

                        {/* Automation Configuration */}
                        <div className={`d-flex w-100 flex-column gap-4 ps-4 ${automationEnabled ? '' : 'opacity-75'}`}>
                            {/* Created Stage Configuration */}
                            <div>
                                <div className="form-check form-switch form-switch-md" dir="ltr">
                                    <Input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={createdStageEnabled}
                                        onChange={() => onCreateStageUpdate()}
                                        disabled={!automationEnabled}
                                    />
                                    <Label className="form-check-label fw-bold">
                                        {props.t('properties.lifecycle_stage.student.toggle_create_label')}
                                    </Label>
                                </div>
                                <p className="text-muted ps-5">
                                    {props.t('properties.lifecycle_stage.student.toggle_create_description')}
                                </p>
                                <div className="d-flex flex-column ps-5">
                                    <Label className={automationEnabled ? "fw-bold" : "fw-bold text-muted"}>
                                        {props.t('properties.lifecycle_stage.student.dropdown_label')}
                                    </Label>
                                    <ButtonGroup>
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                tag="button"
                                                className="btn btn-light text-left d-flex align-items-center justify-content-between"
                                                style={{ width: '200px' }}
                                                disabled={!automationEnabled || !createdStageEnabled}
                                            >
                                                {selectedCreatedStage?.label} <i className="mdi mdi-chevron-down"></i>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdownmenu-primary">
                                                {stages.map((stage, index) => (
                                                    <DropdownItem
                                                        key={index}
                                                        onClick={() => onSelectedCreatedStage(stage)}
                                                    >
                                                        {stage?.label}
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </ButtonGroup>
                                </div>
                            </div>

                            {/* Application Stage Configuration */}
                            <div>
                                <div className="form-check form-switch form-switch-md" dir="ltr">
                                    <Input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={applicationStageEnabled}
                                        onChange={() => onApplicationStageUpdate()}
                                        disabled={!automationEnabled}
                                    />
                                    <Label className="form-check-label fw-bold">
                                        {props.t('properties.lifecycle_stage.student.toggle_application_create_label')}
                                    </Label>
                                </div>
                                <p className="text-muted ps-5">
                                    {props.t('properties.lifecycle_stage.student.toggle_application_create_description')}
                                </p>
                                <div className="d-flex flex-column ps-5">
                                    <Label className={automationEnabled ? "fw-bold" : "fw-bold text-muted"}>
                                        {props.t('properties.lifecycle_stage.student.dropdown_label')}
                                    </Label>
                                    <ButtonGroup>
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                tag="button"
                                                className="btn btn-light text-left d-flex align-items-center justify-content-between"
                                                style={{ width: '200px' }}
                                                disabled={!automationEnabled || !applicationStageEnabled}
                                            >
                                                {selectedApplicationStage?.label} <i className="mdi mdi-chevron-down"></i>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdownmenu-primary">
                                                {stages.map((stage, index) => (
                                                    <DropdownItem
                                                        key={index}
                                                        onClick={() => onSelectedApplicationStage(stage)}
                                                    >
                                                        {stage?.label}
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </ButtonGroup>
                                </div>
                            </div>

                            {/* Enrolled Stage Configuration */}
                            <div>
                                <div className="form-check form-switch form-switch-md" dir="ltr">
                                    <Input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={enrolledStageEnabled}
                                        onChange={() => onEnrolledStageUpdate()}
                                        disabled={!automationEnabled}
                                    />
                                    <Label className="form-check-label fw-bold">
                                        {props.t('properties.lifecycle_stage.student.toggle_application_enrolled_label')}
                                    </Label>
                                </div>
                                <p className="text-muted ps-5">
                                    {props.t('properties.lifecycle_stage.student.toggle_application_enrolled_description')}
                                </p>
                                <div className="d-flex flex-column ps-5">
                                    <Label className={automationEnabled ? "fw-bold" : "fw-bold text-muted"}>
                                        {props.t('properties.lifecycle_stage.student.dropdown_label')}
                                    </Label>
                                    <ButtonGroup>
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                tag="button"
                                                className="btn btn-light text-left d-flex align-items-center justify-content-between"
                                                style={{ width: '200px' }}
                                                disabled={!automationEnabled || !enrolledStageEnabled}
                                            >
                                                {selectedEnrolledStage?.label} <i className="mdi mdi-chevron-down"></i>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdownmenu-primary">
                                                {stages.map((stage, index) => (
                                                    <DropdownItem
                                                        key={index}
                                                        onClick={() => onSelectedEnrolledStage(stage)}
                                                    >
                                                        {stage?.label}
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>

            <Card className="mt-4">
                <CardBody className="border border-dashed border-end-0 border-start-0 vstack gap-2">
                    <h5>
                        {props.t('properties.lifecycle_stage.student.configuration')}
                    </h5>
                    <p>{props.t('properties.lifecycle_stage.student.heading_line')}</p>
                    {/* Stage Table */}
                    <div className="table-responsive mt-3">
                        <Table className="align-middle table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>{props.t('properties.lifecycle_stage.student.configuration_table.stage_name')}</th>
                                    <th>{props.t('properties.lifecycle_stage.student.configuration_table.roles')}</th>
                                    <th>{props.t('properties.lifecycle_stage.student.configuration_table.about_view')}</th>
                                </tr>
                            </thead>
                            <tbody id="stage-list">
                                <DndProvider backend={HTML5Backend}>
                                    {
                                        stages.map((value: any, valueIndex: number) => {
                                            return (
                                                <DNDRow
                                                    key={value.id}
                                                    index={valueIndex}
                                                    id={value.id}
                                                    value={value}
                                                    moveRow={moveStage}
                                                    onUpdateClick={() => setSelectedStageToUpdate(value)}
                                                />
                                            )
                                        })
                                    }
                                </DndProvider>
                            </tbody>
                            <tfoot className="table-light">
                                <tr>
                                    <td>
                                        <Button
                                            onClick={() => setIsCreatingStage(true)}
                                            color="primary"
                                            className="btn-label btn-soft-primary">
                                            <i className="ri-play-list-add-line label-icon align-middle fs-16 me-2"></i>
                                            {props.t("properties.lifecycle_stage.student.add_stage")}
                                        </Button>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </Table>
                    </div>
                </CardBody>
            </Card>

            {/* Stage Creation Drawer */}
            {isCreatingStage ? <CreateStageDrawer stages={stages} show={isCreatingStage} onCloseClick={setIsCreatingStage} onAddStage={onAddStage} /> : null}

            {/* Stage Update Drawer */}
            {selectedStageToUpdate ? <StageUpdateDrawer stages={stages} stage={selectedStageToUpdate} onCloseClick={setSelectedStageToUpdate} onUpdateStage={onUpdateStage} /> : null}

        </React.Fragment>
    );
};

export default withTranslation()(Student);

import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Nav,
	NavLink,
	Row,
	TabContent,
	TabPane,
	Button,
	Label,
} from "reactstrap";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { GroupListForStudentForm } from "store/properties/action";
import { userGetRequest } from "store/auth/action";
import Form from "Components/Common/FormBuilder/Form";
import ReviewForm from "Components/Common/FormBuilder/UpdateForm";
import {
	studentCreateRequest,
	studentExternalApplication,
	StudentListGetRequest,
} from "store/student/action";
import { toast } from "react-toastify";
import SimpleBar from "simplebar-react";
import { Link, useParams , useSearchParams } from "react-router-dom";
import logoLight from "../../assets/images/dtsimg/svg/zilter_logo_blue.svg";
import AddEntry from "./AddEntry";

const UploadDocuments = (props: any) => {
	const { id } = useParams();
	const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
	const me = useSelector((state: ApplicationState) => state.user.user);
	const groups = useSelector(
		(state: ApplicationState) => state.properties.groupsForm
	);
	const [completedSteps, setCompletedSteps] = useState<any>([]);
	const [activeVerticalTab, setActiveVerticalTab] = useState(0);
	const [formData, setFormData] = useState<{ [key: number]: any }>({});
	const [entryAdded, setEntryAdded] = useState(false);
	const [statusMetaData, setStatusMetaData] = useState<any>([]);
    const [allstepData, setallstepData] = useState<any>({});
	const [steps, setsteps] = useState<any>([])

	const isJson = (str: any) => {
		try {
			return JSON.parse(str);
		} catch (e) {
			return false;
		}
	};

	useEffect(() => {
		dispatch(userGetRequest());
	}, [dispatch]);

	useEffect(() => {
		dispatch(GroupListForStudentForm("students", id));
	}, []);

	useEffect(() => {
		let steps: any = [];
		let allfields: any= [];
		let count: any = 0;
		if(groups && groups.length) {
			groups.map((item: any, index: any) => {
				if(item.model !== "students") return
				if(item.groupTitle == 'Lead Information' || item.groupTitle == 'Previous Education' || item.groupTitle == 'English & Entrance Test')
					 {
						const parsedFields = item.studentsMetadata.map((meta: any) => ({
							...meta,
							valuesJson: isJson(meta.valuesJson) ? meta.valuesJson : "{}",
						}));
						let newFields = [...allfields, ...parsedFields]
						allfields = newFields;
						let fieldsData =  {
							label: item.groupTitle,
							tab: count + 1,
							fields: parsedFields,
						};
						count = count + 1;
						steps.push(fieldsData)
					}
			})
			
			let nameColumnIndex = steps.findIndex((group: any) => group.label === "Lead Information");        
            if (nameColumnIndex !== -1) {
                const nameColumn = steps.splice(nameColumnIndex, 1)[0];
				steps.splice(0, 0, nameColumn);
            }
			nameColumnIndex = steps.findIndex((group: any) => group.label === "Previous Education");
            if (nameColumnIndex !== -1) {
                const nameColumn = steps.splice(nameColumnIndex, 1)[0]; 
				steps.splice(1, 0, nameColumn);
            }
			nameColumnIndex = steps.findIndex((group: any) => group.label === "English & ENtrance Test");
            if (nameColumnIndex !== -1) {
                const nameColumn = steps.splice(nameColumnIndex, 1)[0]; 
				steps.splice(2, 0, nameColumn);
            }
			const finalstep = {
					label: "Review and Submit",
					tab: count + 1,
					fields: allfields,
				}

			steps.push(finalstep)
			setsteps(steps)
		}
	}, [groups]);

	const toggleVerticalTab = (tab: any) => {
		setActiveVerticalTab(tab);
		if (!completedSteps.includes(tab)) {
			setCompletedSteps([...completedSteps, tab]);
		}
	};

	useEffect(() => {
		const filters = { pageSize: 100 };
		// dispatch(studentsMetadataGetRequest(filters));
	}, []);

	// console.log("activeVerticalTab", activeVerticalTab, "steps", steps);

    console.log("allstepData", allstepData)
	return (
		<React.Fragment>
			{/* <div className="page-content"> */}
			<Container fluid-content>
				<div className="navbar-brand-box horizontal-logo">
					<Link to="/" className="logo logo-dark">
						<span className="logo-sm">
							<img src={logoLight} alt="" height="30" />
						</span>
						<span className="logo-lg">
							<img src={logoLight} alt="" height="30" />
						</span>
					</Link>

					<Link to="/" className="logo logo-light">
						<span className="logo-sm">
							<img src={logoLight} alt="" height="30" />
						</span>
						<span className="logo-lg">
							<img src={logoLight} alt="" height="30" />
						</span>
					</Link>
				</div>
				<Row className="justify-content-center">
					<Col xxl={12} xl={9} md={9} sm={12}>
						<Card>
							<CardHeader>
								<h4 className="card-title mb-0 fw-bold fs-16">{props.t("student.student_form")}</h4>
							</CardHeader>
							<CardBody className="form-steps">
								<form className="vertical-navs-step">
									<Row className="gy-5">
										<Col lg={3}>
											<Nav className="flex-column custom-nav nav-pills pt-3">
												{steps.map(( item : any, tab: any) => (
													<NavLink
														key={tab}
														href="#"
														className={classnames({
															active: activeVerticalTab === tab,
															done: completedSteps.includes(tab),
														})}
														// onClick={() => toggleVerticalTab(tab)}
													>
														<div>
															{item.label}
														</div>
														
													</NavLink>
												))}
											</Nav>
										</Col>
										<Col lg={9}>
											<div>
												<TabContent activeTab={activeVerticalTab}>
													{steps.map((item : any, tab: any) => (
														<TabPane tabId={tab} key={tab}>
															<Label className="fs-15 fw-bolder text-center w-100">{item.label}</Label>
															<AddEntry allstepData={allstepData} setallstepData={setallstepData} dataFields={item.fields} toggleVerticalTab={toggleVerticalTab} activeVerticalTab={activeVerticalTab} steps={steps}  />
														</TabPane>
													))}
												</TabContent>
											</div>
										</Col>
									</Row>
								</form>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	);
};

export default withTranslation()(UploadDocuments);


import React, { useEffect, useState } from "react";
import { Card, CardBody, Form, Button, Col } from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import moment from "moment";
import { toast } from "react-toastify";
import { studentExternalApplication } from "store/student/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

const DTSFullForm = ({ onCloseClick, dataFields, btn_text, data, md, lg, sm, xl, xs, BtnClassName,tenantId, isUpdate, preformValues}: any) => {
	
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch(); 
	const [filterFields, setfilterFields] = useState<any>([]);
	const isJson = (str: any) => {
		try {
			let options = JSON.parse(str);
			return options;
		} catch (e) {
			return false;
		}
	};

	useEffect(() => {
		let fields: any= [];
		dataFields &&
		dataFields.length &&
		dataFields.map((field: any) => {
			let item = field ? field : {};
			if (field) return fields.push(field);
		});
		setfilterFields(fields)
	}, [dataFields])
	

	const [formValues, setFormValues] = useState<any>({});
	const [fieldValues, SetFieldValues] = useState<any>({});
	const [disabled, setdisabled] = useState<any>(true);
	const [formerror, setError] = useState<any>([]);


	const onChange = (option: any, key: any) => {
		if (typeof option == "object") {
			setFormValues({ ...formValues, [key]: option.value });
		} else setFormValues({ ...formValues, [key]: option });
	};

	const btnAction = (formValues: any) => {
		setdisabled(true)
        const handleSuccess = (body: any) => {
			window.location.reload(); 
			let newValues: any = {}
			Object.keys(formValues).forEach(key => {
				if(data[key]) {
					newValues[key] = data[key];
				}
				else {
					newValues[key] ="";
				}
			  });
			  setFormValues(newValues);
            const message = () => toast(`Entry Added Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
			setdisabled(false)
        }
        const handleError = () => {

        }
        dispatch(studentExternalApplication(tenantId,formValues, handleSuccess ,handleError))
    }
	
	useEffect(() => {

	}, [disabled])
	console.log("formValues", formValues)

	const allMandatoryFieldsFilled = () => {
		for (const field of filterFields) {
			if (
				field &&
				field.validate &&
				field.validate.required &&
				!formValues[field.key]
			) {
				return false;
			}
		}
		return true;
	};

	useEffect(() => {
		if (data) {
			setFormValues(data);
			SetFieldValues(data);
		}
	}, [data]);
	const formdisabled = Object.keys(formerror).length === 0;
	return (
		<Form action="" className="d-flex flex-column justify-content-center h-100">
			<div className="live-preview hstack flex-wrap">
				{filterFields && filterFields.length
					? filterFields.map((field: any) => {
						console.log("formValues[field.key]", formValues[field.key], "field.key", field.key, "fieldValues[field.key]", fieldValues[field.key])
						if(field.hidden) return
						return (
							<Col xl={xl} lg={lg} md={md} sm={sm} xs={xs} className="align-self-start">
								<FormBuilder
									placeholder={field.placeholder}
									name={field.key}
									onChange={(e: any) => onChange(e, field.key)}
									options={(field.values && field.values.reverse()) || []}
									label={field.label}
									type={field.type}
									value={formValues[field.key] ? formValues[field.key] : ""}
									defaultValue={formValues[field.key] ? formValues[field.key] : ""}
									resetValue={fieldValues[field.key]}
									isEditState={true}
									actionButtons={isUpdate}
									Editable={true}
									rest={field}
									invalid={true}
									formValues={formValues}
									dataFields={filterFields}
									setdisabled={setdisabled}
									setError={setError}
									formerror={formerror}
									isTextract={true}
								/>
							</Col>
						);
					})
					: null}
			</div>
			<div className="offcanvas-footer bg-opacity-10 border-top p-3 justify-content-center hstack gap-2">
				<Button
					className={BtnClassName ? `btn btn-success ${BtnClassName}` : "btn btn-success w-100"}
					disabled={isUpdate ? false : !allMandatoryFieldsFilled() || disabled || !formdisabled}
					onClick={() => btnAction(formValues)}
				>
					{btn_text}
				</Button>
			</div>
		</Form>
	);

};

export default DTSFullForm;

import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Card, CardBody, Nav, NavItem, NavLink, Offcanvas, OffcanvasBody, OffcanvasHeader, TabContent, TabPane } from "reactstrap";
import BasicInfo from "../editstep/BasicInfo";
import FieldType from "../editstep/FieldType";
import Rules from "../editstep/Rules";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { propertyCreateRequest, propertyListGetRequest, propertyUpdateRequest } from '../../../store/properties/action';
import { useDispatch } from "react-redux";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { ToastContainer, toast } from "react-toastify";

const EditPropertyModal = ({props,id, record,model, show, onCloseClick,setEditInputs,  EditInputs, filterObject, pagination}: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();   
    const [activeArrowTab, setactiveArrowTab] = useState(1);
    const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
    const [disabled, setdisabled] = useState<boolean>(false);
    const [fieldJson, setFieldJson] = useState<any>(null);

    const toggleArrowTab = (tab: any) => {
        if (activeArrowTab !== tab) {
            var modifiedSteps = [...passedarrowSteps, tab];

            if (tab >= 1 && tab <= 3) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    }
    const nextClicked = () => {
        toggleArrowTab(activeArrowTab + 1)
     }
     const backClicked = () => {
        toggleArrowTab(activeArrowTab - 1)
     }

     
    const UpdateProperty = () => {
        const data = {
            "valuesJson": JSON.stringify(EditInputs),
            "model": EditInputs.objectType,
            "groupId": EditInputs.groupId ? EditInputs.groupId : "",
            "permissionsJson":EditInputs.permissionsJson && EditInputs.permissionsJson.permissions ? JSON.stringify(EditInputs.permissionsJson) : null
          }
          const handleSuccess = () => {
            dispatch(propertyListGetRequest(EditInputs.objectType, filterObject,pagination.page ))
            const message = () => toast(`Property updated successfully...!`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            toggleArrowTab(1)
            onCloseClick()
            setEditInputs({})
          }
          const handleFailure = () => {
            
          }
          dispatch(propertyUpdateRequest(id,data, handleSuccess, handleFailure ))
    }

     useEffect(() => {
            switch(activeArrowTab) {
                case 1: setdisabled(!EditInputs.label || !EditInputs.objectType)
                        break;
                case 2: setdisabled(!EditInputs.type)
                        break;
            } 
        
      }, [EditInputs]);   
      useEffect(() => {
            switch(activeArrowTab) {
                case 1: setdisabled(!EditInputs.label || !EditInputs.objectType)
                        break;
                case 2: setdisabled(!EditInputs.type)
                        break;
            }   
      }, [activeArrowTab]);   
      console.log(EditInputs,"EditInputs")
        return (
        <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasWithBothOptions"
            toggle={onCloseClick}
            backdrop={false}
            className={EditInputs.fieldtype == "multi_checkboxes" || EditInputs.fieldtype == "dropdown_select" || EditInputs == "radio_select" ? "w-75" : "w-50"}
        ><ToastContainer />
            <OffcanvasHeader className="bg-info bg-gradient p-3 offcanvas-header-dark" id="offcanvasWithBothOptions" toggle={onCloseClick}>
                <span className="m-0 me-2 text-white">{props.t("properties.update_property")}</span>
            </OffcanvasHeader>
                <OffcanvasBody id="offcanvasWithBothOptions">
                    <Card>
                        <CardBody>
                            <div className="step-arrow-nav mb-4">
                                <Nav
                                    className="nav-pills custom-nav nav-justified"
                                    role="tablist"
                                >
                                    <NavItem>
                                    <NavLink
                                        href="#"
                                        id="basic-info"
                                        className={classnames({
                                        active: activeArrowTab === 1,
                                        done: activeArrowTab <= 3 && activeArrowTab > 1,
                                        })}
                                    >
                                        Basic info
                                    </NavLink>
                                    </NavItem>
                                    <NavItem>
                                    <NavLink
                                        href="#"
                                        id="field-type"
                                        className={classnames({
                                        active: activeArrowTab === 2,
                                        done: activeArrowTab <= 4 && activeArrowTab > 2,
                                        })}
                                    >
                                        Field Type
                                    </NavLink>
                                    </NavItem>
                                    <NavItem>
                                    <NavLink
                                        href="#"
                                        id="rules"
                                        className={classnames({
                                        active: activeArrowTab === 3,
                                        done: activeArrowTab <= 5 && activeArrowTab > 3,
                                        })}
                                    >
                                        Rules
                                    </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                            <TabContent activeTab={activeArrowTab} className="mt-4">
                                <TabPane id="basic-info" tabId={1}>
                               { activeArrowTab == 1 && <BasicInfo
                                        setEditInputs={setEditInputs}
                                        EditInputs={EditInputs}
                                        activeArrowTab={activeArrowTab}
                                    />}
                                </TabPane>
                                <TabPane  id="field-type" tabId={2}>
                                { activeArrowTab == 2 && <FieldType
                                        setEditInputs={setEditInputs}
                                        EditInputs={EditInputs}
                                        fieldJson={fieldJson}
                                        setFieldJson={setFieldJson}
                                    />}
                                </TabPane>
                                <TabPane  id="rules" tabId={3}>
                                { activeArrowTab == 3 &&  <Rules
                                        setEditInputs={setEditInputs}
                                        EditInputs={EditInputs}
                                        fieldJson={fieldJson}
                                        setFieldJson={setFieldJson}
                                    />}
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </OffcanvasBody>
                <div className="offcanvas-footer bg-info bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">
                    <div className="hstack gap-2">
                        { activeArrowTab > 1 && activeArrowTab < 4 ?
                            <Button
                                color="info"
                                className="w-auto"
                                onClick={() => backClicked()}
                                outline={true}
                            >
                                {props.t("properties.back")}                        
                            </Button> : null
                        }
                        <Link
                            to="#"
                            className="text-danger fw-bold w-auto"
                            onClick={() => {onCloseClick(); toggleArrowTab(1); setEditInputs({})}}
                        >
                            {props.t("properties.cancel")}
                        </Link>
                    </div>                    
                    <Button
                        color="info"
                        className="btn btn-info w-auto"
                        disabled={disabled}
                        onClick={activeArrowTab == 3 ? () => UpdateProperty() : () => nextClicked()}
                    >
                        {activeArrowTab == 3 ? props.t("properties.update") : props.t("properties.next")}                     
                    </Button>
                </div>
        </Offcanvas>
    );
};

export default withTranslation()(EditPropertyModal);

import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Button,
  Modal,
  ModalBody,
  Input,
  ModalHeader,
  Row,
  Col,
} from "reactstrap";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useParams } from "react-router-dom";
import { SendEmail, getStudentsEmails } from "store/student/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import DtsEmailsInputs from "Components/Common/FormBuilder/DtsEmailsInputs";

export interface SuggestionOption {
  readonly value: string;
  readonly label: string;
}

interface FProps {
  props: any;
  emailModals: boolean;
  setEmailModals: (event: boolean) => void;
  reply?: boolean;
  info: any;
}

const EmailModal: React.FC<FProps> = ({
  props,
  emailModals,
  setEmailModals,
  reply,
  info
}) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const {id} = useParams();
    const studentDetails = useSelector((state: ApplicationState) => state.student.student);
    const [isccOn, setisccOn] = useState(false)
    const [isBccOn, setisBccOn] = useState(false)
    const [disabled,setdisabled] = useState(true)
    const [emailData, setEmailData] = useState<any>({
        studentId: id,
        to: info?.email
    })
  const toggle = () => {
    if (emailModals) {
      setEmailModals(false);
    } else {
      setEmailModals(true);
    }
};
useEffect(() => {
    setEmailData({...emailData, studentId: id,to: info?.email})
}, [info])
console.log("info", info, "emailData", emailData)
    const onSend = () => {
        const data = { ...emailData, parentId: "" }
        const handleSuccess = () => {
            dispatch(getStudentsEmails(id));
            setInnitial()
            setEmailModals(false)
        }
        const handleFailure = () => { }
        dispatch(SendEmail(data, handleSuccess, handleFailure))
    }

    useEffect(() => {
        if(studentDetails && studentDetails.id) {
            let valuesJson = studentDetails && studentDetails.valuesJson && isJson(studentDetails.valuesJson) ? JSON.parse(studentDetails.valuesJson) : "";     
            setEmailData({...emailData, to: valuesJson.email})
        }
    }, [studentDetails])

    useEffect(() => {
        if(isBccOn) {
            const getID = document.getElementById('BccRecipientsCollapse') as HTMLElement
            if (getID) getID.classList.add("show");
        }
        else {
            const getID = document.getElementById('BccRecipientsCollapse') as HTMLElement
            if (getID) getID.classList.remove("show");
        }
        if(isccOn) {
            const getID = document.getElementById('CcRecipientsCollapse') as HTMLElement
            if (getID) getID.classList.add("show");
        }
        else {
            const getID = document.getElementById('CcRecipientsCollapse') as HTMLElement
            if (getID) getID.classList.remove("show");
        }
    },[isBccOn,isccOn])
    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            return false;
        }
    }
    const setInnitial = () => {
        if(studentDetails && studentDetails.id) {
            let valuesJson: any = studentDetails && studentDetails?.valuesJson;     
            setEmailData({to: valuesJson?.email, studentId: id})
            dispatch(getStudentsEmails(id));
        }
    }

  return (
    <React.Fragment>
      <Modal backdrop="static" id="composemodal" className="modal-lg" isOpen={emailModals} toggle={toggle} centered>
                <ModalHeader className="p-3 bg-light" toggle={() => {setEmailModals(false); setInnitial()}}>
                    New Message
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="mb-3 position-relative">
                        <Row className="hstack mb-3 position-relative">
                            <Col md={10} lg={10} className="pe-0">
                                <DtsEmailsInputs placeholder={"To"} disabled={true} defaultValue={`${emailData.to}`} setdisabled={setdisabled} onChange={(values: any) => setEmailData({ ...emailData, to: values })} />
                            </Col>
                            <Col  md={2} lg={2} className="ps-0 hstack justify-content-end">
                                <div className="d-flex">
                                    <button
                                        className="btn btn-link text-reset fw-semibold px-2"
                                        type="button"
                                        onClick={() => setisccOn(!isccOn)}
                                    >
                                        Cc
                                    </button>
                                    <button
                                        className="btn btn-link text-reset fw-semibold px-2"
                                        type="button"
                                        onClick={() => setisBccOn(!isBccOn)}
                                    >
                                        Bcc
                                    </button>
                                </div>
                            </Col>
                        </Row>
                        </div>
                        {
                            isccOn ?
                        <div className="collapse" id="CcRecipientsCollapse">
                            <div className="mb-3">
                                <label>Cc:</label>
                                <DtsEmailsInputs placeholder={"Cc"} setdisabled={setdisabled} onChange={(values: any) => setEmailData({ ...emailData, cc: values })} />
                            </div>
                        </div>:
                        null}
                        {
                            isBccOn ? <div className="collapse" id="BccRecipientsCollapse">
                            <div className="mb-3">
                                <label>Bcc:</label>
                                <DtsEmailsInputs placeholder={"Bcc"} setdisabled={setdisabled} onChange={(values: any) => setEmailData({ ...emailData, bcc: values })} />
                            </div>
                        </div>
                        : null
                        }

                        <div className="mb-3">
                            <Input type="text" value={emailData.subject || ""} className="form-control" placeholder="Subject" onChange={(e: any) => setEmailData({ ...emailData, subject: e.target.value })} />
                        </div>
                        <div className="ck-editor-reverse">
                            <CKEditor
                                editor={ClassicEditor}
                                onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.

                                }}
                                onChange={(event: any, editor: any) => {
                                    const data = editor.getData();
                                    setEmailData({ ...emailData, body: data })
                                }}
                            />
                        </div>
                    </div>
                </ModalBody>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-ghost-danger"
                        onClick={() => {
                            setEmailModals(false);
                            setInnitial();
                        }}
                    >
                        Discard
                    </button>

                    <UncontrolledDropdown className="btn-group">
                        <button
                            type="button"
                            className="btn btn-success"
                            disabled={disabled || !emailData.subject || !emailData.body}
                            onClick={() => {
                                onSend();
                            }}
                        >
                            Send
                        </button>
                    </UncontrolledDropdown>
                </div>
            </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(EmailModal);

import React, { useEffect, useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
} from "reactstrap";
import { ApplicationsListGetRequest, applicationCreateRequest } from "store/applications/action";
import { ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Form from "Components/Common/FormBuilder/DtsResponsiveForm";
import { useParams, useSearchParams } from "react-router-dom";
import { studentExternalApplication } from "store/student/action";
import UpdateForm from "Components/Common/FormBuilder/UpdateForm";
    
const AddEntry = ({ dataFields, activeVerticalTab, toggleVerticalTab, steps, allstepData, setallstepData }: any) => {  
	const { id } = useParams();
    console.log("activeVerticalTab", activeVerticalTab);
	const [searchParams] = useSearchParams();
	const source = searchParams.get('source');
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [statusMetaData, setStatusMetaData] = useState<any>([]);
    const OnAddEntry = () => {
        const handleSuccess = (body: any) => {
            const message = () => toast(`Entry Added Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            // toggleVerticalTab(activeVerticalTab+ 1)
            // dispatch(ApplicationsListGetRequest());
        }
        const handleError = () => {

        }
        dispatch(studentExternalApplication(id,allstepData, handleSuccess ,handleError))
    }

    const onNextClick = (formValues: any) => {
        console.log("activeVerticalTab", activeVerticalTab, "formValues", formValues)
        console.log("allstepData", allstepData)
        let newallstepdata = {...allstepData, ...formValues, date_of_birth:formValues.date_of_birth};
        console.log("newallstepdata", newallstepdata)
        setallstepData(newallstepdata)
        toggleVerticalTab(activeVerticalTab + 1)
    }

    useEffect(() => {
        let newstatusMetaData: any = []
        dataFields && dataFields.length && dataFields.map((field: any) => {
            let item = field.valuesJson  && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}
            if(!item.hidden) newstatusMetaData.push(field)
        })
        let newmeta = null;
        newstatusMetaData && newstatusMetaData.length && newstatusMetaData.map((field: any,index: any) => {
            let item = field.valuesJson  && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}
            if(item.type === "file") return newmeta = index
        });
        // If "File" column is found, move it to the beginning
        if (newmeta !== -1 && newstatusMetaData) {            
            const nameColumn = newstatusMetaData.splice(newmeta, 1)[0]; // Remove the "file" column
            newstatusMetaData.unshift(nameColumn); // Add it to the beginning
        }
        setStatusMetaData(newstatusMetaData)
    }, [dataFields])

    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            return false;
        }
    }
    return (
        <Card className="px-3 py-1">
            <CardBody>
                <div className="live-preview vstack">
                    {
                        activeVerticalTab < steps.length-1 ?
                        <Form isUpdate={false} BtnClassName={"w-auto justify-content-end"} md={6} lg={6} sm={12} xl={6} xs={12} dataFields={statusMetaData} btnAction={onNextClick} btn_text={'Save and Next'}/>
                        :
                        <Form isUpdate={true} BtnClassName={"w-auto justify-content-end"} md={6} lg={6} sm={12} xl={6} xs={12} dataFields={statusMetaData} btnAction={OnAddEntry} btn_text={'Create'} data={allstepData}  />
                    }
                    </div>
            </CardBody>
        </Card>      
    );
};

export default AddEntry;


import BreadCrumb from "Components/Common/BreadCrumb";
import React, { useCallback, useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  Button,
  Col,
  Container,
  Nav,
  NavItem,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label,
  UncontrolledAlert,
  ListGroup,
  ListGroupItem,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import googleicon from "../../../assets/images/googleicon.png";
import avtarImage2 from '../../../assets/images/users/avatar-1.jpg';
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { userUpdateRequest } from "store/user/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { disconnectGoogleAccount, getGoogleCalendarData } from "store/calendarSync/action";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import CircleBadge from "pages/StudentsDetails/CircleBadge";
import { toast } from "react-toastify";
import ConfirmSyncModal from "../ConfirmSyncModal";
import SyncOptionModal from "../SyncOptionModal";
import { userGetRequest } from "store/auth/action";
import { permissionsGetRequest } from "store/role/action";
import Upload from "../Upload/Upload";
import { useParams } from "react-router";

const Profile = ({ t, avtarImage2 }: any) => {
  const {id} = useParams();
  const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
  const user = useSelector((state: ApplicationState) => state.auth.userProfile);
  const googleConnect = useSelector((state: ApplicationState) => state.calendarSync?.data);
  const googleResponse = useSelector((state: ApplicationState) => state.calendarSync.data);
  const [activeArrowTab, setactiveArrowTab] = useState<number>(1);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState(false);
  const [modal, setModal] = useState(false);
  const [passedarrowSteps, setPassedarrowSteps] = useState<number[]>([1]);
  const [userData, setUserData] = useState<any>(user);
  const [modalFile, setModalFile] = useState<boolean>(false);
  const [picture, setPicture] = useState<string>("")
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [pictureLoading, setPictureLoading] = useState<boolean>(false);
  const [data, setData] = useState(0)
  const [fileInfo, setFileInfo] = useState<any>({});
  const [filterObject, setFilterObject] = useState<any>({});
  const [dataPreviewModal, setDataPreviewModal] = useState<boolean>(false);
  const toggleArrowTab = (tab: number) => {
    if (activeArrowTab !== tab) {
      const modifiedSteps = [...passedarrowSteps, tab];
      if (tab >= 1 && tab <= 3) {
        setactiveArrowTab(tab);
        setPassedarrowSteps(modifiedSteps);
      }
    }
  };

  useEffect(() => {
    if (user) {
      dispatch(userGetRequest());
      dispatch(permissionsGetRequest());
    }
  }, [user.profilePic]);

  useEffect(() => {
    dispatch(getGoogleCalendarData())
  }, [])

  useEffect(() => {
    setUserData(user);
  }, [user]);
  const CreateRole = () => {
    // Implement your logic for creating a role
  };
  const columns = [
    {
      Header: "Email",
      accessor: "email",
      filterable: true,
      isSortable: false,
      disableFilters: true,
      show: true,
    },
    {
      Header: "Status",
      accessor: "status",
      filterable: true,
      isSortable: false,
      disableFilters: true,
      show: true,
    },
  ];

  const calendarcolumns = [
    {
      Header: "Account",
      accessor: "account",
      filterable: true,
      isSortable: false,
      disableFilters: true,
      show: true,
    },
    {
      Header: "Status",
      accessor: "status",
      filterable: true,
      isSortable: false,
      disableFilters: true,
      show: true,
    },
  ];
  const handleUpdateInfo = () => {
    const attributes = [];

    if (userData.name !== user.name) {
        attributes.push({
            key: "name",
            value: userData.name
        });
    }
    if (userData.email !== user.email) {
        attributes.push({
            key: "email",
            value: userData.email
        });
    }
    if (userData.phoneNumber !== user.phoneNumber) {
        attributes.push({
            key: "phone_number",
            value: userData.phoneNumber
        });
    }

    // Only dispatch if there are attributes to update
    if (attributes.length > 0) {
        const data = {
            attributes: attributes
        };
    const handleSuccess = (body: any): void => {
        console.log("Handle success")
        toast(`User information updated successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
        dispatch(userGetRequest(id))
      };
    const handleError = (body: any): void => {
        const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
        message();
      };
    dispatch(userUpdateRequest(id, data, handleSuccess, handleError));
    }
};

  const handleGoogleAccountDisconnect = (email: any) => {
    const handleSuccess = (body: any): void => {
      dispatch(getGoogleCalendarData());
    };
    const handleError = (body: any): void => {
      const message = () =>
        toast(`${body.status}`, {
          position: "top-center",
          hideProgressBar: true,
          className: "bg-danger text-white",
        });
      message();
    };
    dispatch(disconnectGoogleAccount(email, handleSuccess, handleError));
  };

  useEffect(() => {
    if(googleResponse) setPicture(googleResponse[0]?.socialDataDTO?.userProfile?.picture)
   },[googleResponse])

   const fileToggle = useCallback(() => {
    if (modalFile) {
        setModalFile(false);
    } else {
        setModalFile(true);
    }
}, [modalFile]);

   const handleFileClicks = () => {
    setModalFile(!modalFile);
    fileToggle();
};

  return (
    <React.Fragment>

        <ConfirmSyncModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        // props={props}
      />
      <SyncOptionModal
        modal={modal}
        setModal={setModal}
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        // props={props}
      />
       <Modal backdrop="static" id="createFileModal" isOpen={modalFile}  toggle={() => { fileToggle(); setselectedFiles([]); }}  modalClassName="zoomIn" centered tabIndex={1}>
                <ModalHeader  toggle={() => { fileToggle(); setselectedFiles([]); }}  className="p-3 bg-success-subtle">{"Upload File"}</ModalHeader>
                <ModalBody>
                    <Upload setPictureLoading={setPictureLoading} setData={setData} setDataPreviewModal={setDataPreviewModal} filterObject={filterObject} selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} setModalFile={setModalFile} fileInfo={fileInfo} setFileInfo={setFileInfo} />
                </ModalBody>
            </Modal>

      <div className="hstack mt-0 mb-4">
        <Row className="p-2 w-100 hstack gap-3 justify-items-center">
          {/* <Col lg={12} className="vstack justify-content-between"> */}
          <div className="vstack gap -1 justify-content-center">
            <h4 className="">Profile</h4>
            <p>This applies across any Zilter account they have.</p>
          </div>
          <div className="vstack gap-3 align-items-left">
            <Label className="fw-bold fs-14">Profile Image</Label>
            <div className="d-flex justify-content-between">
              <div className="d-flex position-relative gap-3">
                <div className="profile-user position-relative d-inline-block mx-auto">
                  <img
                    src={picture ? picture : user.profilePic}
                    className="rounded-circle avatar-lg img-thumbnail user-profile-image"
                    alt="user-profile"
                  />
                  <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                    <Label
                      htmlFor="profile-img-file-input"
                      className="profile-photo-edit avatar-xxs"
                    >
                      <span className="avatar-title rounded-circle bg-light text-body">
                        <i onClick={() => handleFileClicks()} className="ri-camera-fill"></i>
                      </span>
                    </Label>
                  </div>
                </div>
                <div className="mt-3">
                  <Label className="fs-16">{user?.name}</Label>
                  <div className="vstack gap-1 align-self-center flex-grow-1">
                    <h6>{user?.email}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-between">
            <div className="w-50">
              <FormBuilder
                label={"Name"}
                type={"textfield"}
                value={userData?.name}
                name={"name"}
                placeholder={`Enter name`}
                className="flex-grow-1 me-3 mb-3"
                onChange={(value: any) => {

                  // setvalue(value)
                  setUserData({ ...userData, name: value })
                }}
                Editable={true}
              />
            </div>
            <div className="w-50">
              <FormBuilder
                disabled
                label={"Email"}
                type={"textfield"}
                value={userData?.email}
                name={"last_name"}
                placeholder={`Enter last name`}
                className="flex-grow-1 me-3 mb-3"
                // onChange={(value: any) => {
                //     console.log(value)
                //     // setvalue(value)
                //     setUserData({ ...userData, email: value })
                // } }
                Editable={false}
              />
            </div>
            <div className="w-50" contentEditable={false}>
              <p>

                <FormBuilder
                  disabled
                  label={"Main Phone"}
                  type={"textfield"}
                  defaultValue={userData?.phoneNumber}
                  value={userData?.phoneNumber}
                  name={"phone_number"}
                  placeholder={`Enter Main number`}
                  className="flex-grow-1 me-3 mb-3"
                  // onChange={(value: any) => {
                  //     console.log(value)
                  //     setUserData({ ...userData, phoneNumber: value })
                  // }}
                  Editable={false}
                />
                This phone number may be used to contact them about security events, sending workflows SMS, and for owner property values.
              </p>
              <Button className='align-self-end' color="primary" onClick={handleUpdateInfo}>Update Info</Button>
            </div>
            {/* <div>
              <text className="fs-14 fw-bold">
                {t(
                  "userpreferences.calendar"
                )}
              </text>
            </div> */}
            </div>
            
            <div>
            {googleConnect?.length === 0 ? (
                  <div>
                   
                    <div className="mt-3">
                      <div>
                        <h4>{t("userpreferences.calendar")}</h4>
                        <UncontrolledAlert color="danger" className="mb-xl-3">
                      <strong>
                        {t(
                          "userpreferences.your_calendar_is_disconnected"
                        )}
                      </strong>
                      &nbsp;
                      <span>
                        {t(
                          "userpreferences.connect_it_to_access_all_the_features_in_the_meetings_tool"
                        )}
                      </span>
                    </UncontrolledAlert>
                        <p>
                          {t(
                            "userpreferences.connect_your_calendar_to_use_zilter_meetings_and_calendar_sync"
                          )}
                        </p>
                        <div>
                          <ListGroup>
                            <ListGroupItem>
                              <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i>{" "}
                              {t(
                                "userpreferences.send_meetings_directly_from_zilter"
                              )}
                            </ListGroupItem>
                            <ListGroupItem>
                              <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i>{" "}
                              {t(
                                "userpreferences.log_outgoing_meetings_automatically"
                              )}
                            </ListGroupItem>
                            <ListGroupItem>
                              <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i>{" "}
                              {t(
                                "userpreferences.sync_your_calendar_so_contacts_can_schedule_time_with_you"
                              )}
                              <p className="mx-4 fs-12">
                                &nbsp;
                                {t(
                                  "userpreferences.requires_calendar_sync"
                                )}
                              </p>
                            </ListGroupItem>
                            <ListGroupItem>
                              <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i>{" "}
                              {t(
                                "userpreferences.see_your_zilter_tasks_in_your_calendar"
                              )}
                              <p className="mx-4 fs-12">
                                &nbsp;
                                {t(
                                  "userpreferences.requires_an_additional_step_with_task_sync_only_available_on_Sales_starter_and_up_plans"
                                )}
                              </p>
                            </ListGroupItem>
                          </ListGroup>
                        </div>
                        <div className="my-3">
                          <Button
                            onClick={() => setModal(true)}
                            color="primary"
                            className="bg-gradient"
                          >
                            {" "}
                            {t(
                              "userpreferences.connect_your_calendar"
                            )}{" "}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <h4>{t("userpreferences.calendar")}</h4>
                    <Card>
                      {/* <CardBody>
                        <div className="border p-3 mt-n3 mx-n3 bg-light rounded-top">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                              <Label className="fs-14 mb-0">
                                {t("userpreferences.account")}
                              </Label>
                            </div>
                          </div>
                        </div> */}
                        {googleConnect?.map((item: { email: any }) => (
                          <div
                            key={item.email}
                            className="d-flex justify-content-between my-3 mx-3"
                            onMouseEnter={() => setIsHovered(item.email)}
                            onMouseLeave={() => setIsHovered(false)}
                          >
                            <div className="d-flex justify-content-start">
                              <div>
                                <img
                                  className="avatar-sm"
                                  src={googleicon}
                                  alt="Google Icon"
                                />
                              </div>
                              <div>
                                <Label className="ms-1 mb-0 mt-1 fs-15 fw-medium">
                                  <strong>{item.email}</strong>
                                </Label>
                                <div>
                                  <span className="ms-1">
                                    <CircleBadge />
                                  </span>
                                  {t("userpreferences.connected")}
                                </div>
                              </div>
                            </div>
                            {isHovered === item.email && (
                              <div>
                                <Button
                                  onClick={() =>
                                    handleGoogleAccountDisconnect(item.email)
                                  }
                                  className="btn-danger"
                                >
                                  {t("userpreferences.disconnect")}
                                </Button>
                              </div>
                            )}
                          </div>
                        ))}
                        {/* </CardBody> */}
                        </Card>
                  </div>
                )}
            
          
          </div>
          </div>
          {/* </Col> */}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Profile);

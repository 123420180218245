import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationState } from "store";
import { AllSubLevelPermssion } from "../store/auth/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";

const Navdata = () => {
    const history = useNavigate();   
    const page: any = useParams();   
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [isDashboard, setIsDashboard] = useState<boolean>(false);        
    const user = useSelector((state: ApplicationState) => state.auth.userProfile);
    const auth = useSelector((state: ApplicationState) => state.auth);
    
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const parentPermmission = useSelector((state: ApplicationState) => state.auth.parentPermmission);
    const [isStudents, setIsStudents] = useState<boolean>(false);
    const [isFields, setIsFields] = useState<boolean>(false);
    const [permissions, setPermissions] = useState<any>([]);
    const [permissionsItem, setPermissionsItem] = useState<any>([]);
    const [isRoles, setIsRoles] = useState<boolean>(false);
    const [isApplications, setIsApplications] = useState<boolean>(false);
    const [isUniversities, setIsUniversities] = useState<boolean>(false);
    const [isCourses, setIsCourses] = useState<boolean>(false);
    const [isUsers, setIsUsers] = useState<boolean>(false);
    const [isSources, setIsSources] = useState<boolean>(false);
    const [isChannels, setIsChannels] = useState<boolean>(false);
    const [isLinks, setIsLinks] = useState<boolean>(false);
    const [isInvoices, setIsInvoices] = useState<boolean>(false);
    const [isCompanies, setIsCompanies] = useState<boolean>(false);
    const [isForms, setIsForms] = useState<boolean>(false);
    const [isSubagents, setIsSubagents] = useState<boolean>(false);
    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e : any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul : any = document.getElementById("two-column-menu");
            const iconItems : any = ul.querySelectorAll(".nav-icon.active") ;
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("sub-items");
                const getID = document.getElementById(id) as HTMLElement
                if (getID)
                    getID.classList.remove("show");
            });
        }
    }

    useEffect(() => {
        if(user && user.role  && user.role.title === "Owner") {
            setPermissionsItem(menuItems)
        }
        else if(user && user.role && user.role.permissions && user.role.permissions.length) {
            let newMenuitems: any = [];
                menuItems.map((item: any) => {
                    parentPermmission.map((permission: any) => {
                        if(permission === item.label.toLowerCase()) newMenuitems.push(item)
                    })               
                }); 
            setPermissionsItem(newMenuitems)         
        }
    }, [user])

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');        
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Students') {
            setIsStudents(false);
        }
        if (iscurrentState !== 'Fields') {
            setIsFields(false);
        }
        if (iscurrentState !== 'Roles') {
            setIsRoles(false);
        }
        if (iscurrentState !== 'Applications') {
            setIsApplications(false);
        }
        if (iscurrentState !== 'Courses') {
            setIsCourses(false);
        }
        if (iscurrentState !== 'Institutes') {
            setIsUniversities(false);
        }
        if (iscurrentState !== 'Users') {
            setIsUsers(false);
        }
        if (iscurrentState !== 'Sources') {
            setIsSources(false);
        }
        if (iscurrentState !== 'Channels') {
            setIsChannels(false);
        }
        if (iscurrentState !== 'Links') {
            setIsLinks(false);
        }
        if (iscurrentState !== 'Invoices') {
            setIsInvoices(false);
        }
        if (iscurrentState !== 'Companies') {
            setIsInvoices(false);
        }       
        if (iscurrentState !== 'Forms') {
            setIsInvoices(false);
        }    
        if (iscurrentState !== 'Subagents') {
            setIsSubagents(false);
        }
    }, [
        history,
        iscurrentState,
        isDashboard,
        isStudents,
        isFields,
        isRoles,
        isApplications,
        isCourses,
        isUniversities,
        isUsers,
        isSources,
        isChannels,
        isLinks,
        isInvoices,
        isCompanies,
        isForms,
        isSubagents
    ]);

    useEffect(() => {
        switch(page) {
            case "dashboard": setIsDashboard(true);
            break
            case "Students": setIsStudents(true);
            break
            case "fields": setIsFields(true);
            break
            case "roles": setIsRoles(true);
            break
            case "applications": setIsApplications(true);
            break
            case "courses": setIsCourses(true);
            break
            case "institutes": setIsUniversities(true);
            break
            case "users": setIsUsers(true);
            break
            case "sources": setIsSources(true);
            break
            case "channels": setIsChannels(true);
            break
            case "links": setIsLinks(true);
            break
            case "invoices": setIsInvoices(true);
            break
            case "companies": setIsCompanies(true);
            break
            case "forms": setIsForms(true);
            break
            case "subagents": setIsSubagents(true);
            break
        }
    }, [])
    const menuItems : any= [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: "dashboard",
            label: "Dashboards",
            icon: "ri-dashboard-2-line",
            className: 'text-decoration-none',
            link: "/#",
            stateVariables: isDashboard,
            click: function (e: any) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "analytics",
                    label: "Analytics",
                    link: "/dashboard-analytics",
                    parentId: "dashboard",
                },
                {
                    id: "AI-dashboard",
                    label: "AI-dashboard",
                    link: "/ai-dashborad",
                    parentId: "dashboard",
                }
            ],
        },
        {
            id: "students",
            label: "Students",
            icon: "ri-list-check",
            className: 'text-decoration-none',
            link: "/students",
            stateVariables: isStudents,
            click: function (e: any) {
                setIsStudents(!isStudents);
                setIscurrentState('Students');
                updateIconSidebar(e);
            }
        },
        {
            id: "applications",
            label: "Applications",
            icon: "ri-contacts-book-line",
            className: 'text-decoration-none',
            link: "/applications",
            stateVariables: isApplications,
            click: function (e: any) {
                setIsApplications(!isApplications);
                setIscurrentState('applications');
                updateIconSidebar(e);
            }
        },
        {
            id: "institutes",
            label: "Institutes",
            icon: "ri-building-4-line",
            className: 'text-decoration-none',
            link: "/institutes",
            stateVariables: isUniversities,
            click: function (e: any) {
                setIsUniversities(!isUniversities);
                setIscurrentState('universities');
                updateIconSidebar(e);
            }
        },
        {
            id: "courses",
            label: "Courses",
            icon: " ri-book-open-line",
            className: 'text-decoration-none',
            link: "/courses",
            stateVariables: isCourses,
            click: function (e: any) {
                setIsCourses(!isCourses);
                setIscurrentState('courses');
                updateIconSidebar(e);
            }
        },
        {
            id: "sources",
            label: "Sources",
            icon: "ri-team-fill",
            className: 'text-decoration-none',
            link: "/sources",
            stateVariables: isSources,
            click: function (e: any) {
                setIsSources(!isSources);
                setIscurrentState('sources');
                updateIconSidebar(e);
            }
        },
        {
            id: "subagents",
            label: "Sub Agents",
            icon: "ri-team-fill",
            className: 'text-decoration-none',
            link: "/subagents",
            stateVariables: isSubagents,
            click: function (e: any) {
                setIsSubagents(!isSubagents);
                setIscurrentState('subagents');
                updateIconSidebar(e);
            }
        },
        {
            id: "channel",
            label: "Channels",
            icon: "ri-group-fill",
            className: 'text-decoration-none',
            link: "/channels",
            stateVariables: isChannels,
            click: function (e: any) {
                setIsChannels(!isChannels);
                setIscurrentState('channel');
                updateIconSidebar(e);
            }
        },
        {
            id: "fields",
            label: "Fields",
            icon: "ri-list-settings-line",
            className: 'text-decoration-none',
            link: "/fields",
            stateVariables: isFields,
            click: function (e: any) {
                setIsFields(!isFields);
                setIscurrentState('fields');
                updateIconSidebar(e);
            }
        },
        {
            id: "users",
            label: "Users",
            icon: "ri-user-line",
            className: 'text-decoration-none',
            link: "/users",
            stateVariables: isUsers,
            click: function (e: any) {
                setIsUsers(!isUsers);
                setIscurrentState('users');
                updateIconSidebar(e);
            }
        },
        {
            id: "roles",
            label: "Roles",
            icon: "ri-user-settings-line",
            className: 'text-decoration-none',
            link: "/roles",
            stateVariables: isRoles,
            click: function (e: any) {
                setIsRoles(!isRoles);
                setIscurrentState('roles');
                updateIconSidebar(e);
            }
        },
        // {
        //     id: "links",
        //     label: "Links",
        //     icon: "ri-user-settings-line",
        //     className: 'text-decoration-none',
        //     link: "/links",
        //     stateVariables: isLinks,
        //     click: function (e: any) {
        //         setIsLinks(!isLinks);
        //         setIscurrentState('links');
        //         updateIconSidebar(e);
        //     }
        // },
        {
            id: "invoices",
            label: "Invoices",
            icon: "ri-user-settings-line",
            className: 'text-decoration-none',
            link: "/invoices",
            stateVariables: isInvoices,
            click: function (e: any) {
                setIsInvoices(!isInvoices);
                setIscurrentState('invoices');
                updateIconSidebar(e);
            }
        },
        {
            id: "companies",
            label: "Companies",
            icon: "ri-user-settings-line",
            className: 'text-decoration-none',
            link: "/companies",
            stateVariables: isCompanies,
            click: function (e: any) {
                setIsCompanies(!isCompanies);
                setIscurrentState('companies');
                updateIconSidebar(e);
            }
        },
        {
            id: "forms",
            label: "Forms",
            icon: "ri-user-settings-line",
            className: 'text-decoration-none',
            link: "/forms",
            stateVariables: isForms,
            click: function (e: any) {
                setIsForms(!isForms);
                setIscurrentState('forms');
                updateIconSidebar(e);
            }
        }
    ];
    return <React.Fragment>{permissionsItem}</React.Fragment>;
};
export default Navdata;
import React, { useEffect, useState } from "react";
import {
    Label,
    Input,
    Form,
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormFeedback,
    ButtonGroup,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import country from './countrycode.json';
import SimpleBar from "simplebar-react";
import { ValueType } from 'react-select';
import DtsDropdownMenu from "./DtsDropdownMenu";

interface Country {
    name: string;
    code: string;
    emoji: string;
    unicode: string;
    dial_code: string;
    image: string;
}

interface DtsPhoneInputProps {
    label: string;
    value: string;
    placeholder?: string;
    onChange: (value: string) => void;
    isEditState?: boolean;
    rest?: any;
    invalid?: any;
    formValues?: any;
    dataFields?: any;
    name?: string;
    errorText?: string;
    readOnly?: boolean;
    disabled?: boolean;
    actionButtons?: boolean;
    defaultValue?: string;
    isEditable?: any;
    onSaveClick?: any;
    setdisabled?: any;
    setError?: any;
    formerror?: any;
    resetvalue?: any;
}

const DtsPhoneInput: React.FC<DtsPhoneInputProps> = ({ formerror, setError, setdisabled, onSaveClick, rest, defaultValue, actionButtons, invalid, formValues, dataFields, name, errorText, label, readOnly, disabled, value, placeholder, onChange, isEditState }: any) => {
    const [hover, setHover] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>("");
    const [countryOptions, setCountryOptions] = useState<Country[]>([]);
    const [checkdisabled, setCheckdisabled] = useState<any>(false)
    const [selectedCountry, setSelectedCountry] = useState<Country>({
        name: "India",
        code: "IN",
        emoji: "🇮🇳",
        unicode: "U+1F1EE U+1F1F3",
        dial_code: "+91",
        image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg"
    });
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [phonenumber, setPhonenumber] = useState<string>(""); 
    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
    useEffect(() => {
        setCountryOptions(country);
        if(defaultValue) {
            let dial_code: any = "";
            country.map((item: any) => {
                if (defaultValue.includes(item.dial_code)) {
                    setSelectedCountry(item)
                    return dial_code = item.dial_code
                }
                    
            })            
            let restnumber = defaultValue.slice(dial_code.length)
            setPhonenumber(restnumber)
        }
    }, [defaultValue]);

    useEffect(() => {
        if (isEditState != undefined) setHover(isEditState);
    }, [isEditState]);
    const handleInputChange = (searchvalue: any) => {
        // Use replace with a regular expression to remove all occurrences of '+'
        let value = searchvalue.replace(/\+/g, '');
        setSearchValue(value)
        const updatedData = country.filter((item: any) => {
            return (
                item.name.toLowerCase().search(value.toLowerCase()) !== -1
                || item.code.toLowerCase().search(value.toLowerCase()) !== -1
                || item.dial_code.search(value) !== -1
            );
        });
        setCountryOptions(updatedData);
    }

    const onChangevalue = (value: any) => {
        if (value.includes('e') || value.includes('+') || value.includes('-')) return 
        else {
            setPhonenumber(value)
            onChange(`${selectedCountry.dial_code}${value}`)
        }
        validateInput(value);
    }
    const onChangecountry = (selectedCountry: any) => {
        setSelectedCountry(selectedCountry)
        onChange(`${selectedCountry.dial_code}${phonenumber}`)
    }

    const [errormsg, setErrormsg] = useState();

    const onhandleChange = (value: any) => {
        if(value) {
            let dial_code: any = ""
            country.map((item: any) => {
                if (value.includes(item.dial_code)) {
                    setSelectedCountry(item)
                    return dial_code = item.dial_code
                }
                    
            })            
            let restnumber = value.slice(dial_code.length)
            setPhonenumber(restnumber)
        }
        onChange(value)
        validateInput(value);
    }
    
    useEffect(() => {
        if(rest && rest.validate && rest.validate.required) {           
            if(value === '')
            { 
                const inputdiv: any = document.getElementById(`${name}input`);
                if(inputdiv) inputdiv.classList.add('d-block');
            } 
            else {
                const inputdiv: any = document.getElementById(`${name}input`);
                if(inputdiv) inputdiv.classList.remove('d-block');
            }            
        }
    },[value])

    const validateInput = (value: any) => { 
        if(rest && rest.type == 'email') {
            if (value === '') {
                let error: any =  `Please enter valid ${label}`;
                setErrormsg(error)
                if (typeof setError === 'function') {
                    setError({...formerror, [name] : error})
                }
                setCheckdisabled(true)
                return false
            }
        }
        if(rest && rest.validate && rest.validate.required) {
            if(value === "") {
               let error: any =  `Please enter ${label}`;
                setErrormsg(error)
                if (typeof setError === 'function') {
                    setError({...formerror, [name] : error})
                }
                setCheckdisabled(true)
                return false
            }
        }
        if(rest && rest.validate && rest.validate.minLength) {
            if(value.length < rest.validate.minLength) {
                let error: any =  `Length of ${label} must be atleast ${rest.validate.minLength}`;
                setErrormsg(error)
                if (typeof setError === 'function') {
                    setError({...formerror, [name] : error})
                }
                setCheckdisabled(true)
                return false
            }
        }
        if(rest && rest.validate && rest.validate.maxLength) {   
           if(value.length > rest.validate.maxLength) {
                let error: any =  `Length of ${label} must be not more than ${rest.validate.maxLength}`;
                setErrormsg(error)
                if (typeof setError === 'function') {
                    setError({...formerror, [name] : error})
                }
                setCheckdisabled(true)
                return false
            }
        }
        setErrormsg(undefined) 
        if (typeof setError === 'function') {
            const {[name]: _, ...newObject } = formerror;   
            setError(newObject)
        }
        setCheckdisabled(false)
        setCheckdisabled(false)
        return true
    }


    const onCheckClick = (e: any) => {
        e.preventDefault();
        onSaveClick();
        setHover(false)
    }

    useEffect(() => {
        if(rest && rest.validate) {           
            if(errormsg)
            { 
                if (typeof setdisabled === 'function') {
                    setdisabled(true)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if(inputdiv) inputdiv.classList.add('d-block');
            } 
            else {
                if (typeof setdisabled === 'function') {
                    setdisabled(false)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if(inputdiv) inputdiv.classList.remove('d-block');
            }            
        }
    },[value])

    useEffect(() => {
        if(rest && rest.validate && rest.validate.required) { 
            const feedback: any = document.getElementById(`${name}`);
            if(feedback) feedback.classList.remove('d-none');
        }
    },[rest])
    
   
    return (
        <div className="pb-3">
            <Form>
                <div>
                    {
                        actionButtons ?
                        <div>
                            {hover ?
                                <div>
                                    {label ? <Label htmlFor={name} className="form-label fw-bold fs-14">{label}<span id={`${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label> : null}
                                    <div className="d-flex border rounded-3 align-items-center">
                                        <DtsDropdownMenu options={rest && rest.values && rest.values.length ? rest.values : []} label={""} value={""} onChange={(test) => {onChange(test)}} />
                                        <ButtonGroup>
                                            <Button onClick={(e) => { e.preventDefault(); setHover(false); onChange(defaultValue) }} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-close-fill" /> </Button>
                                            <Button onClick={(e) => {onCheckClick(e)}} disabled={checkdisabled} color="primary" className="btn-icon"> <i className="ri-check-fill" /> </Button>
                                        </ButtonGroup>
                                    </div> 
                                    {<FormFeedback id={`${name}feedback`}>{errormsg}</FormFeedback>}
                                </div>
                                : <div>
                                    {label ? <Label htmlFor={name} className="form-label fw-bold fs-14 mb-0">{label}<span id={`${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label> : null}
                                    <div className="hstack justify-content-between topbar-user">
                                        <div className="fs-14 fw-medium form-control border-0 topbar-user text-nowrap text-truncate text-nowrap-ellipsis" >
                                            {value}
                                        </div>
                                        {
                                            readOnly ? null
                                            : <Button onClick={() => setHover(true)} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-edit-2-fill" /> </Button>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <div>
                              {label ? <Label htmlFor={name} className="form-label fw-bold fs-14">{label}<span id={`${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label> : null}
                                    <div className="position-relative">
                                        <div className="w-100 justify-content-between">
                                            <DtsDropdownMenu placeholder={placeholder} options={rest && rest.values && rest.values.length ? rest.values : []} label={label} value={value} onChange={(test) => {onChange(test)}} />                                    
                                        </div>
                                    </div>
                                    {<FormFeedback id={`${name}feedback`}>{errormsg}</FormFeedback>}
                        </div>
                    }
                </div>
            </Form>
        </div>
    );
};

export default DtsPhoneInput;
import {CourseActionTypes, Course} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";

const { api } = config;

const DTS_API_DOMAIN = api.DTS_API_DOMAIN
const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const courseGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: CourseActionTypes.GET_COURSE_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_DOMAIN}/courses/${id}`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: CourseActionTypes.GET_COURSE_ERROR,
                    payload: body
                });
            } else {
                dispatch({
                    type: CourseActionTypes.GET_COURSE_SUCCESS,
                    payload: body
                });
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

const createQueryLink = (filterObject: any) => {
    const keys = Object.keys(filterObject);
    const values = Object.values(filterObject);
    let query = `${DTS_API_DOMAIN}/api/courses-metadata?`;
    for (let i = 0; i < keys.length; i++) {
        if (values[i]) query += `${keys[i]}=${values[i]}&`;
    }
    return query;
};

export const coursesMetadataGetRequest: AppThunk = (model,filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: CourseActionTypes.GET_COURSEMETADATA_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        if (model) {
            headers.append('Properties-Model', model);
        }   
        const searchQuery =   createQueryLink(filterObject)    
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CourseActionTypes.GET_COURSEMETADATA_ERROR,
                payload: body_1
            });
            // handleError(body_1);
        } else {
            dispatch({
                type: CourseActionTypes.GET_COURSEMETADATA_SUCCESS,
                payload: body_1
            });
            // handleSuccess("Metadata fetched successfully");
        }
    };
};

export const courseUpdateRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CourseActionTypes.PUT_COURSE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_DOMAIN}/courses/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CourseActionTypes.PUT_COURSE_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: CourseActionTypes.PUT_COURSE_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const courseDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CourseActionTypes.DELETE_COURSE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_DOMAIN}/courses/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const body = await response.json();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: CourseActionTypes.DELETE_COURSE_ERROR
                });
                handleError();
            } else {
                dispatch({
                    type: CourseActionTypes.DELETE_COURSE_SUCCESS,
                    payload: []
                });
                handleSuccess(body_1);
            }
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: CourseActionTypes.DELETE_COURSE_ERROR
            });
            handleSuccess();
        }
    };
};

const coursescreateQueryLink = (filterObject: any, page: any) => {
    let query = `${DTS_API_DOMAIN}/courses?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const CourseListGetRequest: AppThunk = (filterObject,pageno) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: CourseActionTypes.GET_COURSES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery = coursescreateQueryLink(filterObject,pageno);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CourseActionTypes.GET_COURSES_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: CourseActionTypes.GET_COURSES_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const courseCreateRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: CourseActionTypes.POST_COURSE_REQUEST
        });
        const response = await fetch(
            `${DTS_API_DOMAIN}/courses`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "token": token
                },
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CourseActionTypes.POST_COURSE_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: CourseActionTypes.POST_COURSE_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const CoursesGetRequest: AppThunk = (data, page, size, signal) => {
    console.log("data", data)
    let requestbody = data ? data : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: CourseActionTypes.GET_FILTER_COURSES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        const response = await fetch(`${DTS_API_DOMAIN}/courses/get?page=${page}&pageSize=${size}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestbody),
            signal
        }).catch((error) => {
            // Your error is here!
            console.log(">>>",error)
            return false;
        });
        if(typeof response === 'object' && !Array.isArray(response) && response !== null) {
            const body = await response?.json();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: CourseActionTypes.GET_FILTER_COURSES_ERROR,
                    payload: body_1
                });
            } else {
                dispatch({
                    type: CourseActionTypes.GET_FILTER_COURSES_SUCCESS,
                    payload: body_1
                });
            }
        }
    };
};




import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner, Table, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, CardHeader } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableComponent';
import EditModal from './innerComponent/EditColumnModal';
import ExportDataModal from './innerComponent/ExportDataModal';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import ImportDataModal from './innerComponent/ImportDataModal';
import AddEntryModal from './innerComponent/AddEntryModal';
import AdvanceFilter from "./innerComponent/AdvanceFilter";
import FeatherIcon from "feather-icons-react";
import moment from 'moment';
import DeleteModal from './innerComponent/DeleteModal';
import PreviewModal from './innerComponent/PreviewModal';
import { withTranslation } from 'react-i18next';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Link, useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { SourceHierachyGetRequest, SourceListGetRequest, sourceDelete, sourceGetRequest, sourcesMetadataGetRequest } from 'store/sources/action';
import { ToastContainer, toast } from 'react-toastify';
import MoreFilters from './innerComponent/MoreFilters';
import DynamicFilters from './dynamicFilters/DynamicFilters';
import data from './sources.json'
import SourcesColumns from './SourceColumns.json'
import { propertyListGetRequest } from 'store/properties/action';
import AccessDenied from '../AccessDenied/Denied';
import ListingLoader from 'Components/Common/FormBuilder/DtsTablePlaceholder';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Sources = (props: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const sourceMetadata = useSelector((state: ApplicationState) => state.properties.list);
    const sourceList = useSelector((state: ApplicationState) => state.sources.sources);
    const hierarchysources = useSelector((state: ApplicationState) => state.sources.hierarchysources);
    const paginationDetails = useSelector((state: ApplicationState) => state.sources.pagination);
    const loading = useSelector((state: ApplicationState) => state.sources.metaloading);
    const dataloading = useSelector((state: ApplicationState) => state.sources.dataloading);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
    const navigate = useNavigate();
    const [columns, setColumns] = useState<any>([])
    const [filterCount, setFilterCount] = useState<any>(0)
    const [filterJson, setFilterJson] = useState<any>([]);
    const [visibleColumns, setvisibleColumns] = useState<any>([])
    const authLoading = useSelector((state: ApplicationState) => state.auth.meloading);
    const [pageSize, setPageSize] = useState<number>(10)
    const [searchData, setsearchData] = useState<any>([])
    const [editModal, setEditOpen] = useState<boolean>(false);
    const [previewModal, setpreview] = useState<boolean>(false);
    const [isFilterModalOpen, setisFilterModalOpen] = useState<boolean>(false);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const [iscreateModal, setCreateOpen] = useState<boolean>(false);
    const [isExportCSV, setIsExportCSV] = useState<boolean>(false);
    const [isImportCSV, setIsImportCSV] = useState<boolean>(false);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [isSaveOpen, setisSaveOpen] = useState<boolean>(false)
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [search, setsearch] = useState<string>("");
    const [record, setrecord] = useState<any>({});
    const [inputs, setInputs] = useState<any>({});
    const [focusid, setfocused] = useState<number>(-1);
    const [statusvalue, setstatusvalue] = useState<string>("");
    const [open, setOpen] = useState('1');
    const [tempcolumns, setTempColumns] = useState<any>([])
    const [Selectedcount, setselectedcount] = useState<number>(0);
    const [applyFilter, setApplyFilter] = useState<boolean>(false);
    const [statusFilter, setStatusFilter] = useState<boolean>(true);
    const [statusFilterLabel, setStatusFilterLabel] = useState<string>("Status")
    const [viewAccess, setViewAccess] = useState<boolean>(false);
    const [access, setAccess] = useState<any>([]);
    const [metaData, setMetaData] = useState(sourceMetadata);
    const [source, setSource] = useState([]);
    const [sourceJson, setsourceJson] = useState<any>([]);
    const [sortBy, setSortBy] = useState<any>("")
    const [sortOrder, setSortOrder] = useState<string>("")
    const [pagesList, setPagesList] = useState<any>([]);
    const [filterObject, setFilterObject] = useState<any>({});
    const [notesModals, setNotesModals] = useState<boolean>(false);
    const [emailModals, setEmailModals] = useState<boolean>(false);
    const [tasksModals, setTasksModals] = useState<boolean>(false);
    const [meetingsModals, setMeetingsModals] = useState<boolean>(false);
    const [isDynamicModalOpen, setIsDynamicModalOpen] = useState<boolean>(false);
    const [create, setCreate] = useState<boolean>(false);
    const [onAddNote, setOnAddNote] = useState<string>("");
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )
    const toggle = (id: any) => {
        setOpen(id)
    };

    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, pageSize: value })
        setPageSize(value)
        const filters = { ...filterObject, pageSize: value }
        setFilterObject(filters)
        dispatch(SourceListGetRequest(filters, 0));
        setsourceJson([])
    }

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setViewAccess(true)
            setCreate(true)
        }
        else if(subLevelPermissionsList) {
            let access = subLevelPermissionsList?.sources;
            if(access && access?.length) {
                setAccess(access)
                if(access && access.length) {            
                    access.map((item: any, index: any) => {
                        if(item.name.toLowerCase() == 'view') {
                            item.value == "" || item.value == "none" ? setViewAccess(false)  : setViewAccess(true) 
                       }
                       if(item.name.toLowerCase() == 'create') {
                            item.value == "" || item.value == "none" ? setCreate(false)  : setCreate(true) 
                       }
           
                   })
               }
            }
            else {
                setViewAccess(false)
            }
        }
    }, [subLevelPermissionsList, userProfile])

    useEffect(() => {
        const filters = { pageSize: 100 }
        dispatch(propertyListGetRequest("sources",filters));
        setMetaData(sourceMetadata)
    }, [])

    useEffect(() => {
        setMetaData(sourceMetadata);
    }, [sourceMetadata]);

  
    const handleOnvalueSave = () => {
        Object.keys(record).filter(key => record[key] !== inputs[key])
    }

    const handleLeadClick = (lead: any) => {
        setrecord(lead)
        dispatch(sourceGetRequest(lead.id))
    };

    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                newPageNo = 0;
                setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                newPageNo = lastPage - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        dispatch(SourceListGetRequest(filterObject, newPageNo));
        setsourceJson([])
    };

    const onDelete = () => {
        const handleSuccess = (body: any) => {
            const message = () => toast(`Source Deleted Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(SourceListGetRequest())
            setisDeleteOpen(false)
        }
        const handleFailure = (body: any) => {
            // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            // message();
            setisDeleteOpen(false)
        }
        dispatch(sourceDelete(record.id, handleSuccess, handleFailure))
    }

    const handleEditClick = (data: any) => {

    }

    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    }, [paginationDetails])

    const toggleEnable = (el1: any) => {
        let a: any = document.getElementById(el1.target.id)
        a.disabled = true;
    }

    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            //Error
            //JSON is not okay
            return false;
        }
    }

    const handleAccess = (permissionJson : any) => {        
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if(userRole === "Owner") return true 
        else if(permissions) {
            switch(permissions) {
                case "Owner": 
                case "owner": if(userRole === "Owner") return true;
                                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based":  const items = permissionJson.item || [];
                                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
                                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                                    return isview && isview.length;
                default: return false;
            }

        }
    }

    const handleselectValue = (value: any, options: any) => {  
        if (options && options.length) {
            const foundItem = options.find((item: any) => item.value === value);
            if (foundItem) {
                return foundItem.label;
            }
        }
        return null; // or any default value you prefer
    }

    useEffect(() => {
        console.log("useEffect", useEffect)
        let columns: any[] = [];
        if (metaData && metaData.length > 0) {
            metaData.map((fieldName: any) => {
                let fields = fieldName.valuesJson  && isJson(fieldName.valuesJson) ? JSON.parse(fieldName.valuesJson) : {} 
                let permissionsJson = fieldName?.permissionsJson ? JSON.parse(fieldName?.permissionsJson)  : {}
                let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleAccess(permissionsJson) :  true;          
                if(isFieldviewaccess) {  
                    let newColumn = {
                        Header: _.startCase(fields.label),
                        accessor: fields.key,
                        id: fields.key,
                        filterable: true,
                        isSortable: false,
                        disableFilters: true,
                        valueJson: fields,
                        meta: fieldName,
                        show: fields.tablecolumn,
                        Cell: (cellProps: any) => {
                            let rendervalue = cellProps.row.original[fields.key];
                            if(fields.type=='select' || fields.type=='radio' || fields.type=='checboxes'){
                                rendervalue = handleselectValue(cellProps.row.original[fields.key], fields.values);
                            }
                            return  <div className="d-flex align-items-center justify-content-between">                                      {
                                            fields.key == "name" ? <Row style={{width: 250}} className='justfy-content-start align-items-center'>                                           
                                                <Col md={11} lg={11} className={'gap-1 hstack justify-content-between'}>
                                                    <Link to={`/sources/${cellProps.row.original.id}`} className="text-decoration-none text-ellipsis" >                                            
                                                        {cellProps.row.original[fields.key]}
                                                    </Link>
                                                    <Button
                                                        size='sm'
                                                        onClick={() => { setpreview(true); handleLeadClick(cellProps.row.original) }}
                                                        color="primary"
                                                        className="btn-primary justfy-self-end hoverbutton">
                                                            <i className="ri-layout-right-2-fill label-icon align-middle fs-16 me-2"></i>
                                                            {props.t("student.preview")}
                                                    </Button>
                                                </Col>                                           
                                            </Row> : 
                                            <div>
                                                {rendervalue}
                                            </div>
                                        }
                                    </div>
                        }
                    }
                    columns.push(newColumn)
                }   
            });
           
            const nameColumnIndex = columns.findIndex(column => column.accessor === "name");
        
            // If "Name" column is found, move it to the beginning
            if (nameColumnIndex !== -1) {
                const nameColumn = columns.splice(nameColumnIndex, 1)[0]; // Remove the "Name" column
                columns.unshift(nameColumn); // Add it to the beginning
            }  
            let parentColumn = {
                Header:"Parent",
                accessor: "parent",
                id: "parent",
                filterable: true,
                type:'textfield',
                isSortable: false,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => {
                    return  <div className="d-flex align-items-center justify-content-between">                                
                               {cellProps.row.original["parent"] ? cellProps.row.original["parent"]:
                    ''}
                        </div>
                }
            }
            if (nameColumnIndex !== -1) {
                columns.splice(nameColumnIndex + 1, 0, parentColumn); // Add new element after nameColumnIndex
              }
            const shortnameindex = columns.findIndex(column => column.accessor === "source_short_name");
        
              if (nameColumnIndex !== -1) {
                const  shortname = columns.splice(shortnameindex, 1)[0]; // Remove the "Name" column
                columns.splice(nameColumnIndex + 1, 0, shortname);  // Add it to the beginning
            }  
              
            columns.push({
                Header:"Created At",
                accessor: "createdAt",
                id: "createdAt",
                filterable: true,
                type:'datetime',
                isSortable: false,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => {
                    return  <div className="d-flex align-items-center justify-content-between">                                
                               {cellProps.row.original["createdAt"] ? moment(cellProps.row.original["createdAt"]).format('lll'):
                    ''}
                        </div>
                }
            })
        }
        setvisibleColumns(columns)
        setColumns(columns)
        setTempColumns(columns)
    }, [metaData, userProfile]);


    useEffect(() => {
        setMetaData(sourceMetadata)
    }, [sourceMetadata != undefined])

    const onchange = (column: { accessor: string; }) => {
        let index = visibleColumns.findIndex((x: { accessor: string; }) => x.accessor === `${column.accessor}`);
        let newCols = visibleColumns
        if (column.accessor == 'created_date' || column.accessor == 'updated_date') {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => { return moment(cellProps.row.original.heading).format('DD MMM, YYYY'); }
            }
        }
        else {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
            }
        }
        setvisibleColumns(newCols)
    }

    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        let a = Object.keys(record).filter(key => record[key] !== inputs[key])
        setisSaveOpen(true)
        setInputs({
            ...inputs,
            [name]: value
        });
    }

    const onClickmove = (column: { accessor: string; }) => {
        const newCols = [...columns]
        const index = columns.findIndex((obj: { accessor: string; }) => obj.accessor === column.accessor);
        if (column.accessor == 'created_date' || column.accessor == 'updated_date') {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
                Cell: (cellProps: any) => { return moment(cellProps.row.original.heading).format('DD MMM, YYYY'); }
            }
        }
        else {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
            }
        }
        setvisibleColumns(newCols)
    }


    const toggleColumn = (columnAccessor: any) => {
        setTempColumns((prevColumns: any[]) => {
            const updatedColumns = prevColumns.map(column => {
                if (column.accessor === columnAccessor) {
                    return { ...column, show: !column.show };
                }
                return column;
            });
            return updatedColumns;
        });
    };

    const onApplyClick = () => {
        setColumns(tempcolumns)
        setEditOpen(false)
    }

    const handleChange = (value: any) => {
        setsearch(value);
        const filters = { ...filterObject, search: value }
        setFilterObject(filters)
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {
            dispatch(SourceListGetRequest(filters));
            setsourceJson([])
        }, 2000);
        setTypingTimeout(newTimeout);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            const filters = {...filterObject}
            setFilterObject(filters)
            dispatch(SourceListGetRequest(filters));
            setsourceJson([])
        }
    };

    const ClearFilter = () => {
        setsearch("")
        setstatusvalue("")
        setApplyFilter(false);
        setFilterJson([])
        let filter= {pageSize: pageSize}
        setFilterObject(filter)
        setFilterCount(0)
        setPageSize(pageSize)
        setPagination({page: 0})
        dispatch(SourceListGetRequest(filter));
    }

    const deSelectColumnall = () => {
        let tempcolumnsNew: any = []
        tempcolumnsNew = columns.map((column: any, index: number) => {
            if (column.accessor == 'name') return {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true
            }
            else return {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
            }
        })
        setColumns(tempcolumnsNew)
        setTempColumns(tempcolumnsNew)
        setEditOpen(false)
    }


    const execute = () => {
        let a = isEqual(sourceList, sourceJson)
        return !a
    }

    useEffect(() => {
        const parsedData: any = sourceList && sourceList.length ? sourceList.map((item: any) => {
             const valuesJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson): {};           
            let values = { ...valuesJson, id: item.id, createdAt: item.createdAt, parent: item.parent }
            return values
        }) : []
        let source = parsedData && parsedData.length && parsedData.reverse()
        setSource(source)
        setsourceJson(sourceList)
    }, [execute()])


    useEffect(() => {
        dispatch(SourceListGetRequest())
        dispatch(SourceHierachyGetRequest())
    }, [])

    const applyFilters = (filters: any) => {
        dispatch(SourceListGetRequest(filters, 0));
        setsourceJson([])
    }

    const onChangeIndex = (column: any, index: any) => {
        console.log("tempcolumns", tempcolumns, "columns", columns)
        if (index !== -1) {
            const removedColumn = tempcolumns.splice(tempcolumns.indexOf(column), 1)[0];
            tempcolumns.splice(index, 0, removedColumn);
            setTempColumns([...tempcolumns]);
        }
    }

    const handleSort = (columnName: any) => {
        if (sortBy === columnName) {
            // Reverse the sorting order if the same column is clicked again
            setSortBy(columnName);
            let sort = sortOrder === 'asc' ? 'desc' : 'asc'
            setSortOrder(sort);
            let filters = { ...filterObject, sortBy: columnName, sortOrder: sort }
            setFilterObject(filters)    
            dispatch(SourceListGetRequest(filters, pagination.page));
        } else {
            // Set the new column to sort by and default to ascending order
            setSortBy(columnName);
            setSortOrder('asc');
            let filters = { ...filterObject, sortBy: columnName, sortOrder: 'asc' }
            setFilterObject(filters)    
            dispatch(SourceListGetRequest(filters, pagination.page));
        }
    };


    document.title = "DTS | Zilter";
    return (
        <>
       { !authLoading ?
            viewAccess ? 
    <React.Fragment>
        <ToastContainer />
        {isExportCSV && <ExportDataModal
            show={isExportCSV}
            onCloseClick={() => setIsExportCSV(false)}
            // data={data}
            props={props}
        />}
        {isFilterModalOpen && <AdvanceFilter
            show={isFilterModalOpen}
            onCloseClick={() => setisFilterModalOpen(false)}
            dataFields={columns}
            props={props}
            setFilterObject={setFilterObject}
            filterObject={filterObject}
            applyFilters={applyFilters}
        />}
        {isDynamicModalOpen && <DynamicFilters
            show={isDynamicModalOpen}
            onCloseClick={() => setIsDynamicModalOpen(false)}
            dataFields={columns}
            props={props}
            setFilterObject={setFilterObject}
            filterObject={filterObject}
            applyFilters={applyFilters}
            filterJson={filterJson}
            setFilterJson={setFilterJson}
            filterCount={filterCount}
            setFilterCount={setFilterCount}
        />}
        {previewModal && <PreviewModal
            show={previewModal}
            onCloseClick={() => setpreview(false)}
            record={record}
            ontoggle={toggle}
            open={open}
            setrecord={setrecord}
            metaData={metaData}
        />}
        {editModal && <EditModal
            show={editModal}
            onCloseClick={() => setEditOpen(false)}
            onApplyClick={onApplyClick}
            onchange={onchange}
            columns={columns}
            visibleColumns={visibleColumns}
            toggleColumn={toggleColumn}
            onClickmove={onClickmove}
            deSelectColumnall={deSelectColumnall}
            onChangeIndex={onChangeIndex}
            tempcolumns={tempcolumns}
            setTempColumns={setTempColumns}
        />}
        {iscreateModal && <AddEntryModal
            show={iscreateModal}
            onCloseClick={() => setCreateOpen(false)}
            dataFields={metaData}
            props={props}
        />}
        {isImportCSV && <ImportDataModal
            show={isImportCSV}
            onCloseClick={() => setIsImportCSV(false)}
            props={props}
        />}
        {isDeleteOpen && <DeleteModal
            show={isDeleteOpen}
            onCloseClick={() => setisDeleteOpen(false)}
            props={props}
            record={record}
            onDelete={onDelete}
        />}

        <Row className='h-100'>
            <Col xl={12} lg={12} md={12} className='h-100'>
                <div>
                    <Card className='p-3'>
                        <CardBody className="vstack gap-2">
                            <Row className='hstack gap-3'>
                                <Col md={3} sm={12} xl={3} xxl={3} lg={3}>
                                    <div className="search-box">
                                        <Input
                                            type="text"
                                            size={14}
                                            className="search"
                                            onKeyDown={(e) =>handleKeyDown(e)}
                                            placeholder={props.t("sources.search_for")}
                                            onChange={(e) => handleChange(e.target.value)}
                                            value={search}
                                        />
                                        {search == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleChange("")}></i>}

                                    </div>
                                </Col>
                                <Col>
                                    <div className='hstack gap-2 justify-content-end'>
                                        <div className='hstack gap-2'>
                                        {
                                            create ? 
                                            <Button
                                                onClick={() => setCreateOpen(true)}
                                                color="primary"
                                                className="btn-label">
                                                    <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
                                                    {props.t("applications.create_entry")}
                                            </Button>
                                        :         
                                            <Button
                                                // onClick={() => setCreateOpen(true)}
                                                color="primary"
                                                disabled
                                                className="btn-label">
                                                    <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
                                                    {props.t("applications.create_entry")}
                                            </Button>}
                                            <Button
                                                onClick={() => setEditOpen(true)}
                                                color="primary"
                                                className="btn-label btn-soft-primary">
                                                    <i className="ri-layout-column-fill label-icon align-middle fs-16 me-2"></i>
                                                    {props.t("sources.edit_columns")}
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <div>
                                    <MoreFilters
                                        dataFields={metaData}
                                        setFilterObject={setFilterObject}
                                        filterObject={filterObject}
                                        applyFilters={applyFilters}
                                        ClearFilter={ClearFilter}
                                        setApplyFilter={setApplyFilter}
                                        setIsDynamicModalOpen={setIsDynamicModalOpen}
                                        filterCount={filterCount}
                                        props={props}
                                    />
                                </div>
                            </Row>
                            <Row className='students-table mt-2'>
                                <TableContainer
                                    columns={columns && columns.length ? columns.filter((column: any) => column.show == true): []}
                                    data={source || []}
                                    customPageSize={pageSize}
                                    SearchPlaceholder='Search...'
                                    divClass="table-responsive"
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light text-muted"
                                    thClass="border-bottom-1 table-soft-primary"
                                    isPageSizeChange={true}
                                    isBordered={true}
                                    handleEditClick={handleEditClick}
                                    handleLeadClick={handleLeadClick}
                                    setisDeleteOpen={setisDeleteOpen}
                                    setIsMultiDeleteButton={setIsMultiDeleteButton}
                                    setselectedcount={setselectedcount}
                                    pageChanged={pageChanged}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    pagesList={pagesList}
                                    onChangePageSize={onChangePageSize}
                                    pageSize={pageSize}
                                    setpreview={setpreview}
                                    setNotesModals={setNotesModals}
                                    handleSort={handleSort}
                                    sortBy={sortBy}
                                    sortOrder={sortOrder}
                                    props={props}
                                    access={access}
                                    loading={loading}
                                    dataloading={dataloading}
                                />
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </Col>
        </Row>
    </React.Fragment>
     : <AccessDenied />
     : <ListingLoader             
        columns={columns} 
        tableClass="align-middle table-nowrap"
        theadClass="table-light text-muted"
        thClass="border-bottom-1 table-soft-primary"
    /> 
    }
    </>
    );
};
export default withTranslation()(Sources);



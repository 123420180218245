import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner ,Table, Dropdown, DropdownToggle, DropdownItem, DropdownMenu, Nav, TabContent, TabPane, NavLink, NavItem, ButtonGroup} from 'reactstrap';
import _ from 'lodash';
import TableContainer from '../TableComponent';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { withTranslation } from 'react-i18next';
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { propertyArchivedListGetRequest, propertyDeleteRequest, GroupGetByModelRequest, groupCreateRequest, groupDelete, groupUpdateRequest } from 'store/properties/action';
import { ApplicationState } from 'store';
import isEqual from 'lodash/isEqual';
import { usersListGetRequest } from 'store/user/action';
import moment from 'moment';
import AddEntry from './AddEntry';
import DeleteModal from './DeleteModal';
import UpdateEntry from './UpdateEntry';
import ListingLoader from 'Components/Common/FormBuilder/DtsTablePlaceholder';
import AccessDenied from 'pages/AccessDenied/Denied';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Groups = (props: any) => {
    console.log("props", props, props.model)
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();   
    const groups = useSelector((state: ApplicationState) => state.properties.groups); 
    console.log("groups", groups)
    const paginationDetails = useSelector((state: ApplicationState) => state.properties.groupspagination);   
    const loading = useSelector((state: ApplicationState) => state.properties.groupsloading);   
    const authLoading = useSelector((state: ApplicationState) => state.auth.meloading);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    // const achivedpagination = useSelector((state: ApplicationState) => state.properties.achivedpagination); 
    const [isEntryOpen, setisEntryOpen] = useState(false)
    const [isEditOpen, setisEditOpen] = useState<boolean>(false);
    const [pageSize, setPageSize] = useState<number>(10)
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);  
    const [columns, setColumns] = useState<any>([])
    const [propertiesData, setPropertiesData] = useState<any>([])
    const [viewAccess, setViewAccess] = useState<boolean>(false);
    const [access, setAccess] = useState<any>([]);
    const [create, setCreate] = useState<boolean>(false);
    const [edit, setEdit] = useState(false);
    const [record, setrecord] = useState<any>({});
    const users = useSelector((state: ApplicationState) => state.user.users);
    const [filterObject, setFilterObject] =  useState<any>({});
    const [arrowNavTab, setarrowNavTab] = useState<any>("1"); 
    const [sortBy, setSortBy] = useState<any>("")
    const [sortOrder, setSortOrder] = useState<string>("")  
    const [pagesList, setPagesList] = useState<any>([]);
    const [propertiesJson, setPropertiesJson] = useState<any>([])
    const [isDeleteOpen, setisDeleteOpen] = useState<any>(false)
    const [propertyObject, setpropertyObject] = useState<any>({
        label: "Students",
        value: "students"
    })
    const [EditInputs, setEditInputs] = useState<any>({
        objectType: propertyObject.value,
        label: '',
        description: '',
        fieldtype: '',
        options: [],
        rules: []
    });
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            totalPages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false,
            totalElements: 0
        }
    )
    const [dropdownOpen, setDropdownOpen] = useState(false);  
    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);
   
    
    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setViewAccess(true)
            setCreate(true) 
            setEdit(true)
        }        
        else if(subLevelPermissionsList) {      
            let access = subLevelPermissionsList?.sections;
            if(access && access?.length) {
                setAccess(access)
                setViewAccess(true)
                if(access && access.length) {            
                    access.map((item: any, index: any) => {
                       if(item.name.toLowerCase() == 'create') {
                            item.value == "" || item.value == "none" ? setCreate(false)  : setCreate(true) 
                       }
                       if(item.name.toLowerCase() == 'edit') {
                            item.value == "" || item.value == "none" ? setEdit(false)  : setEdit(true) 
                        }           
                   })
               }
            }
            else {
                setViewAccess(false)
                setEdit(false)
            }
        }
    }, [subLevelPermissionsList, userProfile])

    useEffect(() => {
        if(groups && groups.length) {
            let fields =  groups[0]
            const keys = Object.keys(fields)
            const columns: any = []
            if(keys && keys.length) {
                keys.map((field,index) =>{  
                    if(field == 'groupTitle')  return columns[0] = {
                        Header: "Name",
                        accessor: field,
                        filterable: true,
                        disableFilters: true,
                        isSortable: true,
                        show: true,
                        Cell: (cellProps: any) => {   
                            // let fields =  cellProps.row.original && cellProps.row.original.valuesJson ? JSON.parse(cellProps.row.original.valuesJson) : []    
                            return  <div className="d-flex align-items-center justify-content-between">                                
                            <div className="hstack flex-grow-1  gap-2">
                                    <div className="flex-shrink-0 text-decoration-none hstack flex-grow-1 gap-2" >                                 
                                   
                                    {cellProps.row.original[field]}
                                </div>
                            </div>
                            {
                                field == "groupTitle" ? <div  className={"btn_preview"}>
                                    <>{console.log("cellProps.row", cellProps.row)}</>
                                    <div className={"hstack gap-2"}>
                                        {edit ?
                                            <Button 
                                                size='sm'
                                                color="dark"
                                                outline={true}
                                                className={""}   
                                                onClick={() => {setisEditOpen(true); setrecord(cellProps.row.original);}} >
                                                    {props.t("properties.edit")}
                                            </Button> 
                                            : null}
                                    </div>
                                </div>
                                    : null
                                }
                    </div>                        
                            
                        }
                    }
                    else if(field == 'model') return columns[1] = {
                        Header: "Model",
                        accessor: field,
                        filterable: true,
                        isSortable: true,
                        disableFilters: true,
                        show: true,
                        Cell: (cellProps: any) => {   
                            // let fields =  cellProps.row.original && cellProps.row.original.valuesJson ? JSON.parse(cellProps.row.original.valuesJson) : []    
                            return  <div className="d-flex align-items-center justify-content-between">                                
                                <div className="hstack flex-grow-1  gap-2">
                                        <div className="flex-shrink-0 text-decoration-none hstack flex-grow-1 gap-2" >                                 
                                        {cellProps.row.original[field].charAt(0).toUpperCase() + cellProps.row.original[field].slice(1)}
                                        {/* { cellProps.row.original[field].toUpperCase()} */}
                                    </div>
                                </div>
                            </div>                        
                            
                        }
                    }
                })
                setColumns(columns)
            }   
            // columns.push({
            //     Header:"Created At",
            //     accessor: "createdAt",
            //     id: "createdAt",
            //     filterable: true,
            //     isSortable: false,
            //     disableFilters: true,
            //     show: true,
            //     Cell: (cellProps: any) => {
            //         return  <div className="d-flex align-items-center justify-content-between">                                
            //                    {cellProps.row.original["createdAt"] ? moment(cellProps.row.original["createdAt"]).format('lll'):
            //         ''}
            //             </div>
            //     }
            // })        
        }
    }, [groups, edit]); 
    
    const handleSort = (columnName: any) => {
        console.log("columnName", columnName)
        if (sortBy === columnName) {
          // Reverse the sorting order if the same column is clicked again
          setSortBy(columnName);
          setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
          let sort = sortOrder === 'asc' ? 'desc' : 'asc'
          let filters = {...filterObject, sortBy: columnName,sortOrder: sort}
          setFilterObject(filters)          
          dispatch(GroupGetByModelRequest(props.model,filters, pagination.page));
        } else {
          // Set the new column to sort by and default to ascending order
          setSortBy(columnName);
          setSortOrder('asc');
          let filters = {...filterObject, sortBy: columnName, sortOrder: 'asc'}   
          setFilterObject(filters)           
          dispatch(GroupGetByModelRequest(props.model,filters, pagination.page));
        }
      };

    useEffect(() => {
        // dispatch(GroupGetByModelRequest(props.model))
        // dispatch(propertyArchivedListGetRequest(props.model))
        dispatch(usersListGetRequest())        
    }, []); 


    const execute = () => {
        let a = isEqual(groups, propertiesJson);
        return !a
     }

    useEffect(() => {
        const properties: any = groups && groups.length ? groups.map((item: any) => {
            return item
        })  : [];
        let groupList = properties.reverse()
        setPropertiesData(groupList)
        setPropertiesJson(groups)
    }, [execute()])

    
    const pageChanged = (pageVal: any) => {
        console.log("pageVal", pageVal)
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                    newPageNo = 0;
                    setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                    newPageNo = lastPage - 1;
                    setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal-1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        console.log("props.model", props.model)
        dispatch(GroupGetByModelRequest(props.model,filterObject, newPageNo));
    };

    const onTabChange = () => {

    }

    
    const onChangePageSize = (value: any) => {
        setPagination({...pagination, pageSize: value})
        setPageSize(value);
        let filters= {...filterObject, pageSize: value}
        setFilterObject(filters)
        dispatch(GroupGetByModelRequest(props.model,filters, 0));
    }

    const arrowNavToggle = (tab:any) => {
        if (arrowNavTab !== tab) {
            setarrowNavTab(tab);
        }
    };

    const dissortbyMulti = [
        { label: "All Field type", value: "" },
        {
            label: "Text input",
            options: [
                { label: "Single-line text", value: "textfield" },
                { label: "Multi-line text", value: "textarea" },
                { label: "Phone number", value: "phone" },
                { label: "Email", value: "email" },
            ]
        },
        {
            label: "Choosing options",
            options: [
                { label: "Single checkbox", value: "checkbox" },
                { label: "Multiple checkboxes", value: "selectboxes" },
                { label: "Date picker", value: "datetime" },
                { label: "Dropdown select", value: "select" }, 
                { label: "Suggest dropdown", value: "datasetselect" },                 
                { label: "Radio select", value: "radio" }
            ]
        },
        {
            label: "values",
            options: [
                { label: "Number", value: "number" }
            ]
        },
        {
            label: "other",
            options: [
                { label: "File", value: "file" },
            ]
        }

    ];


    useEffect(() => {        
        if (paginationDetails) {            
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    },[paginationDetails])

    useEffect(() => {        
        dispatch(GroupGetByModelRequest(props.model));  
        dispatch(propertyArchivedListGetRequest(props.model)); 
    },[props.model])

    const objectOption = [
        {
            label: "Students",
            value: "students"
        },
        {
            label: "Applications",
            value: "applications"
        }
    ]
    const onSave = (groupName: string) => {
        const data = {
            "groupTitle": groupName,
            "model": props.model
            }
            const handleSuccess = () => {
                let filters= {...filterObject, pageSize: pageSize}
                console.log("filters", filters)
                dispatch(GroupGetByModelRequest(props.model, filters, 0))
                const message = () => toast(`Group created successfully...!`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
                message();
                setisEntryOpen(false)
            }
            const handleFailure = () => {
            
            }
            dispatch(groupCreateRequest(data, handleSuccess, handleFailure ))
    }

    const onUpdate = (groupName: string) => {
        console.log("record", record)
        const data = {
            "groupTitle": groupName,
            "model": props.model
            }
            const handleSuccess = () => {
                let filters= {...filterObject, pageSize: pageSize}
                dispatch(GroupGetByModelRequest(props.model, filters, pagination.page))
                const message = () => toast(`Group updated successfully...!`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
                message();
                setisEditOpen(false)
            }
            const handleFailure = () => {
            
            }
            console.log("data", data)
            dispatch(groupUpdateRequest(record.id,data, handleSuccess, handleFailure ))
    }

    const onDelete = () => {
        const handleSuccess = () => {
            let filters= {...filterObject, pageSize: pageSize}
            dispatch(GroupGetByModelRequest(props.model, filters,pagination.page))
            const message = () => toast(`Group created successfully...!`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            setisDeleteOpen(false)
        }
        const handleFailure = () => {
            setisDeleteOpen(false)
            let filters= {...filterObject, pageSize: pageSize}
            dispatch(GroupGetByModelRequest(props.model, filters,pagination.page))
        }
        dispatch(groupDelete(record.id, handleSuccess, handleFailure ))
    }
    document.title="Properties | Zilter";
    return (
        <div> { !authLoading ?
            viewAccess ? 
            <React.Fragment>
                <ToastContainer />
                {isEntryOpen && <AddEntry
                    show={isEntryOpen}
                    onCloseClick={() => setisEntryOpen(false)}
                    onSave={onSave}
                    props={props}
                    model={props.model}
                />}
                {isEditOpen && <UpdateEntry
                    show={isEditOpen}
                    onCloseClick={() => setisEditOpen(false)}
                    onUpdate={onUpdate}
                    record={record} 
                    props={props}
                />}
                {isDeleteOpen && <DeleteModal 
                    show={isDeleteOpen}
                    onCloseClick={() => setisDeleteOpen(false)}
                    onDelete={onDelete}
                    record={record} 
                    props={props}
                />}
                <Card>
                    <CardBody className='border border-dashed border-end-0 border-start-0 vstack gap-2'>
                        <Row className='hstack gap-2 justify-content-start m-0'>              
                            <Col className="p-0 m-0" >
                                <div className='hstack gap-2 justify-content-end'>
                                {
                                        create ? 
                                        <Button
                                            onClick={() => setisEntryOpen(true)}
                                            color="primary"
                                            className="btn-label">
                                                <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
                                                {props.t("properties.create_groups")}
                                        </Button>
                                    :         
                                        <Button
                                            color="primary"
                                            size='sm'
                                            disabled
                                            className="btn-label">
                                                <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
                                                {props.t('roles.create_role')}
                                        </Button>}  
                                      
                                    </div>
                            </Col>
                        </Row>
                        <Row className='students-table'>
                            <TableContainer
                                columns={columns}
                                data={propertiesData || []}
                                customPageSize={pageSize}
                                divClass="table-responsive"
                                tableClass="align-middle table-nowrap"
                                theadClass="table-light text-muted"
                                thClass="border-bottom-1 table-soft-primary"
                                SearchPlaceholder='Search...'
                                isPageSizeChange={true}
                                isBordered={true}                                            
                                pageChanged={pageChanged}
                                pagination={pagination}
                                setPagination={setPagination}
                                pagesList={pagesList}
                                onChangePageSize={onChangePageSize}
                                setisDeleteOpen={setisDeleteOpen}
                                setrecord={setrecord}
                                pageSize={pageSize}                   
                                handleSort={handleSort}
                                sortBy={sortBy}
                                sortOrder={sortOrder}     
                                props={props}  
                                loading={loading}    
                                access={access}               
                            />
                        </Row>
                    </CardBody>
                </Card>         
            </React.Fragment>: 
            <AccessDenied />
            : <ListingLoader 
                columns={columns} 
                tableClass="align-middle table-nowrap"
                theadClass="table-light text-muted"
                thClass="border-bottom-1 table-soft-primary"
        /> }
       </div>
    );
};

export default withTranslation()(Groups);



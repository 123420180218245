import React, { useEffect, useState, useCallback } from "react";
import { Button, Card, CardBody, Col, Row, UncontrolledButtonDropdown, UncontrolledCollapse } from "reactstrap";

import { Link, useParams } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import isEqual from 'lodash/isEqual';
//import Images
import avatar7 from "../../../src/assets/images/users/avatar-7.jpg";
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar6 from "../../../src/assets/images/users/avatar-6.jpg";
import avatar8 from "../../../src/assets/images/users/avatar-8.jpg";
import image2 from "../../../src/assets/images/small/img-2.jpg";
import image3 from "../../../src/assets/images/small/img-3.jpg";
import image4 from "../../../src/assets/images/small/img-4.jpg";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch, useSelector } from "react-redux";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { ApplicationState } from "store";
import { getStudentsActivity } from "store/student/action";
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import moment from "moment";
import SimpleBar from "simplebar-react";
import { SendEmail, applicationGetRequest, getApplicationsEmails, noteAddRequest, notesListGetRequest } from "store/applications/action";
import { toast } from "react-toastify";
import EditTasks from "./EditTask";
import { withTranslation } from "react-i18next";


const Activities = (props: any) => {
    let { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const activitiesList = useSelector((state: ApplicationState) => state.student.activities);
    const paginationDetails = useSelector((state: ApplicationState) => state.student.activitiesPagination);
    const applicationDetails = useSelector((state: ApplicationState) => state.applications.applicationDetails);
    const loading = useSelector((state: ApplicationState) => state.student.loading);
    const [activities, setActivities] = useState([]) 
    const [taskDetails, setTaskDetails] = useState({})
    const [previousActivities, setPreviousActivities] = useState([])
    const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
    const [showReplies, setShowReplies] = useState(false);
    const [emailOpen, setEmailOpen] = useState<any>(false);
    const [newNoteId, setNewNoteId] = useState<string | null>(null)
    const [replyNoteId, setReplyNoteId] = useState<string | null>(null);
    const [newNote, setNewNote] = useState("");
    const [replyNote, setReplyNote] = useState("")
    const [emailinfo, setEmailinfo] = useState<any>([]);
    const [page, setPage] = useState(0);
    const [items, setItems] = useState<any>([]);
    const [hasMore, setHasMore] = useState(true);
    const [isBottom, setisBottom] = useState(false);
    const [emailData, setEmailData] = useState<any>({
        applicationId: id
    })
    const [filterObject, setFilterObject] = useState<any>({
        applicationId: id
    });

    const [studentId, setStudentId] = useState<any>("");
    const toggleReplies = () => {
        setShowReplies(!showReplies);
    };

    const handleOnDocumentBottom = useCallback(async () => {
        setisBottom(true)
        let isLastPage = paginationDetails?.last;
        if (!isLastPage) {            
            setPage(page + 1)
            setisBottom(false)
        }
        else {
            setisBottom(false)
        }
    }, []);

    useEffect(() => {
        // dispatch(applicationGetRequest(id,filterObject,page))
        dispatch(getStudentsActivity(id, filterObject))
    }, [])


    useEffect(() => {
        if (applicationDetails && applicationDetails.id) {
            let studentsId = applicationDetails?.student?.id;
            setStudentId(studentsId)
            const handleSuccess = (data: any) => {
                setItems([data.content]);
                setHasMore(!data.last);
            }
            dispatch(getStudentsActivity(studentsId, filterObject, page, handleSuccess))
        }
    }, [applicationDetails])

    function calculateTimeDifference(targetTime: any) {
        const currentTime: any = new Date();
        const timeDifference = Math.abs(targetTime - currentTime);
        const seconds = Math.floor(timeDifference / 1000) % 60;
        const minutes = Math.floor(timeDifference / (1000 * 60)) % 60;
        const hours = Math.floor(timeDifference / (1000 * 60 * 60)) % 24;
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        return {
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds
        };
    }

    const Status = ({ status }: any) => {
        switch (status) {
            case "NEW":
                return <span className="badge bg-info-subtle  text-info text-uppercase">{status}</span>;
            case "PENDING":
                return <span className="badge bg-warning-subtle  text-warning text-uppercase">{status}</span>;
            case "INPROGRESS":
                return <span className="badge bg-secondary-subtle text-secondary  text-uppercase">{status}</span>;
            case "COMPLETED":
                return <span className="badge bg-success-subtle text-success text-uppercase">{status}</span>;
            default:
                return <span className="badge bg-success-subtle text-success text-uppercase">{status}</span>;
        }
    };

    const Priority = ({ priority }: any) => {
        switch (priority) {
            case "HIGH":
                return <span className="badge bg-danger text-uppercase">{priority}</span>;
            case "MEDIUM":
                return <span className="badge bg-warning text-uppercase">{priority}</span>;
            case "LOW":
                return <span className="badge bg-success text-uppercase">{priority}</span>;
            default:
                return <span className="badge bg-success text-uppercase">{priority}</span>;
        }
    };

    const rendertime = (time: any) => {
        if (time.days > 0) return <small className="mb-0 text-muted">{time.days} {props.t("applications.days")} {props.t("applications.ago")}</small>
        else if (time.hours > 0) return <small className="mb-0 text-muted">{time.hours} {props.t("applications.hours")}   {props.t("applications.ago")}</small>
        else if (time.minutes > 0) return <small className="mb-0 text-muted">{time.minutes} {props.t("applications.minutes")}  {props.t("applications.ago")}</small>
        else if (time.seconds > 0) return <small className="mb-0 text-muted">{time.seconds} {props.t("applications.seconds")} {props.t("applications.ago")}</small>
    }

    useEffect(() => {
        console.log("activitiesList", activitiesList)
        if (activitiesList && activitiesList.length) setItems(activitiesList)
        if (paginationDetails && paginationDetails.last) setHasMore(false)
    }, [activitiesList])

    useEffect(() => {
        let element: any = document.getElementById('emailsrighbBar');
        if (element && element[0]) element[0].classList.remove("email-detail-show")
    }, [])

    useEffect(() => {
        let element: any = document.getElementById('actiivtytab');
        if (element) {
            emailOpen ? element.classList.add("email-detail-show") : element.classList.remove("email-detail-show")
        }
    }, [emailOpen])

    const toggleReply = (noteId: string) => {
        if (replyNoteId === noteId) {
            setReplyNoteId(null);
        } else {
            setReplyNoteId(noteId);
        }
    };

    const rightOpenState = (e: any, emailinfo: any) => {
        e.preventDefault()
        setEmailinfo(emailinfo)
        setEmailOpen(!emailOpen);
    }

    const handleReplyNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReplyNote(event.target.value);
        setNewNoteId("");
        setReplyNoteId(replyNoteId);
        console.log(newNote, newNoteId)
    };

    const handleReplyNote = () => {
        const data =
        {
            applicationId: id,
            studentId: "",
            parentId: replyNoteId,
            note: replyNote
        }
        const handleSuccess = (body: any): void => {
            const message = () => toast(`Reply sent successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(notesListGetRequest(studentId, filterObject));
            setReplyNote("");
            let studentsId = applicationDetails && applicationDetails.student && applicationDetails.student.id ? applicationDetails.student.id : "";
            const handleSuccess = (data: any) => {
                setItems([data.content]);
                setHasMore(!data.last);
            }
            dispatch(getStudentsActivity(studentsId, filterObject, 0, handleSuccess))
        };

        const handleError = (body: any): void => {
            const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            message();
        };

        dispatch(noteAddRequest(data, handleSuccess, handleError));
    };

    const renderComments = (comment: any, key: any) => {
        const targetTime = new Date(comment?.createdAt).getTime();
        let time = calculateTimeDifference(targetTime)
        let collapse = comment.replies && comment.replies.length ? true : false;
        console.log("comment", comment)
        return (
            <React.Fragment>
                {
                    collapse ? <div className="accordion accordion-flush mb-3 cursor-pointer w-100">
                        <div className="hstack gap-2 justify-content-between">
                            {/* <div> */}
                                <div className="d-flex align-items-center" id={`note-collapse1-${key}`}>
                                    <div className="flex-shrink-0">
                                        <img src={avatar3} alt="" className="avatar-xs rounded-circle acitivity-avatar" />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h5 className="fs-15 mb-1">
                                            <Link to="/pages-profile">{comment?.createdBy?.name}</Link>{" "}
                                            <small className="text-muted">{moment(comment?.createdAt).format('MMMM DD, YYYY hh:mm A')}</small>
                                        </h5>
                                        <p className="text-muted mb-0">{comment?.detailedNote}</p>
                                    </div>
                                </div>
                                <div className="align-selt-start" id={`note-collapse1-${key}`}>
                                    <i className="ri-arrow-down-s-line fs-24"></i>
                                </div>
                            {/* </div> */}

                        </div>
                        <UncontrolledCollapse toggler={`#note-collapse1-${key}`}>
                            <div className="accordion-body text-body px-0">
                                {comment.replies && comment.replies.length > 0 && (
                                    comment.replies.map((item: any, index: any) => {
                                        return <div className="ms-5">
                                            {renderComments(item, item.id)}
                                        </div>
                                    }

                                    )
                                )}
                            </div>
                        </UncontrolledCollapse>
                        <small className="ms-5 fs-14 text-muted">{rendertime(time)}</small>
                    </div>
                        : <div className="vstack mb-3">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                    <img src={avatar3} alt="" className="avatar-xs rounded-circle acitivity-avatar" />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <h5 className="fs-15 mb-1">
                                        <Link to="/pages-profile">{comment?.createdBy?.name}</Link>{" "}
                                        <small className="text-muted">{moment(comment?.createdAt).format('MMMM DD, YYYY hh:mm A')}</small>
                                    </h5>
                                    <p className="text-muted mb-0">{comment?.detailedNote}</p>
                                </div>
                            </div>
                            <div className="ms-5 mb-2">
                                {replyNoteId === comment.id ? (
                                    <div>
                                        <textarea className="form-control bg-light border-light" value={replyNote} onChange={handleReplyNoteChange} rows={3} placeholder={props.t("applications.reply_to_this_note")}></textarea>
                                        <div className="d-flex gap-3">
                                            <button onClick={(e) => {
                                                handleReplyNote();
                                                e.preventDefault();
                                                toggleReply(comment.id);
                                            }} disabled={!replyNote} className="btn btn-sm btn-success mt-1">{props.t("applications.reply")}</button>
                                            <button onClick={() => toggleReply(comment.id)} className="btn btn-sm btn-danger mt-1">{props.t("applications.cancel")}</button>
                                        </div>
                                    </div>
                                ) :
                                    <>
                                        <Link to="#" className="badge text-muted bg-light" onClick={() => {
                                            // e.preventDefault(); 
                                            toggleReply(comment.id)
                                            setReplyNoteId(comment.id)
                                        }}>
                                            <i className="mdi mdi-reply"></i> {props.t("applications.reply")}
                                        </Link>
                                    </>
                                }
                            </div>
                            <small className="ms-5 fs-14 text-muted">{rendertime(time)}</small>
                        </div>
                }
            </React.Fragment>
        );
    };

    const rendertask = (task: any, key: any) => {
        const targetTime = new Date(task?.taskAssignments[0]?.assignedAt).getTime();
        let time = calculateTimeDifference(targetTime)
        console.log("task", task)
        return (
            <React.Fragment>
                {
                    <div className="accordion accordion-flush mb-3">
                        <div className="d-flex align-items-center" id={`note-collapse1-${key}`}>
                            <div className="flex-shrink-0">
                                <img src={avatar7} alt="" className="avatar-xs rounded-circle acitivity-avatar" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h5 className="fs-15 mb-1">
                                    <span className="text-primary cursor-pointer" onClick={() => { setTaskDetails(task); setIsEditOpen(true) }}>{task?.title}</span>{" "}
                                    <Status status={task.status.toUpperCase()} />
                                    <Priority priority={task.priority.toUpperCase()} />
                                </h5>
                                <div className="avatar-group">
                                    {
                                        task?.taskAssignments[0]?.assignedTo ?
                                            <Link to="#" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-placement="top" title={task?.taskAssignments[0]?.assignedTo?.name} data-bs-original-title="Christi">
                                                <img src={avatar4} alt="" className="rounded-circle avatar-xs" />
                                            </Link> : null
                                    }
                                </div>
                            </div>
                        </div>
                        <small className="ms-5 fs-14 text-muted">{rendertime(time)}</small>
                    </div>
                }
            </React.Fragment>
        )
    }

    const renderEmails = (emailinfo: any, key: any) => {
        const targetTime = new Date(emailinfo?.createdAt).getTime();
        let time = calculateTimeDifference(targetTime)
        let innerHtml = emailinfo.body ? emailinfo.body.replace(/<[^>]+>/g, '') : "";
        let teaser = innerHtml ? innerHtml.substring(0, 256) + '...' : ""
        return (
            <React.Fragment>
                <div className="accordion accordion-flush mb-3">
                    <div className="accordion-item border-dashed left">
                        <div className="accordion-header">
                            <a className="btn w-100 text-start px-0 bg-transparent shadow-none collapsed" id={`email-collapse1-parent-${key}`}>
                                <div className="d-flex align-items-center text-muted">
                                    <div className="flex-shrink-0 avatar-xs rounded-circle acitivity-avatar me-3">
                                        <img src={avatar1} alt="" className="img-fluid rounded-circle" />
                                    </div>
                                    <div className="flex-grow-1 overflow-hidden">
                                        <h5 className="fs-15 mb-1">
                                            <Link to={""}>{emailinfo?.subject}</Link>{" "}
                                            <small className="text-muted">{moment(emailinfo?.createdAt).format('MMMM DD, YYYY hh:mm A')}</small>
                                        </h5>
                                        <span className="teaser" onClick={(e) => e.preventDefault()}>{teaser}</span><span className="text-primary" onClick={(e) => rightOpenState(e, emailinfo)}>{props.t("applications.more")}</span>

                                    </div>{" "}
                                </div>
                            </a>
                        </div>
                        <small className="ms-5 fs-14 text-muted">{rendertime(time)}</small>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const handleComponent = (type: any, item: any, index: any) => {
        switch (type) {
            case "Note": return renderComments(item, index);
            case "Task": return rendertask(item, index);
            case "Email": return renderEmails(item, index);
        }
    }

    const onReply = () => {
        let toemail = emailinfo && emailinfo.to ? emailinfo.to : "";
        const data = {
            parentId: emailinfo.id,
            studentId: studentId,
            applicationId: id,
            to: toemail,
            subject: emailinfo.subject,
            body: emailData.body
        }
        const handleSuccess = () => {
            dispatch(getApplicationsEmails(studentId, filterObject));
            setEmailData({})
            setEmailOpen(false)
            let studentsId = applicationDetails && applicationDetails.student && applicationDetails.student.id ? applicationDetails.student.id : "";
            const handleSuccess = (data: any) => {
                setItems([data.content]);
                setHasMore(!data.last);
            }
            dispatch(getStudentsActivity(studentsId, filterObject, 0, handleSuccess))
        }
        const handleFailure = () => { }
        dispatch(SendEmail(data, handleSuccess, handleFailure))
    }
    const fetchData = async () => {
        try {
            const handleSuccess = (data: any) => {
                console.log("items", items)
                setItems([...items, ...data.content]);
                setHasMore(!data.last);
                // setLoading(false);
                setPage(page + 1)
            }
            let studentsId = applicationDetails && applicationDetails.student && applicationDetails.student.id ? applicationDetails.student.id : "";           
            dispatch(getStudentsActivity(studentsId, filterObject, page + 1, handleSuccess))
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    return (
        <React.Fragment>
            {isEditOpen && <EditTasks setTaskDetails={setTaskDetails} taskDetails={taskDetails} isEditOpen={isEditOpen} setIsEditOpen={setIsEditOpen} />}

            <Card>
                <CardBody>
                    <div className="acitivity-timeline">
                        {items && items.length ? items.map((item: any, index: any) => {
                            return <div className="acitivity-item d-flex gap-1">
                                {handleComponent(item.type, item.activity, index)}
                            </div>
                        }) : null
                        }
                        {!loading && items.length == 0 ? <small className="text-muted">{props.t("student.no_activity")}</small> : null}
                        {hasMore && !loading ? <div className="acitivity-item d-flex gap-1 cursor-pointer p-1 m-1 text-primary" onClick={() => fetchData()}>Load more</div> : null}
                        {loading ? <div className="acitivity-item d-flex gap-1 p-1 m-1 text-primary">Loading...</div> : null}
                  
                    </div>
                    <div id={"emailtab"} className="email-detail-content email-detail-content-scroll h-100">
                        <div className="p-4 d-flex flex-column">
                            <div className="pb-4 border-bottom border-bottom-dashed">
                                <Row>
                                    <Col className="col">
                                        <div className="">
                                            <button type="button" className="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" onClick={() => setEmailOpen(false)}>
                                                <i className="ri-close-fill align-bottom"></i>
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <SimpleBar className="mx-n4 px-4 email-detail-content-scroll">
                                <div className="mt-4 mb-3">
                                    <h5 className="fw-bold email-subject-title">{emailinfo.subject}</h5>
                                </div>
                                <div className="accordion accordion-flush">
                                    {emailinfo && <div className="accordion-item border-dashed left">
                                        <div className="accordion-header">
                                            <a role="button" href='/#' className="btn w-100 text-start px-0 bg-transparent shadow-none collapsed" id={`email-collapse1-parent`}>
                                                <div className="d-flex align-items-center text-muted">
                                                    <div className="flex-shrink-0 avatar-xs me-3">
                                                        <img src={avatar1} alt="" className="img-fluid rounded-circle" />
                                                    </div>
                                                    <div className="flex-grow-1 overflow-hidden">
                                                        <h5 className="fs-14 text-truncate mb-0 email-user-name">{emailinfo?.createdBy?.name}</h5>
                                                        <div className="text-truncate fs-12">to:{emailinfo?.to}</div>
                                                    </div>
                                                    <div className="flex-shrink-0 align-self-start">
                                                        <div className="text-muted fs-12">{moment(emailinfo?.createdAt).format('DD MMM YYYY, HH:mm A')}</div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <UncontrolledCollapse toggler={`#email-collapse1-parent`}>
                                            <div className="accordion-body text-body px-0">
                                                <div dangerouslySetInnerHTML={{ __html: emailinfo?.body }} />
                                            </div>
                                        </UncontrolledCollapse>
                                    </div>}

                                    {emailinfo && emailinfo.replies && emailinfo.replies.length ? emailinfo.replies.map((item: any, key: any) => {

                                        return <div className="accordion-item border-dashed left">
                                            <div className="accordion-header">
                                                <a role="button" href='/#' className="btn w-100 text-start px-0 bg-transparent shadow-none collapsed" id={`email-collapse1-${key}`}>
                                                    <div className="d-flex align-items-center text-muted">
                                                        <div className="flex-shrink-0 avatar-xs me-3">
                                                            <img src={avatar1} alt="" className="img-fluid rounded-circle" />
                                                        </div>
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <h5 className="fs-14 text-truncate mb-0 email-user-name">{item?.createdBy?.name}</h5>
                                                            <div className="text-truncate fs-12">to:{item?.to}</div>
                                                        </div>
                                                        <div className="flex-shrink-0 align-self-start">
                                                            <div className="text-muted fs-12">{moment(item?.createdAt).format('DD MMM YYYY, HH:mm A')}</div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <UncontrolledCollapse toggler={`#email-collapse1-${key}`}>
                                                <div className="accordion-body text-body px-0">
                                                    <div dangerouslySetInnerHTML={{ __html: item?.body }} />
                                                </div>
                                            </UncontrolledCollapse>
                                        </div>
                                    }) : null}
                                </div>
                                <div className="mt-auto email-detail-content-scroll">
                                    <form className="mt-2">
                                        <div>
                                            <label htmlFor="exampleFormControlTextarea1" className="form-label">{props.t("applications.reply")} :</label>
                                            <div className="ck-editor-reverse">
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    onReady={(editor) => { }}
                                                    onChange={(event: any, editor: any) => {
                                                        const data = editor.getData();
                                                        setEmailData({ ...emailData, body: data })
                                                    }}
                                                />
                                            </div>
                                            <div className="bg-light px-2 py-1 rouned-bottom border">
                                                <Row>
                                                    <Col className="col-auto">
                                                        <UncontrolledButtonDropdown>
                                                            <Button color="success" className="btn-sm" onClick={(e: any) => { e.preventDefault(); onReply() }}><i className="ri-send-plane-2-fill align-bottom" /></Button>

                                                        </UncontrolledButtonDropdown>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </SimpleBar>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default withTranslation()(Activities);

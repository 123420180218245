const multikeys = [
    {key:"mobile", "keys": [ "phone number", "phone no", "contact no", "contact number", "contact", "mobile number", "mobile no","mobile", "cell phone", "phone" ]},
    {key:"date_of_birth", "keys": [ "date of birth", "birth date", "birthday", "dob", "fecha de nacimiento", "date de naissance" ]},
    {key:"email", "keys": [ "email id", "email address", "email", "email id", "contact email" ]},
    {key:"gender", "keys": [ "sex", "gender"]},
    {key:"city", "keys": [ "city", "city of residence", "residence", "town", "village" ]},
    {key:"nationality", "keys": [ "nationality", "country_of_origin", "citizenship", "nationalite", "nacionalidad" ]},
    {key:"zip_code", "keys": [ "zip_code", "postal code", "pin code" ]},
    {key:"marital_status", "keys": [ "marital_status", "marital status", "status", "relationship status" ]},
    {key:"first_name", "keys": ["name" , "first name", "given names", "prenoms"]},
    {key:"last_name", "keys": ["last name", "surname", "nom", "nombre", "nome" ]},    
    {key:"twitter", "keys": ["twitter" ]},    
    {key:"fax", "keys": ["fax" ]},    
    {key: "name", "keys": [ "full name", "name"]},
]
export default multikeys;


    
import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { Table, Row, Col, Button, CardBody, Input, Label, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Dropdown } from "reactstrap";
import { DefaultColumnFilter } from "../../Components/Common/filters";
import {
    ProductsGlobalFilter,
    CustomersGlobalFilter,
    OrderGlobalFilter,
    ContactsGlobalFilter,
    CompaniesGlobalFilter,
    LeadsGlobalFilter,
    CryptoOrdersGlobalFilter,
    InvoiceListGlobalSearch,
    TicketsListGlobalFilter,
    NFTRankingGlobalFilter,
    TaskListGlobalFilter,
} from "../../Components/Common/GlobalSearchFilter";
import { Link, useNavigate } from "react-router-dom";
import Paginator from "Components/Common/Paginator";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
interface GlobalFilterProps {
    preGlobalFilteredRows?: any;
    globalFilter?: any;
    setGlobalFilter?: any;
    SearchPlaceholder?: string;
    isProductsFilter?: boolean;
    isCustomerFilter?: any;
    isOrderFilter?: any;
    isContactsFilter?: any;
    isCompaniesFilter?: any;
    isCryptoOrdersFilter?: any;
    isInvoiceListFilter?: any;
    isTicketsListFilter?: any;
    isNFTRankingFilter?: any;
    isTaskListFilter?: any;
    isLeadsFilter?: any;
}

// Define a default UI for filtering
function GlobalFilter({
    globalFilter,
    setGlobalFilter,
    isCustomerFilter,
    isOrderFilter,
    isContactsFilter,
    isCompaniesFilter,
    isCryptoOrdersFilter,
    isInvoiceListFilter,
    isTicketsListFilter,
    isNFTRankingFilter,
    isTaskListFilter,
    isProductsFilter,
    isLeadsFilter,
    SearchPlaceholder
}: GlobalFilterProps) {
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value: any) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <React.Fragment>
            <CardBody className="border border-dashed border-end-0 border-start-0">
                <form>
                    <Row>
                        <Col sm={5}>
                            <div className={(isProductsFilter || isContactsFilter || isCompaniesFilter || isNFTRankingFilter) ? "search-box me-2 mb-2 d-inline-block" : "search-box me-2 mb-2 d-inline-block col-12"}>
                                <input
                                    onChange={(e) => {
                                        setValue(e.target.value);
                                        onChange(e.target.value);
                                    }}
                                    id="search-bar-0"
                                    type="text"
                                    className="form-control border-0 search /"
                                    placeholder={SearchPlaceholder}
                                    value={value || ""}
                                />
                                <i className="bx bx-search-alt search-icon"></i>
                            </div>
                        </Col>
                        {isProductsFilter && (
                            <ProductsGlobalFilter />
                        )}
                        {isCustomerFilter && (
                            <CustomersGlobalFilter />
                        )}
                        {isOrderFilter && (
                            <OrderGlobalFilter />
                        )}
                        {isContactsFilter && (
                            <ContactsGlobalFilter />
                        )}
                        {isCompaniesFilter && (
                            <CompaniesGlobalFilter />
                        )}
                        {isLeadsFilter && (
                            <LeadsGlobalFilter onClickDelete={undefined} />
                        )}
                        {isCryptoOrdersFilter && (
                            <CryptoOrdersGlobalFilter />
                        )}
                        {isInvoiceListFilter && (
                            <InvoiceListGlobalSearch />
                        )}
                        {isTicketsListFilter && (
                            <TicketsListGlobalFilter />
                        )}
                        {isNFTRankingFilter && (
                            <NFTRankingGlobalFilter />
                        )}
                        {isTaskListFilter && (
                            <TaskListGlobalFilter />
                        )}
                    </Row>
                </form>
            </CardBody>

        </React.Fragment>
    );
}

interface TableContainerProps {
    columns?: any;
    data?: any;
    isGlobalFilter?: any;
    isAddOptions?: any;
    divClassName?: any;
    tableClassName?: any;
    theadClassName?: any,
    tableClass?: any;
    theadClass?: any;
    isBordered?: boolean;
    isAddUserList?: any;
    handleOrderClicks?: any;
    handleUserClick?: any;
    handleCustomerClick?: any;
    isAddCustList?: any;
    customPageSize?: any;
    className?: any;
    customPageSizeOptions?: any;
    iscustomPageSize?: boolean;
    SearchPlaceholder: string;
    isProductsFilter?: boolean;
    isPageSizeChange?: any;
    isCustomerFilter?: any;
    isOrderFilter?: any;
    isContactsFilter?: any;
    isCompaniesFilter?: any;
    isLeadsFilter?: any;
    isCryptoOrdersFilter?: any;
    isInvoiceListFilter?: any;
    isTicketsListFilter?: any;
    isNFTRankingFilter?: any;
    isTaskListFilter?: any;
    trClass?: any;
    thClass?: any;
    divClass?: any;
    setselectedcount: (e: any) => void,
    setIsMultiDeleteButton: (e: any) => void,
    handleEditClick: (e: any) => void,
    handleLeadClick: (e: any) => void,
    setisDeleteOpen: (e: any) => void,
    pageChanged: (e: any) => void,
    pagination: any;
    pageSize: any;
    pagesList: any;
    setpreview: (e: any) => void;
    setPagination: (e: any) => void;
    onChangePageSize: (e: any) => void;
    setNotesModals: (e: any) => void;
    handleSort: (e: any) => void;
    sortBy:string;
    sortOrder:string;
    props: any;
    access: any;
    loading: any;
    dataloading: boolean;
}

const TableContainer = ({
    columns,
    data,
    isPageSizeChange,
    isGlobalFilter,
    isProductsFilter,
    isCustomerFilter,
    isOrderFilter,
    isContactsFilter,
    isCompaniesFilter,
    isLeadsFilter,
    isCryptoOrdersFilter,
    isInvoiceListFilter,
    isTicketsListFilter,
    isNFTRankingFilter,
    isTaskListFilter,
    isAddOptions,
    isAddUserList,
    handleOrderClicks,
    handleUserClick,
    handleCustomerClick,
    isAddCustList,
    customPageSize,
    tableClass,
    theadClass,
    trClass,
    thClass,
    divClass,
    SearchPlaceholder,
    setIsMultiDeleteButton,
    setselectedcount,
    handleEditClick,
    handleLeadClick,
    setisDeleteOpen,
    pageChanged,
    pagesList,
    pagination,
    onChangePageSize,
    setpreview,
    setNotesModals,
    handleSort,
    sortOrder,
    sortBy,
    pageSize,
    props,
    access,
    loading,
    dataloading
}: TableContainerProps) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        // preGlobalFilteredRows,
        setGlobalFilter,
        // state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                pageIndex: 0, pageSize: customPageSize, selectedRowIds: 0, sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );
    const navigate = useNavigate();
    
    const navigateDetails = (id: any) => {
        navigate(`/companies/${id}`)
    }

    const onChangeInSelect = (event: any) => {
        setPageSize(Number(event.target.value));
        onChangePageSize(event.target.value);
    };
    const [view, setView] = useState(false);
    const [edit, setEdit] = useState(false);
    const [deleteaccess, setDeleteAccess] = useState(false);
    const [open, setOpen] = useState("");
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    
    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setView(true) 
            setEdit(true)
            setDeleteAccess(true)
        }
        else {
            access && access.length && access.map((item: any, index: any) => {
                if(item.name) {
                    switch(item.name) {
                        case "View": item.value == "" || item.value == "none" ? setView(false)  : setView(true) 
                        break
                        case "Edit": item.value == "" || item.value == "none" ? setEdit(false)  : setEdit(true)
                        break
                        case "Delete": item.value == "" || item.value == "none" ? setDeleteAccess(false)  : setDeleteAccess(true)
                        break
                    }
                }
    
            })
        }
    })
    return (
        <div>
        {loading || dataloading ?
            <DtsTablePlaceholder 
            columns={columns} 
            theadClass={theadClass}     
            tableClass="align-middle table-nowrap"
            thClass="border-bottom-1 table-soft-primary"
        />
            : 
        <div>{!loading && !dataloading && page && page.length ? 
            <Table responsive={true} hover {...getTableProps()} className={tableClass + ' m-0'}>
                <thead className={theadClass}>
                    {headerGroups.map((headerGroup: any) => (
                        <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th key={column.id} className={thClass + " fw-bold"}
                                    {...column.isSortable ? { ...column.getSortByToggleProps() } : ""}
                                >
                                    <div onClick={() => handleSort(column.id)}  className={"cursor-pointer" + `${column.accessor == 'channel_name' ? '': ' otherth'}`}  title={column.Header}>
                                        {column.render("Header")}
                                        {sortBy === column.id && (
                                            <span>
                                                {sortOrder === 'asc' ? ' ▲' : ' ▼'}
                                            </span>
                                        )}
                                    </div>   
                                </th>
                            ))}
                            <th className={thClass + " fw-bold"} >
                                Actions
                            </th>
                        </tr>
                    ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {page.map((row: any) => {
                        prepareRow(row);
                        return (
                            <Fragment key={row.getRowProps().key}>
                                <tr style={{verticalAlign: 'middle'}}>
                                    {row.cells.map((cell: any) => {
                                        let classList = cell.column.className;
                                        return (
                                            <td key={cell.id} style={{verticalAlign: 'middle'}} className='align-items-center justify-content-between' {...cell.getCellProps()}>
                                                    <Link to={`/companies/${row.original.id}`} className="text-decoration-none"  style={{width:"230px"}} >{cell.render("Cell")}</Link>
                                            </td>
                                        );
                                    })}
                                    <td>
                                        <ul className="list-inline hstack gap-2 mb-0">
                                            {/* <li className="list-inline-item edit" title={props.t("student.note")}>
                                                <button className="btn btn-sm btn-soft-info remove-list"
                                                    onClick={() => {setNotesModals(true); handleLeadClick(row.original); setpreview(false);  }}
                                                        >
                                                    <i className="ri-question-answer-fill"></i>
                                                </button>
                                            </li> */}
                                            <li className="list-inline-item">
                                                <Dropdown isOpen={open==row.original.id}  toggle={() => open==row.original.id ? setOpen("") : setOpen(row.original.id)}>
                                                    <DropdownToggle
                                                        href="#"
                                                        className="btn btn-soft-primary btn-sm dropdown"
                                                        tag="button"
                                                    >
                                                        <i className="ri-more-fill"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu  container="body" className="dropdown-menu-center">
                                                    {view ?
                                                        <DropdownItem className="dropdown-item" href={`/companies/${row.original.id}`}
                                                        >
                                                            <button className="btn btn-sm btn-soft-info remove-list fs-12"
                                                                      >
                                                                        <i className="ri-eye-fill"></i>{" "}
                                                            </button>{" "}
                                                            {props.t("student.view")}                                                           
                                                        </DropdownItem>
                                                        : null}
                                                        {edit ?
                                                        <DropdownItem
                                                            className="dropdown-item edit-item-btn fs-12"
                                                            href={`/companies/${row.original.id}`}
                                                        >
                                                            <button className="btn btn-sm btn-soft-secondary remove-list"
                                                                      >
                                                                <i className="ri-pencil-fill"></i>{" "}
                                                            </button>{" "}
                                                            {props.t("student.edit")}   
                                                        </DropdownItem> : null}
                                                        {deleteaccess ?
                                                        <DropdownItem
                                                            className="dropdown-item remove-item-btn fs-12"

                                                            onClick={() => { const LeadData = row.original; handleLeadClick(LeadData); setisDeleteOpen(true); setpreview(false); }}
                                                        >
                                                            <button className="btn btn-sm btn-soft-danger remove-list"
                                                                      >
                                                               <i className="ri-delete-bin-fill"></i>
                                                            </button>  {" "}
                                                            {props.t("student.delete")}    
                                                        </DropdownItem> : null}
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </Fragment>
                        );
                    })}
                </tbody>
            </Table>: <div className="hstack p-5 fw-semibold fs-16 justify-content-center w-100"><span className="hstack p-5  justify-content-center  fw-semibold fs-16 text-center w-100">No data Found</span></div>}
            <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
            <div className="col-sm">
                    <div className="text-muted">
                        {props.t("applications.showing")}
                        <span className="fw-semibold ms-1">
                        {data.length > 0 ? `${pagination.offset + 1}-${Math.min(pagination.offset + pagination.size, pagination.elements)} of ${pagination.elements} results` : `0-0 of 0 results`}
                        </span>
                    </div>
                </div>    
                <div className="col-sm-auto">
                    <Paginator total={pagination && pagination.elements ? pagination.elements : 0}
                        currentPage={pagination?.page}
                        pageChanged={(e:any) => pageChanged(e)}
                        pageSize={pageSize} 
                        isPageSizeChange={isPageSizeChange}
                        onChangeInSelect={(e:any) => onChangeInSelect(e)}
                        pagination={pagination}
                        props={props}
                     />
                </div>
            </Row>
        </div>}
        </div>
    );
};

TableContainer.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
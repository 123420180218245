import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner, Table, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, CardHeader, Modal, ModalHeader, ModalBody } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableComponent';
import EditModal from './innerComponent/EditColumnModal';
import ExportDataModal from './innerComponent/ExportDataModal';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import ImportDataModal from './innerComponent/ImportDataModal';
import AddEntryModal from './innerComponent/AddEntryModal';
import company1 from "../../../assets/images/companies/img-1.png";

import company2 from "../../../assets/images/companies/img-2.png";
// "../../assets/images/companies/img-1.png";
import AdvanceFilter from "./innerComponent/AdvanceFilter";
import FeatherIcon from "feather-icons-react";
import moment from 'moment';
import DeleteModal from './innerComponent/DeleteModal';
import { withTranslation } from 'react-i18next';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import university from '../../../../src/assets/images/dtsimg/svg/university.svg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../../assets/images/users/avatar-6.jpg";
import isEqual from 'lodash/isEqual';
import { financeChannelListGetRequest, financeDelete, financeGetRequest, financeMetadataGetRequest } from "store/finance/action";
import { ToastContainer, toast } from 'react-toastify';
import MoreFilters from './innerComponent/MoreFilters';
import DynamicFilters from './dynamicFilters/DynamicFilters';
import { propertyListGetRequest } from 'store/properties/action';
import AccessDenied from '../../AccessDenied/Denied';
import Upload from './Upload/Upload';
import UpdateModal from './innerComponent/UpdateModal';
import FieldsJson from "./Fields.json"
import currency from 'common/currency';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Sources = (props: any) => {
    const { id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const financeMetadata = useSelector((state: ApplicationState) => state.properties.list);
    const [createModal, setCreateModal] = useState<boolean>(false);
    const financeList = useSelector((state: ApplicationState) => state.finance.channelfinances);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const paginationDetails = useSelector((state: ApplicationState) => state.finance.pagination); 
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);  
    const navigate = useNavigate();
    const [columns, setColumns] = useState<any>([])   
    const [filterCount, setFilterCount] = useState<any>(0)  
    const [channeldatafields, setDatafields] = useState<any>([]);
    const [filterJson, setFilterJson] = useState<any>([]);
    const [visibleColumns, setvisibleColumns] = useState<any>([])
    const [pageSize, setPageSize] = useState<number>(10)
    const [searchData, setsearchData] = useState<any>([])
    const [editModal, setEditOpen] = useState<boolean>(false);
    const [previewModal, setpreview] = useState<boolean>(false);
    const [isFilterModalOpen, setisFilterModalOpen] = useState<boolean>(false);
    const [iscreateModal, setCreateOpen] = useState<boolean>(false);
    const [isExportCSV, setIsExportCSV] = useState<boolean>(false);
    const [isImportCSV, setIsImportCSV] = useState<boolean>(false);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [isSaveOpen, setisSaveOpen] = useState<boolean>(false)
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [selectedFiles, setselectedFiles] = useState<any>([]);
    const [search, setsearch] = useState<string>("");
    const [record, setrecord] = useState<any>({});
    const [inputs, setInputs] = useState<any>({});
    const [focusid, setfocused] = useState<number>(-1);
    const [statusvalue, setstatusvalue] = useState<string>("");
    const [open, setOpen] = useState('1');
    const [tempcolumns, setTempColumns] = useState<any>([])  
    const [Selectedcount, setselectedcount] = useState<number>(0);
    const [applyFilter, setApplyFilter] = useState<boolean>(false);
    const [statusFilter, setStatusFilter] = useState<boolean>(true);
    const [statusFilterLabel, setStatusFilterLabel] = useState<string>("Status")
    const [fileInfo, setFileInfo] = useState<any>({});
    const [metaData, setMetaData] = useState(financeMetadata);
    const [finance, setFinance] = useState([]);
    const [financeJson, setfinanceJson] = useState<any>([]);
    const [sortBy, setSortBy] = useState<any>("")
    const [sortOrder, setSortOrder] = useState<string>("")
    const [pagesList, setPagesList] = useState<any>([]);
    const [filterObject, setFilterObject] =  useState<any>({});
    const [notesModals, setNotesModals] = useState<boolean>(false);
    const [emailModals, setEmailModals] = useState<boolean>(false);
    const [tasksModals, setTasksModals] = useState<boolean>(false);
    const [meetingsModals, setMeetingsModals] = useState<boolean>(false);
    const [isDynamicModalOpen, setIsDynamicModalOpen] = useState<boolean>(false);
    const [viewAccess, setViewAccess] = useState<boolean>(false);
    const [modalFile, setModalFile] = useState<boolean>(false);
    const [access, setAccess] = useState<any>([]);
    const [onAddNote, setOnAddNote] = useState<string>("");
    const [isTable, setisTable] = useState<boolean>(true);
    const [isUpdateModalOpen, setUpdateModal] = useState<boolean>(false);
    const [create, setCreate] = useState<boolean>(false);
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )
    const toggle = (id: any) => {
        setOpen(id)
    };

    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, pageSize: value })
        setPageSize(value)
        const filters = {...filterObject, pageSize: value}
        setFilterObject(filters)
        dispatch(financeChannelListGetRequest(id,filters, 0));
        setfinanceJson([])
    }
    

    // useEffect(() => {
    //     setMetaData(financeMetadata)
    // }, [])

    // useEffect(() => {
    //     setMetaData(financeMetadata);
    // }, [financeMetadata]);


    const handleOnvalueSave = () => {
        Object.keys(record).filter(key => record[key] !== inputs[key])
    }

    const handleLeadClick = (lead: any) => {
        setrecord(lead)
        dispatch(financeGetRequest(lead.id))
    };

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setViewAccess(true)
        }
        else if(subLevelPermissionsList) {
            let access = subLevelPermissionsList?.finances;
            setAccess(access)
            setViewAccess(true)
        }
    }, [subLevelPermissionsList, userProfile])

    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                    newPageNo = 0;
                    setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                    newPageNo = lastPage - 1;
                    setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal-1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        dispatch(financeChannelListGetRequest(id,filterObject, newPageNo));
        setfinanceJson([])
    };

    const onDelete = () => {
        const handleSuccess = (body: any) => {
            const message = () => toast(`Entry Deleted Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(financeChannelListGetRequest(id))
            setisDeleteOpen(false)
        }
        const handleFailure = (body: any) => {
            // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            // message();
            setisDeleteOpen(false)
        }
        dispatch(financeDelete(record.id, handleSuccess, handleFailure))
    }

    const handleEditClick = (data: any) => {

    }

    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    }, [paginationDetails])

    const toggleEnable = (el1: any) => {
        let a: any = document.getElementById(el1.target.id)
        a.disabled = true;
    }

    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            //Error
            //JSON is not okay
            return false;
        }
    }
    const handleAccess = (permissionJson : any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if(userRole === "Owner") return true 
        if (!permissionJson || !permissionJson.permissions) return false;
        if(permissionJson.permissions === "Owner") {
            if(userRole === "Owner") return true 
            else return false
        }
        if(permissionJson.permissions === "view") {
            return true
        }
        if(permissionJson.permissions === "view_edit") {
            return true
        }
        else if (permissions === 'role_based') 
        {            
            const items = permissionJson.item || [];
            let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
            let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
            return isview && isview.length
        }
        else return false
    }

    const handleType = (row: any, type: any, currency: any) => {
        if(type == 'percent') return '%';
        else if(currency) return currency
    }

    useEffect(() => {
        if(financeList && financeList.length) {
            let fields = financeList[0];  
            const keys = Object.keys(fields)
            const columns: any = []
            if(keys && keys.length) {
                keys.map((field,index) =>{  
                    if(field == "universityName")  return columns[0] = {
                        Header: "University",
                        accessor: field,
                        filterable: true,
                        disableFilters: true,
                        show: true,
                        Cell: (cellProps: any) => {   
                            return  <div className="d-flex align-items-center">                                
                                        <div className="hstack flex-grow-1 gap-2">
                                            <div className="flex-shrink-0 text-decoration-none hstack gap-2 fw-bold" >
                                                <img src={company2} alt="" className="avatar-xs" />  
                                                {cellProps.row.original[field]}
                                            </div>
                                        </div>
                                    </div>                        
                            
                        }
                    }
                    if(field == "ug")  return columns[1] = {
                        Header: "Undergraduate",
                        accessor: field,
                        filterable: true,
                        disableFilters: true,
                        show: true,
                        Cell: (cellProps: any) => {   
                           return  <div className="d-flex align-items-center justify-content-between">                                
                            <div className="hstack flex-grow-1  gap-2">
                                    <div className="flex-shrink-0 text-decoration-none vstack flex-grow-1 gap-2 text-center" >   
                                        <h5 className="fs-14 my-1 fw-medium text-center">{cellProps.row.original[field]}
                                            {handleType(cellProps.row.original, cellProps.row.original['ugType'], cellProps.row.original['ugCurrency'])}
                                        </h5>
                                        <span className="text-muted">Undergraduate</span>
                                </div>
                            </div>
                    </div>                        
                            
                        }
                    }
                    if(field == "pg")  return columns[2] = {
                        Header: "Postgraduate",
                        accessor: field,
                        filterable: true,
                        disableFilters: true,
                        show: true,
                        Cell: (cellProps: any) => {   
                           return  <div className="d-flex align-items-center justify-content-between">                                
                            <div className="hstack flex-grow-1  gap-2">
                                    <div className="flex-shrink-0 text-decoration-none vstack flex-grow-1 gap-2 text-center" > 
                                    <h5 className="fs-14 my-1  fw-medium text-center">{cellProps.row.original[field]}
                                    {handleType(cellProps.row.original, cellProps.row.original['pgType'], cellProps.row.original['pgCurrency'])}
                                    </h5>
                                    <span className="text-muted">Postgraduate</span>
                                </div>
                            </div>
                    </div>   
                        }
                    }
                    if(field == "fo")  return columns[3] = {
                        Header: "Foundation",
                        accessor: field,
                        filterable: true,
                        disableFilters: true,
                        show: true,
                        Cell: (cellProps: any) => {   
                            return  <div className="d-flex align-items-center justify-content-between">                                
                            <div className="hstack flex-grow-1  gap-2">
                                    <div className="flex-shrink-0 text-decoration-none vstack flex-grow-1 gap-2 text-center" >                                  
                                    <h5 className="fs-14 my-1 fw-medium text-center">{cellProps.row.original[field]}
                                    {handleType(cellProps.row.original, cellProps.row.original['foType'], cellProps.row.original['foCurrency'])}
                                   
                                    </h5>
                                    <span className="text-muted">Foundation</span>
                                </div>
                            </div>
                    </div>    
                        }
                    }
                    if(field == "phd")  return columns[4] = {
                        Header: "Research",
                        accessor: field,
                        filterable: true,
                        disableFilters: true,
                        show: true,
                        Cell: (cellProps: any) => {   
                            return  <div className="d-flex align-items-center justify-content-between">                                
                            <div className="hstack flex-grow-1  gap-2">
                                    <div className="flex-shrink-0 text-decoration-none vstack flex-grow-1 gap-2 text-center" >   
                                    <h5 className="fs-14 my-1 fw-medium text-center">{cellProps.row.original[field]}
                                    {handleType(cellProps.row.original, cellProps.row.original['phdType'], cellProps.row.original['phdCurrency'])}
                                   
                                    </h5>
                                    <span className="text-muted">Phd</span>
                                </div>
                            </div>
                    </div>  
                        }
                    }
                    if(field == "ps")  return columns[5] = {
                        Header: "PS",
                        accessor: field,
                        filterable: true,
                        disableFilters: true,
                        show: true,
                        Cell: (cellProps: any) => {   
                            return  <div className="d-flex align-items-center justify-content-between">                                
                            <div className="hstack flex-grow-1  gap-2">
                                    <div className="flex-shrink-0 text-decoration-none vstack flex-grow-1 gap-2 text-center" > 
                                    <h5 className="fs-14 my-1 fw-medium text-center">{cellProps.row.original[field]}&nbsp; {handleType(cellProps.row.original, cellProps.row.original['psType'], cellProps.row.original['psCurrency'])}</h5>
                                    <span className="text-muted">Pre Sessional</span>
                                </div>
                            </div>
                    </div>                        
                            
                        }
                    }
                })
                setColumns(columns)
            } 
            setvisibleColumns(columns)
            setColumns(columns)
            setTempColumns(columns)       
        }
    }, [financeList]); 
    const options = [
        {
            label: "Fixed",
            value: "fixed",
            in_forms: true
        },
        {
            label: "Percent",
            value: "percent",
            in_forms: true
        }
    ]

    const onchange = (column: { accessor: string; }) => {
        let index = visibleColumns.findIndex((x: { accessor: string; }) => x.accessor === `${column.accessor}`);
        let newCols = visibleColumns
        if (column.accessor == 'created_date' || column.accessor == 'updated_date') {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => { return moment(cellProps.row.original.heading).format('DD MMM, YYYY'); }
            }
        }
        else {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
            }
        }
        setvisibleColumns(newCols)
    }

    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        let a = Object.keys(record).filter(key => record[key] !== inputs[key])
        setisSaveOpen(true)
        setInputs({
            ...inputs,
            [name]: value
        });
    }

    const onClickmove = (column: { accessor: string; }) => {
        const newCols = [...columns]
        const index = columns.findIndex((obj: { accessor: string; }) => obj.accessor === column.accessor);
        if (column.accessor == 'created_date' || column.accessor == 'updated_date') {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
                Cell: (cellProps: any) => { return moment(cellProps.row.original.heading).format('DD MMM, YYYY'); }
            }
        }
        else {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
            }
        }
        setvisibleColumns(newCols)
    }

   
    const toggleColumn = (columnAccessor: any) => {
        setTempColumns((prevColumns: any[]) => {
            const updatedColumns = prevColumns.map(column => {
                if (column.accessor === columnAccessor) {
                    return { ...column, show: !column.show };
                }
                return column;
            });
            return updatedColumns;
        });
    };

    const onApplyClick = () => {       
        setColumns(tempcolumns)
        setEditOpen(false)
    }

    const handleChange = (value: any) => {
        setsearch(value);
        const filters = {...filterObject, search: value}
        setFilterObject(filters)
        
        
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {            
            dispatch(financeChannelListGetRequest(id,filters));
            setfinanceJson([])
        }, 2000);

        setTypingTimeout(newTimeout);
        
    };

    const ClearFilter = () => {
        setsearch("")
        setstatusvalue("")
        setApplyFilter(false);
        setFilterJson([])
        setFilterCount(0)
        setPageSize(pageSize)
        let filter= {pageSize: pageSize}
        setFilterObject(filter)
        dispatch(financeChannelListGetRequest(id, filter));
    }

    const deSelectColumnall = () => {
        let tempcolumnsNew: any = []
        tempcolumnsNew = columns.map((column: any, index: number) => {
            if (column.accessor == 'finance_name') return {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true
            }
            else return {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
            }
        })
        setColumns(tempcolumnsNew)
        setTempColumns(tempcolumnsNew)
        setEditOpen(false)
    }

    
    const execute = () => {
        let a= isEqual(financeList, financeJson)
        return !a
     }

    useEffect(() => {
        const parsedData: any = financeList && financeList.length ? financeList.map((item: any) => {
            const valuesJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson): {};
            let values = { ...valuesJson, id: item.id , createdAt: item.createdAt}
            return values
        }) : []
        let finance = parsedData && parsedData.length && parsedData.reverse() 
        setFinance(finance)
        setfinanceJson(financeList)
    }, [execute()])

    
    useEffect(() => {
        dispatch(financeChannelListGetRequest(id))
    }, [])

    const applyFilters = (filters: any) => {
        dispatch(financeChannelListGetRequest(id,filters,0));
        setfinanceJson([])
    }
    
    const onChangeIndex = (column: any,index: any) => {
        if (index !== -1) {
            const removedColumn = tempcolumns.splice(tempcolumns.indexOf(column), 1)[0];
            tempcolumns.splice(index, 0, removedColumn);
            setTempColumns([...tempcolumns]);
        }
    }

    const handleSort = (columnName: any) => {
        if (sortBy === columnName) {
          // Reverse the sorting order if the same column is clicked again
          setSortBy(columnName);
          let sort = sortOrder === 'asc' ? 'desc' : 'asc'
          setSortOrder(sort);
          let filters = {...filterObject, sortBy: columnName,sortOrder: sort} 
          setFilterObject(filters)         
          dispatch(financeChannelListGetRequest(id,filters, pagination.page));
        } else {
          // Set the new column to sort by and default to ascending order
          setSortBy(columnName);
          setSortOrder('asc');
          let filters = {...filterObject, sortBy: columnName, sortOrder: 'asc'}         
          setFilterObject(filters)     
          dispatch(financeChannelListGetRequest(id,filters, pagination.page));
        }
      };
      const handleFileClicks = () => {
        setModalFile(!modalFile);
        fileToggle();
    };

    useEffect(() => {
        console.log("subLevelPermissionsList", subLevelPermissionsList)
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setViewAccess(true)
            setCreate(true)
        }
        else if(subLevelPermissionsList) {
            let access = subLevelPermissionsList?.commissions;            
            if(access && access?.length) {
                setAccess(access)
                setViewAccess(true)
                if(access && access.length) {            
                    access.map((item: any, index: any) => {
                        
                       if(item.name.toLowerCase() == 'create') {
                        console.log("item.name", item.name, "subLevelPermissionsList", subLevelPermissionsList)
                            item.value == "" || item.value == "none" ? setCreate(false)  : setCreate(true) 
                       }
           
                   })
               }
            }
            else {
                setViewAccess(false)
            }
        }
    }, [subLevelPermissionsList, userProfile])

    useEffect(() => {
        let dataFields: any = []
        let count = 0
         FieldsJson.map((item: any) =>{
            // let newField = JSON.stringify(item);
            // console.log("newField", newField)
            // dataFields.push(JSON.stringify(item))
            let field =JSON.stringify(item);
            dataFields[count]= {valuesJson : field};
            count++;
         })
         setDatafields(dataFields)

    }, [FieldsJson])
    const fileToggle = useCallback(() => {
        if (modalFile) {
            setModalFile(false);
        } else {
            setModalFile(true);
        }
    }, [modalFile]);

    document.title = "DTS | Zilter";
    return ( 
        <>
        {/* {
            viewAccess ? */}
    <React.Fragment>
            {/* <ToastContainer />    */}
            {isExportCSV && <ExportDataModal
            show={isExportCSV}
            onCloseClick={() => setIsExportCSV(false)}
            // data={data}
            props={props}
        />}
        {isFilterModalOpen && <AdvanceFilter
            show={isFilterModalOpen}
            onCloseClick={() => setisFilterModalOpen(false)}
            dataFields={columns}
            props={props}
            setFilterObject={setFilterObject}
            filterObject={filterObject}
            applyFilters={applyFilters}
        />}
        {isDynamicModalOpen && <DynamicFilters
            show={isDynamicModalOpen}
            onCloseClick={() => setIsDynamicModalOpen(false)}
            dataFields={columns}
            props={props}
            setFilterObject={setFilterObject}
            filterObject={filterObject}
            applyFilters={applyFilters}
            filterJson={filterJson}
            setFilterJson={setFilterJson}
            filterCount={filterCount}
            setFilterCount={setFilterCount}
        />}
        {editModal && <EditModal
            show={editModal}
            onCloseClick={() => setEditOpen(false)}
            onApplyClick={onApplyClick}
            onchange={onchange}
            columns={columns}
            visibleColumns={visibleColumns}
            toggleColumn={toggleColumn}
            onClickmove={onClickmove}
            deSelectColumnall={deSelectColumnall}
            onChangeIndex={onChangeIndex}
            tempcolumns={tempcolumns}
            setTempColumns={setTempColumns}
        />}
        {createModal && <AddEntryModal
            show={createModal}
            onCloseClick={() => setCreateModal(false)}
            dataFields={channeldatafields}
            props={props}
        />}
        {isUpdateModalOpen && <UpdateModal
            show={isUpdateModalOpen}
            onCloseClick={() => setUpdateModal(false)}
            dataFields={channeldatafields}
            record={record}
            props={props}
        />}
        {isImportCSV && <ImportDataModal
            show={isImportCSV}
            onCloseClick={() => setIsImportCSV(false)}
            props={props}
        />}
        {isDeleteOpen && <DeleteModal
            show={isDeleteOpen}
            onCloseClick={() => setisDeleteOpen(false)}
            props={props}
            record={record}
            onDelete={onDelete}
        />}
        {/* {isUpdateModalOpen && <DeleteModal
            show={isUpdateModalOpen}
            onCloseClick={() => setUpdateModal(false)}
            props={props}
            record={record}
            onDelete={onDelete}
        />} */}
            <Row  className='h-100'>
                <Col xl={12} lg={12} md={12} className='h-100'>
                    <Card className='p-4'>
                        <div>
                            <Row className='hstack gap-3'>
                                <Col md={3} sm={12} xl={3} xxl={3} lg={3}>
                                    <div className="search-box">
                                        <Input
                                            type="text"
                                            size={14}
                                            className="search"
                                            placeholder={props.t("finance.search_for")}
                                            onChange={(e) => handleChange(e.target.value)}
                                            value={search}
                                        />
                                        {search == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleChange("")}></i>}

                                    </div>
                                </Col>
                                <Col>
                                    <div className='hstack gap-2 justify-content-end'>
                                            {
                                                create ? 
                                                <Button
                                                    onClick={() => setCreateModal(true)}
                                                    color="primary"
                                                    className="btn-label">
                                                        <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
                                                        {props.t("applications.create_entry")}
                                                </Button>
                                            :         
                                                <Button
                                                    color="primary"
                                                    disabled
                                                    className="btn-label">
                                                        <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
                                                        {props.t("applications.create_entry")}
                                                </Button>
                                            }
                                            {
                                                create ? 
                                            <Button
                                                color="success"
                                                onClick={() => handleFileClicks()}
                                                className="btn-label">
                                                    <i className="ri-upload-2-line label-icon align-middle fs-16 me-2"></i>
                                                    Upload file
                                            </Button>: null}
                                    </div>
                                </Col>
                            </Row>                        
                            {/* <Row>
                                <div>                         
                                    <MoreFilters 
                                        dataFields={metaData}                                 
                                        setFilterObject={setFilterObject}
                                        filterObject={filterObject}
                                        applyFilters={applyFilters}
                                        ClearFilter={ClearFilter}
                                        setApplyFilter= {setApplyFilter}
                                        setIsDynamicModalOpen = {setIsDynamicModalOpen}
                                        filterCount={filterCount}
                                        props={props}
                                    />
                                </div>
                            </Row> */}
                            {/* <Row>
                                <div className='hstack gap-2 justify-content-end mt-1'>
                                    <button className={isTable ? "btn btn-sm btn-soft-success remove-list" : "btn btn-sm btn-soft-success remove-list bg-success text-light" } title="Grid View" onClick={() => {setisTable(false)}}>
                                        <i className="ri-layout-grid-line fs-"></i>{" "}
                                    </button>
                                    <button className={isTable ? "btn btn-sm btn-soft-success remove-list bg-success text-light" : "btn btn-sm btn-soft-success remove-list" } title="Table View" onClick={() => {setisTable(true)}}>
                                        <i className="ri-table-line"></i>{" "}
                                    </button>
                                </div>
                            </Row> */}
                            {/* {
                                isTable ?  */}
                                <Row className="row-cols-xxl-12 row-cols-lg-12 row-cols-1 mt-1">
                                    <TableContainer
                                        columns={columns && columns.length ? columns.filter((column: any) => column.show == true) : []}
                                        data={financeList || []}
                                        customPageSize={pageSize}
                                        divClass="table-responsive table-card mb-1"
                                        tableClass="align-middle table-nowrap"
                                        theadClass="table-light text-muted"
                                        SearchPlaceholder='Search...'
                                        isPageSizeChange={true}
                                        isBordered={true}
                                        handleEditClick={handleEditClick}
                                        handleLeadClick={handleLeadClick}
                                        setisDeleteOpen={setisDeleteOpen}
                                        setIsMultiDeleteButton={setIsMultiDeleteButton}
                                        setselectedcount={setselectedcount}
                                        pageChanged={pageChanged}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        pagesList={pagesList}
                                        onChangePageSize={onChangePageSize}
                                        pageSize={pageSize}
                                        setpreview={setpreview}
                                        setNotesModals={setNotesModals}
                                        handleSort={handleSort}
                                        sortBy={sortBy}
                                        sortOrder={sortOrder}
                                        props={props}
                                        access={access}
                                        setUpdateModal={setUpdateModal}
                                    />
                                </Row>
                                :
                                {/* <Row className="row-cols-xxl-4 row-cols-lg-3 row-cols-1 mt-1">
                                    {financeList && financeList.length ? 
                                        financeList.map((item: any) => {
                                            return <Col>
                                                <Card className="card-body border-1 border-dark border-opacity-10 shadow-md">
                                                    <div className="d-flex mb-2 align-items-center">
                                                        <div className="">
                                                            <p className="text-muted mb-0"><h5 className="card-title mb-1">University:</h5>{item.universityId}</p>
                                                        </div>
                                                    </div>
                                                    <div className='hstack justify-content-between'>
                                                        <div className="flex-shrink-0 text-decoration-none vstack flex-grow-1 gap-1 justify-content-start" > 
                                                            <span className="fs-14 fw-normal text-center">{item.ug}</span>
                                                            <span className="text-muted text-center">Undergraduate</span>
                                                        </div>
                                                        <div className="flex-shrink-0 text-decoration-none vstack flex-grow-1 gap-1 justify-content-start" > 
                                                            <span className="fs-14 fw-normal text-center">{item.pg}</span>
                                                            <span className="text-muted text-center">Postgraduate</span>
                                                        </div>
                                                    </div>
                                                    <div className='hstack justify-content-between'>
                                                        <div className="flex-shrink-0 text-decoration-none vstack flex-grow-1 gap-1 justify-content-start" > 
                                                            <span className="fs-14 fw-normal text-center">{item.phd}</span>
                                                            <span className="text-muted text-center">Research</span>
                                                        </div>
                                                        <div className="flex-shrink-0 text-decoration-none vstack flex-grow-1 gap-1 justify-content-start" > 
                                                            <h5 className="fs-14 fw-normal text-center">{item.fo}</h5>
                                                            <span className="text-muted text-center">Foundation</span>
                                                        </div>
                                                    </div>
                                                    <div className='hstack justify-content-between'>
                                                        <div className="flex-shrink-0 text-decoration-none vstack flex-grow-1 gap-1 justify-content-start" > 
                                                            <h5 className="fs-14 fw-normal text-center">{item.ps}</h5>
                                                            <span className="text-muted  text-center">PS</span>
                                                        </div>
                                                    </div>
                                                    <div className='hstack gap-1 justify-content-between'>
                                                        <p className="mb-1">{item.notes}</p>
                                                    </div>
                                                <Link to="#" className="btn btn-primary btn-sm">See Details</Link>
                                            </Card>
                                            </Col>
                                        })
                                        : null
                                    }
                                </Row>
                            } */}
                        </div>
                        
                    </Card>
                </Col>
            </Row>
          {/* File Modal */}
          <Modal backdrop="static" id="createFileModal" isOpen={modalFile} toggle={fileToggle} modalClassName="zoomIn" centered tabIndex={1}>
                <ModalHeader toggle={() => { fileToggle(); setselectedFiles([]) }} className="p-3 bg-success-subtle">{"Upload File"}</ModalHeader>
                <ModalBody>
                    <Upload filterObject={filterObject} selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} setModalFile={setModalFile} fileInfo={fileInfo} setFileInfo={setFileInfo} />
                </ModalBody>
            </Modal>
    </React.Fragment>
    {/* : <AccessDenied />
    } */}
    </>
    );
};
export default withTranslation()(Sources);



import { toast } from "react-toastify";
export const levelhandle = (levelvalue: any) => {
	let level = ""
	switch (levelvalue) {
		case 1: level = 'Undergraduate';
			break
		case 2: level = 'Postgraduate';
			break
		case 3: level = 'Foundation';
			break
		case 4: level = 'Research';
			break
		default: level = 'NA';
			break
	}
	return level;
}

const errorGlobal: any = {
	500: {
		"message": "Server error, please try again later",
		"success": false
	},
	502: {
		"message": "Server error, please try again later",
		"success": false
	},
	400: {
		"message": "Invalid request, please check your input",
		"success": false
	},
	401: {
		"message": "You need to log in to access this page",
		"success": false
	},
	403: {
		"message": "Access denied, you don't have permission",
		"success": false
	},
	408: {
		"message": "Request timed out, please try again",
		"success": false
	},
	503: {
		"message": "Server is currently unavailable",
		"success": false
	},
	504: {
		"message": "Server timed out, please try again",
		"success": false
	},
	201: {
		"message": "Created Successfully",
		"success": true
	},	
	200: {
		"message": "Request Successful",
		"success": true
	},		
	204: {
		"message": "Deleted Successfully",
		"success": true
	},		
	208: {
		"message": "You are trying to add duplicate Entry",
		"success": false
	}
}

export const responseHandler = async (response: any, dispatch: any, typeSuccess: any, typeError: any, error?: any, handleSuccess?: any, handleError?: any) => {
		if (response?.status) {
		const httpResponse: any=  error && error[response?.status]
		  ? error[response?.status]
		  : errorGlobal[response?.status];
   
		if (httpResponse?.success) {
			try {
				const body = await response.json();
				dispatch({
					type: typeSuccess,
					payload: body
				});
				handleSuccess && handleSuccess(body)
			} catch {
				dispatch({
					type: typeSuccess,
					payload: null
				});
				handleSuccess && handleSuccess()
			}
		} else {
			dispatch({
				type: typeError,
				payload: httpResponse?.message || 'An unexpected error occurred, please try again later'
			});
			handleError && handleError()
		}	
		let bgColor = 	httpResponse?.success ? "bg-success" : "bg-danger";
		const message = () => toast(`${httpResponse?.message || 'An unexpected error occurred, please try again later'}`, { position: "top-center", hideProgressBar: true, className: `${bgColor} text-white` });
		message();
	} else {
		dispatch({
			type: typeError,
			payload: 'An unexpected error occurred, please try again later'
		});
		handleError && handleError()
		
		const message = () => toast(`An unexpected error occurred, please try again later`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
		message();
	}		
}
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from "../../../config";
import { GetQDashboardURLRequest } from 'store/aws/action';

const { api } = config;

const API_Endpoint = api.AWS_API;

const EmbedDashboard = ({initialDashboardId, height, width}: any) => {
    const [embedUrl, setEmbedUrl] = useState(null);
    const fetchEmbedUrl = async () => {
        // console.log("response>>>>>>>>>>>>")
        const config = {
            initialDashboardId: initialDashboardId,
            quicksightNamespace: 'default',
            authorizedResourceArns: [
                `arn:aws:quicksight:eu-west-2:200024303214:dashboard/${initialDashboardId}`
            ],
            sessionTags: [
                {
                    "Key": "Department",
                    "Value": "Finance"
                },
                {
                    "Key": "Role",
                    "Value": "Analyst"
                }
            ]
        }
        // dispatch(GetQDashboardURLRequest(config))
        try {
            const response: any = await axios.post(`${API_Endpoint}/quicksight/generate-embed-url`, config);
            console.log("response", response)
            setEmbedUrl(response.embedUrl);
        } catch (error) {
            console.error("Error fetching embed URL", error);
        }
    };
    useEffect(() => {
        fetchEmbedUrl()
    }, [])

    return (
        <div className='h-100'>
            {embedUrl && <iframe src={embedUrl} width={width ? width: "100%"} height={height ? height:"100%"} title="Embedded Dashboard"></iframe>}
        </div>
    );
};

export default EmbedDashboard;

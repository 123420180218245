import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner, Table, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, CardHeader, Modal, ModalHeader, ModalBody, ButtonGroup } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableComponent';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import isEqual from 'lodash/isEqual';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import InviteUser from "./InviteUser";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import { ApplicationState } from 'store';
import { userDelete, userInviteRequest, usersListGetRequest } from 'store/user/action';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import moment from 'moment';
import { SubagentUserListGetRequest, userUpdateRequest } from 'store/subagents/action';
import UserAvatar from 'pages/Students/innerComponent/UserAvatar';
import DeleteModal from './DeleteModal';
import AccessDenied from 'pages/AccessDenied/Denied';
import ListingLoader from 'Components/Common/FormBuilder/DtsTablePlaceholder';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Sources = (props: any) => {
	const { id } = useParams()
	const navigate = useNavigate();
	const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
	const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
	const me = useSelector((state: ApplicationState) => state.user.user);
	const [inviteUser, setInviteUser] = useState<boolean>(false);
	const userList = useSelector((state: ApplicationState) => state.subagents.subagentusers);
	const usersLoading = useSelector((state: ApplicationState) => state.subagents.usersLoading);
	const paginationDetails = useSelector((state: ApplicationState) => state.subagents.pagination);
	const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
	const authLoading = useSelector((state: ApplicationState) => state.auth.meloading);
	const [columns, setColumns] = useState<any>([])
	const [data, setData] = useState<any>([])
	const [usersJson, setUserJson] = useState<any>([])
	const [record, setRecord] = useState<any>({})
	const [pageSize, setPageSize] = useState<any | null>(10)
	const [viewAccess, setViewAccess] = useState<boolean>(false);
	const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
	const [access, setAccess] = useState<any>([]);
	const [create, setCreate] = useState<boolean>(false);
    const [filterObject, setFilterObject] =  useState<any>({});
	const [edit, setEdit] = useState<boolean>(false);
	const [deleteaccess, setDeleteAccess] = useState(false);
    const [pagesList, setPagesList] = useState<any>([]);
    const [users, setUserList] = useState<any>([]);
	const [name, setName] = useState<any>("");
	const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )

	const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            //Error
            //JSON is not okay
            return false;
        }
    }

	
    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    }, [paginationDetails])
	console.log("pagesList", pagesList)
	const execute = () => {
		let a: any = isEqual(userList, usersJson);
		return !a
	}
    useEffect(() => {
        const parsedData: any = userList && userList.length ? userList.map((item: any) => {
            let values = { ...item, createdAt: item.createdAt}
            return values
        }) : []
        let subagent = parsedData && parsedData.length && parsedData
        setUserList(subagent)
        setUserJson(userList)
    }, [execute()])

	
    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, pageSize: value })
        setPageSize(value)
        const filters = {...filterObject, pageSize: value}
        setFilterObject(filters)
        dispatch(SubagentUserListGetRequest(id, filters, 0));
        setUserJson([])
    }

	
    const pageChanged = (pageVal: any) => {
        console.log("pageVal", pageVal)
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                    newPageNo = 0;
                    setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                    newPageNo = lastPage - 1;
                    setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal-1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        dispatch(SubagentUserListGetRequest(id, filterObject, newPageNo));
        setUserJson([])
    };

	const handleResendInvite = (id: any, email: string, name: string) => {
		const data = {
			"attributes": [
				{
					"key": "email",
					"value": email
				},
				{
					"key": "role_Id",
					"value": id
				},
				{
					"key": "name",
					"value": name
				}
			]
		};
		const handleSuccess = (body: any): void => {
			const message = () => toast(`Invite resent successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
			message();
		}
		const handleError = (body: any): void => {
			const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
			message();
		}
		dispatch(userInviteRequest(data, handleSuccess, handleError));
	}

	const onDelete = (userId: any) => {
		const handleSuccess = (body: any): void => {
			dispatch(SubagentUserListGetRequest(id,{pageSize: pageSize}, pagination.page))
			setisDeleteOpen(false);
			const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
			message();
		}
		const handleError = (body: any): void => {
			const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
			message();
		};
		dispatch(userDelete(record.id, handleSuccess, handleError));
	}

	useEffect(() => {

		let userRole = userProfile?.role?.title;
		if (userRole === "Owner") {
			setViewAccess(true)
			setCreate(true)
			setEdit(true)
			setDeleteAccess(true)
		}
		else if (subLevelPermissionsList && subLevelPermissionsList.users) {
			let access = subLevelPermissionsList.users;
			console.log("access]", access)
			if (access && access?.length) {
				setAccess(access)
				if (access && access.length) {
					access.map((item: any, index: any) => {
						if (item.name.toLowerCase() == 'view') {
							item.value == "" || item.value == "none" ? setViewAccess(false) : setViewAccess(true)
						}
						if (item.name.toLowerCase() == 'invite') {
							item.value == "" || item.value == "none" ? setCreate(false) : setCreate(true)
						}
						if (item.name.toLowerCase() == 'edit') {
							item.value == "" || item.value == "none" ? setEdit(false) : setEdit(true)
						}
						if (item.name.toLowerCase() == 'delete') {
							item.value == "" || item.value == "none" ? setDeleteAccess(false) : setDeleteAccess(true)
						}
					})
				}
			}
			else {
				setViewAccess(false)
			}
		}
	}, [subLevelPermissionsList, userProfile])

	const handleEditClick = (id: string) => {
		navigate(`/users/${id}`);
	};

	useEffect(() => {
		dispatch(SubagentUserListGetRequest(id))
	}, [])

	useEffect(() => {
		if (userList && userList.length) {
			const keys = Object.keys(userList[0])
			console.log("keys", keys)
			const columns: any = []
			if (keys && keys.length) {
				keys.map((field, index) => {
					if (field == 'name') return columns[0] = {
						Header: _.startCase(field),
						accessor: field,
						filterable: true,
						disableFilters: true,
						show: true,
						Cell: (cellProps: any) => {
							return (
								<div className="d-flex align-items-center justify-content-between" style={{width:400}}>
									<div className="hstack flex-grow-1  gap-2">
										<div className="flex-shrink-0 text-decoration-none hstack flex-grow-1 gap-2 text-ellipsis" >

											<div className="flex-shrink-0">
												<UserAvatar img={cellProps.row.original?.profile} firstName={cellProps.row.original?.name || cellProps.row.original?.email} />
											</div>
											<div>
													<h6
														className="cursor-pointer text-primary text-decoration-none text-ellipsis"
														onClick={() => {
															handleEditClick(cellProps.row.original.id);
														}}
													>
														{cellProps.row.original.name || cellProps.row.original.email}
													</h6>
													<p className="text-muted fs-12 fw-light mb-0">
														{cellProps.row.original.email}
													</p>
											</div>
										</div>
									</div>
									<div  className={"btn_preview"}>
										<div className={"hstack justify-content-end"} style={{marginRight: 10}}> 
											{cellProps.row.original.id === me?.id || cellProps.row.original.confirmationStatus === "CONFIRMED" ?
											 null :
											 create ?
												<Button
														disabled={
															cellProps.row.original.id === me?.id ||
															cellProps.row.original.confirmationStatus === "CONFIRMED"
														}
														color='primary'
														className="btn-label btn-sm"									
														onClick={() => {
															handleResendInvite(
																cellProps.row.original.role.id,
																cellProps.row.original.email,
																cellProps.row.original.name
															);
														}}
													>
													<i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>{" "}
													Resend Invite 
												</Button>: null}                  
										</div>
									</div>
								</div>)
						}
					}
					else if (field == "confirmationStatus")
						console.log("confirmationStatus field", field)
					return columns[1] = {
						Header: "Confirmation Status",
						isSortable: true,
						accessor: field,
						filterable: true,
						disableFilters: true,
						show: true,
						Cell: (cellProps: any) => {
							return <div className="">{cellProps.row.original.confirmationStatus}</div>;
						},
					};
				})
			}
			columns.push({
				Header: "Created At",
				accessor: "createdAt",
				id: "createdAt",
				filterable: true,
				isSortable: false,
				disableFilters: true,
				show: true,
				Cell: (cellProps: any) => {
					return <div className="d-flex align-items-center justify-content-between">
						{cellProps.row.original["createdAt"] ? moment(cellProps.row.original["createdAt"]).format('lll') :
							''}
					</div>
				}
			})
			setColumns(columns)
		}
		setData(userList)
	}, [userList]);

	

	useEffect(() => {
		const parsedData: any = userList && userList.length ? userList.map((item: any) => {
			return item
		}) : []
		let user = parsedData && parsedData.length ? parsedData.reverse() : []
		setData(user)
		setUserJson(userList)
	}, [execute()])


	console.log("pagination", pagination)
	return (
		<React.Fragment>
			 { !authLoading || !usersLoading ?
            viewAccess ? 
			<div>
				<ToastContainer />
				<InviteUser
					inviteUser={inviteUser}
					setInviteUser={setInviteUser}				
					setName={setName}
					name={name}
				/>			
				<DeleteModal
						show={isDeleteOpen}
						onCloseClick={() => setisDeleteOpen(false)}
						onDelete={onDelete}
						record={record}
						props={props}
					/>
				<Row className='text-end pb-3'>
					<div>
						{
							create ?
								<Button
									onClick={() => setInviteUser(true)}
									color="primary"
									className="btn-label btn-sm">
									<i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
									Invite User
								</Button>
								:
								<Button
									color="primary"
									disabled
									className="btn-label btn-sm">
									<i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
									Invite User
								</Button>
						}
					</div>
				</Row>
				<Row>
					<div className="students-table mx-3">
						<div className="table-card gridjs-border-none pb-2 mt-n2 mx-0">
							<TableContainer
								columns={columns}
								data={users || []}
								isPagination={true}
								isGlobalFilter={true}
								customPageSize={pageSize}
								divClass="table-responsive table-card"
								tableClass="align-middle table-nowrap"
								theadClass="table-light"
								SearchPlaceholder="Search..."
								isPageSizeChange={true}
								isBordered={true}
								access={access}
								props={props}
								setRecord={setRecord}
								setisDeleteOpen={setisDeleteOpen}
								pageChanged={pageChanged}
								pagination={pagination}
								setPagination={setPagination}
								pagesList={pagesList}
								onChangePageSize={onChangePageSize}
								pageSize={pageSize}
								usersLoading={usersLoading}
								thClass="border-bottom-1 table-soft-primary"

							/>
						</div>
					</div>
				</Row>
			</div>
            : <AccessDenied />
            : <ListingLoader                
                columns={columns} 
                tableClass="align-middle table-nowrap"
                theadClass="table-light text-muted"
                thClass="border-bottom-1 table-soft-primary"
            /> 
        }
		</React.Fragment >

	);
};
export default withTranslation()(Sources);


import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import FeatherIcon from "feather-icons-react";
import Select from 'react-select'
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import avatar7 from "../../../../src/assets/images/users/avatar-7.jpg";
import { noteAddRequest, notesListGetRequest } from "store/note/action";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import moment from "moment";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import Sidebar from "Layouts/Sidebar";
import SimpleBar from "simplebar-react";
import Comments from "pages/StudentsDetails/Modals/Comments";
import AddTask from "pages/StudentsDetails/Modals/AddTask";
import FollowUpTask from "pages/StudentsDetails/Modals/FollowUpTask";

interface FProps {
  props: any;
  notesModals: boolean;
  setNotesModals: (event: boolean) => void;
  onAddNote: (content: string) => void;
  record: any
}

const noteType = [
  {
    options: [
      { label: "Counselling Session Summary", value: "COUNSELLING SESSION SUMMARY" },
      { label: "Follow-up call", value: "FOLLOW-UP CALL" },
      { label: "Incoming Call", value: "INCOMING CALL" },
      { label: "Attempt to book", value: "ATTEMPT TO BOOK" },
      { label: "Proof of Funds Details", value: "PROOF OF FUNDS DETAILS" },
      { label: "Course Notes", value: "COURSE NOTES" },
      { label: "Finance Notes", value: "FINANCE NOTES" },
      { label: "Others", value: "OTHERS" }
    ],
  },
];

const NotesModal: React.FC<FProps> = ({
  props,
  notesModals,
  setNotesModals,
  onAddNote,
  record
}) => {
  const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
  const notes = useSelector((state: ApplicationState) => state.note.notes);
  const [noteInput, setNoteInput] = useState<string>("");
  const [newNote, setNewNote] = useState("");
  const [replyNoteId, setReplyNoteId] = useState<string | null>(null);
  const [selectedAddNoteType, setSelectedAddNoteType] = useState(null);
  const [addShortNoteValue, setAddShortNoteValue] = useState("");
  const [replyNote, setReplyNote] = useState("")
  const [selectedNoteType, setSelectedNoteType] = useState(null);
  const [newNoteId, setNewNoteId] = useState<string | null>(null)
  const [shortNoteValue, setShortNoteValue] = useState("");
  const [isReply, setIsReply] = useState(false)
  const [studentId, setStudentId] = useState('')
  const [tasksModals, setTasksModals] = useState<boolean>(false);
  const [followupRequired, setFollowupRequired] = useState(false)
  const [transcribing, setTranscribing] = useState("modalparent")
  const [isAddNoteButtonDisabled, setIsAddNoteButtonDisabled] = useState<boolean>(false);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition({transcribing: transcribing == "modalparent"});

  useEffect(() => {
    // console.log("Record",record.id)
    if(record && record.id) {
      setStudentId(record.id)
      dispatch(notesListGetRequest(record.id));
    }
  }, [record])

  useEffect(() => {
    // console.log("Record",record.id)
    if(studentId) {
      dispatch(notesListGetRequest(studentId));
    }
  }, [studentId])

  useEffect(() => {
    setNewNote(transcript);
  }, [transcript])

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const startListening = () => {
    SpeechRecognition.startListening();
  };

  const stopListening = () => {
    SpeechRecognition.stopListening();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoteInput(e.target.value);
  };
  const handleNewNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewNote(event.target.value);
  };

  const handleAddNoteTypeChange = (selectedOption: any) => {
    setSelectedAddNoteType(selectedOption);
    setAddShortNoteValue(selectedOption.label)
    // You can also update the formData here if needed
  };

  const handleReplyNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReplyNote(event.target.value);
    setNewNoteId("");
    setReplyNoteId(replyNoteId);
  };

  const handleAddNote = () => {
    setIsAddNoteButtonDisabled(true);
    const data = 
      {
        studentId: studentId,
        shortNote: addShortNoteValue,
        parentId: "",
        note: newNote || transcript
      }
    const handleSuccess = (body: any): void => {
      createFollowUp()
      handleReset();
      const message = () => toast(`Note added successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
      message();
      dispatch(notesListGetRequest(studentId));
    };
  
    const handleError = (body: any): void => {
      // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
      // message();
    };
  
    dispatch(noteAddRequest(data, handleSuccess, handleError));
  };

  const handleReset = () => {
    setNewNote("");
      setSelectedAddNoteType(null)
      // setNotesModals(false);
      setIsAddNoteButtonDisabled(false);
    resetTranscript();
    setAddShortNoteValue('')
  };

  const handleFollowupCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFollowupRequired(event.target.checked);
  };

  const createFollowUp = () => {
    setTasksModals(followupRequired)
  }
  return (
    <>
       <FollowUpTask
       followupRequired={followupRequired}
       setFollowupRequired={setFollowupRequired}
              record={record?.id}
              props={props}
              tasksModals={tasksModals}
              setTasksModals={setTasksModals}
          />
    <Col lg={9}>
      <Modal className="w-75" id="modalForm" autoFocus={true} isOpen={notesModals} toggle={() => {
            setNotesModals(false);
          }} centered={true} size="lg">
        <ModalHeader className="p-2 bg-primary-subtle" toggle={() => {
            setNotesModals(false);
          }}>
          {"Add New Note"}
        </ModalHeader>
        <ModalBody className="my-2 p-1">
            <Row className="g-3">
              <Col xs={12} >
              <div className="mx-3">
                <textarea className="form-control bg-light border-light" id="exampleFormControlTextarea1" value={newNote}
                  onChange={handleNewNoteChange} rows={3} placeholder={props.t("applications.add_note")}></textarea>
              </div>
              </Col>
              <Col xs={12} className="text-start">
                {/* <Dictaphone /> */}
                <div className='mx-3 d-flex justify-content-end gap-2'>
                    <div className="form-check mb-0 align-self-center">
                      <Input className="form-check-input" type="checkbox" onChange={handleFollowupCheck} id="formCheck6" />
                      <Label className="form-check-label" for="formCheck6">
                        Follow-up required ?
                      </Label>
                    </div>
                    <div style={{ minWidth: '250px' }}>
                <Select
                  placeholder="Select Note Type"
                  classNamePrefix="js-example-data-array"
                  isLoading={false}
                  options={noteType}
                  value={selectedAddNoteType}
                  onChange={handleAddNoteTypeChange}
                />
                </div>
                  {listening ? (
                    <>
                      <Button color="danger" className="ms-2 btn-icon" outline type="button" onClick={stopListening}> <i className="ri-mic-off-line"></i> </Button>
                    </>
                  ) : (
                    <>
                      <Button color="primary" className="ms-2 btn-icon" outline type="button" onClick={startListening}> <i className="ri-mic-line"></i> </Button>
                    </>
                  )}

                  {/* <button type="button" className="btn btn-ghost-secondary btn-icon waves-effect me-1"><i className="ri-attachment-line fs-16"></i></button> */}
                  <Button color="primary" className="ms-2 btn btn-primary"
                      disabled={isAddNoteButtonDisabled || (!newNote || !selectedAddNoteType) && (!transcript || !selectedAddNoteType)}
                      onClick={handleAddNote}>{props.t("applications.add_note")}</Button>
                </div>
              </Col>
            </Row>
              <Row>
              <Comments record={studentId} noteType={noteType} transcribing={transcribing} setTranscribing={setTranscribing}/>
              </Row>
        </ModalBody>
        </Modal>
    </Col>
    </>
  );
};

export default withTranslation()(NotesModal);

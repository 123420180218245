import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { ApplicationState } from "store";
import { ToastContainer } from "react-toastify";
import { roleListGetRequest } from "store/role/action";
import { ViewListGetRequest } from "store/views/action";

// Type definition for options used in Select components
interface OptionsState {
    value: string;
    label: string;
}

interface CreateStageDrawerProps {
    show: boolean;
    onCloseClick: (value: boolean) => void;
    stages: any[];
    onAddStage: (value: any) => void;
    t: (key: string) => string;
}

const CreateStageDrawer: React.FC<CreateStageDrawerProps> = ({
    show,
    onCloseClick,
    stages,
    onAddStage,
    t
}) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();

    // Fetching state from Redux store
    const rolesList = useSelector((state: ApplicationState) => state.role.list);
    const viewlist = useSelector((state: ApplicationState) => state.views.views);

    // State management for the form
    const [stageName, setStageName] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [roleOptions, setRoleOptions] = useState<OptionsState[]>([]);
    const [aboutViewOptions, setAboutViewOptions] = useState<OptionsState[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<OptionsState[]>([]);
    const [selectedAboutView, setSelectedAboutView] = useState<OptionsState | null>(null);

    // Fetch roles and views on component mount
    useEffect(() => {
        dispatch(roleListGetRequest());
        dispatch(ViewListGetRequest());
    }, [dispatch]);

    // Update roles state when roles list is fetched
    useEffect(() => {
        if (rolesList?.length) {
            const roles = rolesList.map((role: any) => ({
                value: role.id,
                label: role.title
            }));
            setRoleOptions(roles);
        }
    }, [rolesList]);

    // Update about views state when view list is fetched
    useEffect(() => {
        if (viewlist?.length) {
            const aboutViews = viewlist
                .filter((view: any) => view.location === 'about')
                .map((view: any) => ({
                    value: view.id,
                    label: view.name
                }));
            setAboutViewOptions(aboutViews);
        }
    }, [viewlist]);

    // Handle changes to the stage name input field
    const handleStageNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value;
        const value = name.trim().replace(/\s+/g, '_').toLowerCase();
        setStageName(name);

        // Check for existing stages with the same value
        const stageExists = stages.some((stage) => stage.value === value);
        if (stageExists) {
            setErrorMessage('Stage with the same name already exists.');
        } else {
            setErrorMessage('');
        }
    };

    // Handle adding a new stage
    const handleAddStage = () => {
        const value = stageName.trim().replace(/\s+/g, '_').toLowerCase();
        const newStage = {
            label: stageName,
            value,
            in_forms: true,
            roles: selectedRoles,
            about_view: selectedAboutView
        };
        onAddStage(newStage)
        onCloseClick(false);
        resetFormValues();
    };

    // Reset form values after adding or closing the drawer
    const resetFormValues = () => {
        setStageName('');
        setErrorMessage('');
        setSelectedRoles([]);
        setSelectedAboutView(null);
    };

    return (
        <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasWithBothOptions"
            backdrop={false}
            toggle={() => onCloseClick(false)}
        >
            <ToastContainer />
            <OffcanvasHeader
                className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary"
                id="offcanvasWithBothOptions"
                toggle={() => onCloseClick(false)}
            >
                <span className="m-0 me-2 text-white">
                    {t('properties.lifecycle_stage.student.create.header')}
                </span>
            </OffcanvasHeader>
            <OffcanvasBody className="bg-light" id="offcanvasWithBothOptions">
                <div className="d-flex flex-column gap-2 p-3">
                    <div className="form-group mb-3">
                        <Label className="form-label fw-bold fs-6">
                            {t('properties.lifecycle_stage.student.create.stage_label')}:
                        </Label>
                        <Input
                            type="text"
                            placeholder={t('properties.lifecycle_stage.student.create.stage_placeholder')}
                            onChange={handleStageNameChange}
                            value={stageName}
                        />
                        {errorMessage && <p className="form-text text-danger">{errorMessage}</p>}
                    </div>
                    <div className="form-group mb-3">
                        <Label className="form-label fw-bold fs-6">
                            {t('properties.lifecycle_stage.student.create.roles_label')}:
                        </Label>
                        <Select
                            closeMenuOnSelect={false}
                            isMulti
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable
                            isSearchable
                            name="roles"
                            options={roleOptions}
                            onChange={setSelectedRoles}
                            value={selectedRoles}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <Label className="form-label fw-bold fs-6">
                            {t('properties.lifecycle_stage.student.create.about_views_label')}:
                        </Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable
                            isSearchable
                            name="aboutViews"
                            options={aboutViewOptions}
                            onChange={setSelectedAboutView}
                            value={selectedAboutView}
                        />
                    </div>
                </div>
            </OffcanvasBody>
            <div className="offcanvas-footer bg-info bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">
                <Link
                    to="#"
                    className="text-danger fw-bold w-auto"
                    onClick={() => onCloseClick(false)}
                >
                    {t('properties.lifecycle_stage.student.create.cancel')}
                </Link>
                <Button
                    color="primary"
                    className="btn btn-primary w-auto"
                    disabled={!stageName || !selectedRoles?.length || !selectedAboutView || !!errorMessage}
                    onClick={handleAddStage}
                >
                    {t('properties.lifecycle_stage.student.create.add')}
                </Button>
            </div>
        </Offcanvas>
    );
};

export default withTranslation()(CreateStageDrawer);

import React, { useEffect, useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
} from "reactstrap";
import { StudentListGetRequest, studentCreateRequest } from "store/student/action";
import { ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import QuickForm from "Components/Common/FormBuilder/QuickForm";
import buildUrl, { IQueryParams } from "build-url-ts";
import config from "config";
const { api } = config;

const DTS_API_DOMAIN = api.DTS_API_STUDENT    

const CreateLinkOffCanvas = ({ props, show, onCloseClick, dataFields }: any) => {    
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const OnAddEntry = (formValues: any) => {
        const queryParams: IQueryParams = {};
        for (const key in formValues) {
            queryParams[key] = formValues[key];
        }
        const dynamicUrl = buildUrl(`${DTS_API_DOMAIN}`, {
            queryParams
        });
        const handleSuccess = (body: any) => {
            const message = () => toast(`Student Added Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            onCloseClick();
            dispatch(StudentListGetRequest());
        }
        console.log("data", dynamicUrl);
        // Dispatch studentCreateRequest with data and handleSuccess function
        // dispatch(studentCreateRequest(formValues, handleSuccess, () => {}));
    }
    
    return (
        <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            toggle={onCloseClick}            
            backdrop={false}
        >
            
            <OffcanvasHeader className="bg-light" toggle={() =>{onCloseClick(!show)}}>
                {props.t("student.create_link")}
            </OffcanvasHeader>
                <OffcanvasBody>
                    <Card>
                        <CardBody>
                            <div className="live-preview vstack gap-2">
                                <QuickForm dataFields={dataFields} btnAction={OnAddEntry}  btn_text={'Create Link'} formfor="students" />
                            </div>
                        </CardBody>
                    </Card>                    
                </OffcanvasBody>
        </Offcanvas>
    );
};

export default CreateLinkOffCanvas;


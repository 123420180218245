import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Button,
  Modal,
  ModalBody,
  Input,
  ModalHeader,
  Col,
  Row,
} from "reactstrap";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useParams } from "react-router-dom";
import { SendEmail, getStudentsEmails } from "store/student/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationsEmails } from "store/applications/action";
import { ApplicationState } from "store";
import DtsEmailsInputs from "Components/Common/FormBuilder/DtsEmailsInputs";

export interface SuggestionOption {
  readonly value: string;
  readonly label: string;
}

interface FProps {
  props: any;
  emailModals: boolean;
  setEmailModals: (event: boolean) => void;
  reply?: boolean;
}

const SingleOptions = [
  { value: "Anna Adame", label: "Anna Adame(annaadane@gmail.com)" },
  { value: "James Will", label: "James Will(james@gmail.com" },
];

const EmailModal: React.FC<FProps> = ({
  props,
  emailModals,
  setEmailModals,
  reply,
}) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const {id} = useParams();
    const [isccOn, setisccOn] = useState(false)
    const [isBccOn, setisBccOn] = useState(false)
    const [disabled,setdisabled] = useState(true)
    const applicationDetails = useSelector((state: ApplicationState) => state.applications.applicationDetails);
    const [studentId, setSudentId] = useState<any>("");
    const [filterObject, setFilterObject] = useState<any>({
        applicationId: id
    });
    const [emailData, setEmailData] = useState<any>({
        applicationId: id
    })
  const toggle = () => {
    if (emailModals) {
      setEmailModals(false);
    } else {
      setEmailModals(true);
    }
};

const isJson = (str: any) => {
    try {
        let options = JSON.parse(str);
        return options
    } catch (e) {
        return false;
    }
}
useEffect(() => {
    if(applicationDetails && applicationDetails.id) {
        let studentsId = applicationDetails && applicationDetails.student && applicationDetails.student.id ? applicationDetails.student.id : "";
        let valuesJson = applicationDetails && applicationDetails.student && applicationDetails.student.valuesJson && isJson(applicationDetails.student.valuesJson) ? JSON.parse(applicationDetails.student.valuesJson) : "";
     
        setEmailData({...emailData, studentId: studentsId, to: valuesJson.email})
        setSudentId(studentsId)            
        dispatch(getApplicationsEmails(studentsId, filterObject))   
    }
}, [applicationDetails])

const onSend = () => {
    const data = { ...emailData, parentId: "" }
    const handleSuccess = () => {
        dispatch(getApplicationsEmails(studentId, filterObject));
        setInnitial()
        setEmailModals(false)
    }
    const handleFailure = () => { }
    dispatch(SendEmail(data, handleSuccess, handleFailure))
}
useEffect(() => {
    if(isBccOn) {
        const getID = document.getElementById('BccRecipientsCollapse') as HTMLElement
        if (getID) getID.classList.add("show");
    }
    else {
        const getID = document.getElementById('BccRecipientsCollapse') as HTMLElement
        if (getID) getID.classList.remove("show");
    }
    if(isccOn) {
        const getID = document.getElementById('CcRecipientsCollapse') as HTMLElement
        if (getID) getID.classList.add("show");
    }
    else {
        const getID = document.getElementById('CcRecipientsCollapse') as HTMLElement
        if (getID) getID.classList.remove("show");
    }
},[isBccOn,isccOn])
const setInnitial = () => {
    if(applicationDetails && applicationDetails.id) {
        let studentsId = applicationDetails?.student?.id
        let valuesJson = applicationDetails && applicationDetails.valuesJson && isJson(applicationDetails.student.valuesJson) ? JSON.parse(applicationDetails.valuesJson) : "";     
        setEmailData({studentId: studentsId, to: valuesJson.email, applicationId:id })
        setSudentId(studentsId)            
        dispatch(getApplicationsEmails(studentsId, filterObject))   
    }
}

  return (
    <React.Fragment>
      <Modal backdrop="static" id="composemodal" className="modal-lg" isOpen={emailModals} toggle={toggle} centered>
                <ModalHeader className="p-3 bg-light" toggle={() => {setEmailModals(false);  setInnitial()}}>
                    New Message
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="mb-3 position-relative">
                            <Row className="hstack mb-3 position-relative">
                                <Col md={10} lg={10} className="pe-0">
                                    <DtsEmailsInputs placeholder={"To"} defaultValue={`${emailData.to}`} setdisabled={setdisabled} onChange={(values: any) => setEmailData({ ...emailData, to: values })} />
                                </Col>
                                <Col  md={2} lg={2} className="ps-0 hstack justify-content-end">
                                    <div className="d-flex">
                                        <button
                                            className="btn btn-link text-reset fw-semibold px-2"
                                            type="button"
                                            onClick={() => setisccOn(!isccOn)}
                                        >
                                            Cc
                                        </button>
                                        <button
                                            className="btn btn-link text-reset fw-semibold px-2"
                                            type="button"
                                            onClick={() => setisBccOn(!isBccOn)}
                                        >
                                            Bcc
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {
                            isccOn ?
                        <div className="collapse" id="CcRecipientsCollapse">
                            <div className="mb-3">
                                <label>Cc:</label>
                                <DtsEmailsInputs placeholder={"Cc"} setdisabled={setdisabled} onChange={(values: any) => setEmailData({ ...emailData, cc: values })} />
                            </div>
                        </div>:
                        null}
                        {
                            isBccOn ? <div className="collapse" id="BccRecipientsCollapse">
                            <div className="mb-3">
                                <label>Bcc:</label>
                                <DtsEmailsInputs placeholder={"Bcc"} setdisabled={setdisabled} onChange={(values: any) => setEmailData({ ...emailData, bcc: values })} />
                            </div>
                        </div>
                        : null
                        }

                        <div className="mb-3">
                            <Input type="text" value={emailData.subject || ""} className="form-control" placeholder="Subject" onChange={(e: any) => setEmailData({ ...emailData, subject: e.target.value })} />
                        </div>
                        <div className="ck-editor-reverse">
                            <CKEditor
                                editor={ClassicEditor}
                                onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.

                                }}
                                onChange={(event: any, editor: any) => {
                                    const data = editor.getData();
                                    setEmailData({ ...emailData, body: data })
                                }}
                            />
                        </div>
                    </div>
                </ModalBody>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-ghost-danger"
                        onClick={() => {
                            setEmailModals(false); 
                            setInnitial()
                        }}
                    >
                        Discard
                    </button>

                    <UncontrolledDropdown className="btn-group">
                        <button
                            type="button"
                            disabled={disabled || !emailData.subject || !emailData.body}
                            className="btn btn-success"
                            onClick={() => {
                                onSend();
                            }}
                        >
                            Send
                        </button>
                    </UncontrolledDropdown>
                </div>
            </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(EmailModal);

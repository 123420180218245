import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner ,Table, Dropdown, DropdownToggle, DropdownItem, DropdownMenu, Nav, TabContent, TabPane, NavLink, NavItem, ButtonGroup} from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableComponent';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { withTranslation } from 'react-i18next';
import classnames from "classnames";
import archiveddataFields from './archivedpropertiesfields.json';
import archiveddata from './archivedpropertiesList.json';
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import FeatherIcon from "feather-icons-react";
import ArchiveModal from './innerComponents/ArchivedModal';
import DeleteModal from './innerComponents/DeleteModal';
import CreatePropertyModal from './innerComponents/CreatePropertyModal';
import { ToastContainer, toast } from 'react-toastify';
import EditPropertyModal from './innerComponents/EditPropertyModal';
import ManageAccess from './innerComponents/ManageAccess';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { GroupGetByModelRequest, propertyArchivedListGetRequest, propertyDeleteRequest, propertyListGetRequest } from 'store/properties/action';
import { ApplicationState } from 'store';
import isEqual from 'lodash/isEqual';
import { Link } from 'react-router-dom';
import { usersListGetRequest } from 'store/user/action';
import RestoreModal from './innerComponents/RestoreModal';
import ArchivedProperties from './ArchivedProperties';
import moment from 'moment';
import Groups from './Groups/Groups';
import { ModelListGetRequest } from 'store/auth/action';
import AccessDenied from 'pages/AccessDenied/Denied';
import ListingLoader from 'Components/Common/FormBuilder/DtsTablePlaceholder';
import Lifecycle from './Lifecycle/Lifecycle';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Properties = (props: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();   
    const modelList = useSelector((state: ApplicationState) => state.auth.models)
    const propertiesList = useSelector((state: ApplicationState) => state.properties.list); 
    const paginationDetails = useSelector((state: ApplicationState) => state.properties.pagination);   
    const achivedpagination = useSelector((state: ApplicationState) => state.properties.achivedpagination); 
    const groupspagination = useSelector((state: ApplicationState) => state.properties.groupspagination); 
    const loading = useSelector((state: ApplicationState) => state.properties.loading); 
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const users = useSelector((state: ApplicationState) => state.user.users);
    const [columns, setColumns] = useState<any>([])
    const [propertiesData, setPropertiesData] = useState<any>([])
    const [record, setrecord] = useState<any>({});
    const [inputs, setInputs] = useState<any>({});
    const [model, setModel] = useState<any>('students');
    const [filterObject, setFilterObject] =  useState<any>({pageSize: 10});
    const [archivedCount, setArchivedarchivedCount] = useState()
    const authLoading = useSelector((state: ApplicationState) => state.auth.meloading);
    const [createInputs, setCreateInputs] = useState<any>({
        objectType: '',
        label: '',
        description: '',
        fieldtype: '',
        options: [],
        rules: []
    });
    const [searchArchivedData, setArchivedsearchData] = useState<any>([])
    const [pageSize, setPageSize] = useState<number>(10)
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);  
    const [searchvalue, setsearchvalue] = useState<string>("");
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [arrowNavTab, setarrowNavTab] = useState<any>("1");
    const [selectedPropertyFieldType, setSelectedPropertyFieldType] = useState<any>(null);
    const [selecteduser, setUser] = useState<any>(null);
    const [Selectedcount, setselectedcount] = useState<number>(0)
    const [isArchivedOpen, setIsArchivedOpen] = useState<boolean>(false);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [isEditOpen, setisEditOpen] = useState<boolean>(false);
    const [accessOpen, setisAccessOpen] = useState<boolean>(false);
    const [isCreateOpen, setisCreateOpen] = useState<boolean>(false);  
    const [sortBy, setSortBy] = useState<any>("")
    const [sortOrder, setSortOrder] = useState<string>("")  
    const [viewAccess, setViewAccess] = useState<boolean>(false);
    const [access, setAccess] = useState<any>([]);
    const [pagesList, setPagesList] = useState<any>([]);
    const [create, setCreate] = useState<boolean>(false);
    const [edit, setEdit] = useState(false);
    const [propertiesJson, setPropertiesJson] = useState<any>([])
    const [id, setId] = useState();
    const [propertiesArchivedJson, setArchivedPropertiesJson] = useState<any>([])
    const [usersOptions, SetUserOptions] = useState();
    const [propertyObject, setpropertyObject] = useState<any>({
        label: "Students",
        value: "students"
    })
    const [EditInputs, setEditInputs] = useState<any>({
        objectType: propertyObject.value,
        label: '',
        description: '',
        fieldtype: '',
        options: [],
        rules: []
    });
    const [permissionInputs, setpermissionInputs] = useState<any>({
        objectType: propertyObject.value,
    });
    
    const objectTypeOption = modelList && modelList.length ? 
    modelList.map((item: any) => {
        return { label: item.charAt(0).toUpperCase() + item.slice(1), value: item}
    }) : []

    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            totalPages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false,
            totalElements: 0
        }
    )
    const [dropdownOpen, setDropdownOpen] = useState(false);  
    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);
    const fieldtype = [
        {
            label: "Text input",
            options: [
                { label: "Single-line text", value: "textfield" },
                { label: "Multi-line text", value: "textarea" },
                { label: "Phone number", value: "phone" },
                { label: "Email", value: "email" },
            ]
        },
        {
            label: "Choosing options",
            options: [
                { label: "Single checkbox", value: "checkbox" },
                { label: "Multiple checkboxes", value: "selectboxes" },
                { label: "Date picker", value: "datetime" },
                { label: "Dropdown select", value: "select" }, 
                { label: "Suggest dropdown", value: "datasetselect" },                 
                { label: "Radio select", value: "radio" }
            ]
        },
        {
            label: "values",
            options: [
                { label: "Number", value: "number" }
            ]
        },
        {
            label: "other",
            options: [
                { label: "File", value: "file" },
            ]
        }
    ];

    const renderField = (value: any) => {        
        let fieldlabel =""
          let a = fieldtype.map((field: any) => {
            field && field.options.length && field.options.map((item: any) => {
                if(item.value == value) fieldlabel = item.label;
            })
        })
        return fieldlabel
    }

    useEffect(() => {
        if(propertiesList && propertiesList.length) {
            let fields =  JSON.parse(propertiesList[0].valuesJson);  
            const keys = Object.keys(fields)
            keys.push('createdBy') 
            const columns: any = []
            if(keys && keys.length) {
                keys.map((field,index) =>{  
                    if(field == 'label')  return columns[0] = {
                        Header: _.startCase(field),
                        accessor: field,
                        filterable: true,
                        disableFilters: true,
                        isSortable: true,
                        show: true,
                        Cell: (cellProps: any) => {   
                            // let fields =  cellProps.row.original && cellProps.row.original.valuesJson ? JSON.parse(cellProps.row.original.valuesJson) : []    
                            return  <div className="d-flex align-items-center justify-content-between">                                
                            <div className="hstack flex-grow-1  gap-2"  style={{minWidth:50}}>
                                    <div className="flex-shrink-0 text-decoration-none hstack flex-grow-1 gap-2 text-ellipsis" >                                  
                                   
                                    {cellProps.row.original[field]}
                                </div>
                            </div>
                            {
                                field == "label" ? <div  className={"btn_preview"}>
                                    <div className={"hstack justify-content-end"}>
                                        {
                                            edit ?
                                                <Button
                                                    size='sm'
                                                    onClick={() => {setisEditOpen(true); onClickrow(cellProps.row); setId(cellProps.row.original.id)}}
                                                    color="primary"
                                                    title='Edit'
                                                    className="btn-primary justfy-self-end hoverbutton ms-0">
                                                        <i className="ri-pencil-fill label-icon align-middle"></i>
                                                </Button> : null}
                                            {edit ?
                                                <Button
                                                    size='sm'
                                                    onClick={() => {setisAccessOpen(true); onClickrow(cellProps.row); setId(cellProps.row.original.id)}}
                                                    color="info"
                                                    title='Manage access'
                                                    className="btn-primary justfy-self-end hoverbutton ms-1">
                                                        <i className="ri-settings-2-fill label-icon align-middle"></i>
                                                </Button>: null}
                                    </div>
                                </div>
                                    : null
                                }
                    </div>                        
                            
                        }
                    }
                    else if(field == 'type')  return columns[2] = {
                        Header: _.startCase(field),
                        accessor: field,
                        filterable: true,
                        disableFilters: true,
                        isSortable: false,
                        show: true,
                        Cell: (cellProps: any) => {   
                            return <div className="d-flex align-items-center justify-content-between">                                
                                        <div className="hstack flex-grow-1  gap-2">
                                                <div className="flex-shrink-0 text-decoration-none hstack flex-grow-1 gap-2" >                                  
                                                {renderField(cellProps.row.original[field])}
                                            </div>
                                        </div>
                                </div>                               
                        }
                    }
                    else if(field == 'createdBy' )  return columns[3] = {
                        Header: _.startCase(field),
                        accessor: field,
                        filterable: true,
                        disableFilters: true,
                        isSortable: false,
                        show: true,
                        Cell: (cellProps: any) => {        
                            return <span className="fw-semibold fs-14">{cellProps.row.original.createdBy}</span>                               
                        }
                    }
                })
                setColumns(columns)
            } 
            columns.push({
                Header:"Section",
                accessor: "groupId",
                id: "groupId",
                filterable: true,
                isSortable: true,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => {
                    return  <div className="d-flex align-items-center justify-content-between">                                
                               {cellProps.row.original["groupId"] }
                        </div>
                }
            })     
            columns.push({
                Header:"Created At",
                accessor: "createdAt",
                id: "createdAt",
                filterable: true,
                isSortable: true,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => {
                    return  <div className="d-flex align-items-center justify-content-between">                                
                               {cellProps.row.original["createdAt"] ? moment(cellProps.row.original["createdAt"]).format('lll'):
                    ''}
                        </div>
                }
            })        
        }
    }, [propertiesList]); 
    
    const handleSort = (columnName: any) => {
        if (sortBy === columnName) {
          // Reverse the sorting order if the same column is clicked again
          setSortBy(columnName);
          setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
          let sort = sortOrder === 'asc' ? 'desc' : 'asc'
          let filters = {...filterObject, sortBy: columnName,sortOrder: sort, pageSize: pageSize}
          setFilterObject(filters)      
          dispatch(propertyListGetRequest(model,filters, pagination.page));
        } else {
          // Set the new column to sort by and default to ascending order
          setSortBy(columnName);
          setSortOrder('asc');
          let filters = {...filterObject, sortBy: columnName, sortOrder: 'asc', pageSize: pageSize}  
          setFilterObject(filters)              
          dispatch(propertyListGetRequest(model,filters, pagination.page));
        }
      };

    useEffect(() => {
        // dispatch(propertyListGetRequest(model))
        // dispatch(propertyArchivedListGetRequest(model))
        let query = `status=CONFIRMED`

        dispatch(usersListGetRequest(query))      
        dispatch(ModelListGetRequest())  
    }, []); 

    useEffect(() =>{
       if(users && users.length) {
         let Options: any = users.map((user: any) => {
            return { label: user.name, value: user.id }
         })
         let option:any = {label: "All Users", value: ""}
         let newOptions: any= [option,...Options]
         SetUserOptions(newOptions)
       }
    },[users])

    const onClickrow = (data: any) => {
        setrecord(data);
        setInputs(data);
        let object = {...data.original,objectType: propertyObject.value, group: data.groupId}
        setEditInputs(object)
        let permissions = data?.original?.permissionsJson
        setpermissionInputs(permissions)
    }

    const execute = () => {
        let a = isEqual(propertiesList, propertiesJson);
        return !a
     }

    useEffect(() => {
        const parsedData: any = propertiesList && propertiesList.length ? propertiesList.map((item: any) => {
            const valuesJson = JSON.parse(item.valuesJson);
            const permissionsJson = item?.permissionsJson ? JSON.parse(item?.permissionsJson) : {};
            let created = item.createdBy && item.createdBy.name
            let values = { ...valuesJson, id: item.id , createdBy: created, createdAt: item.createdAt, permissionsJson, groupId: item?.groupId?.groupTitle}
            return values
        }) : []
        let properties = parsedData && parsedData.length ? parsedData.reverse() : []
        setPropertiesData(properties)
        setPropertiesJson(propertiesList)
    }, [execute()])

    
    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                    newPageNo = 0;
                    setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                    newPageNo = lastPage - 1;
                    setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal-1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        dispatch(propertyListGetRequest(model,filterObject, newPageNo));
    };

    const onTabChange = () => {

    }

    
    const onChangePageSize = (value: any) => {
        setPagination({...pagination, pageSize: value})
        setPageSize(value);
        let filters= {...filterObject, pageSize: value}
        setFilterObject(filters)
        dispatch(propertyListGetRequest(model,filters, 0));
    }

    const arrowNavToggle = (tab:any) => {
        if (arrowNavTab !== tab) {
            setarrowNavTab(tab);
        }
    };

    const dissortbyMulti = [
        { label: "All Field type", value: "" },
        {
            label: "Text input",
            options: [
                { label: "Single-line text", value: "textfield" },
                { label: "Multi-line text", value: "textarea" },
                { label: "Phone number", value: "phone" },
                { label: "Email", value: "email" },
            ]
        },
        {
            label: "Choosing options",
            options: [
                { label: "Single checkbox", value: "checkbox" },
                { label: "Multiple checkboxes", value: "selectboxes" },
                { label: "Date picker", value: "datetime" },
                { label: "Dropdown select", value: "select" }, 
                { label: "Suggest dropdown", value: "datasetselect" },                 
                { label: "Radio select", value: "radio" }
            ]
        },
        {
            label: "values",
            options: [
                { label: "Number", value: "number" }
            ]
        },
        {
            label: "other",
            options: [
                { label: "File", value: "file" },
            ]
        }

    ];

    const onArchived = () => {
        const handleSuccess = () => {
            dispatch(propertyListGetRequest(model))
            const message = () => toast(`Property is deleted successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message()
            setIsArchivedOpen(false)
            
        }
        const handleFailure = () => {
            setIsArchivedOpen(false)
            dispatch(propertyListGetRequest(model))
            const message = () => toast(`Error Occured while deleting property...!`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            message();
        }
        dispatch(propertyDeleteRequest(model,record.id, handleSuccess, handleFailure))  
    }

    const onDelete = () => {
        const handleSuccess = () => {
            const message = () => toast(`${record.label} is deleted successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message()
            dispatch(propertyListGetRequest(model, filterObject))
            dispatch(propertyArchivedListGetRequest(model));
            setisDeleteOpen(false)
        }
        const handleFailure = () => {
            setisDeleteOpen(false)
            dispatch(propertyListGetRequest(model, filterObject))
            dispatch(propertyArchivedListGetRequest(model));

        }
        dispatch(propertyDeleteRequest(model,record.id, handleSuccess, handleFailure))  
    }

    const clearFilter = () => {
        const filters = {pageSize: pageSize}
        setFilterObject(filters)
        setUser(null)
        setsearchvalue("")
        setFilterObject({pageSize: pageSize})
        setSelectedPropertyFieldType(null)
        setPageSize(pageSize)
        setPagination({page: 0})
        dispatch(propertyListGetRequest(model,filters));     
    }
    
    const handleChange = (value: any) => {
        setsearchvalue(value);
        const filters = {...filterObject, search: value, pageSize: pageSize}
        setFilterObject(filters);            
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {            
            dispatch(propertyListGetRequest(model,filters));  
        }, 2000);

        setTypingTimeout(newTimeout);
    };

    useEffect(() => {        
        if (paginationDetails) {            
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    },[paginationDetails])

    useEffect(() => {        
        dispatch(propertyListGetRequest(model,{pageSize: filterObject.pageSize},0 ));  
        dispatch(propertyArchivedListGetRequest(model)); 
        dispatch(GroupGetByModelRequest(model));
    },[model])

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setViewAccess(true)
            setCreate(true) 
            setEdit(true)
        }
        else if(subLevelPermissionsList) {      
            let access = subLevelPermissionsList?.fields;
            if(access && access?.length) {
                setAccess(access)
                if(access && access.length) {            
                    access.map((item: any, index: any) => {
                        if(item.name.toLowerCase() == 'view') {
                            item.value == "" || item.value == "none" ? setViewAccess(false)  : setViewAccess(true) 
                       }
                       if(item.name.toLowerCase() == 'create') {
                            item.value == "" || item.value == "none" ? setCreate(false)  : setCreate(true) 
                       }
                       if(item.name.toLowerCase() == 'edit') {
                            item.value == "" || item.value == "none" ? setEdit(false)  : setEdit(true) 
                        }           
                   })
               }
            }
            else {
                setViewAccess(false)
                setEdit(false)
            }
        }
    }, [subLevelPermissionsList, userProfile])

  
    const handleselected = (option: any,name:any) => {
        let filters: any = {}
        switch(name) {
            case "propertyObject": setpropertyObject(option)
                    setModel(option.value)
                    setFilterObject({pageSize: pageSize})
                    dispatch(propertyListGetRequest(option.value, {pageSize: pageSize}));
                    break;
            case "property-field-type": setSelectedPropertyFieldType(option)
                        filters = {...filterObject, type: option.value, pageSize: pageSize}
                        setFilterObject(filters)
                        dispatch(propertyListGetRequest(model, filters)); 
                break;
            case "user": setUser(option)
                        filters = {...filterObject, user: option.value, pageSize: pageSize}
                        setFilterObject(filters)
                        dispatch(propertyListGetRequest(model, filters)); 
                break;
        }
    }
    
    useEffect(() => {        
        dispatch(GroupGetByModelRequest(model))
    }, [])
    return (
        <div>
        { !authLoading ?
            viewAccess ? 
        <React.Fragment>
            <ToastContainer />
            {isArchivedOpen && <ArchiveModal 
                show={isArchivedOpen}
                onCloseClick={() => setIsArchivedOpen(false)}
                record={record} 
                onArchived={onArchived}
                props={props}
            />}
            {isDeleteOpen && <DeleteModal 
                show={isDeleteOpen}
                onCloseClick={() => setisDeleteOpen(false)}
                onDelete={onDelete}
                record={record} 
                props={props}
            />}
            {isCreateOpen && <CreatePropertyModal 
                show={isCreateOpen}
                createInputs={createInputs}
                setCreateInputs={setCreateInputs}
                onCloseClick={() => setisCreateOpen(false)}
                props={props}
                model={model}
            />}
             {isEditOpen && <EditPropertyModal 
                show={isEditOpen}
                setEditInputs={setEditInputs}
                EditInputs={EditInputs}
                onCloseClick={() => setisEditOpen(false)}
                record={record}
                props={props}
                propertiesList={propertiesList}
                filterObject={filterObject}
                pagination={pagination}
                id={id}
            />}
            {accessOpen && <ManageAccess 
                show={accessOpen}
                setpermissionInputs={setpermissionInputs}
                filterObject={filterObject}
                permissionInputs={permissionInputs}
                setEditInputs={setEditInputs}
                EditInputs={EditInputs}
                onCloseClick={() => setisAccessOpen(false)}
                record={record}
                props={props}
                propertiesList={propertiesList}
                model={model}
                pagination={pagination}
                // onApply={onApply}
                id={id}
            />}
            <Row>
                <Col>
                    <Row  className='hstack mb-3'>
                        <Col xxl={2} md={2} lg={2}>
                            <Label className='fw-bold fs-14'>Select Module</Label>
                        </Col>
                        <Col xxl={4} md={4} lg={4}>
                             <Select
                                className="fs-16 fw-medium"
                                defaultOptions
                                placeholder={props.t("properties.select_property_object")}
                                value={propertyObject}
                                onChange={(option : any) => handleselected(option, 'propertyObject')}
                                options={objectTypeOption}
                                name="choices-single-default"
                            ></Select>
                        </Col>
                    </Row>    
                </Col>     
            </Row>
            <Nav pills className="nav nav-pills arrow-navtabs nav-primary bg-light mb-3">
                <NavItem>
                    <NavLink
                        style={{ cursor: "pointer", fontWeight:600 }}
                        className={classnames({
                            active: arrowNavTab === "1",
                        })}
                        onClick={() => {
                            arrowNavToggle("1");
                            onTabChange()
                        }}
                    >{props.t("properties.properties")} &nbsp;({paginationDetails?.elements})
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={{ cursor: "pointer", fontWeight:600 }}
                        className={classnames({
                            active: arrowNavTab === "2",
                        })}
                        onClick={() => {
                            arrowNavToggle("2");
                            onTabChange()
                        }}
                    >
                        {props.t("properties.groups")} {groupspagination?.elements ? `(${groupspagination?.elements})` : null}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={{ cursor: "pointer", fontWeight:600 }}
                        className={classnames({
                            active: arrowNavTab === "3",
                        })}
                        onClick={() => {
                            arrowNavToggle("3");
                            onTabChange()
                        }}
                    >
                        {props.t("properties.lifecycle_stage.lifecycle_stage")}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={{ cursor: "pointer", fontWeight:600 }}
                        className={classnames({
                            active: arrowNavTab === "4",
                        })}
                        onClick={() => {
                            arrowNavToggle("4");
                            onTabChange()
                        }}
                    >
                        {props.t("properties.archived_properties")} &nbsp;({achivedpagination?.elements})
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent
                activeTab={arrowNavTab}
                className="text-muted p-0"
            >
                <TabPane tabId="1" id="all-properties">
                    <Card>
                        <CardBody className='border border-dashed border-end-0 border-start-0 vstack gap-2'>
                            <Row className='hstack gap-2 justify-content-start m-0'>
                                <Col className="p-0">
                                <div className="search-box">
                                    <Input
                                        type="text"
                                        size={14}
                                        className="search"
                                        placeholder="Search for..."
                                        onChange={(e) => handleChange(e.target.value)}
                                        value={searchvalue}
                                    />
                                    { searchvalue == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleChange("")}></i>}
                            
                                </div>                           
                                </Col>    
                                <Col className="p-0">
                                    <div>
                                        {/* <Select
                                            // isMulti
                                            defaultOptions
                                            className='border border-dark border-opacity-25 rounded-2'
                                            placeholder={props.t("properties.select_property_type")}
                                            value={selectedPropertyFieldType}
                                            onChange={(option : any) => handleselected(option, 'property-field-type')}
                                            options={dissortbyMulti}
                                            name="choices-single-default"
                                            id="idStatus"
                                        ></Select> */}
                                    </div>    
                                </Col>     
                                <Col className="p-0">
                                    {/* <Select
                                        // isMulti
                                        className='border border-dark border-opacity-25 rounded-2'
                                        defaultOptions
                                        placeholder={props.t("properties.select_user")}
                                        value={selecteduser}
                                        onChange={(option : any) => handleselected(option, 'user')}
                                        options={usersOptions}
                                        name="choices-single-default"
                                        id="idStatus"
                                    ></Select> */}
                                </Col> 
                                <Col className="p-0">
                                    {/* <div className='hstack gap-2 justify-content-start'>  
                                            <Button
                                                type="button"
                                                color="danger"
                                                outline={true}
                                                size='sm'
                                                className="py-2"
                                                onClick={() => clearFilter()}
                                            >
                                                {props.t("properties.clear_filter")}
                                            </Button> 
                                        </div> */}
                                </Col>                
                                <Col className="p-0 m-0" >
                                    <div className='hstack gap-2 justify-content-end'>  
                                    {
                                        create ? 
                                        <Button
                                            onClick={() => setisCreateOpen(true)}
                                            color="primary"
                                            className="btn-label">
                                                <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
                                                {props.t("properties.create_property")}
                                        </Button>
                                    :         
                                        <Button
                                            color="primary"
                                            size='sm'
                                            disabled
                                            className="btn-label">
                                                <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
                                                {props.t('roles.create_role')}
                                        </Button>}  
                                    </div>
                                </Col>
                            </Row>
                            <Row className='students-table'>
                                <TableContainer
                                    columns={columns}
                                    data={propertiesData || []}
                                    customPageSize={pageSize}
                                    divClass="table-responsive"
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light text-muted"
                                    thClass="border-bottom-1 table-soft-primary"
                                    SearchPlaceholder='Search...'
                                    isPageSizeChange={true}
                                    isBordered={true}
                                    setIsMultiDeleteButton={setIsMultiDeleteButton}
                                    setselectedcount={setselectedcount}                                             
                                    pageChanged={pageChanged}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    pagesList={pagesList}
                                    onChangePageSize={onChangePageSize}
                                    pageSize={pageSize}                                    
                                    onClickrow={onClickrow}
                                    setIsArchivedOpen={setIsArchivedOpen}
                                    setisEditOpen={setisEditOpen}  
                                    setisDeleteOpen={setisDeleteOpen}   
                                    handleSort={handleSort}
                                    sortBy={sortBy}
                                    sortOrder={sortOrder}     
                                    props={props}     
                                    setrecord={setrecord}  
                                    loading={loading}   
                                    access={access}           
                                />
                            </Row>
                        </CardBody>
                    </Card>
                </TabPane>
                <TabPane tabId="2">
                    { arrowNavTab === "2" && <Groups model={propertyObject.value} />}
                </TabPane>
                <TabPane tabId="3">
                    <Lifecycle model={propertyObject.value} />
                </TabPane>
                <TabPane tabId="4">
                    { arrowNavTab === "4" && <ArchivedProperties setArchivedarchivedCount={setArchivedarchivedCount} props={props} columns={columns} model={model} />}
                </TabPane>
            </TabContent>            
        </React.Fragment> : <AccessDenied />
        : <ListingLoader 
            columns={columns} 
            tableClass="align-middle table-nowrap"
            theadClass="table-light text-muted"
            thClass="border-bottom-1 table-soft-primary"
       /> }
       </div>
    );
};

export default withTranslation()(Properties);



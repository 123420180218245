import React from "react";
import { Navigate } from "react-router-dom";

import Students from "pages/Students";
import Properties from "pages/Properties";
import ForgotPassword from "pages/Forgot/ForgetPassword";
import Joinuser from "pages/JoinUser/JoinUser";
import StudentsDetails from "pages/StudentsDetails";
import ResetPassword from "pages/ResetPassword/ResetPassword";
import Signin from "pages/Signin/signin";
import UsersList from "pages/UsersList";
import EditProperty from "pages/EditProperties/EditProperty";
import User from "pages/User/User";
import Signup from "pages/Signup/Signup";
import UserDetailsModal from "pages/UsersList/User/UserDetailsModal";
import PrivacyPolicy from "../Privacy/PrivacyPolicy";
import FormBuilder from "../pages/FormBuilder/FormBuilder";
import Form from "../pages/FormBuilder/Form";
import Application from "../pages/Applications";
import Universities from "../pages/Universities";
import ApplicationDetails from "../pages/ApplicationsDetails";
import UserPreferences from "pages/UserPreferences";
import Courses from "../pages/Courses";
import Dashboard from "../pages/Dashboard";
import AIDashboard from "../pages/Dashboard/Dashboard1"
import CourseDetails from "../pages/CourseDetails";
import UniversityDetails from "../pages/UniversityDetails";
import Sources from "../pages/Sources";
import Channels from "../pages/Channel";
import SourceDetails from "../pages/SourceDetails";
import CompanyDetails from "../pages/CompanyDetails";
import ChannelDetails from "../pages/ChannelDetails";
import ApplicationStatusHandler from "../pages/Applications/ApplicationStatusComponent/ApplicationStatusHandler";
import UploadDocuments from "pages/UploadDocuments/UploadDocuments";
import Companies from "../pages/Companies";
import Charts from "../pages/Charts/ECharts"
import Links from "pages/Links";
import Invoices from "pages/Invoices";
import InvoiceCreate from "pages/Invoices/InvoiceCreate";
import InvoicePreview from "pages/Invoices/InvoicePreview";
import Recommendations from "pages/Recommendations";
import Textract from "../Components/Common/Textract/textract";
import CustomForms from "../pages/Forms";
import CustomForm from "../pages/Forms/Form";
import CustomFormsBuilder from "../pages/Forms/FormBuilder";
import EditForm from "../pages/Forms/EditForm";
import Subagent from "../pages/SubAgent"
import SubagentDetails from "../pages/SubAgentDetails"
import SessionError from "../Components/Common/SessionError"
import Roles from "../pages/Roles/Index"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/Students", component: <Students /> },
  { path: "/roles", component: <Roles /> },
  { path: "/user" , component: <User /> },
  { path: "/students/:id", component: <StudentsDetails /> },
  { path: "/fields", component: <Properties /> },
  { path: "/edit-property", component: <EditProperty /> },
  { path: "/users", component: <UsersList /> },
  { path: "/users/:id", component: <UserDetailsModal /> },
  { path: "/privacy-policy", component: <PrivacyPolicy /> },
  { path: "/form", component: <Form /> },
  { path: "/form-builder", component: <FormBuilder /> },
  { path: "/courses", component: <Courses /> },
  { path: "/courses/:id", component: <CourseDetails /> },
  { path: "/institutes", component: <Universities /> },
  { path: "/institutes/:id", component: <UniversityDetails /> },
  { path: "/applications", component: <Application /> },
  { path: "/sources", component: <Sources /> },
  { path: "/channels", component: <Channels /> },
  { path: "/applications/:id", component: <ApplicationDetails /> },
  { path: "/user-preferences/:id", component: <UserPreferences /> },
  { path: "/sources/:id", component: <SourceDetails /> },
  { path: "/channels/:id", component: <ChannelDetails /> },
  { path: "/charts", component: <Charts /> },
  { path: "/ai-dashborad", component: <AIDashboard /> },
  { path: "/links", component: <Links /> },
  { path: "/invoices", component: <Invoices /> },
  { path: "/invoices/create-invoice", component: <InvoiceCreate /> },
  { path: "/invoices/preview-invoice", component: <InvoicePreview /> },
  { path: "/invoices/preview-invoice", component: <InvoicePreview /> },
  {path: "/status", component:  <ApplicationStatusHandler />},
  { path: "/companies", component: <Companies /> },
  { path: "/companies/:id", component: <CompanyDetails /> },
  { path: "/textract", component: <Textract /> },
  { path: "/forms", component: <CustomForms /> },
  { path: "/subagents", component: <Subagent /> },
  { path: "/subagents/:id", component: <SubagentDetails /> },
  { path: "/forms/custom-form-builder", component: <CustomFormsBuilder /> },
  { path: "/forms/edit/:tenantId/:id/", component: <EditForm /> },
  { path: "/forms/view/:tenantId/:id/", component: <EditForm /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/signup", component: <Signup /> },
  { path: "/Signin", component: <Signin /> },
  { path: "/password/forgot", component: <ForgotPassword /> },
  { path: "/join/:id", component: <Joinuser /> },
  { path: "/password/reset/:id", component: <ResetPassword /> },
  { path: "/students/forms/:id", component: <UploadDocuments /> },
  { path: "/recommendations/:tenant/:id", component: <Recommendations /> },
  { path: "/forms/:tenantId/:id", component: <CustomForm /> },
  { path: "/session-expired", component: <SessionError /> },
];

export { authProtectedRoutes, publicRoutes };

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from "../../../config";
import SimpleBar from 'simplebar-react';

const { api } = config;

const API_Endpoint = api.AWS_API;

const { QuickSightEmbedding } = require('amazon-quicksight-embedding-sdk');

const EmbedQSearchBar: React.FC = () => {
  const [embedUrl, setEmbedUrl] = useState<string | null>(null);


  const fetchEmbedUrl = async () => {
    try {
        const response: any = await axios.get(`${API_Endpoint}/quicksight/getQEmbedUrl`);
        console.log("response", response)
        setEmbedUrl(response.embedUrl);
    } catch (error) {
        console.error("Error fetching embed URL", error);
    }

  //   try {
  //     const response = await fetch(`${API_Endpoint}/getQEmbedUrl`);
  //     if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //     }
  //     const data = await response.json();
  //     console.log(data);
  // } catch (error) {
  //     console.error('Error:', error);
  // }
  };

  useEffect(() => {
    fetchEmbedUrl();
  }, []);


  useEffect(() => {
    // if (embedUrl) {
    //   const containerDiv = document.getElementById('qSearchBarContainer');
    //   if (containerDiv) {
    //     const embeddingContext = QuickSightEmbedding.createEmbeddingContext({
    //       onChange: (changeEvent: any, metadata: any) => {
    //         console.log('Context received a change', changeEvent, metadata);
    //       },
    //     });

    //     const frameOptions = {
    //       url: embedUrl,
    //       container: containerDiv,
    //       height: "700px",
    //       width: "1000px",
    //       onChange: (changeEvent: any, metadata: any) => {
    //         switch (changeEvent.eventName) {
    //           case 'FRAME_MOUNTED':
    //             console.log("Q Search Bar frame mounted.");
    //             break;
    //           case 'FRAME_LOADED':
    //             console.log("Q Search Bar frame loaded.");
    //             break;
    //           default:
    //             break;
    //         }
    //       },
    //     };

    //     const contentOptions = {
    //       hideTopicName: false,
    //       theme: 'your-theme-id',
    //       allowTopicSelection: true,
    //       onMessage: (messageEvent: any, experienceMetadata: any) => {
    //         switch (messageEvent.eventName) {
    //           case 'Q_SEARCH_OPENED':
    //             console.log("Q Search content expanded.");
    //             break;
    //           case 'Q_SEARCH_CLOSED':
    //             console.log("Q Search content collapsed.");
    //             break;
    //           case 'Q_SEARCH_SIZE_CHANGED':
    //             console.log("Q Search size changed.");
    //             break;
    //           case 'CONTENT_LOADED':
    //             console.log("Q Search content loaded.");
    //             break;
    //           case 'ERROR_OCCURRED':
    //             console.log("Q Search error occurred.");
    //             break;
    //           default:
    //             break;
    //         }
    //       },
    //     };

    //     embeddingContext.embedQSearchBar(frameOptions, contentOptions).catch(console.error);
    //   }
    // }
  }, [embedUrl]);

  return <> 
    {embedUrl && 
    <iframe
      width="100%"
      height={"100%"}
      src={embedUrl}>
  </iframe>
}
  </>;
};

export default EmbedQSearchBar;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import Form from "Components/Common/FormBuilder/UpdateForm";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { StudentListGetRequest, studentGetRequest, studentUpdateRequest, studentsMetadataGetRequest } from "store/student/action";
import SimpleBar from "simplebar-react";
import { propertyListGetRequest } from "store/properties/action";
import { SourceListGetRequest, sourceUpdateRequest } from "store/sources/action";
import { ChannelListGetRequest, channelGetRequest, channelUpdateRequest } from "store/channels/action";
// Define types or interfaces
interface FormField {
    name: string;
    label: string;
    type: string;
    options?: { value: string; label: string }[];
}

interface FormData {
    fields: FormField[];
}

const SourceInfo = () => {
    const { id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const metadata = useSelector((state: ApplicationState) => state.properties.list);
    const channel: any = useSelector((state: ApplicationState) => state.channels.channel);

    const onUpdate = (formValues: any) => {
        let data = { ...channel, valuesJson: formValues };
        const handleSuccess = (body: any) => {
            console.log("body", body)
            const message = () => toast(`Channel updated Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(ChannelListGetRequest());
            dispatch(channelGetRequest(id))
        }
        dispatch(channelUpdateRequest(id, formValues, handleSuccess, () => { }))
    }
    useEffect(() => {
        const filters = { pageSize: 100 }
        dispatch(propertyListGetRequest("channels", filters));
    }, [])

    return (
        <Row className="justify-content-start">
            
            <Col md={6} lg={6}>                
                <Form dataFields={metadata} onUpdate={onUpdate} showbtn={false} data={channel} />
            </Col>
        </Row>

    );
};


export default SourceInfo;

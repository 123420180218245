import {
    Button,
    Card,
    CardBody,
    Col,
    Row,
} from "reactstrap";
import { WithTranslation, withTranslation } from "react-i18next";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import avatar7 from "../../../assets/images/users/avatar-3.jpg";
import avatar8 from "../../../assets/images/users/avatar-2.jpg";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { noteAddRequest, noteGetRequest } from "store/note/action";
import { notesListGetRequest } from "store/applications/action";
import moment from "moment";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { toast } from "react-toastify";

const noteType = [
    {
      options: [
        { label: "Counselling Session Summary", value: "COUNSELLING SESSION SUMMARY" },
        { label: "Follow-up call", value: "FOLLOW-UP CALL" },
        { label: "Incoming Call", value: "INCOMING CALL" },
        { label: "Attempt to book", value: "ATTEMPT TO BOOK" },
        { label: "Proof of Funds Details", value: "PROOF OF FUNDS DETAILS" },
        { label: "Course Notes", value: "COURSE NOTES" },
        { label: "Finance Notes", value: "FINANCE NOTES" },
        { label: "Others", value: "OTHERS" }
      ],
    },
  ];

const Notes = ({t, record} : any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const notes = useSelector((state: ApplicationState) => state.applications.notes);
    const [replyNoteId, setReplyNoteId] = useState<string | null>(null);
    const [replyNote, setReplyNote] = useState("")
    const [newNoteId, setNewNoteId] = useState<string | null>(null)
    const [newNote, setNewNote] = useState("");
    const [selectedNoteType, setSelectedNoteType] = useState(null);
    const [isAddNoteButtonDisabled, setIsAddNoteButtonDisabled] = useState<boolean>(false);
    const [shortNoteValue, setShortNoteValue] = useState("");
    const [transcribing, setTranscribing] = useState("child")
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
      } = useSpeechRecognition({transcribing: transcribing == "child"});

      useEffect(() => {
        if(record && record.id) {
            dispatch(notesListGetRequest(record.id));
        }
    }, [record])

      useEffect(() => {
        setReplyNote(transcript)
      }, [transcript])
      
        if (!browserSupportsSpeechRecognition) {
          return <span>Browser doesn't support speech recognition.</span>;
        }
      
        const startListening = () => {
          setTranscribing("child");
          SpeechRecognition.startListening();
        };
      
        const stopListening = () => {
          SpeechRecognition.stopListening();
        };

    const handleReplyNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReplyNote(event.target.value);
        setNewNoteId("");
        setReplyNoteId(replyNoteId);
    };

    const handleNoteTypeChange = (selectedOption: any) => {
        setSelectedNoteType(selectedOption);
        setShortNoteValue(selectedOption.label)
        // You can also update the formData here if needed
      };

    const handleReplyNote = () => {
        const data =
        {
            studentId: newNoteId,
            shortNote: shortNoteValue,
            parentId: replyNoteId,
            note: replyNote
        }
        const handleSuccess = (body: any): void => {
            handleReset()
            const message = () => toast(`Reply sent successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(notesListGetRequest(record.id));
           
        };

        const handleError = (body: any): void => {
            const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            message();
        };

        dispatch(noteAddRequest(data, handleSuccess, handleError));
    };


    const toggleReply = (noteId: string) => {
        if (replyNoteId === noteId) {
            setReplyNoteId(null);
        } else {
            setReplyNoteId(noteId);
        }
    };

    const handleReset = () => {
        setReplyNote("");
        setSelectedNoteType(null)
        resetTranscript();
        setShortNoteValue('')
      };

    const renderComments = (notes: any) => {
        return (
          <React.Fragment>
            {notes && notes.map((comment: any, key: number) => (
              <div className="d-flex flex-column" key={key}>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img src={avatar7} alt="" className="avatar-xs rounded-circle" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="d-flex gap-2 fs-15 mb-1">
                      <Link to="/pages-profile">{comment?.createdBy?.name}</Link>{" "}
                      <small className="text-muted">{moment(comment?.createdAt).format('MMMM DD, YYYY hh:mm A')}</small>
                      <span className="badge rounded-pill bg-info-subtle text-info">{comment?.shortNote}</span>
                    </h5>
                    <p className="text-muted mb-0">{comment?.detailedNote}</p>
                  </div>
                </div>
                <div className="ms-5 mb-4">
                  {replyNoteId === comment.id ? (
                    <div>
                      <textarea className="form-control bg-light border-light" value={replyNote} onChange={handleReplyNoteChange} rows={3} placeholder={t("applications.reply_to_this_note")}></textarea>
                      <div className="d-flex justify-content-end gap-1 mt-1">
                        <Select
                          placeholder="Select Note Type"
                          classNamePrefix="js-example-data-array"
                          isLoading={false}
                          options={noteType}
                          value={selectedNoteType}
                          onChange={handleNoteTypeChange}
                          menuPlacement="auto" // You can also try "bottom" or "top"
                          menuPosition="fixed"
                        />
                        {listening ? (
                          <>
                            <Button color="danger" className="ms-2 btn-icon" outline type="button" onClick={stopListening}> <i className="ri-mic-off-line"></i> </Button>
                          </>
                        ) : (
                          <>
                            <Button color="primary" className="ms-2 btn-icon" outline type="button" onClick={startListening}> <i className="ri-mic-line"></i> </Button>
                          </>
                        )}
                        <button onClick={(e) => {
                          handleReplyNote();
                          e.preventDefault();
                          toggleReply(comment.id);
                        }}
                        disabled={isAddNoteButtonDisabled || (!replyNote || !selectedNoteType) && (!transcript || !selectedNoteType)}
                        className="btn btn-sm btn-success mt-1">{t("applications.reply")}</button>
                        <button onClick={() => toggleReply(comment.id)} className="btn btn-sm btn-danger mt-1">{t("applications.cancel")}</button>
                      </div>
                    </div>
                  ) :
                    <>
                      <Link to="#" className="badge text-muted bg-light" onClick={() => {
                        // e.preventDefault(); 
                        toggleReply(comment.id)
                        setReplyNoteId(comment.id)
                      }}>
                        <i className="mdi mdi-reply"></i> {t("applications.reply")}
                      </Link>
                    </>
                  }
                </div>
                {/* Render nested comments */}
                {comment.replies && comment.replies.length > 0 && (
                  <div className="ms-5">
                    {renderComments(comment.replies)}
                  </div>
                )}
              </div>
            ))}
          </React.Fragment>
        );
      };

    return (
        <React.Fragment>
            {/* Your existing JSX code */}
            <Card>
                <CardBody className="px-0">
                <SimpleBar style={{ maxWidth: "fit-content" }} className="">
            {/* Render the comments recursively */}
            {renderComments(notes)}
          </SimpleBar>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default withTranslation()(Notes);

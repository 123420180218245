import {CustomFormActionTypes} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";

const { api } = config;

const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const formGetRequest: AppThunk = (tenantId, id) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {      
        const { auth: { token } } = getState();
        dispatch({
            type: CustomFormActionTypes.GET_CUSTOMFORM_REQUEST
        }); 
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const response = await fetch(`${DTS_API_STUDENT}/forms/${tenantId}/${id}`, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CustomFormActionTypes.GET_CUSTOMFORM_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: CustomFormActionTypes.GET_CUSTOMFORM_SUCCESS,
                payload: body_1
            });
        }
    };
};


const createQueryLink = (filterObject: any, page: any) => {
    let query = `${DTS_API_STUDENT}/forms?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const formListGetRequest: AppThunk = (filterObject,pageno) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: CustomFormActionTypes.GET_CUSTOMFORMS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery = createQueryLink(filterObject,pageno);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CustomFormActionTypes.GET_CUSTOMFORMS_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: CustomFormActionTypes.GET_CUSTOMFORMS_SUCCESS,
                payload: body_1
            });
        }
    };
};
export const formNameListRequest: AppThunk = (queryParams, page, pageSize, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: CustomFormActionTypes.GET_CUSTOMFORMS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const response = await fetch(`${DTS_API_STUDENT}/forms?${queryParams}`, {
            method: "GET",
            headers: headers
        });
    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CustomFormActionTypes.GET_CUSTOMFORMS_ERROR,
                payload: body_1
            });
            // handleError(body_1);
        } else {
            dispatch({
                type: CustomFormActionTypes.GET_CUSTOMFORMS_SUCCESS,
                payload: body_1
            });
            // handleSuccess("Users fetched successfully");
        }
    };
};
export const formUpdateRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (id,data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: CustomFormActionTypes.PUT_CUSTOMFORM_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/forms/${id}`,
            {
                method: "PUT",
                headers:headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CustomFormActionTypes.PUT_CUSTOMFORM_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: CustomFormActionTypes.PUT_CUSTOMFORM_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const formCreateRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: CustomFormActionTypes.POST_CUSTOMFORM_REQUEST
        });
        const response = await fetch(
            `${DTS_API_STUDENT}/forms`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "token": token
                },
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CustomFormActionTypes.POST_CUSTOMFORM_ERROR
            });
            handleError && handleError();
        } else {
            dispatch({
                type: CustomFormActionTypes.POST_CUSTOMFORM_SUCCESS,
                payload: []
            });
            handleSuccess && handleSuccess(body_1);
        }
    };
};

export const formDeleteRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();  
        dispatch({
            type: CustomFormActionTypes.DELETE_CUSTOMFORM_REQUEST
        });
        const response = await fetch(`${DTS_API_STUDENT}/forms/${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "token": token
            }
        });
        const body = response;
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CustomFormActionTypes.DELETE_CUSTOMFORM_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: CustomFormActionTypes.DELETE_CUSTOMFORM_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const getMataData: AppThunk = (tenantId, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: CustomFormActionTypes.GET_METADATA_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const response = await fetch(`${DTS_API_STUDENT}/students-metadata/forms?pageSize=500&tenantId=${tenantId}`, {
            method: "GET",
            headers: headers
        });
    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CustomFormActionTypes.GET_METADATA_ERROR,
                payload: body_1
            });
            // handleError(body_1);
        } else {
            dispatch({
                type: CustomFormActionTypes.GET_METADATA_SUCCESS,
                payload: body_1
            });
            // handleSuccess("Users fetched successfully");
        }
    };
};


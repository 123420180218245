import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//import images
// import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar1 from "../../assets/images/dtsimg/svg/avatar1.gif"
import { createSelector } from 'reselect';
import {signoutRequest, userGetRequest} from "../../store/auth/action"
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { Link, useNavigate } from 'react-router-dom';
import { ApplicationState } from 'store';

const ProfileDropdown = () => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    // const userProfileh = useSelector((state: ApplicationState) => state.auth.userProfileh);
    const auth = useSelector((state: ApplicationState) => state.auth);
    const user = useSelector((state: ApplicationState) => state.auth.userProfile);
    const googleResponse = useSelector((state: ApplicationState) => state.calendarSync.data);
    const {userProfile, isUserLoggedin, token} = auth;
    const [picture, setPicture] = useState<string>("")
    const navigate = useNavigate();
    // //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState<boolean>(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    const logout =  async () : Promise<void> => {
       await dispatch(signoutRequest());
        navigate('/signin');
    }
    
    useEffect(() => {
        if(googleResponse) setPicture(googleResponse[0]?.socialDataDTO?.userProfile?.picture)
       },[googleResponse])

  useEffect(() => {
    const handleSuccess = (res: any) => {
        console.log("res", res)
        if(res?.errorMessage == "Unauthorized access: Invalid token") {
           
            navigate("/session-expired")
        }
    }
    const handleFailure = (error: any) => {
        console.log("error", error)
        if(error?.errorMessage == "Unauthorized access: Invalid token") {
            navigate("/session-expired")
        }
    }
    dispatch(userGetRequest("", handleSuccess, handleFailure));
  }, [token]);

    return (
        <React.Fragment>
            {
                userProfile ? 
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <img height={50} width={50} className={'rounded-circle fw-semibold rounded-circle img-thumbnail hstack justify-content-center'} src={picture ? picture : user.profilePic}
                            alt="Header Avatar" />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-bold">{userProfile.name}</span>
                            <span className="d-none d-xl-block ms-1 fs-12">{userProfile?.role?.title}</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header">Welcome {userProfile.name}</h6>
                    <DropdownItem
                    onClick={() => {
                        navigate(`/user-preferences/${userProfile.id}`)
                    }}><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                        <span className="align-middle">Profile</span></DropdownItem>
                    <DropdownItem onClick={logout}><i
                        className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle" data-key="t-logout">Logout</span></DropdownItem>
                </DropdownMenu>
            </Dropdown>
            :
            <div className='ms-2'>
                <Link to="/signin" className="d-inline-block fw-semibold">
                    Signin
                </Link>
            </div>              
            }
        </React.Fragment>
    );
};

export default ProfileDropdown;
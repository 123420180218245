import React, { useEffect, useState } from "react";
import {
  Col,
  Input,
  Label,
  Row,
  Form
} from "reactstrap";
import Select from "react-select";
import TextPreviewComponent from "./TextPreviewComponent";
import PhoneNumberPreview from "./PhoneNumberPreview";
import SingleCheckbox from"./SingleCheckbox";
import MultipleCheckbox from "./MultipleCheckBoxPreview";
import DropdownSelectPreview from "./DropdownSelectPreview";
import RadioPreview from "./RadioPreview";
import DatePickerPreview from "./DatePickerPreview";
import NumberPreview from "./NumberPreview";
import FieldArray from '../Inputs.json';
import DataSetDropdown from "./DataSetDropdown";

const FieldType = ({setEditInputs,  EditInputs, setFieldJson, fieldJson}: any) => {  
    const [selectedFieldtype, setSelectedFieldtype] = useState<any>(null);

    const handleselected = (option: any ) => {
        setSelectedFieldtype(option);
        let fieldvalue = option.value;
        console.log("fieldvalue", fieldvalue)
        let field = FieldArray.filter((field: any) => field.type === option.value)[0];
        console.log("field", field)
        let newfield = {...field, label: EditInputs.label, key: EditInputs.key, description: EditInputs.description, placeholder: ""}
        setEditInputs({...EditInputs, type: fieldvalue,  placeholder: ""})
        setFieldJson(newfield)
    }

    const fieldtype = [
        {
            label: "Text input",
            options: [
                { label: "Single-line text", value: "textfield" },
                { label: "Multi-line text", value: "textarea" },
                { label: "Phone number", value: "phone" },
                { label: "Email", value: "email" },
            ]
        },
        {
            label: "Choosing options",
            options: [
                { label: "Single checkbox", value: "checkbox" },
                { label: "Multiple checkboxes", value: "selectboxes" },
                { label: "Date picker", value: "datetime" },
                { label: "Dropdown select", value: "select" }, 
                { label: "Suggest dropdown", value: "datasetselect" },                 
                { label: "Radio select", value: "radio" }
            ]
        },
        {
            label: "values",
            options: [
                { label: "Number", value: "number" }
            ]
        },
        {
            label: "other",
            options: [
                { label: "File", value: "file" },
            ]
        }

    ];

    const handlePreviewComponent = () => {
        let renderfieldtype = selectedFieldtype && selectedFieldtype.value ? selectedFieldtype.value : '';
        console.log("renderfieldtype", renderfieldtype)
        switch(renderfieldtype) { 
            case 'email':  
            case 'textarea':   
            case 'textfield': return <TextPreviewComponent EditInputs={EditInputs} setEditInputs={setEditInputs} fieldType={renderfieldtype} fieldJson={fieldJson} />
            case 'phone': return <PhoneNumberPreview EditInputs={EditInputs} setEditInputs={setEditInputs}  fieldType={renderfieldtype} fieldJson={fieldJson}/>
            case 'checkbox': return <SingleCheckbox EditInputs={EditInputs} setEditInputs={setEditInputs}  fieldType={renderfieldtype} fieldJson={fieldJson}/>
            case 'selectboxes': return <MultipleCheckbox setFieldJson={setFieldJson} fieldJson={fieldJson} EditInputs={EditInputs} setEditInputs={setEditInputs} fieldType={renderfieldtype}  />
            case 'select': return <DropdownSelectPreview setFieldJson={setFieldJson} fieldJson={fieldJson} EditInputs={EditInputs} setEditInputs={setEditInputs} fieldType={renderfieldtype}  />
            case 'datasetselect': return <DataSetDropdown setFieldJson={setFieldJson} fieldJson={fieldJson} EditInputs={EditInputs} setEditInputs={setEditInputs} fieldType={renderfieldtype}  />
            case 'radio': return <RadioPreview setFieldJson={setFieldJson} fieldJson={fieldJson}  EditInputs={EditInputs} setEditInputs={setEditInputs}  fieldType={renderfieldtype}   />
            case 'datetime': return <DatePickerPreview EditInputs={EditInputs} setEditInputs={setEditInputs}  fieldType={renderfieldtype}  fieldJson={fieldJson} />
            case 'file': return null
            case 'number': return <NumberPreview EditInputs={EditInputs} setEditInputs={setEditInputs}  fieldType={renderfieldtype} />
            default: return null
        }
    }

    const handlePlaceholderComponent = () => {
        let renderfieldtype = selectedFieldtype && selectedFieldtype.value ? selectedFieldtype.value : ''
        switch(renderfieldtype) {  
            case 'textarea':   
            case 'textfield':
            case 'phone': 
            case 'datetime': 
            case 'email': 
            case 'number': return Placeholder()
            default: return null
        }
    }

    const Placeholder = () => {
        return <div>
                <Label className="fs-16 fw-medium my-1">Enter Placeholder</Label>                            
                <Input
                    type={"text"}
                    name={"label"}
                    className="fs-16 fw-medium"
                    value={EditInputs.placeholder}
                    onChange={(e) => setEditInputs({...EditInputs, placeholder: e.target.value})}                                                         
                />
            </div>
    }

    const renderField = (value: any) => {
        console.log("value", value)
        let field = {}
          let a = fieldtype.map((field: any) => {
            field && field.options.length && field.options.map((item: any) => {  
                console.log("item", item)
                if(item.value == value)  {
                    setSelectedFieldtype(item)
                    console.log("item", item)
                    field = item;
                }
            })
        })
        console.log("field", field)
        return field
    }

    useEffect(() => {
        let newfield = {...fieldJson, placeholder: EditInputs.placeholder}        
        setFieldJson(newfield)
    }, [EditInputs])
    useEffect(() => {
        renderField(EditInputs.type)
    }, [])

    return (
        <div>  
            <Row className="hstack gap-3 flex-column justify-content-center">
                <Col lg={12} className="d-flex gap-2 fs-24 fw-bold flex-column justify-content-center text-center">    
                        {EditInputs.label}
                </Col>
                <Col lg={12} className="d-flex gap-2 flex-column justify-content-center">    
                    <div>
                        <Label className="fs-16 fw-semibold">Field Type</Label>                            
                        <Select
                            defaultOptions
                            placeholder={'Select field type'}
                            className="fs-16 fw-medium"
                            value={selectedFieldtype}
                            onChange={(option : any) => handleselected(option)}
                            options={fieldtype}
                            isDisabled={true}
                            name="choices-single-default"
                        ></Select>
                    </div>
                    <div>
                        {handlePlaceholderComponent()}
                    </div>
                    <div>
                        {handlePreviewComponent()}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default FieldType;

import React, { useEffect, useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
    Modal,
    ModalBody,
    Button,
    ModalHeader,
    ListGroup,
    ListGroupItem,
} from "reactstrap";
import StarRatingInput from 'react-star-ratings';
import { ApplicationsListGetRequest, applicationCreateRequest, applicationUpdateRequest } from "store/applications/action";
import { ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SimpleBar from "simplebar-react";
import { ApplicationState } from "store";
import { applicationsCommissions } from "store/channels/action";
import { recommendApplicationList } from "store/recommendation/action";
import { useParams } from "react-router";


const ChannelWiseDetailsAddEntry = ({ props, show, onCloseClick, dataFields, record,studentId , fetchAppliedCourses}: any) => {
    const {id} = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const channelList = useSelector((state: ApplicationState) => state.channels.applicationsChannel);
    const [formMetaData, setFormMetaData] = useState<any>([]);    
    const [formdata, setformdata] = useState<any>({});
    const [financeList, setfinanceList]= useState<any>({});
    
    const CreateApplication = (item: any) => {
        let formValues= {...record, channel: item , application_status: "application"};
        const handleSuccess = (body: any) => {
            const message = () => toast(`Application Added Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            fetchAppliedCourses()
            dispatch(recommendApplicationList(id))
            onCloseClick()            
        }
        const handleError = () => {

        }
        dispatch(applicationUpdateRequest(record.id, formValues, handleSuccess, () => { }))
    }
    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            return false;
        }
    }
    useEffect(() => {
        let newstatusMetaData: any = []
        dataFields && dataFields.map((field: any) => {
            let item = field.valuesJson  && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}
            if(item?.quickCreate) return newstatusMetaData.push(field)
        })
        let addEntryFields = newstatusMetaData.filter((obj: any) => JSON.parse(obj.valuesJson).key !== 'student' && JSON.parse(obj.valuesJson).key !== 'course_name');
        setFormMetaData(addEntryFields)
    }, [dataFields])

    useEffect(() => {
        let prefilleddata: any = []
        console.log("record", record)       
       if(record && record.length) {
        prefilleddata = {
            student: studentId,
            course_name: record.id
        }
    }
        // let addEntryFields = newstatusMetaData.filter((obj: any) => JSON.parse(obj.valuesJson).key !== 'student');
        setformdata(prefilleddata)
    }, [record])

    useEffect(() => {     
        console.log("record", record)    
        dispatch(applicationsCommissions(record.id))     
    }, [record.id])

    useEffect(() => {
        const parsedData: any = channelList && channelList.length ? channelList.map((item: any) => {
            const valuesJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : {};
            let values = { ...valuesJson, channel_id: item.id, createdAt: item.createdAt,averageRating: item.averageRating, commission: item.commission,commissionPercentage: item.commissionPercentage, taxIncluded: item.taxIncluded, ...item }
            return values
        }) : []
        let channels = parsedData && parsedData.length && parsedData.reverse() 
        setfinanceList(channels)
    }, [channelList])

    console.log("financeList", financeList)
    return (
          <Offcanvas
          direction="end"
          isOpen={show}
          id="offcanvasExample"
          toggle={onCloseClick}
          backdrop={false}
      >
          <OffcanvasHeader className="bg-light" toggle={() => { onCloseClick(!show) }}>
              {props.t("student.create_application")}
          </OffcanvasHeader>
          <OffcanvasBody>
              <Card>
                  <CardBody className="p-0">
                  <SimpleBar style={{ maxHeight: "800px" }}>
                        <ListGroup className="mb-1">
                            
                       { financeList && financeList.length ? financeList.map((item: any) => {
                        console.log("item", item);
                            return <ListGroupItem>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 w-75">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0 avatar-xs">
                                            <div className="avatar-title bg-danger-subtle text-danger rounded">
                                                <i className="ri-netflix-fill"></i>
                                            </div>
                                        </div>
                                        <div className="ms-2">
                                            <h6 className="fs-14 mb-0 flex-wrap">{item.name}</h6>
                                            <StarRatingInput
                                                rating={parseFloat(item.averageRating) || 0}
                                                starRatedColor="yellow"
                                                numberOfStars={5}
                                                name='rating'
                                                starDimension="10"
                                                starSpacing="2px"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-shrink-0 w-25">
                                    <Button
                                        color="secondary"
                                        className="btn-sm"
                                        style={{width: 'fit-content', minWidth: '80px'}}
                                        outline={true}                                        
                                        onClick={() =>CreateApplication(item)}
                                    >
                                    £{item.commission}
                                    </Button>
                                </div>
                            </div>
                            </ListGroupItem>
                       }) : <div>No Data to show for Channels</div>}                          
                        </ListGroup>
                    </SimpleBar>
                  </CardBody>
              </Card>
          </OffcanvasBody>
      </Offcanvas>
    );
};

export default ChannelWiseDetailsAddEntry;
import React, { useEffect, useState } from "react";
import {
    Label,
    Input,
    Form,
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormFeedback,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import country from './countrycode.json';
import SimpleBar from "simplebar-react";
import { ValueType } from 'react-select';

interface Country {
    name: string;
    code: string;
    emoji: string;
    unicode: string;
    label: string;
    image: string;
}

interface DtsPhoneInputProps {
    label: string;
    value: string;
    placeholder?: string;
    onChange: (value: string) => void;
    isEditState?: boolean;
    rest?: any;
    invalid?: any;
    formValues?: any;
    dataFields?: any;
    name?: string;
    errorText?: string;
    readOnly?: boolean;
    disabled?: boolean;
    actionButtons?: boolean;
    defaultValue?: string;
    isEditable?: any;
    onSaveClick?: any;
    setdisabled?: any;
    setError?: any;
    formerror?: any;
    options: any;
}

const DtsDropdownInput: React.FC<DtsPhoneInputProps> = ({ options, formerror, setError, setdisabled, onSaveClick, rest, defaultValue, actionButtons, invalid, formValues, dataFields, name, errorText, label, readOnly, disabled, value, placeholder, onChange, isEditState }: any) => {
    const [hover, setHover] = useState<boolean>(false);
    const [fulldata, setFulldata] = useState<any>({});
    const [searchValue, setSearchValue] = useState<string>("");
    const [countryOptions, setCountryOptions] = useState<any[]>([]);
    const [currencyoptions, setCurrencyOption] = useState<any>([]);
    const [checkdisabled, setCheckdisabled] = useState<any>(false)
    const [activecurrency, setactivecurrency] = useState<any>({"value": "", "label": "Currency", "symbol": "", "country": ""});
    const [active, setactive] = useState<any>({
       label: "Percent",
       value:"percent"
    });
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [dropdownOpen2, setDropdownOpen2] = useState<boolean>(false);
    const [phonenumber, setPhonenumber] = useState<string>(""); 
    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
    const toggleDropdown2 = () => setDropdownOpen2((prevState) => !prevState);
    useEffect(() => {
        if(defaultValue) {
            setPhonenumber(defaultValue)
        }
    }, [defaultValue]);
    useEffect(() => {
        if (isEditState != undefined) setHover(isEditState);
    }, [isEditState]);
    // const handleInputChange = (searchvalue: any) => {
    //     // Use replace with a regular expression to remove all occurrences of '+'
    //     let value = searchvalue.replace(/\+/g, '');
    //     setSearchValue(value)
    //     const updatedData = country.filter((item: any) => {
    //         return (
    //             item.name.toLowerCase().search(value.toLowerCase()) !== -1
    //             || item.code.toLowerCase().search(value.toLowerCase()) !== -1
    //             || item.label.search(value) !== -1
    //         );
    //     });
    //     setCountryOptions(updatedData);
    // }
    const handleCurrencyInputChange = (searchvalue: any) => {
        setSearchValue(searchvalue)
        let value = searchvalue
        if(value == "") setCurrencyOption(currencies)
        else {
                const updatedData = currencyoptions.filter((item: any) => {
                    return (
                        item.label && item.label.toLowerCase().search(value.toLowerCase()) !== -1
                        || item.currency && item.currency.toLowerCase().search(value) !== -1
                        || item.country && item.country.toLowerCase().search(value) !== -1
                    );
                });
                setCurrencyOption(updatedData);
        }
    }

    const onChangevalue = (value: any) => {
        if (value.includes('e') || value.includes('+') || value.includes('-')) return 
        else {
            let data = {...fulldata, value: value }  
            setPhonenumber(value)    
            setFulldata(data)  
            onChange(JSON.stringify(data))
        }
        validateInput(value);
    }
    const onChangecountry = (active: any) => {
        setactive(active)
        let data = {...fulldata, type: active.value }     
        setFulldata(data)
        onChange(JSON.stringify(data))  
    }

    const onChangecurrency = (item: any) => {
        setactivecurrency(item)        
        let data = {...fulldata, currency: item.symbol }    
        setFulldata(data)
        onChange(JSON.stringify(data))  
    }

    const [errormsg, setErrormsg] = useState();

  
    
    useEffect(() => {
        if(rest && rest.validate && rest.validate.required) {           
            if(value === '')
            { 
                const inputdiv: any = document.getElementById(`${name}input`);
                if(inputdiv) inputdiv.classList.add('d-block');
            } 
            else {
                const inputdiv: any = document.getElementById(`${name}input`);
                if(inputdiv) inputdiv.classList.remove('d-block');
            }            
        }
    },[value])

    const validateInput = (value: any) => { 
        if(rest && rest.type == 'email') {
            if (value === '') {
                let error: any =  `Please enter valid ${label}`;
                setErrormsg(error)
                if (typeof setError === 'function') {
                    setError({...formerror, [name] : error})
                }
                setCheckdisabled(true)
                return false
            }
        }
        if(rest && rest.validate && rest.validate.required) {
            if(value === "") {
               let error: any =  `Please enter ${label}`;
                setErrormsg(error)
                if (typeof setError === 'function') {
                    setError({...formerror, [name] : error})
                }
                setCheckdisabled(true)
                return false
            }
        }
        if(rest && rest.validate && rest.validate.minLength) {
            if(value.length < rest.validate.minLength) {
                let error: any =  `Length of ${label} must be atleast ${rest.validate.minLength}`;
                setErrormsg(error)
                if (typeof setError === 'function') {
                    setError({...formerror, [name] : error})
                }
                setCheckdisabled(true)
                return false
            }
        }
        if(rest && rest.validate && rest.validate.maxLength) {   
           if(value.length > rest.validate.maxLength) {
                let error: any =  `Length of ${label} must be not more than ${rest.validate.maxLength}`;
                setErrormsg(error)
                if (typeof setError === 'function') {
                    setError({...formerror, [name] : error})
                }
                setCheckdisabled(true)
                return false
            }
        }
        setErrormsg(undefined) 
        if (typeof setError === 'function') {
            const {[name]: _, ...newObject } = formerror;   
            setError(newObject)
        }
        setCheckdisabled(false)
        setCheckdisabled(false)
        return true
    }


    useEffect(() => {
        if(rest && rest.validate) {           
            if(errormsg)
            { 
                if (typeof setdisabled === 'function') {
                    setdisabled(true)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if(inputdiv) inputdiv.classList.add('d-block');
            } 
            else {
                if (typeof setdisabled === 'function') {
                    setdisabled(false)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if(inputdiv) inputdiv.classList.remove('d-block');
            }            
        }
    },[value])

    useEffect(() => {
        if(rest && rest.validate && rest.validate.required) { 
            const feedback: any = document.getElementById(`${name}`);
            if(feedback) feedback.classList.remove('d-none');
        }
    },[rest])

    const currencies : any =  [
        {"value": "", "label": "Currency", "symbol": "", "country": ""},
        {"value": "USD", "label": "US Dollar", "symbol": "$", "country": "United States"},
        {"value": "EUR", "label": "Euro", "symbol": "€", "country": "Eurozone"},
        {"value": "JPY", "label": "Japanese Yen", "symbol": "¥", "country": "Japan"},
        {"value": "GBP", "label": "Pound Sterling", "symbol": "£", "country": "United Kingdom"},
        {"value": "AED", "label": "United Arab Emirates Dirham", "symbol": "د.إ", "country": "United Arab Emirates"},
        {"value": "AFN", "label": "Afghan Afghani", "symbol": "؋", "country": "Afghanistan"},
        {"value": "AUD", "label": "Australian Dollar", "symbol": "A$", "country": "Australia"},
        {"value": "CAD", "label": "Canadian Dollar", "symbol": "C$", "country": "Canada"},
        {"value": "CHF", "label": "Swiss Franc", "symbol": "CHF", "country": "Switzerland"},
        {"value": "CNY", "label": "Chinese Yuan", "symbol": "¥", "country": "China"},
        {"value": "INR", "label": "Indian Rupee", "symbol": "₹", "country": "India"},
        {"value": "MXN", "label": "Mexican Peso", "symbol": "$", "country": "Mexico"},
        {"value": "NZD", "label": "New Zealand Dollar", "symbol": "NZ$", "country": "New Zealand"},
        {"value": "RUB", "label": "Russian Ruble", "symbol": "₽", "country": "Russia"},
        {"value": "SGD", "label": "Singapore Dollar", "symbol": "S$", "country": "Singapore"},
        {"value": "ZAR", "label": "South African Rand", "symbol": "R", "country": "South Africa"},
        {"value": "BRL", "label": "Brazilian Real", "symbol": "R$", "country": "Brazil"},
        {"value": "KRW", "label": "South Korean Won", "symbol": "₩", "country": "South Korea"},
        {"value": "TRY", "label": "Turkish Lira", "symbol": "₺", "country": "Turkey"},
        {"value": "SEK", "label": "Swedish Krona", "symbol": "kr", "country": "Sweden"},
        {"value": "NOK", "label": "Norwegian Krone", "symbol": "kr", "country": "Norway"},
        {"value": "DKK", "label": "Danish Krone", "symbol": "kr", "country": "Denmark"},
        {"value": "PLN", "label": "Polish Zloty", "symbol": "zł", "country": "Poland"},
        {"value": "THB", "label": "Thai Baht", "symbol": "฿", "country": "Thailand"},
        {"value": "IDR", "label": "Indonesian Rupiah", "symbol": "Rp", "country": "Indonesia"}
    ];
    
    useEffect(() => {
        setCurrencyOption(currencies)
    }, [])
    return (
        <div className="hstack w-100">
            <Dropdown  isOpen={dropdownOpen} className="hstack w-25" toggle={toggleDropdown}>             
                <DropdownToggle className="hstack btn btn-light border border-dark border-opacity-25 w-100 m-0" style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, height: 37 }}>            
                    <span className="fs-12">{active.label}</span>&nbsp;
                    <i className=" ri-arrow-down-s-line fs-14"></i>
                </DropdownToggle>
                <DropdownMenu className="w-100">
                    <SimpleBar style={{ maxHeight: "220px" }} className="px-1">                                                        
                        {options.map((item: any, key: number) => (
                            <DropdownItem onClick={() => onChangecountry(item)} key={key} className="d-flex">
                                <div className="flex-grow-1">
                                    <div className="d-flex">
                                        <div className="country-name me-1">{item.label}</div>
                                    </div>
                                </div>
                            </DropdownItem>
                        ))}
                    </SimpleBar>
                </DropdownMenu>
            </Dropdown>
            <div style={{ borderRadius:0, height: 37 }} className={active.value == "fixed" ? "hstack w-50 justify-content-between " : " hstack w-75 justify-content-between"}>
                <Input
                    type="number"
                    name="label"
                    value={phonenumber}
                    defaultValue={defaultValue}
                    placeholder={placeholder ? placeholder : `Enter placeholder`}
                    className="w-100 h-100 text-start fs-12"
                    onChange={(e: any) => onChangevalue(e.target.value)}
                />
            </div>   
            {
                active.value == "fixed" ? <Dropdown isOpen={dropdownOpen2}  className="hstack w-25" toggle={toggleDropdown2}>
                    <DropdownToggle className="hstack btn btn-light border border-dark border-opacity-25 w-100 " style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, height: 37 }}>            
                    {activecurrency.value == ""  ? <span className="fs-12">{activecurrency.label}{" "}</span> : null}
                    <span className="fs-12">{activecurrency.symbol}</span>&nbsp;
                    <i className=" ri-arrow-down-s-line fs-14"></i>
                    </DropdownToggle>                    
                    <DropdownMenu className="w-100">
                        <div className="search-box">
                            <Input
                                type="text"
                                size={14}
                                className="search"
                                placeholder="Search for..."
                                onChange={(e) => handleCurrencyInputChange(e.target.value)}
                                value={searchValue}
                            />
                            <i className="ri-search-line search-icon"></i>{" "}
                        </div>
                        <SimpleBar style={{ maxHeight: "220px" }} className="px-1">                                                        
                            {currencyoptions.map((item: any, key: number) => (
                                <DropdownItem onClick={() => onChangecurrency(item)} key={key} className="d-flex">
                                    <div className="flex-grow-1">
                                        <div className="d-flex">
                                            <div className="country-name fs-12">{item.label}</div>&nbsp;
                                            <div className="country-name fs-12">{item.symbol}</div>
                                        </div>
                                    </div>
                                </DropdownItem>
                            ))}
                        </SimpleBar>
                    </DropdownMenu>
                </Dropdown> 
                : null
            }
        </div>
    );
};

export default DtsDropdownInput;
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import CompanyInfoEdit from './CompanyInfoEdit';

const OverviewTab = ({ info, metaData, props }: any) => {
  const [isEdit, setIsEdit] = useState(false);
  const [filterFields, setFilterFields] = useState<any>([]);

    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            return false;
        }
    }

    useEffect(() => {
        let newfilterFields: any = []
        metaData && metaData.length && metaData.map((field: any) => {
            console.log("field", field)
            let item = field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}
            if (field.valuesJson) return newfilterFields.push(item)
        })
        setFilterFields(newfilterFields)
    }, [metaData])
    
    const DetailsList = ({ label, value, item, index }: any) => {
        return (
            <React.Fragment>
                <Col md={3} lg={3} sm={6} Key={index}>
                    <div className="p-2 border border-dashed rounded">
                        <div className="d-flex align-items-center">
                            <div className="avatar-sm me-2">
                                <div className="avatar-title rounded bg-transparent text-success fs-24">
                                    <i className={item.icon}></i>
                                </div>
                            </div>
                            <div className="flex-grow-1">
                                <p className="text-muted mb-1 fs-14">{label} :</p>
                                <h5 className="mb-0 fs-14">{value}</h5>
                            </div>
                        </div>
                    </div>
                </Col>
            </React.Fragment>
        );
    };
    return (
        <React.Fragment>
            <Row>
                <Col xl={12} lg={12}>
                    <Card>
                        <CardHeader className='hstack align-items-center'>  
                        <Col  className='hstack justify-content-start p-0 ps-3 align-items-end'>
                            <h4>Company Information</h4>
                        </Col> 
                        <Col  className='hstack justify-content-end'>
                            {
                                    isEdit ? <Button
                                    type="button"
                                    size='sm'
                                    className="py-2"
                                    color="danger"
                                    onClick={() => setIsEdit(false)}
                                    outline
                                >
                                    <i className="ri-close-fill"></i>{" "}
                                    Close
                                    {/* {props.t("channel.update")} */}
                                </Button> :                        
                                <Button
                                    type="button"
                                    size='sm'
                                    className="py-2"
                                    color="primary"
                                    onClick={() => setIsEdit(true)}
                                >
                                    <i className="ri-pencil-fill"></i>{" "}
                                    {props.t("company.update")}
                                </Button>
                            }
                        </Col>                        
                        </CardHeader>
                        <CardBody>
                            <div className="text-muted">
                                <div className="border-top border-top-dashed p-3">
                                  {
                                    isEdit ? <CompanyInfoEdit /> :
                                    <Row className="gy-3">
                                        {filterFields && filterFields.length ? filterFields.map((item: any, key: any) => {
                                            console.log("item?>>>>>>>>>", item)
                                            return <DetailsList
                                                label={item.label}
                                                value={info[item.key]}
                                                item={item}
                                                key={key}
                                            />
                                        }): null}
                                    </Row>
                                  }
                                </div>
                            </div>
                        </CardBody>
                    </Card>

                </Col>
            </Row>
        </React.Fragment>
    );
};

export default OverviewTab;
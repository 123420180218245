import type { Identifier, XYCoord } from 'dnd-core'
import type { FC } from 'react'
import { useRef } from 'react'
import { Link } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd'

export interface DNDRowProps {
  id: any
  value: any
  index: number
  moveRow: (dragIndex: number, hoverIndex: number, isDragging: boolean) => void
  onUpdateClick: (value: any) => void
}

interface DragItem {
  index: number
  id: string
  type: string
}

export const DNDRow: FC<DNDRowProps> = ({ id, value, index, moveRow, onUpdateClick }) => {
  const ref = useRef<HTMLTableRowElement>(null)
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'tr',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex, isDragging)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'tr',
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <tr ref={ref}  key={index} data-handler-id={handlerId} style={{opacity}}>
        <td>
            <div className="d-flex align-items-start">
                <div className="flex-shrink-0 me-3">
                    <div className="task-handle px-1 bg-light rounded">: :</div>
                </div>
                <Link className='fs-5' to="" onClick={onUpdateClick}>{value?.label}</Link>
            </div>
        </td>
        <td>
            <div className='d-flex flex-wrap gap-2'>
                {
                  value?.roles?.map((role: any) => <h5><span className="badge bg-primary-subtle text-primary">{role?.label}</span></h5>)
                }
            </div>
        </td>
        <td>
            <div className='d-flex flex-wrap gap-2'>
                <h5>
                    <span className="badge border border-primary text-primary">{value?.about_view?.label || ''}</span>
                </h5>
            </div>
        </td>
    </tr>
  )
}

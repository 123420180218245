import { use } from "i18next";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import googleicon from "../../assets/images/googleicon.png";
import outlook from "../../assets/images/outlook.png";
import exchange from "../../assets/images/exchange.png";

// interface FProps {
//     props: any;
//     modal: boolean,
//     setModal: (event: boolean) => void;
//     confirmModal: boolean,
//     setConfirmModal: (event: boolean) => void;
// }
const SyncOptionModal = ({ t, modal, setModal, confirmModal, setConfirmModal }: any) => {
    const [googleSync, setGoogleSync] = useState<boolean>(false)
    const [outlookSync, setOutlookSync] = useState<boolean>(false)
    const [exchangeSync, setExchangeSync] = useState<boolean>(false)
    const tog_animationZoom = () => {
        setModal(!modal);
    }
    const handleGoogleClick = () => {
        setGoogleSync(true)
        setOutlookSync(false)
        setExchangeSync(false)
    }
    const handleOutlookClick = () => {
        setGoogleSync(false)
        setOutlookSync(true)
        setExchangeSync(false)
    }
    const handleExchangeClick = () => {
        setGoogleSync(false)
        setOutlookSync(false)
        setExchangeSync(true)
    }
    return (
        <>
            <Modal id="flipModal" isOpen={modal} modalClassName="zoomIn" centered >
                <ModalHeader className="pb-3 bg-info" toggle={() => { tog_animationZoom() }}>
                    <h5 className="text-white modal-title" id="exampleModalLabel">{t("userpreferences.connect_your_calendar")}</h5>
                </ModalHeader>
                <ModalBody>
                    <div className="d-flex align-self-center justify-content-between">
                        <div>
                            <Button color="primary" outline className={`avatar-lg ${googleSync ? 'active' : ''}`} onClick={() => handleGoogleClick()}><img width="45" height="45" src={googleicon}></img>{t("userpreferences.gmail")}</Button></div>
                        <div> <Button color="primary" outline className={`avatar-lg ${outlookSync ? 'active' : ''}`} onClick={() => handleOutlookClick()}><img width="45" height="45" src={outlook}></img>{t("userpreferences.outlook")}</Button></div>
                        <div> <Button color="primary" outline className={`avatar-lg ${exchangeSync ? 'active' : ''}`} onClick={() => handleExchangeClick()}><img width="45" height="45" src={exchange}></img>{t("userpreferences.exchange")}</Button></div>
                    </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                    <Button
                        disabled={!googleSync && !outlookSync && !exchangeSync}
                        color="primary"
                        className="btn btn-primary"
                        onClick={() => {
                            setConfirmModal(true)
                            setModal(false);
                        }}
                    >
                        {t("userpreferences.connect_your_calendar")}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default withTranslation()(SyncOptionModal);
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import Chatbot from 'Components/Common/Chatbot/Chatbot';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Input,
    Label,
    ListGroup,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledDropdown,
    UncontrolledTooltip,
} from "reactstrap";
import classnames from "classnames";
import FeatherIcon from "feather-icons-react";
import avatar10 from "../../../src/assets/images/users/avatar-7.jpg";
import Activities from "./Activities";
import Notes from "./Notes";
import Tasks from "./Tasks";
import Meetings from "./Meetings";
import NotesModal from "./Modals/NotesModal";
import EmailModal from "./Modals/EmailModal";
import Email from "./Email";
import MeetingsModal from "./Modals/MeetingsModal";
import StudentInfo from "./innerComponent/StudentInfoEdit";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { ToastContainer, toast } from "react-toastify";
import { StudentListGetRequest, studentDelete, studentGetRequest, studentsMetadataGetRequest } from "store/student/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import Application from "pages/Students/Applications/Application";
import AddTask from "./Modals/AddTask";
import { getGoogleCalendarData } from "store/calendarSync/action";
import DeleteModal from "./innerComponent/DeleteModal";
import Documents from "./Documents/Documents";
import { useCCP } from '../../CCPContext/CCPContext';
import OverviewPane from "./OverviewPane";
import Calls from "./Calls";
import { callAddRequest, callsListGetRequest } from "store/calls/action";
import ArrangeFields from "Components/Common/FieldsArranger/ArrangeComponent";
import { viewCreateRequest, viewUpdateRequest } from "store/views/action";
import Whatsapp from "./Whatsapp/Whatsapp";


interface DropdownOption {
    id: number;
    label: string;
}

const Students = (props: any) => {
    const { initializeCCP } = useCCP();
  const navigate = useNavigate();
  const {id} = useParams();
  const googleConnect = useSelector((state: ApplicationState) => state.calendarSync.data);
  const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch(); 
  document.title =
    "Profile Settings | Zilter";

    const [activeTab, setActiveTab] = useState<any>("1");

    const tabChange = (tab: any) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const [activeSubTab, setActiveSubTab] = useState<string>("1");
    const toggleTab = (tab: any) => {
        if (activeSubTab !== tab) setActiveSubTab(tab);
    };
    const [onAddNote, setOnAddNote] = useState<string>("");
    const [dropdownValues, setDropdownValues] = useState<DropdownOption[]>([]);
    const [initialData, setInitialData] = useState<[]>([]);
    const [search, setValue] = useState<string>("");
    const metadata = useSelector((state: ApplicationState) => state.student.studentsMetadata);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);

    useEffect(() => {
        // Import JSON file dynamically (for example, using Webpack's require)
        const jsonData: DropdownOption[] = require("./dropdownValues.json");
        // Set dropdown values in the state
        setDropdownValues(jsonData);
      }, []);
  const studentDetails = useSelector((state: ApplicationState) => state.student.student);
  const viewlist:any= useSelector((state: ApplicationState) => state.views.views);
  const [notesModals, setNotesModals] = useState<boolean>(false);
  const [emailModals, setEmailModals] = useState<boolean>(false);
  const [tasksModals, setTasksModals] = useState<boolean>(false);
  const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
  const [viewId, setViewId]= useState<any>('');
  const [fields, setFields] = useState<any>([]);
  const [isWhatsapp, setIsWhatsapp] = useState<boolean>(false);
  const [info, setInfo] = useState<any>({});
  const [renderTasks, setRenderTasks] = useState<boolean>(false)
  const [meetingsModals, setMeetingsModals] = useState<boolean>(false);
  const [deleteAccess, setDeleteAccess] = useState<boolean>(false);
  const [communicate, setCommunicate] = useState<boolean>(false);

  const handleButtonClick = () => {
    initializeCCP();
    handleLogAWSCall();
  };

  useEffect(() => {
    if(viewlist && viewlist.length) {
        let view = viewlist.find((item: any) => (item.model === "students" && item.deletedAt == null  && item.location == 'about')) 
        if(view && view.id) setViewId(view.id)  
        let newFields: any = view && view.fields && isJson(view.fields)  ? JSON.parse(view.fields) : {}
        setFields(newFields)
    }
}, [viewlist])

useEffect(() => {
    const filters = { pageSize: 100 }
    dispatch(studentsMetadataGetRequest(filters));
}, [])

useEffect(() => {
    let userRole = userProfile?.role?.title;
    if(userRole === "Owner") {
        setDeleteAccess(true)
        setCommunicate(true)
    }
    else if(subLevelPermissionsList) {
        let access = subLevelPermissionsList?.students;
        if(access && access?.length) {
            if(access && access.length) {            
                access.map((item: any, index: any) => {
                   if(item.name.toLowerCase() == 'edit') {
                        item.value == "" || item.value == "none" ? setDeleteAccess(false)  : setDeleteAccess(true) 
                   }
       
               })
           }
        }
        let communicateaccess = subLevelPermissionsList?.communicate;
        if(communicateaccess && communicateaccess?.length) {
            if(communicateaccess && communicateaccess.length) {            
                communicateaccess.map((item: any, index: any) => {
                   if(item.name.toLowerCase() == 'communicate') {
                        item.value == "" || item.value == "none" ? setCommunicate(false)  : setCommunicate(true) 
                   }
       
               })
           }
        }
    }
}, [subLevelPermissionsList, userProfile])

  const handleLogAWSCall = () => {
    const data = {
        studentId: id,
        provider: "AWS"
      }
    const handleSuccess = (): void => {
        toast(`Call logged successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
        dispatch(callsListGetRequest(id));
    };
    const handleError = (): void => {
        // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
        // message();
    };
    console.log("Call log",data)
    dispatch(callAddRequest(data, handleSuccess, handleError));
}

  useEffect(() => {
    dispatch(getGoogleCalendarData())
    dispatch(studentGetRequest(id))
    // setInfo(studentDetails)
  }, [])
  const isJson = (str: any) => {
    try {
        let options = JSON.parse(str);
        return options
    } catch (e) {
        return false;
    }
}
  useEffect(() => {
    if(studentDetails && studentDetails.valuesJson) {
      let values =  studentDetails.valuesJson
      setInfo(values)
    }
  }, [studentDetails])
    const onClickview = () => {
        navigate('/fields')
    }
  
    const onDelete = () => {
        const handleSuccess = (body: any) => {
            const message = () => toast(`Student Deleted Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(StudentListGetRequest())
            setisDeleteOpen(false)
            navigate('/students')
        }
        const handleFailure = (body: any) => {
            // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            // message();
            setisDeleteOpen(false)
        }
        dispatch(studentDelete(id, handleSuccess, handleFailure))
    }

    const onChange = (fields: any, viewname: any) => {
        if(viewId) {
            setFields(fields)    
            let data = {            
                "fields": JSON.stringify(fields)
            }  
            const handleSuccess = () => {
    
            }
            const handleFailure = () => {
    
            }
            dispatch(viewUpdateRequest(viewId, data, handleSuccess, handleFailure))  
        }
        else {
            setFields(fields)    
            let data = {            
                "name": viewname,
                "primary": "true",
                "model": "students",
                "location": "about",
                "fields": JSON.stringify(fields),
                "filters": JSON.stringify([]),
            }  
            const handleSuccess = () => {
    
            }
            const handleFailure = () => {
    
            }
            dispatch(viewCreateRequest(data, handleSuccess, handleFailure))  
        }
    }
    
    const handleChange = (value: any) => {
        setValue(value)
    }
  
    return (
        <React.Fragment>
            <ToastContainer />
            <NotesModal
                props={props}
                notesModals={notesModals}
                setNotesModals={setNotesModals}
                onAddNote={setOnAddNote}
            />

            <EmailModal
                props={props}
                emailModals={emailModals}
                setEmailModals={setEmailModals}
                info={info}
            />
  
            <AddTask
                setRenderTasks={setRenderTasks}
                renderTasks={renderTasks}
                props={props}
                tasksModals={tasksModals}
                setTasksModals={setTasksModals}
            />

            <MeetingsModal
                props={props}
                meetingsModals={meetingsModals}
                setMeetingsModals={setMeetingsModals}
                info={info}
            />
            <DeleteModal
                props={props}
                show={isDeleteOpen}
                onCloseClick={() => setisDeleteOpen(false)}
                record={info}
                onDelete={onDelete}
            />
            <Col xxl={3} md={4} lg={4}>
                <Card className="mt-1">
                    <CardBody className="p-4">
                        <ListGroup className="mb-3 justify-content-between" horizontal>
                            <Link to="/students" color="primary" className="btn-sm">
                                <span>
                                    <FeatherIcon icon="chevron-left" className="icon-sm" />

                                    <strong>{props.t("student.students_link")}</strong>
                                </span>
                            </Link>
                            <UncontrolledDropdown>
                                <DropdownToggle
                                    href="#"
                                    className="btn btn-ghost-primary btn-sm dropdown"
                                    tag="button"
                                >
                                    <span>
                                        <strong className="fs-14 fw-bold">{props.t("student.actions_link")}</strong>
                                        <FeatherIcon icon="chevron-down" className="icon-sm" />
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem className="dropdown-item" href={`/fields`}>
                                        <span className="fs-12 fw-normal"  >
                                            {props.t("student.view_all_properties_button")}
                                        </span>
                                    </DropdownItem>
                                    {
                                        deleteAccess ? 
                                    <DropdownItem className="dropdown-item" onClick={() => setisDeleteOpen(true)} >
                                        <span className="fs-12">
                                            Delete
                                        </span>
                                    </DropdownItem>: null }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </ListGroup>
                        <div className="text-center">
                            <div className="profile-user position-relative d-inline-block mx-auto">
                                <img
                                    src={avatar10}
                                    className="rounded-circle avatar-lg img-thumbnail user-profile-image"
                                    alt="user-profile"
                                />
                                <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                    <Input
                                        id="profile-img-file-input"
                                        type="file"
                                        className="profile-img-file-input"
                                    />
                                    <Label
                                        htmlFor="profile-img-file-input"
                                        className="profile-photo-edit avatar-xxs"
                                    >
                                        <span className="avatar-title rounded-circle bg-light text-body">
                                            <i className="ri-camera-fill"></i>
                                        </span>
                                    </Label>
                                </div>
                            </div>
                            <h5 className="mt-4 mb-1">{info.name}</h5>
                            <p className="text-muted">{info.email}</p>
                        
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item avatar-xs">
                                    <Link
                                        id="notes"
                                        to="#"
                                        onClick={() => setNotesModals(true)}
                                        className="avatar-title bg-primary-subtle text-primary fs-15 rounded"
                                    >
                                        <UncontrolledTooltip placement="top" target="notes">
                                            Notes
                                        </UncontrolledTooltip>
                                        <i className="ri-sticky-note-line"></i>
                                    </Link>
                                </li>
                                {communicate ?
                                <li className="list-inline-item avatar-xs">
                                    <Link
                                        id="email"
                                        to="#"
                                        onClick={() => setEmailModals(true)}
                                        className="avatar-title bg-success-subtle text-success fs-15 rounded"
                                    >
                                        <UncontrolledTooltip placement="top" target="email">
                                            Email
                                        </UncontrolledTooltip>
                                        <i className="ri-mail-line"></i>

                                    </Link>
                                </li>: null}
                                {communicate ?
                                <li className="list-inline-item avatar-xs">
                                    <Link
                                    onClick={()=> handleButtonClick()}
                                        id="call"
                                        to="#"
                                        className="avatar-title bg-danger-subtle text-danger fs-15 rounded"
                                    >
                                        <UncontrolledTooltip placement="top" target="call">
                                            Call
                                        </UncontrolledTooltip>
                                        <i className="ri-phone-line"></i>
                                    </Link>
                                </li>: null}
                                <li className="list-inline-item avatar-xs">
                                    <Link
                                        id="task"
                                        to="#"
                                        onClick={() => setTasksModals(true)}
                                        className="avatar-title bg-info-subtle text-info fs-15 rounded"
                                    >
                                        <UncontrolledTooltip placement="top" target="task">
                                            Task
                                        </UncontrolledTooltip>
                                        <i className="ri-task-line"></i>
                                    </Link>
                                </li>
                                {communicate ?
                                <li className="list-inline-item avatar-xs">
                                    <Link
                                        id="meeting"
                                        to="#"
                                        onClick={() => {
                                          setMeetingsModals(true)
                                          // navigate(`/users/${email}`)
                                        }}
                                        className="avatar-title bg-warning-subtle text-warning fs-15 rounded"
                                    >
                                        <UncontrolledTooltip placement="top" target="meeting">
                                            Meetings
                                        </UncontrolledTooltip>
                                        <i className="ri-calendar-check-line"></i>
                                    </Link>
                                </li>: null}
                                {communicate ?
                                <li className="list-inline-item avatar-xs">
                                    <Link
                                        id="whatsapp"
                                        to="#"
                                        onClick={() => {
                                          setIsWhatsapp(true)
                                          // navigate(`/users/${email}`)
                                        }}
                                        className="avatar-title bg-success-subtle text-success fs-15 rounded"
                                    >
                                        <UncontrolledTooltip placement="top" target="whatsapp">
                                            Whatsapp
                                        </UncontrolledTooltip>
                                        <i className="ri-whatsapp-line"></i>
                                    </Link>
                                </li>: null}
                            </ul>
                        </div>
                        <hr></hr>
                        <div className="mb-3 hstack justify-content-between">
                            <h5>{props.t("student.about")} </h5>
                            <ArrangeFields metadata={metadata} onChange={onChange} fields={fields} />
                        </div>
                        <StudentInfo fields={fields} />
                    </CardBody>
                    <CardFooter className="d-flex align-items-center justify-content-center">
                        <Button
                            onClick={() => onClickview()}
                            color="primary"
                            className="btn btn-ghost-primary waves-effect waves-light">
                                {props.t("student.view_all_properties_button")}
                        </Button>
                        {/* <Button color="info" className="btn-sm w-100">
                    {props.t("student.view_property_history")}
                  </Button> */}
                    </CardFooter>
                </Card>
            </Col>

            <Col xxl={9} md={8} lg={8}>
                <Card className="mt-xxl-1">
                    <CardHeader>
                        <Nav
                            className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                            role="tablist"
                        >
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === "1" })}
                                    onClick={() => {
                                        tabChange("1");
                                    }}
                                    type="button"
                                >
                                    <i className="ri-home-3-line"></i>{" "}
                                    <strong>{props.t("student.overview_tab")}</strong>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to="#"
                                    className={classnames({ active: activeTab === "2" })}
                                    onClick={() => {
                                        tabChange("2");
                                    }}
                                    type="button"
                                >
                                    <i className="ri-discuss-line"></i>{" "}
                                    <strong>{props.t("student.activities_tab")}</strong>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to="#"
                                    className={classnames({ active: activeTab === "3" })}
                                    onClick={() => {
                                        tabChange("3");
                                    }}
                                    type="button"
                                >
                                    <i className="ri-file-line"></i>{" "}
                                    <strong>{props.t("applications.documents")}</strong>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to="#"
                                    className={classnames({ active: activeTab === "4" })}
                                    onClick={() => {
                                        tabChange("4");
                                    }}
                                    type="button"
                                >
                                    <i className="ri-contacts-book-line"></i>{" "}
                                    <strong>{props.t("student.applications")}</strong>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </CardHeader>
                    <CardBody className="p-2">
                        <TabContent activeTab={activeTab}>
                           {activeTab == "1" && <TabPane tabId="1">
                                  <OverviewPane />
                            </TabPane>}
                            {activeTab == "2" && <TabPane tabId="2">
                                    <Row className="g-2">
                                        <Col lg={12}>
                                            <ListGroup
                                                className="mb-3 justify-content-between"
                                                horizontal
                                            >
                                                {/* <div>
                                                    <input
                                                        placeholder="Search activities"
                                                        id="autoCompleteFruit"
                                                        dir="ltr"
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div> */}
                                                <div className="search-box mb-1">
                                                    <Input
                                                        type="text"
                                                        size={14}
                                                        className="search"
                                                        // onKeyDown={handleKeyDown}
                                                        placeholder={props.t("student.search_for")}
                                                        onChange={(e) => handleChange(e.target.value)}
                                                        value={search}
                                                    />
                                                    { search == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleChange("")}></i>}
                                            
                                                </div>
                                                {/* Commented Collapse All Button for further enhancement */}
                                                {/* <div className="justify-content-end">
                                <Button className="btn-soft" size="sm">
                                  {props.t("student.collapse_all_button")}
                                  <span>
                                    <FeatherIcon
                                      icon="chevron-down"
                                      className="icon-sm"
                                    />
                                  </span>
                                </Button>
                              </div> */}
                                            </ListGroup>
                                        </Col>

                                        <CardHeader className="my-n3">
                                            <Nav
                                                className="nav-custom-light rounded card-header-tabs nav-border-top"
                                                role="tablist"
                                            >
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        className={classnames({
                                                            active: activeSubTab === "1",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("1");
                                                        }}
                                                    >
                                                        <strong>{props.t("student.activity")}</strong>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        className={classnames({
                                                            active: activeSubTab === "2",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("2");
                                                        }}
                                                    >
                                                        <strong>{props.t("student.notes")}</strong>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        className={classnames({
                                                            active: activeSubTab === "3",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("3");
                                                        }}
                                                    >
                                                        <strong>{props.t("student.email")}</strong>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        className={classnames({
                                                            active: activeSubTab === "4",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("4");
                                                        }}
                                                    >
                                                        <strong>{props.t("student.calls")}</strong>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        className={classnames({
                                                            active: activeSubTab === "5",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("5");
                                                        }}
                                                    >
                                                        <strong>{props.t("student.tasks")}</strong>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        className={classnames({
                                                            active: activeSubTab === "6",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("6");
                                                        }}
                                                    >
                                                        <strong>{props.t("student.meetings")}</strong>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        className={classnames({
                                                            active: activeSubTab === "7",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("7");
                                                        }}
                                                    >
                                                        <strong>{props.t("student.whatsapp")}</strong>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </CardHeader>
                                        <CardBody className="p-4">
                                            <TabContent activeTab={activeSubTab}>
                                                <TabPane tabId="1" id={"actiivtytab"}>
                                                    {activeSubTab == '1' && <Activities />}
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    {activeSubTab == '2' && <Notes initialData={initialData} />}
                                                </TabPane>

                                                <TabPane tabId="3" id={"emailtab"}>
                                                    {activeSubTab == '3' && <Email communicate={communicate} />}
                                                </TabPane>
                                                <TabPane tabId="4" id={"emailtab"}>
                                                    {activeSubTab == '4' && <Calls info={info} communicate={communicate} />}
                                                </TabPane>
                                                <TabPane tabId="5">
                                                    {activeSubTab == '5' && <Tasks renderTasks={renderTasks} setRenderTasks={setRenderTasks} />}
                                                </TabPane>
                                                <TabPane tabId="6">
                                                    {activeSubTab == '6' && <Meetings info={info} communicate={communicate} />}
                                                </TabPane>
                                                <TabPane tabId="7">
                                                    {activeSubTab == '7' && <Whatsapp communicate={communicate} />}
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Row>
                            </TabPane> }                           
                            {activeTab == "3" && <TabPane tabId="3"  id={"documenttab"}>
                                <Documents />
                            </TabPane>}
                            {activeTab == "4" && <TabPane tabId="4">
                                <Application />
                            </TabPane>}
                        </TabContent>
                    </CardBody>
                </Card>
            </Col>
            {/* //   </Row> */}
            {/* // </Container> */}
            {/* </div> */}
            {isWhatsapp ? <Chatbot onClose={setIsWhatsapp} /> : null}
        </React.Fragment>
    );
};

export default withTranslation()(Students);

import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Col, Row } from "reactstrap";

interface Step7Props {  
    t: (key: string) => string;
}

const Step7: React.FC<Step7Props> = ({ t }: Step7Props) => {
  const navigate =  useNavigate()
  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/students');
    }, 3000);

    // Cleanup function to clear the timeout if component unmounts before the timeout expires
    return () => clearTimeout(timeout);
  }, [])
  return (
    <div>
      <Row className="hstack gap-3 flex-column justify-content-center">
        <Col lg={12}>
          <div className="text-center">
            <div className="avatar-md mt-5 mb-4 mx-auto">
              <div className="avatar-title bg-light text-success display-4 rounded-circle">
                <i className="ri-checkbox-circle-fill"></i>
              </div>
            </div>
            <h5>{t("signup.well_done")}</h5>
            <p className="text-muted">{t("signup.success_statement")}</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Step7;

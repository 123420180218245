import React, { useState, useRef } from 'react'
import axios from 'axios'
import New from './New'
import Uploading from './Uploading'
import { ApplicationState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import config from "../../../config"
import { useParams } from 'react-router'
import { DocumentListGetRequest } from 'store/documents/action'
const { DTS_API_STUDENT } = config.api;

interface FProps {
    selectedFiles: any;
    setselectedFiles: (event: any) => void;
    setModalFile:(event: boolean) => void;
    setDocumentType: (event: any) => void;
    documentType: any;
    fileInfo: any;
    filterObject: any;
    setFileInfo:  (event: any) => void;
}
const Upload: React.FC<FProps> = ({
    selectedFiles,
    setselectedFiles,
    setModalFile,
    documentType,
    fileInfo,
    setFileInfo,
    filterObject,
    setDocumentType}
) => {
    const { id } = useParams();
    const dispatch: any = useDispatch();
    const loading = useSelector((state: ApplicationState) => state.auth.loading)
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)
    const applicationDetails = useSelector((state: ApplicationState) => state.applications?.applicationDetails)
    const inputRef = useRef<HTMLInputElement>(null)
    const [dragActive, setDragActive] = useState(false)
    const [file, setFile] = useState<File | null>(null)
    const [percentage, setPercentage] = useState(0)
    const [uploading, setUploading] = useState('not_started')
    
    const SubmitUpload = async () => {
        const file = selectedFiles && selectedFiles.length ? selectedFiles[0] : null;       
        const applicationId: any = id;
        let studentId: any = null;
        if(applicationDetails && applicationDetails.id) {
            let studentsId = applicationDetails && applicationDetails.student && applicationDetails.student.id ? applicationDetails.student.id : "";
            studentId= studentsId;
        }
        setFileInfo(file)
        if (file) {
                setUploading('started');
                const formData = new FormData();
                formData.append('documentType', documentType);
                formData.append('studentId', studentId);
                formData.append('applicationId', applicationId);
                formData.append('file', file);
                setUploading('started');
                const config = {
                    onUploadProgress: function (progressEvent: any) {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total,
                        )
                        setPercentage(percentCompleted - 10)
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        "token": access_token
                    },
                }
                axios.post(`${DTS_API_STUDENT}/documents`, formData, config)
                .then((res) => {
                    setPercentage(100)
                    setUploading('completed')
                    setModalFile(false)                    
                    setselectedFiles([])
                    dispatch(DocumentListGetRequest(filterObject, 0));
                })
        }
    }

    let component = null;
    switch(uploading) {
        case 'not_started':
            component = <New selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} SubmitUpload={SubmitUpload} setDocumentType={setDocumentType} documentType={documentType} />
            break;
        case 'started':
            component = <Uploading file={fileInfo} percentage={percentage} />
            break;
    }
    return (
        <div>
            {component}
        </div>
    )
}
export default Upload
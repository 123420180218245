
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
    Row,
    Label,
} from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { userGetRequest } from "store/user/action";

const Profile = ({ t, user }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const userDetails = useSelector((state: ApplicationState) => state.user.user);
    const [activeArrowTab, setactiveArrowTab] = useState<number>(1);
    const [passedarrowSteps, setPassedarrowSteps] = useState<number[]>([1]);
    const toggleArrowTab = (tab: number) => {
        if (activeArrowTab !== tab) {
            const modifiedSteps = [...passedarrowSteps, tab];
            if (tab >= 1 && tab <= 3) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    };

    useEffect(() => { if (user) dispatch(userGetRequest(user)) }, [user])

    const getInitials = (name: string): string => {
        const initials = name.match(/\b\w/g) || [];
        return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    };

    console.log("Profile user", userDetails)
    return (
        <React.Fragment>

            <div className="hstack mt-0 mb-4">
                <Row className="p-2 w-100 hstack gap-3 justify-items-center">
                    {/* <Col lg={12} className="vstack justify-content-between"> */}
                    <div className="vstack gap -1 justify-content-center">
                        <h4 className="">Profile</h4>
                        <p>This applies across any Zilter account they have.</p>
                    </div>
                    <div className="vstack gap-3 align-items-left">
                        <Label className="fw-bold fs-14">Profile Image</Label>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex position-relative gap-3">
                                <div className="profile-user position-relative d-inline-block mx-auto">
                                    {userDetails?.profilePic ? (
                                        <img
                                            src={userDetails.profilePic}
                                            className="rounded-circle avatar-lg img-thumbnail user-profile-image"
                                            alt="user-profile"
                                        />
                                    ) : (
                                        <div
                                            className="avatar-lg img-thumbnail rounded-circle d-flex justify-content-center align-items-center"
                                            style={{
                                                backgroundColor: "#40759c",
                                                color: "#fff",
                                                width: "45px",
                                                height: "45px",
                                                fontSize: "16px",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            {userDetails?.name
                                                ? getInitials(userDetails.name)
                                                : getInitials(userDetails?.email || '')}
                                        </div>
                                    )}
                                </div>
                                <div className="mt-0">
                                    <Label className="mb-1 fs-16">{userDetails?.name}</Label>
                                    <div className="vstack gap-1 align-self-center flex-grow-1">
                                        <h6>{userDetails?.email}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-wrap justify-content-between">
                            <div className="w-50">
                                <FormBuilder
                                    disabled
                                    label={"Name"}
                                    type={"textfield"}
                                    value={userDetails?.name}
                                    name={"name"}
                                    placeholder={`Enter name`}
                                    className="flex-grow-1 me-3 mb-3"
                                    onChange={(value: any) => {

                                        // setvalue(value)
                                        //   setUserData({ ...userData, name: value })
                                    }}
                                    Editable={true}
                                />
                            </div>
                            <div className="w-50">
                                <FormBuilder
                                    disabled
                                    label={"Email"}
                                    type={"textfield"}
                                    value={userDetails?.email}
                                    name={"last_name"}
                                    placeholder={`Enter last name`}
                                    className="flex-grow-1 me-3 mb-3"
                                    // onChange={(value: any) => {
                                    //     console.log(value)
                                    //     // setvalue(value)
                                    //     setUserData({ ...userData, email: value })
                                    // } }
                                    Editable={false}
                                />
                            </div>
                            <div className="w-50" contentEditable={false}>
                                <p>

                                    <FormBuilder
                                        disabled
                                        label={"Main Phone"}
                                        type={"textfield"}
                                        defaultValue={userDetails?.phoneNumber}
                                        value={userDetails?.phoneNumber}
                                        name={"phone_number"}
                                        placeholder={`Enter Main number`}
                                        className="flex-grow-1 me-3 mb-3"
                                        // onChange={(value: any) => {
                                        //     console.log(value)
                                        //     setUserData({ ...userData, phoneNumber: value })
                                        // }}
                                        Editable={false}
                                    />
                                    This phone number may be used to contact them about security events, sending workflows SMS, and for owner property values.
                                </p>
                            </div>
                            {/* <div>
              <text className="fs-14 fw-bold">
                {t(
                  "userpreferences.calendar"
                )}
              </text>
            </div> */}
                        </div>
                    </div>
                    {/* </Col> */}
                </Row>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Profile);

import React from 'react';
import Student from './Student';
import General from './General';


const Lifecycle = ({model}: any) => {
    switch(model) {
        case 'students':
            return <Student model={model} />
        default:
            return <General model={model} />
    }
};

export default Lifecycle



import React, { useEffect, useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
} from "reactstrap";
import { UniversityListGetRequest, universityCreateRequest } from "store/university/action";
import { ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import QuickForm from "Components/Common/FormBuilder/QuickForm";
import { financeUniversityListGetRequest, financeCreateRequest } from "store/finance/action";
import { useParams } from "react-router";
const AddEntry = ({ props, show, onCloseClick, dataFields }: any) => {   
    const { id } = useParams(); 
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
  
    const OnAddEntry = (formValues: any) => {
        const isJson = (str: any) => {
            try {
                let options = JSON.parse(str);
                return options
            } catch (e) {
                return false;
            }
        }
        console.log("formValues", formValues)
        let ug = formValues.ug && isJson(formValues.ug) ? JSON.parse(formValues.ug) : null
        let pg = formValues.pg && isJson(formValues.pg) ? JSON.parse(formValues.pg) : null 
        let phd = formValues.phd && isJson(formValues.phd) ? JSON.parse(formValues.phd) : null
        let fo =  formValues.fo && isJson(formValues.fo) ? JSON.parse(formValues.fo) : null
        let ps = formValues.ps && isJson(formValues.ps) ? JSON.parse(formValues.ps) : null
        let data = {
            universityId: id,
            channelId: formValues.channelId,
            ratings:formValues.ratings,
            notes:formValues.notes,
            ug:ug && ug?.value ? ug?.value : null,
            pg:pg && pg?.value ? pg?.value : null,
            fo:fo && fo?.value ? fo?.value : null,
            phd:phd && phd?.value ? phd?.value : null,
            ps:ps && ps?.value ? ps?.value : null,
            ugType: ug?.type ? ug?.type  : ug?.value ? 'percent': null,
            pgType:pg?.type ? pg?.type : pg?.value ?  'percent' : null,
            phdType:phd?.type ? phd?.type : phd?.value ? 'percent' :  null,
            psType:ps?.type ? ps?.type : ps?.value ? 'percent' : null,
            foType:fo?.type ? fo?.type  : fo?.value ? 'percent' : null,
            ugCurrency: ug?.currency ? ug?.currency : null,
            pgCurrency:pg?.currency ? pg?.currency : null,
            psCurrency:ps?.currency ? ps?.currency : null,
            foCurrency:fo?.currency ? fo?.currency : null,
            phdCurrency:phd?.currency ? phd?.currency : null,
            isTaxIncluded:formValues.is_tax_excluded
        }
        console.log("data", data)
        const handleSuccess = (body: any) => {
            const message = () => toast(`Channel Added Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            onCloseClick()
            dispatch(financeUniversityListGetRequest(id));
        }
        const handleError = () => {
    
        }
        dispatch(financeCreateRequest(data, handleSuccess ,handleError))
    }

    
    console.log("dataFields", dataFields);
    return (
        <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            toggle={onCloseClick}
            backdrop={false}
        >
            
            <OffcanvasHeader className="bg-light" toggle={() =>{onCloseClick(!show)}}>
                {props.t("student.add_new_entry")}
            </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <Card>
                        <CardBody>
                            <div className="live-preview vstack gap-2">
                                <QuickForm dataFields={dataFields} btnAction={OnAddEntry} btn_text={'Add Entry'} />
                            </div>
                        </CardBody>
                    </Card>                    
                </OffcanvasBody>
        </Offcanvas>
    );
};

export default AddEntry;


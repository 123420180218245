import React, { useEffect, useState } from "react";
import {
  Col,
  Input,
  Label,
  Row,
  Form,
  Button,
  Card,
  Dropdown
} from "reactstrap";
import Select from "react-select";

import FeatherIcon from 'feather-icons-react';
import Number from "./Number";
import Text from './Text';

import isEqual from 'lodash/isEqual';
import DropdownFilter from "./DropdownFilter";
import DateFilter from "./DateFilter";
const Filter = ({setCreateInputs, newFilter, setNewFilter,filterCount, setFilterCount, createInputs, dataFields, nextClicked, filterJson, setFilterJson, filterObject, setFilterObject}: any) => {  
    const [properties, setProperties] = useState([]);
    const [property, setproperty] = useState<any>({});
    const [conditionjson, setConditionjson] = useState<any>({});

    const handleselected = (option: any, name: string, ) => {
        console.log("option", option.valueJson.values)
        let options= option.valueJson && option.valueJson.values;
        let newProperty ={...option, options}
       console.log("newProperty", newProperty)
        switch(name) {
            case 'property': setproperty(newProperty);
                            setConditionjson({});
                            break
        }
    }
    const execute = (item1: any, item2: any) => {
        let a= isEqual(item1, item2)
        return a
     }

    const onApply = (e: any) => {
        e.preventDefault();     
        if(filterJson && filterJson.length && newFilter.operator) {
            filterJson.map((itemfilterJson: any, indexfilterJson: any) =>{
                setFilterJson((prevState: any) => {
                    let operator = newFilter.operator;
                    const updatedGroups = [...prevState];  
                    if(updatedGroups[newFilter.index]) {                    
                        let object = {[operator] : [updatedGroups[newFilter.index],conditionjson]}   
                        updatedGroups[newFilter.index] = [object];
                    }
                    else if(itemfilterJson.length) {
                        itemfilterJson.map((group: any, groupindex: any) => {
                            if(group.OR) {
                                if(group.OR.length) {                                    
                                    group.OR.map((item: any,itemindex: any) =>{                                      
                                        if (item.AND && execute(item.AND, newFilter.item)) {
                                            let object = {[operator] : [...item.AND,conditionjson]}   
                                            itemfilterJson[groupindex].OR[itemindex] = [object]
                                        }
                                        else if (item && execute(item.AND, newFilter.item)) {
                                            let object={[operator]: [item, conditionjson]};
                                            itemfilterJson[groupindex].OR[itemindex] = [object]
                                            // group.OR[itemindex] = 
                                        }
                                        else if (item && item.length) {
                                            item.map((subitem: any,subindex: any) =>{
                                                if(execute(subitem.AND, newFilter.item)) {
                                                   let object={[operator]: [...subitem.AND, conditionjson]};
                                                    itemfilterJson[groupindex].OR[itemindex] = [object]
                                                }
                                            })                                               
                                        }
                                        else if(item && execute(item, newFilter.item)) {
                                            let object={[operator]: [item, conditionjson]};
                                            itemfilterJson[groupindex].OR[itemindex] = [object]
                                            // group.OR[itemindex] = 
                                        }
                                    })
                                }
                                    
                            }
                            else if(group.AND) {
                                if(execute(group.AND,newFilter.item)) {
                                    let object = {[operator] : [...group.AND,conditionjson]}
                                    updatedGroups[indexfilterJson] = [object];
                                }
                            }
                        })
                    }
                    return updatedGroups;
                })
            })
        }
        else {
            let newfilterJson: any = filterJson;
            newfilterJson.push(conditionjson)
            setFilterJson(newfilterJson)
        }
        setFilterCount(filterCount+1)
        setConditionjson({})
        setproperty({})
        nextClicked()
    }

    useEffect(() => {
        let properties = dataFields && dataFields.length ? dataFields.map((item: any,index: any) =>{
            if(item.valueJson && item.valueJson.type) return { label: item.Header, value: item.accessor, type: item.valueJson.type, valueJson: item.valueJson }
            else return { label: item.Header, value: item.accessor, type: item.type,  valueJson: {}}
        }):[]
        setProperties(properties)
    },[])

    const handleFieldType = () => {
        let renderfieldtype = property && property.type ? property.type : '';
        switch(renderfieldtype) {  
            case 'textarea':  
            case 'email' :
            case 'textfield': return <Text property={property} setConditionjson={setConditionjson} conditionjson={conditionjson}  filterJson={filterJson} setFilterJson={setFilterJson} setFilterObject={setFilterObject} filterObject={filterObject} />
            case 'number': return <Number property={property} setConditionjson={setConditionjson} conditionjson={conditionjson} filterJson={filterJson} setFilterJson={setFilterJson} setFilterObject={setFilterObject} filterObject={filterObject} />
            case 'select': return <DropdownFilter property={property} setConditionjson={setConditionjson} conditionjson={conditionjson} filterJson={filterJson} setFilterJson={setFilterJson} setFilterObject={setFilterObject} filterObject={filterObject} />
            case 'datetime': return <DateFilter property={property} setConditionjson={setConditionjson} conditionjson={conditionjson} filterJson={filterJson} setFilterJson={setFilterJson} setFilterObject={setFilterObject} filterObject={filterObject} />   
            case '': return null      
            default: return <Text property={property} setConditionjson={setConditionjson} conditionjson={conditionjson}  filterJson={filterJson} setFilterJson={setFilterJson} setFilterObject={setFilterObject} filterObject={filterObject} />
        }
    }
    useEffect(() => {
    }, [filterJson])
    return (
        <div>  
            <Row className="hstack gap-3 flex-column justify-content-center">
                <Col lg={12} className="d-flex gap-2 flex-column justify-content-center">    
                    <div>
                        <Label className="fs-16 fw-medium">Applications properties</Label>                            
                        <Select
                            placeholder='Select property type'
                            className="fs-16 fw-medium"
                            value={property}
                            onChange={(option : any) => handleselected(option, 'property')}
                            options={properties}
                            name="choices-single-default"
                        ></Select>
                    </div>
                    <div>
                        {handleFieldType()}
                    </div>                        
                </Col>
            </Row>
            {conditionjson && conditionjson.value ?  <Row>
                <Col className="mt-2">
                    <Button                            
                        className="btn btn-success w-auto"
                        onClick={(e: any) => onApply(e)}
                    >
                        Apply Filter
                    </Button>
                </Col>
            </Row> : null}  
        </div>
    );
};
export default Filter;

import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { Table, Row, Col, Button, CardBody, Input, Label, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { DefaultColumnFilter } from "../../../../Components/Common/filters";
import {
    ProductsGlobalFilter,
    CustomersGlobalFilter,
    OrderGlobalFilter,
    ContactsGlobalFilter,
    CompaniesGlobalFilter,
    LeadsGlobalFilter,
    CryptoOrdersGlobalFilter,
    InvoiceListGlobalSearch,
    TicketsListGlobalFilter,
    NFTRankingGlobalFilter,
    TaskListGlobalFilter,
} from "../../../../Components/Common/GlobalSearchFilter";
import { Link } from "react-router-dom";
import Paginator from "Components/Common/Paginator";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
interface TableContainerProps {
    columns?: any;
    data?: any;
    tableClass?: any;
    theadClass?: any;
    customPageSize?: any;
    isPageSizeChange?: any;
    trClass?: any;
    thClass?: any;
    setselectedcount: (e: any) => void,
    setIsMultiDeleteButton: (e: any) => void,
    handleLeadClick: (e: any) => void,
    setisDeleteOpen: (e: any) => void,
    pageChanged: (e: any) => void,
    pagination: any;
    pageSize: any;
    pagesList: any;
    setpreview: (e: any) => void;
    onChangePageSize: (e: any) => void;
    handleSort: (e: any) => void;
    sortBy:string;
    sortOrder:string;
    props: any;
    access: any;
    handleselect: (item: any, e: any) => void;
    selectedlist: any;
    checkedRows: any;
    loading: any;
    dataloading: boolean;
    create: boolean;
}

const TableContainer = ({
    columns,
    data,
    isPageSizeChange,
    customPageSize,
    tableClass,
    theadClass,
    trClass,
    thClass,
    setIsMultiDeleteButton,
    setselectedcount,
    handleLeadClick,
    setisDeleteOpen,
    pageChanged,
    pagesList,
    pagination,
    onChangePageSize,
    setpreview,
    handleSort,
    sortOrder,
    sortBy,
    pageSize,
    props,
    access,
    handleselect,
    selectedlist,
    checkedRows,
    loading,
    dataloading,
    create
}: TableContainerProps) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setPageSize,
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                pageIndex: 0, pageSize: customPageSize, selectedRowIds: 0, sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );
    const [view, setView] = useState(false);
    const [edit, setEdit] = useState(false);
    const [deleteaccess, setDeleteAccess] = useState(false);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);

    const generateSortingIndicator = (column: any) => {
        return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
    };

    const onChangeInSelect = (event: any) => {
        onChangePageSize(event.target.value);
    };

    const checkedAll = () => {
        const checkall: any = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".leadsCheckBox");

        if (checkall.checked) {
            setIsMultiDeleteButton(true)
            setselectedcount(ele.length)
            ele.forEach((ele: any) => {
                ele.checked = true;
            });
        } else {
            setIsMultiDeleteButton(false)
            ele.forEach((ele: any) => {
                ele.checked = false;
            });
        }
        // deleteCheckbox();
    };
    const thstyle = {
        minWidth: "300px"
    }

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setView(true) 
            setEdit(true)
            setDeleteAccess(true)
        }
        else {
            access && access.length && access.map((item: any, index: any) => {
                if(item.name) {
                    switch(item.name) {
                        case "View": item.value == "" || item.value == "none" ? setView(false)  : setView(true) 
                        break
                        case "Edit": item.value == "" || item.value == "none" ? setEdit(false)  : setEdit(true)
                        break
                        case "Delete": item.value == "" || item.value == "none" ? setDeleteAccess(false)  : setDeleteAccess(true)
                        break
                    }
                }
    
            })
        }
    })


    return (
        <div>
            {loading ||  dataloading  || (!page.length && data.length) ?
                <DtsTablePlaceholder 
                    columns={columns} 
                    theadClass={theadClass}     
                    tableClass="align-middle table-nowrap"
                    thClass="border-bottom-1 table-soft-primary"
                />
                : 
            <div>
                { !loading && !dataloading && page && page.length ?  
                    <Table responsive={true} hover {...getTableProps()} className={tableClass + 'm-0'} size="sm">
                    <thead className={theadClass}>
                        {headerGroups.map((headerGroup: any) => (
                            <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                                 {
                                                    create ?     <th className={thClass + "fw-bold"}>
                                    Checkbox
                                </th>: null}
                                {headerGroup.headers.map((column: any) => (
                                    <th key={column.id} className={thClass + "fw-bold"}
                                    style={column.id == "course_name" ? thstyle : {}}
                                        {...column.isSortable ? { ...column.getSortByToggleProps() } : ""}
                                    >
                                        <div onClick={() => handleSort(column.id)}  className={"cursor-pointer" + `${column.accessor == 'course_name' ? '': ' otherth'}`} title={column.Header}>
                                            {column.render("Header")}
                                            {sortBy === column.id && (
                                                <span>
                                                    {sortOrder === 'asc' ? ' ▲' : ' ▼'}
                                                </span>
                                            )}
                                        </div>   
                                    </th>
                                ))}
                                <th className={thClass + "fw-bold"} >
                                    Actions
                                </th>
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row: any) => {
                            prepareRow(row);
                            let result = selectedlist.find((item: any) => item.id == row.original.id);
                            console.log("row.original.id", row.original.id,"page",pagination.page, "checkedRows[row.original.id]", checkedRows[row.original.id], "result", result)
                            return (
                                <Fragment key={row.getRowProps().key}>
                                    <tr style={{verticalAlign: 'middle'}}>
                                    {
                                                    create ? 
                                        <td  style={{width:20}} className="align-content-center" >
                                            <ul className="list-inline hstack gap-2 mb-0">
                                                <Input
                                                    className="form-check-input ms-3 cursor-pointer"
                                                    type="checkbox"
                                                    id={"todo" + row.id}
                                                    // checked={result ? true : false}     
                                                    checked={checkedRows[row.original.id] === undefined ? false : checkedRows[row.original.id]}                                          
                                                    onChange={(e) => {handleselect(row.original, e.target.checked);}}
                                                />
                                            </ul> 
                                        </td>: null}
                                        {row.cells.map((cell: any) => {
                                            let classList = cell.column.className;
                                            return (
                                                <td key={cell.id} style={{verticalAlign: 'middle'}} className='align-items-center justify-content-between' {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                        <td>
                                            <ul className="list-inline hstack gap-2 mb-0">
                                                {/* <li className="list-inline-item edit" title="Call">
                                                    <button className="btn btn-sm btn-soft-danger remove-list">
                                                            <i className="ri-phone-fill"></i>
                                                    </button>
                                                </li> */}
                                                {/* <li className="list-inline-item edit" title="Note">
                                                    <button className="btn btn-sm btn-soft-info remove-list"
                                                        onClick={() => {setNotesModals(true); handleLeadClick(row.original); setpreview(false);  }}
                                                            >
                                                        <i className="ri-question-answer-fill"></i>
                                                    </button>
                                                </li> */}
                                                <li className="list-inline-item">
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle
                                                            href="#"
                                                            className="btn btn-soft-primary btn-sm dropdown"
                                                            tag="button"
                                                        >
                                                            <i className="ri-more-fill"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-end">
                                                            {view ?
                                                            <DropdownItem className="dropdown-item" href={`/courses/${row.original.id}`}
                                                            >
                                                                <button className="btn btn-sm btn-soft-info remove-list fs-12"
                                                                          >
                                                                            <i className="ri-eye-fill"></i>{" "}
                                                                </button>{" "}
                                                                            View                                                           
                                                            </DropdownItem> : null}
                                                            {/* <DropdownItem
                                                                className="dropdown-item edit-item-btn fs-12"
                                                                href={`/courses/${row.original.id}`}
                                                            >
                                                                <button className="btn btn-sm btn-soft-info remove-list"
                                                                          >
                                                                    <i className="ri-pencil-fill"></i>{" "}
                                                                </button>{" "}
                                                                Edit   
                                                            </DropdownItem> */}
                                                            {deleteaccess ?
                                                            <DropdownItem
                                                                className="dropdown-item remove-item-btn fs-12"
    
                                                                onClick={() => { const LeadData = row.original; handleLeadClick(LeadData); setisDeleteOpen(true);  setpreview(false);  }}
                                                            >
                                                                <button className="btn btn-sm btn-soft-danger remove-list"
                                                                          >
                                                                   <i className="ri-delete-bin-fill"></i>
                                                                </button>  {" "}
                                                                Delete 
                                                            </DropdownItem> : null }
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </Fragment>
                            );
                        })}
                    </tbody>
                </Table>
                    : <div className="hstack p-5 fw-semibold fs-16 justify-content-center w-100"><span className="hstack p-5  justify-content-center  fw-semibold fs-16 text-center w-100">No data Found</span></div>}
                    <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
                      
                        <div className="col-sm">
                            <div className="text-muted">
                                {props.t("applications.showing")}
                                <span className="fw-semibold ms-1">
                                {data.length > 0 ? `${pagination.offset + 1}-${Math.min(pagination.offset + pagination.size, pagination.elements)} of ${pagination.elements} results` : `0-0 of 0 results`}
                                </span>
                            </div>
                        </div> 
                        <div className="col-sm-auto">
                            <Paginator total={pagination && pagination.elements ? pagination.elements : 0}
                                currentPage={pagination?.page}
                                pageChanged={(e:any) => pageChanged(e)}
                                pageSize={pagination?.page} 
                                isPageSizeChange={isPageSizeChange}
                                onChangeInSelect={(e:any) => onChangeInSelect(e)}
                                pagesList={pagesList}
                                pagination={pagination}
                                props={props}
                            />
                        </div>
                    </Row>
            </div>}
        </div>
    );
};

export default TableContainer;
import {InvoiceActionTypes, Invoice} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";

const { api } = config;

const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const invoiceGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: InvoiceActionTypes.GET_INVOICE_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/invoices/${id}`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: InvoiceActionTypes.GET_INVOICE_ERROR,
                    payload: body
                });
            } else {
                dispatch({
                    type: InvoiceActionTypes.GET_INVOICE_SUCCESS,
                    payload: body
                });
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

const createQueryLink = (filterObject: any) => {
    const keys = Object.keys(filterObject);
    const values = Object.values(filterObject);
    let query = `${DTS_API_STUDENT}/invoices-metadata?`;
    for (let i = 0; i < keys.length; i++) {
        if (values[i]) query += `${keys[i]}=${values[i]}&`;
    }
    return query;
};

export const invoicesMetadataGetRequest: AppThunk = (filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: InvoiceActionTypes.GET_INVOICEMETADATA_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery =   createQueryLink(filterObject)    
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: InvoiceActionTypes.GET_INVOICEMETADATA_ERROR,
                payload: body_1
            });
            // handleError(body_1);
        } else {
            dispatch({
                type: InvoiceActionTypes.GET_INVOICEMETADATA_SUCCESS,
                payload: body_1
            });
            // handleSuccess("Metadata fetched successfully");
        }
    };
};

export const invoiceUpdateRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (id, data, handleSuccess, handleError) => {
    console.log("invoiceUpdateRequest", data,"handleSuccess", handleSuccess)
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        console.log("token", token)
        dispatch({
            type: InvoiceActionTypes.PUT_INVOICE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/invoices/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        console.log("response_1", response_1)
        if (!response_1.ok) {
            dispatch({
                type: InvoiceActionTypes.PUT_INVOICE_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: InvoiceActionTypes.PUT_INVOICE_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const invoiceDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        console.log("token", token)

        dispatch({
            type: InvoiceActionTypes.DELETE_INVOICE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/invoices/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const body = await response.json();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: InvoiceActionTypes.DELETE_INVOICE_ERROR
                });
                handleError();
            } else {
                dispatch({
                    type: InvoiceActionTypes.DELETE_INVOICE_SUCCESS,
                    payload: []
                });
                handleSuccess(body_1);
            }
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: InvoiceActionTypes.DELETE_INVOICE_ERROR
            });
            handleSuccess();
        }
    };
};


const invoicescreateQueryLink = (filterObject: any, page: any) => {
    let query = `${DTS_API_STUDENT}/invoices?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const InvoiceListGetRequest: AppThunk = (filterObject,pageno) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: InvoiceActionTypes.GET_INVOICES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery = invoicescreateQueryLink(filterObject,pageno);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: InvoiceActionTypes.GET_INVOICES_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: InvoiceActionTypes.GET_INVOICES_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const invoiceCreateRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (parent,data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: InvoiceActionTypes.POST_INVOICE_REQUEST
        });
        const response = await fetch(
            `${DTS_API_STUDENT}/invoices?parent=${parent}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "token": token
                },
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: InvoiceActionTypes.POST_INVOICE_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: InvoiceActionTypes.POST_INVOICE_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

///invoices/hierarchy



const invoiceshierarchycreateQueryLink = (filterObject: any, page: any) => {
    let query = `${DTS_API_STUDENT}/invoices/hierarchy?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};
export const InvoiceHierachyGetRequest: AppThunk = (filterObject,pageno) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: InvoiceActionTypes.GET_INVOICES_HIERARCHY_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery = invoiceshierarchycreateQueryLink(filterObject,pageno);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: InvoiceActionTypes.GET_INVOICES_HIERARCHY_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: InvoiceActionTypes.GET_INVOICES_HIERARCHY_SUCCESS,
                payload: body_1
            });
        }
    };
};




import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Col,
  Input,
  Label,
  Row,
  Form
} from "reactstrap";

import Flatpickr from "react-flatpickr";

const DateFilter = ({property, setConditionjson, conditionjson, setFilterObject, filterObject}: any) => {  
    
    const currentDate = moment();
    const [condition, setCondition]= useState<any>("");
    const handleChange = (value: any, name: any, label: any) => {
        if(value == 'on') {
            setCondition({value:name, label: label })
            let newconditionjson = {propertylabel: property.label, propertyvalue: property.value, conditionLabel: label, conditionvalue: value}
            setConditionjson(newconditionjson)
        }
    }

    const handleTextChange = (value: any, name: any) => {
        console.log("value", value);
        // const formattedDate = `${value[0].toLocaleDateString()}`;
        const formattedDate =`${value[0].toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        })}`;
        let newconditionjson ={...conditionjson, value: formattedDate}
        console.log("newconditionjson", newconditionjson);
        setConditionjson(newconditionjson)
    }
    useEffect(() => {
    }, [conditionjson])   
    
    return (
        <div className="vstack gap-2"> 
        <div className="vstack gap-1">
                <Input className="form-check-input" type="radio" name="formradiocolor1" id="is" checked={condition.value == "is"} onChange={(e) => handleChange(e.target.value,"is","is")} />
                <Label className="form-check-label" htmlFor="is">
                    is
                </Label>
                {condition.value == "is" ?  <Flatpickr
                    className={"form-control w-100 h-100 text-start"}
                    id="datepicker-publish-input"
                    placeholder={'Select Date'}
                    // value={value}
                    onChange={(date: any) => handleTextChange(date, "is")}
                    defaultValue={currentDate.format('YYYY-MM-DD HH:mm:ss')}
                    options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "dd mmm yyyy",
                    }}
                /> : null}
            </div>
            <div className="vstack gap-1">
                <Input className="form-check-input" type="radio" name="formradiocolor1" id="equal" checked={condition.value == "equal"} onChange={(e) => handleChange(e.target.value,"equal","is equal to")} />
                <Label className="form-check-label" htmlFor="equal">
                    is equal to
                </Label>
                {condition.value == "equal" ?  <Flatpickr
                    className={"form-control w-100 h-100 text-start"}
                    id="datepicker-publish-input"
                    placeholder={'Select Date'}
                    // value={value}
                    onChange={(date: any) => handleTextChange(date, "equal")}
                    defaultValue={currentDate.format('YYYY-MM-DD HH:mm:ss')}
                    options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "dd mmm yyyy",
                    }}
                /> : null}
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="formradiocolor1" id="before" checked={condition.value =="before"} onChange={(e) => handleChange(e.target.value,"before", "is before")}/>
                <Label className="form-check-label" htmlFor="before">
                    is before
                </Label>
                {condition.value =="before" ?  
                <Flatpickr
                className={"form-control w-100 h-100 text-start"}
                id="datepicker-publish-input"
                placeholder={'Select Date'}
                // value={value}
                onChange={(date: any) => handleTextChange(date, "before")}
                defaultValue={currentDate.format('YYYY-MM-DD HH:mm:ss')}
                options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "dd mmm yyyy",
                }}
            /> : null}
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="formradiocolor1" id="after" checked={condition.value =="after"} onChange={(e) => handleChange(e.target.value,"after", "is after")}/>
                <Label className="form-check-label" htmlFor="after">
                    is after
                </Label>
                {condition.value =="after" ?  
                <Flatpickr
                className={"form-control w-100 h-100 text-start"}
                id="datepicker-publish-input"
                placeholder={'Select Date'}
                // value={value}
                onChange={(date: any) => handleTextChange(date, "after")}
                defaultValue={currentDate.format('YYYY-MM-DD HH:mm:ss')}
                options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "dd mmm yyyy",
                }}
            /> : null}
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="formradiocolor1" id="more" checked={condition.value =="more"} onChange={(e) => handleChange(e.target.value,"more", "is more than")}/>
                <Label className="form-check-label" htmlFor="more">
                    is more than
                </Label>
                {condition.value =="more" ?  
                <Flatpickr
                className={"form-control w-100 h-100 text-start"}
                id="datepicker-publish-input"
                placeholder={'Select Date'}
                // value={value}
                onChange={(date: any) => handleTextChange(date, "more")}
                defaultValue={currentDate.format('YYYY-MM-DD HH:mm:ss')}
                options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "dd mmm yyyy",
                }}
            /> : null}
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="lessthan" id="lessthan" checked={condition.value == "lessthan"} onChange={(e) => handleChange(e.target.value,"lessthan", "is less than")} />
                <Label className="form-check-label" htmlFor="lessthan">
                    is less than
                </Label>
                {condition.value == "lessthan" ? <Flatpickr
                    className={"form-control w-100 h-100 text-start"}
                    id="datepicker-publish-input"
                    placeholder={'Select Date'}
                    // value={value}
                    onChange={(date: any) => handleTextChange(date, "equal")}
                    defaultValue={currentDate.format('YYYY-MM-DD HH:mm:ss')}
                    options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "dd mmm yyyy",
                    }}
                /> : null}
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="between" id="between" checked={condition.value == "between"} onChange={(e) => handleChange(e.target.value,"between", "is between")} />
                <Label className="form-check-label" htmlFor="between">
                    is between
                </Label>
                {condition.value == "between"  ? <div className="hstack gap-1">
                <Flatpickr
                    className={"form-control w-100 h-100 text-start"}
                    id="datepicker-publish-input"
                    placeholder={'Select Date'}
                    // value={value}
                    onChange={(date: any) => handleTextChange(date, "equal")}
                    defaultValue={currentDate.format('YYYY-MM-DD HH:mm:ss')}
                    options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "dd mmm yyyy",
                    }}
                />
                    and
                    <Flatpickr
                    className={"form-control w-100 h-100 text-start"}
                    id="datepicker-publish-input"
                    placeholder={'Select Date'}
                    // value={value}
                    onChange={(date: any) => handleTextChange(date, "equal")}
                    defaultValue={currentDate.format('YYYY-MM-DD HH:mm:ss')}
                    options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "dd mmm yyyy",
                    }}
                />
                </div>: null}
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="known" id="known" checked={condition.value == "known"} onChange={(e) => handleChange(e.target.value,"known","is known")}/>
                <Label className="form-check-label" htmlFor="known">
                    is known
                </Label>
            </div> 
            <div>
                <Input className="form-check-input" type="radio" name="unknown" id="unknown" checked={condition.value == "unknown"} onChange={(e) => handleChange(e.target.value,"unknown", "is unknown")}/>
                <Label className="form-check-label" htmlFor="unknown">
                    is unknown
                </Label>
            </div> 
        </div>
    );
};

export default DateFilter;

import React, { useEffect, useRef, useState } from "react";
import cloneDeep from 'lodash/cloneDeep';
import conditionsJson from "./conditionsJson.json";
import datesJson from "./datesJson.json";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { getOptions } from "store/auth/action";
import { ApplicationState } from "store";
import SelectDropdown from "../SelectDropdown/SelectDropdown";
import SelectDateDropdown from "../SelectDateDropdown/SelectDateDropdown";
import { Button } from "reactstrap";
import Filter from "./Filter";
import SelectTree from "../SelectTree/SelectTree";
import SelectRange from "../SelectRange/SelectRange";

// Define the type for the options used in the Select component
interface Option {
    label: string;
    value: string;
    disabled?: boolean;
}

// Define types for Property and Filter
interface PropertyProps {
    key: string;
    label: string;
    type?: string;
    value: string;
    quick?: boolean;
    valueJson?: any;
}

interface FilterProps {
    key: string;
    keyLabel: string;
    condition: string;
    conditionLabel: string;
    values: string[];
    valuesLabel: any;
    property: PropertyProps;
    value?: number | string | null | undefined;
    highValue?: number | string | null | undefined;
    valueOption?: Option;
    quick?: boolean;
}

// Interface for TransformedNode
interface TransformedNode {
    label: any;
    value: any;
    createdBy: any;
    createdAt: any;
    updatedAt: any;
    deletedAt: any;
    parentId: any;
    parent: any;
    children?: TransformedNode[]; // Mark children as optional
}

// Helper function to check if a string is valid JSON
const isJson = (str: string) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return [];
    }
};

const QuickFilter = ({ dataFields, columns, translation, onFilter, filter, clearFilterParent }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const optionsObj = useSelector((state: ApplicationState) => state.auth.optionData);
    const optionsLoading = useSelector((state: ApplicationState) => state.auth.optionsLoading);

    // State Variables
    const [properties, setProperties] = useState<PropertyProps[]>([]);
    const [groups, setGroups] = useState<any[]>([]);
    const [selectedProperty, setSelectedProperty] = useState<PropertyProps | null>(null);
    const [options, setOptions] = useState<any[]>([]);
    const [filters, setFilters] = useState<FilterProps[]>([]);
    const [toggleAdvanceFilter, setToggleAdvanceFilter] = useState<boolean>(false);
    const [filtersCount, setFiltersCount] = useState<any>(0);
    const prevFilterRef: any = useRef();

    useEffect(() => {
        // Only update if filterGroups has changed
        if (JSON.stringify({filters, groups}) !== JSON.stringify(prevFilterRef.current)) {
  
            // Store a deep copy to avoid mutating references
            prevFilterRef.current = cloneDeep({filters, groups});

            let filtersCount = filters?.length || 0
            groups.forEach((group: any) => {
                filtersCount += group.filters.length
            })
            onFilter(filters, groups)
            setFiltersCount(filtersCount)
        }
    }, [filters, groups])

    // Update parent component when filter groups change
    useEffect(() => {
      // Only update if filterGroups has changed
      if (filter) {
        setFilters(filter?.quick);
        setGroups(filter?.advance);
      }
    }, [filter]);

    // Map data fields to properties on component load
    useEffect(() => {
        const mappedProperties: PropertyProps[] = dataFields
            ?.map((item: any) => {
                const valueJson = isJson(item.valuesJson);
                if (valueJson?.filter && ['datasetselect', 'select', 'number', 'datetime', 'checkbox', 'selectboxes', 'treeselect'].includes(valueJson?.type)) {
                    return {
                        label: valueJson?.label,
                        value: valueJson?.key,
                        type: valueJson?.type || "datetime",
                        key: valueJson?.key,
                        quick: valueJson?.filter,
                        valueJson: valueJson || {},
                    };
                }
                return null;
            })
            .filter(Boolean) as PropertyProps[];
        setProperties(mappedProperties);
    }, [dataFields]);

    // Update options based on selected property and options data from Redux
    useEffect(() => {
        if (selectedProperty && optionsObj?.length) {
            if(selectedProperty.type === 'treeselect') {
                const createdNodes: TransformedNode[] = optionsObj.map(transformTree);
                setOptions(createdNodes);
            } else {
                const newOptions = optionsObj.map((option: any) => {
                    const optionsJson = isJson(option.valuesJson);
                    return { label: optionsJson[selectedProperty?.valueJson?.optionLabel], value: option.id, valuesJson: optionsJson };
                });
                setOptions(newOptions);
            }
        }
    }, [optionsObj, selectedProperty]);

    // Function to find multiple nodes by a list of ids
    const transformTree = (node: any): TransformedNode => {
        // Parse the valuesJson field to extract the name
        const values = JSON.parse(node.valuesJson);
      
        // Create the base transformed node
        const transformedNode: TransformedNode = {
          label: values.name,
          value: node.id,
          createdBy: node.createdBy,
          createdAt: node.createdAt,
          updatedAt: node.updatedAt,
          deletedAt: node.deletedAt,
          parentId: node.parentId,
          parent: node.parent,
        };
      
        // Recursively transform children if they exist and are not empty
        if (node.children && node.children.length > 0) {
          transformedNode.children = node.children.map(transformTree);
        }
      
        return transformedNode;
    }

    // Clear all filter selections
    const clearFilter = () => {
        setSelectedProperty(null);
        setGroups([]);
        setFilters([])
        clearFilterParent && clearFilterParent()
    };

    // Handle property selection
    const onSelectProperty = (property: PropertyProps) => {
        if (property) {
            setSelectedProperty(property);
        } else {
            clearFilter();
        }
    };

    // Handle value selection for a filter
    const onSelectValue = (property: PropertyProps, value: PropertyProps[], quick: boolean = false) => {
        console.log("value>>>", value)
        const conditionOptions = conditionsJson[property.type as keyof typeof conditionsJson];
        
        if (value.length === 0) {
            // Remove filter if value is empty
            const updatedFilters = filters.filter((filter) => filter.key !== property.key);
            setFilters(updatedFilters);
        } else {
            // Update or add filter
            const updatedFilters = filters.map((filter) => {
                if (property.key === filter.key) {
                    return {
                        ...filter,
                        values: value.map((item) => item.value),
                        valuesLabel: value,
                    };
                }
                return filter;
            });
    
            // Add new filter if not already present
            if (!updatedFilters.some((filter) => property.key === filter.key)) {
                updatedFilters.push({
                    key: property.key,
                    keyLabel: property.label,
                    condition: conditionOptions[0].value,
                    conditionLabel: conditionOptions[0].label,
                    values: value.map((item) => item.value),
                    valuesLabel: value,
                    property: property,
                    quick: quick,
                });
            }
    
            setFilters(updatedFilters);
        }
    };


    // Handle value selection for a filter
    const onSelectValueHighValue = (property: PropertyProps, value: any, highValue: any, valueOption: any, quick: boolean = false) => {
        const conditionOptions = conditionsJson[property.type as keyof typeof conditionsJson];
        
        if (value !== 0 && value !== '0' && !value) {
            // Remove filter if value is empty
            const updatedFilters = filters.filter((filter) => filter.key !== property.key);
            setFilters(updatedFilters);
        } else {
            // Update or add filter
            const updatedFilters = filters.map((filter) => {
                if (property.key === filter.key) {
                    return {
                        ...filter,
                        values: [],
                        valuesLabel: [],
                        value,
                        highValue,
                        valueOption
                    };
                }
                return filter;
            });
    
            // Add new filter if not already present
            if (!updatedFilters.some((filter) => property.key === filter.key)) {
                updatedFilters.push({
                    key: property.key,
                    keyLabel: property.label,
                    condition: conditionOptions[0].value,
                    conditionLabel: conditionOptions[0].label,
                    values: [],
                    valuesLabel: [],
                    value,
                    highValue,
                    property: property,
                    quick: quick,
                    valueOption
                });
            }
    
            setFilters(updatedFilters);
        }
    }

    // Clear a specific filter
    const onSelectValueClear = (property: PropertyProps) => {
        setFilters(filters.filter((filter) => filter.key !== property.key));
    };

    // Dispatch option fetch action if a model is associated with the selected property
    const onFocus = (property: PropertyProps) => {
        onSelectProperty(property);
        if (property?.valueJson?.model) {
            dispatch(getOptions(property.valueJson.model, {}));
        }
    };

    // Render input field based on property type
    const renderValueInput = (property: PropertyProps) => {
        const filter = filters.find((filter) => property.key === filter.key);
        switch (property?.type) {
            case 'datasetselect':
                return (
                    <SelectDropdown
                        title="Select"
                        multi={true}
                        options={options}
                        placeholder={property?.label}
                        property={property}
                        onFocus={() => onFocus(property)}
                        values={filter?.valuesLabel}
                        onChange={(newValues: PropertyProps[]) => onSelectValue(property, newValues, true)}
                        onClearFilter={() => onSelectValueClear(property)}
                        isSearchable
                        optionsLoading={optionsLoading}
                    />
                );
            case 'select':
            case 'checkbox':
            case 'selectboxes':
                return (
                    <SelectDropdown
                        title="Select"
                        multi={true}
                        options={property?.valueJson?.values}
                        placeholder={property?.label}
                        property={property}
                        values={filter?.valuesLabel}
                        onChange={(newValues: PropertyProps[]) => onSelectValue(property, newValues, true)}
                        onClearFilter={() => onSelectValueClear(property)}
                        isSearchable
                    />
                );
            case 'datetime':
                return (
                    <SelectDateDropdown
                        title="Select"
                        options={datesJson}
                        placeholder={property?.label}
                        property={property}
                        value={filter?.valueOption}
                        onChange={(value: any, highValue: any, valueOption: any) => onSelectValueHighValue(property, value, highValue, valueOption, true)}
                        onClearFilter={() => onSelectValueClear(property)}
                        isSearchable
                    />
                );
            case 'treeselect':
                return (
                    <SelectTree
                        placeholder={property?.label}
                        setValues={(values: any) => onSelectValue(property, values, true)}
                        onFocus={() => onFocus(property)}
                        options={options}
                        values={filter?.valuesLabel}
                        showValues={false}
                        optionsLoading={optionsLoading}
                    />
                );
            case 'number':
                return (
                    <SelectRange
                        placeholder={property?.label}
                        onChange={(value: any, highValue: any) => onSelectValueHighValue(property, value, highValue, null, true)}
                        value={filter?.value}
                        highValue={filter?.highValue}
                    />
                );
            default:
                return null;
        }
    };
    return (
        <div className="d-flex flex-row justify-content-between gap-2 p-3 pb-0">
            {/* Filter Offcanvas component */}
            <Filter
                show={toggleAdvanceFilter}
                onCloseClick={() => setToggleAdvanceFilter(false)}
                dataFields={columns}
                updateGroups={setGroups}
                quickFilters={filters}
                groups={groups}
            />
            {/* Render input fields for quick filters */}
            <div className="d-flex flex-wrap gap-2">
                {properties.map((property) => renderValueInput(property))}
            </div>
            {/* Action buttons */}
            <div className="d-flex gap-2 justify-content-end" style={{ width: '360px' }}>
                <div className="hstack align-items-start justify-content-end gap-2 w-auto pe-0">
                    <Button
                        size="sm"
                        color="danger"
                        className="btn-label btn-soft-danger"
                        onClick={clearFilter}
                    >
                        <i className="ri-close-circle-fill label-icon align-middle fs-16 me-2"></i>
                        {translation.t("applications.clear_filters")}
                    </Button>
                    <Button
                        size="sm"
                        onClick={() => setToggleAdvanceFilter(true)}
                        color="success"
                        className="btn-label btn-soft-success"
                    >
                        <i className="ri-filter-2-fill label-icon align-middle fs-16 me-2"></i>
                        {translation.t("applications.advanced_filter")}({filtersCount})
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default QuickFilter;

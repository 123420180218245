import React, { useState } from "react";
import Select from "react-select";
import { Input, Label } from "reactstrap";
import MultiCheckboxEdit from "./MultiCheckboxEdit";
import NumbersEdit from "./NumbersEdit";
import SingleCheckboxTable from "./SingleCheckboxTable";

const FieldType = () => {
  const GroupOptions = [
    {
      label: "Text Input",
      options: [
        { label: "Single-line text", value: "single-line-text" },
        { label: "Multi-line text", value: "multi-line-text" },
        { label: "Phone number", value: "phone-number" },
      ],
    },
    {
      label: "Choosing options",
      options: [
        { label: "Single checkbox", value: "single-checkbox" },
        { label: "Multiple checkbox", value: "multiple-checkbox" },
        { label: "Dropdown select", value: "dropdown-select" },
        { label: "Radio select", value: "radio-select" },
        { label: "Datepicker", value: "datepicker" },
      ],
    },
    {
      label: "Values",
      options: [{ label: "Numbers", value: "numbers" }],
    },
  ];
  const [selectedGroup, setSelectedGroup] = useState<any>(null);
  const [selectedSingle, setSelectedSingle] = useState<any>(null);
  const handleSelectGroups = (selectedGroup: any) => {
    setSelectedGroup(selectedGroup);
  };

  const handleSelectSingle = (selectedSingle: any) => {
    setSelectedSingle(selectedSingle);
  };
  const renderSelected = () => {
    switch (selectedGroup?.value) {
      case "single-line-text":
        return null;
      case "multi-line-text":
        return null;
      case "phone-number":
        return null;
      case "single-checkbox":
        return <SingleCheckboxTable />;
      case "multiple-checkbox":
        return <MultiCheckboxEdit />;
      case "dropdown-select":
        return <MultiCheckboxEdit />;
      case "radio-select":
        return <MultiCheckboxEdit />;
      case "numbers":
        return <NumbersEdit />;
      case "datepicker":

      default:
        return null; // Render nothing if no match
    }
  };
  return (
    <div>
      <div className="mb-2">
        <Label className="hstack py-1 form-label">Field type</Label>

        <Select
          value={selectedGroup}
          onChange={(selectedGroup: any) => {
            console.log("selected group", selectedGroup);
            handleSelectGroups(selectedGroup);
          }}
          options={GroupOptions}
        />
      </div>

      <div className="mb-3">
        {GroupOptions ? <div>{renderSelected()}</div> : null}
      </div>
    </div>
  );
};
export default FieldType;

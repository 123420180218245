import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    useTable,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { Table, Row, Input, Label, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { DefaultColumnFilter } from "../../Components/Common/filters";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Paginator from "Components/Common/Paginator";
import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
import { ApplicationState } from "store";
import { useSelector } from "react-redux";


const TableContainer = ({
    columns,
    data,
    isPageSizeChange,
    customPageSize,
    tableClass,
    theadClass,
    trClass,
    thClass,
    divClass,
    setIsMultiDeleteButton, 
    setselectedcount,
    handleEditClick,
    onClickDelete,
    handleLeadClick,
    setisDeleteOpen,
    pageChanged,
    pagesList,
    pagination,
    onChangePageSize,
    pageSize,
    onClickrow,
    setIsArchivedOpen,
    setisEditOpen,
    archive,
    onRestoreClick,    
    handleSort,
    sortOrder,
    sortBy,
    access,
    props,
    setrecord,
    loading,
    tab
}: any) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                pageIndex: 0, pageSize: customPageSize, selectedRowIds: 0, sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );
    const [view, setView] = useState(false);
    const [edit, setEdit] = useState(false);
    const [deleteaccess, setDeleteAccess] = useState(false);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const generateSortingIndicator = (column: any) => {
        return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
    };

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setView(true) 
            setEdit(true)
            setDeleteAccess(true)
        }
        else {
            access && access.length && access.map((item: any, index: any) => {
                if(item.name) {
                    switch(item.name) {
                        case "View": item.value == "" || item.value == "none" ? setView(false)  : setView(true) 
                        break
                        case "Edit": item.value == "" || item.value == "none" ? setEdit(false)  : setEdit(true)
                        break
                        case "Delete": item.value == "" || item.value == "none" ? setDeleteAccess(false)  : setDeleteAccess(true)
                        break
                    }
                }
    
            })
        }
    })

    const onChangeInSelect = (event: any) => {
        setPageSize(Number(event.target.value));
        onChangePageSize(event.target.value);
    };

    const checkedAll = () => {
        const checkall: any = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".leadsCheckBox");

        if (checkall.checked) {
            setIsMultiDeleteButton(true)
            setselectedcount(ele.length)
            ele.forEach((ele: any) => {
                ele.checked = true;
            });
        } else {
            setIsMultiDeleteButton(false)
            ele.forEach((ele: any) => {
                ele.checked = false;
            });
        }
        // deleteCheckbox();
    };
    console.log("data", data)
    return (
        <div>
        {loading ?
           <DtsTablePlaceholder 
            columns={columns} 
            theadClass={theadClass}     
            tableClass="align-middle table-nowrap"
            thClass="border-bottom-1 table-soft-primary"
        />
            : 
        <div>
        {!loading && page && page.length ? 
            <Table hover {...getTableProps()} className={tableClass + ' m-0'}>
                <thead className={theadClass}>
                    {headerGroups.map((headerGroup: any) => (
                        <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th key={column.id} className={thClass + " fw-bold"}
                                    {...column.isSortable ? { ...column.getSortByToggleProps() } : ""}
                                >
                                    <div onClick={column.isSortable ? () => handleSort(column.id): ()=> {}} className={column.isSortable ?  "cursor-pointer": ""}>
                                        {column.render("Header")}
                                        {column.isSortable ? sortBy === column.id && (
                                            <span>
                                                {sortOrder === 'asc' ? ' ▲' : ' ▼'}
                                            </span>
                                        ): null}
                                    </div>     
                                </th>
                            ))}
                           { archive ? null :
                            <th className={thClass + " fw-bold"}>
                                Action
                            </th>}
                        </tr>
                    ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {page.map((row: any) => {
                        prepareRow(row);
                        console.log("row.original", row.original.createdBy)
                        return (
                            <Fragment key={row.getRowProps().key}>
                                <tr style={{verticalAlign: 'middle'}}>
                                    {row.cells.map((cell: any) => {
                                        let classList = cell.column.className;
                                        return (
                                            <td key={cell.id} style={{verticalAlign: 'middle'}} className='align-items-center justify-content-between' {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                    {
                                            archive ? null :
                                            <td>
                                                {
                                                    row.original.createdBy === 'Zilter' ? null 
                                                    :
                                                    <ul className="hstack gap-1 p-0 m-0"> 
                                                        <button disabled={!deleteaccess} className="btn btn-sm btn-soft-danger remove-list" onClick={() => { setrecord(row.original); setisDeleteOpen(true) }}>
                                                            <i className="ri-delete-bin-fill"></i>
                                                        </button>
                                                    </ul>}
                                            </td>            
                                    }
                                </tr>
                            </Fragment>
                        );
                    })}
                   
                </tbody>
            </Table>: <div className="hstack p-5 fw-semibold fs-16 justify-content-center w-100"><span className="hstack p-5  justify-content-center  fw-semibold fs-16 text-center w-100">No data Found</span></div>}
            <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
                <div className="col-sm">
                    <div className="text-muted">
                        {props.t("applications.showing")}
                        <span className="fw-semibold ms-1">
                        {data.length > 0 ? `${pagination.offset + 1}-${Math.min(pagination.offset + pagination.size, pagination.elements)} of ${pagination.elements} results` : `0-0 of 0 results`}
                        </span>
                    </div>
                </div>   
                <div className="col-sm-auto"><>{console.log("props", props)}</>
                    <Paginator total={pagination && pagination.elements ? pagination.elements : 0}
                        currentPage={pagination?.page}
                        pageChanged={(e:any) => pageChanged(e)}
                        pageSize={pageSize} 
                        isPageSizeChange={isPageSizeChange}
                        onChangeInSelect={(e:any) => onChangeInSelect(e)}
                        pagesList={pagesList}
                        pagination={pagination}
                        props={props}
                     />
                </div>
            </Row>
        </div>}
        </div>
    );
};

TableContainer.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(TableContainer);
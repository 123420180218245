import React from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { withTranslation } from 'react-i18next';
import Links from './Links';

const index = (props:any) => {
    
    document.title="DTS | Zilter";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>                           
                    <BreadCrumb title={props.t("student.students")} pageTitle={props.t("student.students")} />
                    <Row>
                        <Links />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(index);
import {Reducer} from "redux";
import {AuthActionTypes, AuthState} from "./types";
export const initialState: AuthState = {
    userProfile:  {       
        id: "",
        name: "",
        tenantId: {
            id: "",
            email: ""
        },
        email: "",
        password: "",
        companyName: "",
        website: "",
        phoneNumber: "",
        role: {
            permissions: [],
            title: "",
            id: ""
        },
        otp: "",
        alternatePhoneNumber: "",
        accountStatus: "",
        profilePic: "",
        confirmationStatus: "",
        createdTime: "",
        updatedTime: "",
        noOfEmployees: "",
        currentStage: undefined,
        tenant: {
            companyName: "",
            email: "",
            noOfEmployees: "",
            website:"",
            id: ""
        },
        salt: [
            ""
        ]
    },
    errors: undefined,
    loading: false,
    response:{},
    token: localStorage.getItem('dts_token') ? JSON.parse(localStorage.getItem('dts_token') || "") : "",
    isUserLoggedin: false,
    optionData: [],
    optionsLoading: false,
    optionmetaData: [],
    models:[],
    subLevelPermissionsList: [],
    permissionloading: false,
    parentPermmission: [],
    meloading: false
};

const reducer: Reducer<AuthState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case AuthActionTypes.POST_SIGNIN_REQUEST: {
            return {...state, loading: true};
        }
        case AuthActionTypes.POST_SIGNIN_SUCCESS: {
            localStorage.setItem('dts_token', JSON.stringify(action.payload.token));
            return {
                ...state,
                loading: false,
                isUserLoggedin: true,
                token: action.payload.token
            };
        }
        case AuthActionTypes.POST_SIGNIN_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }
        case AuthActionTypes.POST_SIGNUP_REQUEST: {
            return {...state, loading: true};
        }
        case AuthActionTypes.POST_SIGNUP_SUCCESS: {
            localStorage.setItem('dts_token', JSON.stringify(action.payload.token));
            let accessToken = action.payload.token && action.payload.token !=="null" ? action.payload.token : null
            return {...state, loading: false, token: accessToken};
        }
        case AuthActionTypes.POST_SIGNUP_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }        
        case AuthActionTypes.POST_VERIFY_REQUEST: {
            return {...state, loading: true};
        }
        case AuthActionTypes.POST_VERIFY_SUCCESS: {
            return {...state, loading: false};
        }
        case AuthActionTypes.POST_VERIFY_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }
        case AuthActionTypes.GET_SIGNOUT: {
            localStorage.clear()
            return {...state, loading: false, userProfile: null, isUserLoggedin: false, token: "", response: {}};
        }       
        case AuthActionTypes.RESET_STUDENTS: {
            localStorage.removeItem('invoiceStudent');
            localStorage.removeItem('invoiceChannel');
            return {...state}
        } 
        case AuthActionTypes.SET_INVOICE_STUDENTS: {
            localStorage.setItem('invoiceStudent', JSON.stringify(action.payload.students))
            localStorage.setItem('invoiceChannel', JSON.stringify(action.payload.channel))
            console.log(">>>>>",action.payload)
            return {...state};               
        }
        case AuthActionTypes.POST_UPDATE_USER_REQUEST: {
            return {...state, loading: true};
        }
        case AuthActionTypes.POST_UPDATE_USER_SUCCESS: {
            return {...state, loading: false};
        }
        case AuthActionTypes.POST_UPDATE_USER_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }                
        case AuthActionTypes.POST_JOIN_USER_REQUEST: {
            return {...state, loading: true};
        }
        case AuthActionTypes.POST_JOIN_USER_SUCCESS: {
            return {...state, loading: false};
        }
        case AuthActionTypes.POST_JOIN_USER_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }                 
        case AuthActionTypes.POST_FORGOT_REQUEST: {
            return {...state, loading: true};
        }
        case AuthActionTypes.POST_FORGOT_SUCCESS: {
            return {...state, loading: false};
        }
        case AuthActionTypes.POST_FORGOT_ERROR: {
            return {...state, loading: false, errors: action.payload};
        } 
                         
        case AuthActionTypes.POST_RESEND_REQUEST: {
            return {...state, loading: true};
        }
        case AuthActionTypes.POST_RESEND_SUCCESS: {
            return {...state, loading: false};
        }
        case AuthActionTypes.POST_RESEND_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }          
        case AuthActionTypes.GET_USER_REQUEST: {
            return {...state, meloading: true};
        }
        case AuthActionTypes.GET_USER_SUCCESS: {
            let permissions =  action.payload?.role?.permissions;
            const parentNames = permissions.filter((permission: any) => permission.parentPermission === null).map((permission: any) => permission.name.toLowerCase());
            let newpermissionsubpermission: any = []
            parentNames && parentNames.length && parentNames.map((item: any) => {
                newpermissionsubpermission[item] = []
                permissions && permissions.length && permissions.map((permission: any) => 
                    {
                        if(permission.parentPermission && permission.parentPermission?.name && permission.parentPermission?.name.toLowerCase() === item  && permission.value != "" && permission.value)
                            {  
                                newpermissionsubpermission[item].push(permission)
                            }
                        else if(permission.parentPermission==null && permission.name.toLowerCase() === item  && permission.value != "" && permission.value)
                            {  
                                newpermissionsubpermission[item].push(permission)
                            }
                    });
             });  
             const result: any = {};
             const parents: any = [];
            for (const [key, value] of Object.entries(newpermissionsubpermission)) {
                if (Array.isArray(value) && value.length > 0) {
                    let istrue = value.some((permission: any) => 
                        permission.value !== "none" && permission.value !== "" && permission.value !== "false" && permission.value !== "FALSE"
                    );
                    if(istrue) {
                        result[key] = value;
                        parents.push(key)
                    }
                }
            }
            return {...state, userProfile: action.payload, isUserLoggedin: true, subLevelPermissionsList:result , parentPermmission: parents, meloading: false,   };
        }
        case AuthActionTypes.GET_USER_ERROR: {
            return {...state, meloading: false, errors: action.payload};
        }   
        case AuthActionTypes.GET_PROPERTIES_REQUEST: {
            return {...state, loading: true};
        }
        case AuthActionTypes.GET_PROPERTIES_SUCCESS: {
            return {...state, loading: false, optionmetaData: action.payload.content, isUserLoggedin: true};
        }
        case AuthActionTypes.GET_PROPERTIES_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }         
        case AuthActionTypes.GET_MODELS_REQUEST: {
            return {...state, loading: true};
        }
        case AuthActionTypes.GET_MODELS_SUCCESS: {
            return {...state, loading: false, models: action.payload, isUserLoggedin: true};
        }
        case AuthActionTypes.GET_MODELS_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }      
        case AuthActionTypes.GET_OPTIONS_REQUEST: {
            return {...state, optionsLoading: true};
        }
        case AuthActionTypes.GET_OPTIONS_SUCCESS: {
            return {...state, optionsLoading: false, optionData: action.payload.content, isUserLoggedin: true};
        }
        case AuthActionTypes.GET_OPTIONS_ERROR: {
            return {...state, optionsLoading: false, errors: action.payload};
        }  
        case AuthActionTypes.POST_RESPONSE_SUCCESS: {
            return {...state, loading: false, response: action.payload};
        }   
        // case AuthActionTypes.POST_SUBLEVEL_PERMISSION_ERROR: {
        //     return {...state, loading: true};
        // }     
        // case AuthActionTypes.POST_SUBLEVEL_PERMISSION_SUCCESS: {
        //     return {...state, permissionloading: false, subLevelPermissionsList: action.payload};
        // }  
        // case AuthActionTypes.POST_SUBLEVEL_PERMISSION_REQUEST: {
        //     return {...state, permissionloading: true};
        // }               
        case AuthActionTypes.POST_RESETPASSWORD_USER_REQUEST: {
            return {...state, permissionloading: true};
        }
        case AuthActionTypes.POST_RESETPASSWORD_USER_SUCCESS: {
            return {...state, loading: false};
        }
        case AuthActionTypes.POST_RESETPASSWORD_USER_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }       
        case AuthActionTypes.GET_VERIFY_PASSWORD_LINK_REQUEST: {
            return {...state, loading: true};
        }
        case AuthActionTypes.GET_VERIFY_PASSWORD_LINK_SUCCESS: {
            return {...state, loading: false};
        }
        case AuthActionTypes.GET_VERIFY_PASSWORD_LINK_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }               
        case AuthActionTypes.GET_VERIFY_JOIN_LINK_REQUEST: {
            return {...state, loading: true};
        }
        case AuthActionTypes.GET_VERIFY_JOIN_LINK_SUCCESS: {
            return {...state, loading: false};
        }
        case AuthActionTypes.GET_VERIFY_JOIN_LINK_ERROR: {
            return {...state, loading: false, errors: action.payload};
        } 
        case AuthActionTypes.POST_GOOGLECALENDARSYNC_REQUEST: {
            return { ...state, loading: true };
        }
        case AuthActionTypes.POST_GOOGLECALENDARSYNC_SUCCESS: {
            console.log("In Calendar Sync Reducer :- " + action.payload.token);
            localStorage.setItem('dts_token', JSON.stringify(action.payload.token));
            let accessToken = action.payload.token && action.payload.token !== "null" ? action.payload.token : null
            return { ...state, loading: false, token: accessToken };
        }
        case AuthActionTypes.POST_GOOGLECALENDARSYNC_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        default: {
            return state;
        }
    }
};

export {reducer as AuthReducer};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import Form from "Components/Common/FormBuilder/UpdateForm";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { CompanyListGetRequest, companyGetRequest, companyUpdateRequest,companiesMetadataGetRequest } from "store/companies/action";
// Define types or interfaces
interface FormField {
    name: string;
    label: string;
    type: string;
    options?: { value: string; label: string }[];
}

interface FormData {
    fields: FormField[];
}

const CompanyInfo = () => {
    const { id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const metadata = useSelector((state: ApplicationState) => state.companies.companiesMetadata);
    const company: any = useSelector((state: ApplicationState) => state.companies.company);

    const onUpdate = (formValues: any) => {
        let data = { ...company, valuesJson: formValues };
        const handleSuccess = (body: any) => {
            console.log("body", body)
            const message = () => toast(`Company updated Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(CompanyListGetRequest());
            dispatch(companyGetRequest(id))
        }
        dispatch(companyUpdateRequest(id, formValues, handleSuccess, () => { }))
    }
    useEffect(() => {
        const filters = { pageSize: 100 }
        dispatch(companiesMetadataGetRequest(filters));
    }, [])

    return (
        <Row className="justify-content-start">            
            <Col md={6} lg={6}>                
                <Form dataFields={metadata} onUpdate={onUpdate} showbtn={false} data={company} />
            </Col>
        </Row>

    );
};

export default CompanyInfo;

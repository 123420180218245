import {PropertiesActionTypes} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";

const { api } = config;

const DTS_API_DOMAIN = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const propertyGetRequest: AppThunk = (id) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {      
        const { auth: { token } } = getState();
        dispatch({
            type: PropertiesActionTypes.GET_PROPERTY_REQUEST
        }); 
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const response = await fetch(`${DTS_API_DOMAIN}/properties/${id}`, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: PropertiesActionTypes.GET_PROPERTY_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: PropertiesActionTypes.GET_PROPERTY_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const GroupListGetRequest: AppThunk = (id) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {      
        const { auth: { token } } = getState();
        dispatch({
            type: PropertiesActionTypes.GET_GROUPS_REQUEST
        }); 
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const response = await fetch(`${DTS_API_DOMAIN}/groups`, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: PropertiesActionTypes.GET_GROUPS_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: PropertiesActionTypes.GET_GROUPS_SUCCESS,
                payload: body_1
            });
        }
    };
};

const createQueryGroupGetByModel = (model: any, filterObject: any, page: any) => {
    let query = `${DTS_API_DOMAIN}/groups/details/${model}?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};


export const GroupGetByModelRequest: AppThunk = (model,filterObject,pageno) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {      
        const { auth: { token } } = getState();
        dispatch({
            type: PropertiesActionTypes.GET_GROUPS_MODEL_REQUEST
        }); 
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }       
        const searchQuery = createQueryGroupGetByModel(model, filterObject, pageno);
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: PropertiesActionTypes.GET_GROUPS_MODEL_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: PropertiesActionTypes.GET_GROUPS_MODEL_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const allGroupsWithToken: AppThunk = (model) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {      
        const { auth: { token } } = getState();
        dispatch({
            type: PropertiesActionTypes.GET_ALLGROUPS_REQUEST
        }); 
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const response = await fetch(`${DTS_API_DOMAIN}/groups/details/${model}?pageSize=100`, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: PropertiesActionTypes.GET_ALLGROUPS_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: PropertiesActionTypes.GET_ALLGROUPS_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const GroupListForStudentForm: AppThunk = (model, tenant_id) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({
            type: PropertiesActionTypes.GET_GROUPS_STUDENTFORM_REQUEST
        }); 
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');        
        const response = await fetch(`${DTS_API_DOMAIN}/groups/forms/${model}/${tenant_id}`, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: PropertiesActionTypes.GET_GROUPS_STUDENTFORM_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: PropertiesActionTypes.GET_GROUPS_STUDENTFORM_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const GroupGetRequest: AppThunk = (id) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {      
        const { auth: { token } } = getState();
        dispatch({
            type: PropertiesActionTypes.GET_GROUP_REQUEST
        }); 
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const response = await fetch(`${DTS_API_DOMAIN}/propertys/${id}`, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: PropertiesActionTypes.GET_GROUP_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: PropertiesActionTypes.GET_GROUP_SUCCESS,
                payload: body_1
            });
        }
    };
};

const createQueryLink = (filterObject: any, page: any) => {
    let query = `${DTS_API_DOMAIN}/properties?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const propertyListGetRequest: AppThunk = (model,filterObject,pageno) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: PropertiesActionTypes.GET_PROPERTIES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        if (model) {
            headers.append('Properties-Model', model);
        }  
        const searchQuery = createQueryLink(filterObject,pageno);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: PropertiesActionTypes.GET_PROPERTIES_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: PropertiesActionTypes.GET_PROPERTIES_SUCCESS,
                payload: body_1
            });
        }
    };
};

const createArchivedQueryLink = (filterObject: any, page: any) => {
    let query = `${DTS_API_DOMAIN}/properties/archived?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const propertyArchivedListGetRequest: AppThunk = (model,filterObject,pageno) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: PropertiesActionTypes.GET_ARCHIVED_PROPERTIES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        if (model) {
            headers.append('Properties-Model', model);
        }  
        const searchQuery = createArchivedQueryLink(filterObject,pageno);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: PropertiesActionTypes.GET_ARCHIVED_PROPERTIES_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: PropertiesActionTypes.GET_ARCHIVED_PROPERTIES_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const propertyUpdateRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (id,data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: PropertiesActionTypes.PUT_PROPERTY_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_DOMAIN}/properties/${id}`,
            {
                method: "PUT",
                headers:headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: PropertiesActionTypes.PUT_PROPERTY_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: PropertiesActionTypes.PUT_PROPERTY_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const propertyCreateRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: PropertiesActionTypes.POST_PROPERTY_REQUEST
        });
        const response = await fetch(
            `${DTS_API_DOMAIN}/properties`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "token": token
                },
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: PropertiesActionTypes.POST_PROPERTY_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: PropertiesActionTypes.POST_PROPERTY_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const groupCreateRequest: ActionCreator<
ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
return async (dispatch: Dispatch, getState: any): Promise<void> => {   
    const { auth: { token } } = getState();   
    dispatch({
        type: PropertiesActionTypes.POST_GROUP_REQUEST
    });
    const response = await fetch(
        `${DTS_API_DOMAIN}/groups`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "token": token
            },
            body: JSON.stringify(data)
        }
    );
    const body = await response.json();
    const {response: response_1, body: body_1} = {response, body};
    if (!response_1.ok) {
        dispatch({
            type: PropertiesActionTypes.POST_GROUP_ERROR
        });
        handleError();
    } else {
        dispatch({
            type: PropertiesActionTypes.POST_GROUP_SUCCESS,
            payload: []
        });
        handleSuccess(body_1);
    }
};
}; 


export const propertyDeleteRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (model, id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();  
        dispatch({
            type: PropertiesActionTypes.DELETE_PROPERTY_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/properties/${model}/${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "token": token
            }
        });
        try {
            const body = await response.json();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: PropertiesActionTypes.DELETE_PROPERTY_ERROR
                });
                handleError();
            } else {
                dispatch({
                    type: PropertiesActionTypes.DELETE_PROPERTY_SUCCESS,
                    payload: []
                });
                handleSuccess(body_1);
            }
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: PropertiesActionTypes.DELETE_PROPERTY_ERROR
            });
            handleError();
        }
       
    };
};

export const propertyRestoreRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (model, id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();  
        dispatch({
            type: PropertiesActionTypes.RESTORED_PROPERTY_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/properties/${model}/restore/${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "token": token
            }
        });
        try {
            const body = await response.json();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: PropertiesActionTypes.RESTORED_PROPERTY_ERROR
                });
                handleError();
            } else {
                dispatch({
                    type: PropertiesActionTypes.RESTORED_PROPERTY_SUCCESS,
                    payload: []
                });
                handleSuccess(body_1);
            }
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: PropertiesActionTypes.RESTORED_PROPERTY_ERROR
            });
            handleError();
        }
       
    };
};

export const groupDelete: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();  
        dispatch({
            type: PropertiesActionTypes.DELETE_GROUP_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/groups/${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "token": token
            }
        });
        try {
            const body = await response.json();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: PropertiesActionTypes.DELETE_GROUP_ERROR
                });
                handleError();
            } else {
                dispatch({
                    type: PropertiesActionTypes.DELETE_GROUP_SUCCESS,
                    payload: []
                });
                handleSuccess(body_1);
            }
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: PropertiesActionTypes.DELETE_GROUP_ERROR
            });
            handleError();
        }
       
    };
};

export const groupUpdateRequest : ActionCreator<
ThunkAction<void, ApplicationState, null, Action<string>>
> = (id,data, handleSuccess, handleError) => {
return async (dispatch: Dispatch, getState): Promise<void> => {        
    const { auth: { token } } = getState();
    dispatch({
        type: PropertiesActionTypes.PUT_GROUP_REQUEST
    });
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    if (token) {
        headers.append('token', token);
    }   
    const response = await fetch(
        `${DTS_API_DOMAIN}/groups/${id}`,
        {
            method: "PUT",
            headers:headers,
            body: JSON.stringify(data)
        }
    );
    const body = await response.json();
    const {response: response_1, body: body_1} = {response, body};
    if (!response_1.ok) {
        dispatch({
            type: PropertiesActionTypes.PUT_GROUP_ERROR
        });
        handleError();
    } else {
        dispatch({
            type: PropertiesActionTypes.PUT_GROUP_SUCCESS,
            payload: []
        });
        handleSuccess(body_1);
    }
};
};

import React, { useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Input,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Button,
} from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
// import { VerticalForm } from "pages/Forms/FormLayouts/FormlayoutsCode";

const AdvancedFilter = ({ props, setApplyFilter, setIsDynamicModalOpen, filterCount, show, onCloseClick, dataFields, ClearFilter, setFilterObject, filterObject, applyFilters }: any) => {
    const filterFields: any = [];
    const [appliedFilters, setappliedFilters] = useState([])
    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            return false;
        }
    }
    dataFields && dataFields.length && dataFields.map((field: any) => {
        let item =  field?.valuesJson && isJson(field?.valuesJson) ?  JSON.parse(field?.valuesJson) : {}
        console.log("item", item)  
        let isFieldType = item && item.type != "textarea" && item.type != "phone" && item.type != "textfield" && item.type != "number" && item.type != 'Password'  && item.type != 'starratings' ? true : false;  
        if (item && isFieldType && item.filter) return filterFields.push(item)
    })
    const onChange = (option: any, key: any) => {
        let filters = { ...filterObject, [key]: option.value }
        setFilterObject(filters)
        applyFilters(filters)
        let newappliedFilters: any = appliedFilters;
        newappliedFilters[key] = option;
        setappliedFilters(newappliedFilters)
    }
    const onClear = () => {
        let newappliedFilters: any = appliedFilters;
        filterFields && filterFields.length && filterFields.map((field: any) => {
            if (field.type == "radio" || field.type == "email" || field.type == "textfield" || field.type == "textarea" || field.type == "checkbox" || field.type == "selectboxes" || field.type == "file" || field.type == "datetime") return
            newappliedFilters[field.key] = null;
        })
        setappliedFilters(newappliedFilters)
    }
    console.log("appliedFilters", appliedFilters, "filterFields", filterFields)
    return (
        <div className="hstack justify-content-between">
            <Col md={9} lg={9} className="flex-wrap hstack justify-content-start gap-1 p-0">
                {filterFields && filterFields.length ? filterFields.map((field: any) => {
                    console.log("field", field)
                    if (field.type == "radio" || field.type == "email" || field.type == "textfield" || field.type == "textarea" || field.type == "checkbox" || field.type == "selectboxes" || field.type == "file" || field.type == "datetime") return
                    let value: any = appliedFilters[field.key]
                    return <Col md={3} lg={3} xxl={3} className="p-0">
                        <FormBuilder
                            placeholder={field.placeholder}
                            onChange={(option: any) => onChange(option, field.key)}
                            options={field.values && field.values.reverse()}
                            type={field.type}                            
                            value={value}
                            rest={field}
                            className="w-100 h-auto text-start"
                        /></Col>
                }) : null}
            </Col>
            <Col md={3} lg={3} className="flex-wrap hstack gap-2 justify-content-end p-0">
                {filterFields && filterFields.length ?
                    <div className="hstack align-items-start  justify-content-end gap-2 w-auto pe-0">
                        <Button
                            color="danger"
                            outline={true}
                            type="button"
                            size='sm'
                            className="py-2 btn w-auto"
                            onClick={() => { ClearFilter(); setApplyFilter(false); onClear() }}
                        >
                            {props.t("applications.clear_filters")}
                        </Button>
                        <Button
                            className="btn btn-success w-auto py-2 "
                            size='sm'
                            onClick={() => {
                                setIsDynamicModalOpen(true);
                            }}
                        >
                            {props.t("applications.advanced_filter")}({filterCount})
                        </Button>
                    </div> : null}
            </Col>
        </div>
    );
};

export default AdvancedFilter;

import React, { useEffect, useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
} from "reactstrap";
import { StudentListGetRequest, studentCreateRequest } from "store/student/action";
import { ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import QuickForm from "Components/Common/FormBuilder/QuickForm";
import { SourceListGetRequest } from "store/sources/action";    
import { useSelector } from "react-redux";
import { ApplicationState } from "store";

const AddEntry = ({ props, show, onCloseClick, dataFields, setTriggerApi }: any) => {    
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const sourcesList = useSelector((state: ApplicationState) => state.sources.sources) 
    const [APAId, SetAPAId] = useState("c017ce92-15ae-47b1-9ab1-197895ce94d6")   
    const isJson = (str: any) => {
        try {
            let options = JSON.parse(str);
            return options
        } catch (e) {
            return false;
        }
    }
  
    
    const OnAddEntry = (formValues: any) => {
        let data: any = formValues;
        if(formValues.subagent) {
            data = {...data, source: APAId}
        }
        console.log("data", data);
        const handleSuccess = (body: any) => {
            setTriggerApi((triggerApi: boolean) => !triggerApi)
            onCloseClick()
        }
        dispatch(studentCreateRequest(data, handleSuccess , () => {}))
    }

    useEffect(() => {
        dispatch(SourceListGetRequest({PageSize: 500}));
    }, [])

    return (
        <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            toggle={onCloseClick}            
            backdrop={false}
        >
            
            <OffcanvasHeader className="bg-light" toggle={() =>{onCloseClick(!show)}}>
                {props.t("student.add_new_entry")}
            </OffcanvasHeader>
                <OffcanvasBody>
                    <Card>
                        <CardBody>
                            <div className="live-preview vstack gap-2">
                                <QuickForm dataFields={dataFields} btnAction={OnAddEntry}  btn_text={'Add Entry'} formfor="students" APAId={APAId} />
                            </div>
                        </CardBody>
                    </Card>                    
                </OffcanvasBody>
        </Offcanvas>
    );
};

export default AddEntry;

